import {
  GET_LEM_REPORTS,
  SET_LEM_REPORTS,
  SET_LEM_REPORT_FAILURE,
} from '../types';

const getLemReports = (projectNo, page, limit, signed, startDate, endDate) => ({
  type: GET_LEM_REPORTS,
  projectNo,
  signed,
  startDate,
  endDate,
  page,
  limit
});

const setLemReports = (payload) => ({
  type: SET_LEM_REPORTS, ...payload,
});

const setLemReportFailure = () => ({
  type: SET_LEM_REPORT_FAILURE,
});

export {
  getLemReports,
  setLemReports,
  setLemReportFailure,
};
