import React, { useEffect, useState } from 'react';
import {
  Select, Form, Divider, Input, Space, Button, notification, Tooltip
} from 'antd';
import {
  func, arrayOf, objectOf, oneOfType, string, number,
  array
} from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { CheckOutlined } from '@ant-design/icons';
import { getMachineType } from '../state/action/advisoryAction';
import { addMachineTypes } from '../services/advisory';
import { trimSpacesInValues } from '../utils/utility';

const { Option } = Select;

function MachineTypeSelect(props) {
  const {
    onGetMachineType, data, placeholder, formName, type, machineSubTypes,
    disabled
  } = props;

  const [optionsList, setoptionList] = useState([]);
  const [name, setName] = useState('');

  useEffect(() => {
    onGetMachineType(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    const optionListData = type ? machineSubTypes : data;
    setoptionList(optionListData);
  }, [type, machineSubTypes, data]);

  const addItem = async () => {
    if (name) {
      const newName = trimSpacesInValues(name);
      const isNameAlreadyExists = optionsList.find((
        item
      ) => item.name.toLowerCase() === newName.toLowerCase());
      if (isNameAlreadyExists) {
        return notification.error({
          message: 'Type already exists.',
        });
      }
      const typeData = {
        types: [newName]
      };
      if (type) typeData.parent_id = type;
      await addMachineTypes(typeData);
      onGetMachineType(type);
      setName('');
    }
    return '';
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };
  return (
    <Form.Item
      name={formName}
      rules={[{
        required: true,
        message: 'Please provide a type'
      }]}
    >
      <Select
        disabled={disabled}
        className="select-dropdown-signup me-2"
        placeholder={placeholder}
        showArrow
        allowClear
        showSearch
        autoClearSearchValue
        optionFilterProp="children"
        filterOption={(input, option) => option.props.children.props.title.toLowerCase()
          .includes(input.toLowerCase())}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Space style={{ padding: '0 8px 4px' }}>
              <Input
                placeholder="Add new item"
                onChange={onNameChange}
                value={name}
                maxLength={50}
              />
              <Button disabled={!name} type="text" icon={<CheckOutlined />} onClick={addItem}>
                Add item
              </Button>
            </Space>
          </>
        )}
      >
        {
          optionsList.length > 0
            ? optionsList.map((item) => (
              <Option key={item.id} value={item.id}>
                <Tooltip title={item.name}>{item.name}</Tooltip>
              </Option>
            ))
            : null
        }
      </Select>
    </Form.Item>
  );
}

MachineTypeSelect.propTypes = {
  onGetMachineType: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired
};

const mapStateToProps = ({ advisoryData }) => {
  const data = get(advisoryData, 'machineTypes', []);
  const machineSubTypes = get(advisoryData, 'machineSubTypes', []);
  const showLoader = get(advisoryData, 'isLoading', false);
  const total = get(advisoryData, 'total', 0);
  return {
    data,
    showLoader,
    total,
    machineSubTypes
  };
};

const mapDispatchToProps = {
  onGetMachineType: getMachineType
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineTypeSelect);
