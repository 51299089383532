/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.dark.css';
import {
  Button, Col, Row, Table, Tabs, Tooltip, notification
} from 'antd';
import { useHistory } from 'react-router-dom';
import {
  ReloadOutlined
} from '@ant-design/icons';
import ComingSoon from '../../Components/Comingsoon';
import AnalysisPage from '../../Components/Analysis';
import { authenticateCurrentUser } from '../../services/auth';

const { TabPane } = Tabs;

const Analysis = () => {
  const history = useHistory();

  const [reload,setReload] = useState(false);
  const [group, setGroup] = useState(false);

  return (
    <div className="h-100 d-flex flex-column">
      <div className="flex-auto">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="project-title ">Project Snapshot</h1>
          <div className="search-container d-flex">
            <Tooltip placement="topLeft" title="Reload">
              <Button
                className="refresh"
                shape="circle"
                onClick={() => {
                  if (!reload) {
                    setReload(true);
                  } else {
                    setReload(false);
                  }
                }}
                disabled={!group}
              >
                <ReloadOutlined />
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="flex-fill overflow-auto">
        <AnalysisPage reload={reload} group={group} setGroup={setGroup} />
      </div>
    </div>
  );
};

export default Analysis;
