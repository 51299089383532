/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
import React from 'react';
import {
  Modal, Input, Form, Spin
} from 'antd';
import * as utility from '../utils/utility';

const NewMessageModal = (props) => {
  const {
    isModalVisible, setIsModalVisible, messageForm, onSave, messageModalLoading, receiverName
  } = props;
  const handleOk = () => {
    messageForm
      .validateFields()
      .then((data) => {
        onSave(data);
      });
  };

  const handleCancel = () => {
    messageForm.resetFields();
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={`New Message to ${receiverName}`}
        className="quality-modal"
        centered
        visible={isModalVisible}
        onOk={() => handleOk(false)}
        okButtonProps={{ disabled: messageModalLoading }}
        cancelButtonProps={{ disabled: messageModalLoading }}
        okText="Send"
        onCancel={() => handleCancel(false)}
      >
        <Form
          form={messageForm}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: 'Please enter a Title',
              }
            ]}
            normalize={(value) => utility.trimSpacesInValues(value)}
          >
            <div>
              <Input.TextArea
                rows={1}
                placeholder="Message Title"
                className="textarea mb-3"
                maxLength={255}
                showCount
              />
            </div>
          </Form.Item>
          <Form.Item
            name="message"
            label="Message"
            rules={[
              {
                required: true,
                message: 'Please enter a Message',
              }
            ]}
            normalize={(value) => utility.trimSpacesInValues(value)}
          >
            <div>
              <Input.TextArea
                rows={4}
                placeholder="Message Text"
                className="textarea mb-3"
                maxLength={1000}
                showCount
              />
            </div>
          </Form.Item>
          <Form.Item name="toUserId" style={{ display: 'none' }} />
          <Form.Item name="toUserName" style={{ display: 'none' }} />
        </Form>
        {messageModalLoading ? <Spin /> : null}
      </Modal>
    </>
  );
};

export default NewMessageModal;
