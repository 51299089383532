import React from 'react';
import {
  object, oneOfType, objectOf, string, symbol, func,
} from 'prop-types';
import logo from '../fsa_logo.jpg';
import 'antd/dist/antd.dark.css';

const headerTitle = 'Stanley Oil & Gas Field Service';

const DefaultLayout = (props) => {
  const { children } = props;
  return (
    <div>
      <header className="App-header">
        <div className="App-img">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div className="App-header-title">
          <h1 className="App-title">{headerTitle}</h1>
        </div>
      </header>
      {children}
    </div>
  );
};

DefaultLayout.propTypes = {
  children: objectOf(oneOfType([object, string, symbol, func])),
};

DefaultLayout.defaultProps = {
  children: {},
};

export default DefaultLayout;
