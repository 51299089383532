import moment from 'moment';
import range from 'lodash/range';

const PERIOD_START_DATE = 21;
const PERIOD_END_DATE = 20;
const MAX_DAYS_IN_PERIOD = 31;

export const periodFromDate = (date) => {
  let d = moment.utc(date);
  if (d.date() >= PERIOD_START_DATE) {
    d = d.add(1, 'month');
  }
  return d.format('YYYY-MM');
};

export const calcReportingPeriod = (period) => {
  const lastMonth = moment.utc(period).subtract(1, 'month').format('YYYY-MM');
  const month1 = `${lastMonth}-${PERIOD_START_DATE}`;
  const month2 = `${period}-${PERIOD_END_DATE}`;
  return `${month1} - ${month2}`;
};

export const calcReportingPeriodMonth = (period) => {
  const month = moment(period, 'YYYY-MM');
  const start = month.startOf('month').format('YYYY-MM-DD');
  const end = month.endOf('month').format('YYYY-MM-DD');
  return `${start} - ${end}`;
};
export const datesInPeriod = (period) => {
  const lastMonth = moment.utc(period).subtract(1, 'month');
  const dates = range(PERIOD_START_DATE, PERIOD_START_DATE + MAX_DAYS_IN_PERIOD + 1)
    .map((n) => moment.utc(lastMonth).date(n).format('YYYY-MM-DD'))
    .filter((d) => periodFromDate(d) === period);
  return dates;
};
export const datesInPeriodForEmea = (period) => {
  const startOfMonth = moment(period, 'YYYY-MM').startOf('month');
  const endOfMonth = moment(period, 'YYYY-MM').endOf('month');

  const dates = [];
  let currentDate = startOfMonth;

  while (currentDate <= endOfMonth) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }

  return dates;
};

export const getMonthFromPeriod = (period) => {
  const month = moment(period).startOf('month').format('MMMM YYYY');
  return month;
};

export const calcDowntime = (time) => {
  let downTime = '';
  if (time != null) {
    const duration = moment.duration(time);
    // Extract hours and minutes
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    if (hours > 0 && minutes > 0) {
      downTime = `${hours} hours ${minutes} mins`;
    } else if (hours > 0) {
      downTime = `${hours} hours`;
    } else if (minutes > 0) {
      downTime = `${minutes} mins`;
    } else {
      downTime = '0 mins';
    }
  } else {
    downTime = '0 mins';
  }
  return downTime;
};
