import React from 'react';
import { Provider } from 'react-redux';
import Amplify from '@aws-amplify/core';
import configureStore from './state/store';
import AppContainer from './routes';
import config from './config';

Amplify.configure(config);
const store = configureStore();

const App = () => (
  <Provider store={store}>
    <AppContainer />
  </Provider>
);
export default App;
