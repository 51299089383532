import get from 'lodash/get';
import moment from 'moment';
import * as constants from '../../utils/constants';
import {
  GET_WIRE_USAGE_REPORTS, SET_WIRE_USAGE_REPORTS, SET_WIRE_USAGE_REPORTS_FAILURE
} from '../types';

const initialState = {
  isLoading: false,
};

const wireUsageReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WIRE_USAGE_REPORTS: {
      const wuReports = get(action, ['response'], []);
      const report = wuReports.data;
      const data = [];
      for (let i = 0; i < report.length; i += 1) {
        let date;
        if (wuReports?.data[i]?.createdAt) {
          date = moment(wuReports?.data[i]?.createdAt).format(constants.DATETIMESEC);
        } else {
          date = null;
        }
        data[i] = {
          key: i,
          size: wuReports?.data[i]?.size,
          heat: wuReports?.data[i]?.heat,
          total: wuReports?.data[i]?.total_sent,
          inventory: wuReports?.data[i]?.inventory,
          usage: wuReports?.data[i]?.usage,
          month: null,
          report: wuReports?.data[i]?.reportUrl,
          pdfReport: wuReports?.data[i]?.wireReportUrl,
          createdAt: date
        };
      }
      return {
        ...state,
        data,
        isLoading: false,
      };
    }

    case GET_WIRE_USAGE_REPORTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_WIRE_USAGE_REPORTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default wireUsageReportReducer;
