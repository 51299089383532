/* eslint-disable no-nested-ternary */
/* eslint-disable no-const-assign */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable dot-notation */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  func, arrayOf, objectOf, oneOfType, string, number,
  array, bool
} from 'prop-types';
import 'antd/dist/antd.dark.css';
import {
  Button, Table, Form, Modal, Spin, notification,Tooltip
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  CheckOutlined
} from '@ant-design/icons';
import AddEditEmailModal from './AddEditEmailModal';
import { getEmails } from '../state/action/emailConfigAction';
import { addEmail,removeEmail,updateEmail } from '../services/emailConfig';

const ReportingEmailPage = ({
  showLoader, onGetEmails, data, projectNumber, isOnshoreEmea, isOffshore
}) => {
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [emailModalLoading, setEmailModalLoading] = useState(false);
  const [isEdit,setIsEdit] = useState(false);
  const [emailData, setEmailData] = useState([]);

  const { Column, ColumnGroup } = Table;

  const [emailForm] = Form.useForm();

  const onEmailSave = async (emailId, reportType) => {
    setEmailModalLoading(true);
    const value = {
      emailAddress: emailId,
      type: reportType
    };
    if (isOnshoreEmea || isOffshore) value.project_number = projectNumber;
    const res = await addEmail(value);
    setEmailModalLoading(false);
    if (res) {
      notification.success({
        message: 'Email added successfully',
      });
      setEmailModalVisible(false);
      emailForm.resetFields();
      setIsEdit(false);
      onGetEmails(projectNumber);
    } else if (!isEdit) {
      notification.error({
        message: 'Email already exist',
        duration: 1.2,
      });
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
      setEmailModalVisible(false);
      emailForm.resetFields();
      setIsEdit(false);
    }
  };

  const onEmailUpdate = async (emailId,emailIdOld,reportType) => {
    setEmailModalLoading(true);
    const value = {
      emailAddress: emailId,
      emailAddressOld: emailIdOld,
      type: reportType
    };
    if (isOnshoreEmea || isOffshore) value.project_number = projectNumber;

    const res = await updateEmail(value);
    setEmailModalLoading(false);
    if (res) {
      notification.success({
        message: 'Email updated successfully',
      });
      setEmailModalVisible(false);
      setIsEdit(false);
      emailForm.resetFields();
      onGetEmails(projectNumber);
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  useEffect(() => {
    onGetEmails(projectNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[projectNumber]);

  const showAddEditEmailModal = (record,edit) => {
    let emailDt = {};
    if (record !== '') {
      emailDt = {
        email: record.email,
        emailOld: record.email,
        reportEmailAccess: record.reportEmailAccess
      };
      emailForm.setFieldsValue(emailDt);
    }

    setIsEdit(edit);

    setEmailModalVisible(true);
  };

  const handleDeletion = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this email?',
      className: 'custom-text-clr',
      async onOk() {
        const value = { emailAddress: record.email };
        if (isOnshoreEmea || isOffshore) value.project_number = projectNumber;

        return removeEmail(value).then((res) => {
          if (res) {
            notification.success({
              message: 'Email deleted successfully',
            });
            onGetEmails(projectNumber);
          } else {
            notification.error({
              message: 'Something went wrong.',
            });
          }
          return true;
        }).catch(() => notification.error({
          message: 'Something went wrong.',
        }));
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    let email = [];
    const emailId = [];
    let emailDetail = {};
    let type = [];
    data.map((dts) => {
      Object.keys(dts).map(() => {
        if (!emailId.includes(dts.email)) {
          type = [];
          emailDetail = {};
          emailId.push(dts.email);
          type.push(dts.reportType);
          emailDetail.email = dts.email;
          emailDetail.reportEmailAccess = type;
          email.push(emailDetail);
        } else {
          if (!type.includes(dts.reportType)) {
            type.push(dts.reportType);
          }
          delete emailDetail['reportEmailAccess'];
          delete emailDetail['email'];
          emailDetail.email = dts.email;
          emailDetail.reportEmailAccess = type;
          email.pop();
          email.push(emailDetail);
        }
      });
    });
    setEmailData(email);
  },[data]);

  const xScroll = emailData.length > 0 ? 1700 : 0;
  return (
    <>
      <div className="h-100 overflow-y-hidden overflow-x-auto">
        <Table
          scroll={{ x: isOnshoreEmea ? 1700 : isOffshore ? 0 : xScroll, y: '100%' }}
          pagination={false}
          dataSource={emailData && emailData}
          className="table-with-button group-table-title"
          loading={{
            indicator: <Spin />,
            spinning: showLoader
          }}
        >
          <ColumnGroup title="">
            <Column title="Email" dataIndex="email" key="email" align="left" width="300px" />
          </ColumnGroup>
          {(isOnshoreEmea || !isOffshore) && (
          <ColumnGroup title="Reports" align="center" className="tableGroupReport">
            <Column
              title="Production"
              dataIndex=""
              key=""
              align="center"
              render={(reportEmailAccess) => (
                <span>
                  {Object.values(reportEmailAccess).map((val) => {
                    if (typeof typeof val !== 'string' !== 'string' && val.includes('ProductionLogReport')) {
                      return (
                        <CheckOutlined />
                      );
                    }
                  })}
                </span>
              )}
            />
            <Column
              title="Tech"
              dataIndex=""
              key=""
              align="center"
              render={(reportEmailAccess) => (
                <span>
                  {Object.values(reportEmailAccess).map((val) => {
                    if (typeof typeof val !== 'string' !== 'string' && val.includes('TechLogReport')) {
                      return (
                        <CheckOutlined />
                      );
                    }
                  })}
                </span>
              )}
            />
            <Column
              title="Quality"
              dataIndex=""
              key=""
              align="center"
              render={(reportEmailAccess) => (
                <span>
                  {Object.values(reportEmailAccess).map((val) => {
                    if (typeof typeof val !== 'string' !== 'string' && val.includes('QualityReport')) {
                      return (
                        <CheckOutlined />
                      );
                    }
                  })}
                </span>
              )}
            />
            <Column
              title="Property Damage"
              dataIndex=""
              key=""
              align="center"
              render={(reportEmailAccess) => (
                <span>
                  {Object.values(reportEmailAccess).map((val) => {
                    if (typeof typeof val !== 'string' !== 'string' && val.includes('PropertyDamageReport')) {
                      return (
                        <CheckOutlined />
                      );
                    }
                  })}
                </span>
              )}
            />
            <Column
              title="Parts Ordering"
              dataIndex=""
              key=""
              align="center"
              render={(reportEmailAccess) => (
                <span>
                  {Object.values(reportEmailAccess).map((val) => {
                    if (typeof typeof val !== 'string' !== 'string' && val.includes('PartsRequestReport')) {
                      return (
                        <CheckOutlined />
                      );
                    }
                  })}
                </span>
              )}
            />
            <Column
              title="Wire Usage"
              dataIndex=""
              key=""
              align="center"
              render={(reportEmailAccess) => (
                <span>
                  {Object.values(reportEmailAccess).map((val) => {
                    if (typeof typeof val !== 'string' !== 'string' && val.includes('WireUsageReport')) {
                      return (
                        <CheckOutlined />
                      );
                    }
                  })}
                </span>
              )}
            />
            {isOnshoreEmea ? (
              <Column
                title="Timesheet"
                dataIndex=""
                key=""
                align="center"
                render={(reportEmailAccess) => (
                  <span>
                    {Object.values(reportEmailAccess).map((val) => {
                      if (typeof typeof val !== 'string' !== 'string' && val.includes('TimesheetReport')) {
                        return (
                          <CheckOutlined />
                        );
                      }
                    })}
                  </span>
                )}
              />
            ) : (
              <Column
                title="LEM"
                dataIndex=""
                key=""
                align="center"
                render={(reportEmailAccess) => (
                  <span>
                    {Object.values(reportEmailAccess).map((val) => {
                      if (typeof typeof val !== 'string' !== 'string' && val.includes('LEMReport')) {
                        return (
                          <CheckOutlined />
                        );
                      }
                    })}
                  </span>
                )}
              />
            )}
            <Column
              title="Inventory"
              dataIndex=""
              key=""
              align="center"
              render={(reportEmailAccess) => (
                <span>
                  {Object.values(reportEmailAccess).map((val) => {
                    if (typeof typeof val !== 'string' !== 'string' && val.includes('CheckoutInventoryReport')) {
                      return (
                        <CheckOutlined />
                      );
                    }
                  })}
                </span>
              )}
            />
          </ColumnGroup>
          )}
          {' '}
          {(isOnshoreEmea || !isOffshore) && (
          <ColumnGroup>
            <Column
              title="Project Pictures"
              dataIndex=""
              key=""
              align="center"
              render={(reportEmailAccess) => (
                <span>
                  {Object.values(reportEmailAccess).map((val) => {
                    if (typeof typeof val !== 'string' !== 'string' && val.includes('ProjectPic')) {
                      return (
                        <CheckOutlined />
                      );
                    }
                  })}
                </span>
              )}
            />
          </ColumnGroup>
          )}
          {isOffshore && (
          <ColumnGroup title="Reports" align="center" className="tableGroupReport">
            <Column
              title="DPR"
              dataIndex=""
              key=""
              align="center"
              render={(reportEmailAccess) => (
                <span>
                  {Object.values(reportEmailAccess).map((val) => {
                    if (typeof typeof val !== 'string' !== 'string' && val.includes('DPRReport')) {
                      return (
                        <CheckOutlined />
                      );
                    }
                  })}
                </span>
              )}
            />
          </ColumnGroup>
          )}
          <ColumnGroup>
            {(!isOnshoreEmea && !isOffshore) && (
            <Column
              title="Daily Status"
              dataIndex=""
              key=""
              align="center"
              render={(reportEmailAccess) => (
                <span>
                  {Object.values(reportEmailAccess).map((val) => {
                    if (typeof typeof val !== 'string' !== 'string' && val.includes('DailyStatusReport')) {
                      return (
                        <CheckOutlined />
                      );
                    }
                  })}
                </span>
              )}
            />
            )}
          </ColumnGroup>
          <ColumnGroup>
            <Column
              title=""
              render={(record) => {
                if (record) {
                  return (
                    <div>
                      <Tooltip
                        arrowPointAtCenter
                        placement="topRight"
                        title="Edit Email"
                      >
                        <EditOutlined style={{ marginRight: '30px', fontSize: '16px' }} onClick={() => showAddEditEmailModal(record,true)} />
                      </Tooltip>
                      <Tooltip
                        arrowPointAtCenter
                        placement="topLeft"
                        title="Delete Email"
                      >
                        <DeleteOutlined style={{ marginRight: '30px', fontSize: '16px' }} onClick={() => handleDeletion(record)} />
                      </Tooltip>
                    </div>
                  );
                  // eslint-disable-next-line brace-style
                }
                // eslint-disable-next-line no-else-return
                else {
                  return <div>-</div>;
                }
              }}
              align="right"
            />
          </ColumnGroup>

        </Table>
        <div className="text-right mb-2">
          <Button
            key="submit"
            size="medium"
            shape="round"
            type="primary"
            disabled={showLoader}
            onClick={() => showAddEditEmailModal('',false)}
          >
            Add Email
          </Button>
        </div>
        {emailModalVisible
          ? (
            <AddEditEmailModal
              setEmailModalVisible={setEmailModalVisible}
              emailModalVisible={emailModalVisible}
              setEmailModalLoading={setEmailModalLoading}
              emailModalLoading={emailModalLoading}
              emailForm={emailForm}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              onSave={onEmailSave}
              onUpdate={onEmailUpdate}
              isOnshoreEmea={isOnshoreEmea}
              isOffshore={isOffshore}
            />
          )
          : null }
      </div>
    </>

  );
};
ReportingEmailPage.propTypes = {
  onGetEmails: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showLoader: bool.isRequired,
};

const mapStateToProps = ({ emailRequests }) => {
  const data = get(emailRequests, 'data', []);
  const showLoader = get(emailRequests, 'isLoading', false);
  return {
    data,
    showLoader
  };
};

const mapDispatchToProps = {
  onGetEmails: getEmails
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportingEmailPage);
