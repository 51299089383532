/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  EditOutlined,
} from '@ant-design/icons';
import {
  func,
  arrayOf,
  objectOf,
  oneOfType,
  string,
  number,
  array,
  bool,
} from 'prop-types';
import {
  Table,
  Spin,
  Button,
  Tooltip,
  Modal,
  notification,
  Form,
  Collapse,
  Input,
} from 'antd';
import AddAssetModal from './AddAssetModal';
import { DeleteIcon } from './svgs/deleteIcon';
import { getProjectAssets } from '../state/action/projectAssetAction';
import {
  addProjectAssets,
  removeProjectAsset,
  updateProjectAssets,
} from '../services/projectAsset';
import { getAwsConfigData } from '../services/awsConfigData';
import EditAssetStatusModal from './EditAssetStatusModal';

const Assets = ({
  projNo,
  projectStatus,
  reload,
  refresh,
  onGetProjectAssets,
  assets,
  setAssets,
  assetsData,
  showAssetLoader,
  projectType
}) => {
  const [assetData, setAssetData] = useState([]);
  const [awsConfigDt, setAwsConfigDt] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);
  const [assetModalLoading, setAssetModalLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [onsiteAssetData, setOnsiteAssetData] = useState([]);
  const [inUseAssetData, setInUseAssetData] = useState([]);
  const [spareAssetData, setSpareAssetData] = useState([]);
  const [sentToTulsaAssetData, setSentToTulsaAssetData] = useState([]);
  const [sentToEdmontonAssetData, setSentToEdmontonAssetData] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [selectedAssetsSerial, setSelectedAssetsSerial] = useState([]);
  const [selectedAssetRows, setSelectedAssetRows] = useState([]);
  const [isStatusEdit, setIsStatusEdit] = useState([]);
  const [assetStatusModalLoading, setAssetStatusModalLoading] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState([]);
  const [assetStatusLength, setAssetStatusLength] = useState(1);

  const [addAssetForm] = Form.useForm();
  const [updateAssetStatusForm] = Form.useForm();
  const { Panel } = Collapse;
  const { Search } = Input;

  const onAssetSave = async (filename) => {
    const res = await addProjectAssets(projNo, filename);
    setAssetModalLoading(false);
    if (res) {
      setIsModalVisible(false);
      addAssetForm.resetFields();
      setIsEdit(false);
      onGetProjectAssets({ projectNo: projNo });
      notification.success({
        message: 'Assets Added',
        duration: 1.5
      });
    }
  };

  const getAwsConfigDatas = async () => {
    const awsConfigData = await getAwsConfigData();
    setAwsConfigDt(awsConfigData.data);
  };

  useEffect(() => {
    refresh(false);
    if (!reload) {
      if (projNo !== '') {
        setSelectedAssetRows([]);
        setSelectedRowKey([]);
        onGetProjectAssets({ projectNo: projNo });
        getAwsConfigDatas();
      } else {
        setAssetData([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo]);

  const handleDeletion = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this asset from the project?',
      className: 'custom-text-clr',
      async onOk() {
        return removeProjectAsset(projNo, record.projectAssetid)
          .then((res) => {
            if (res) {
              notification.success({
                message: 'Asset Deleted',
              });
              onGetProjectAssets({ projectNo: projNo });
            } else {
              notification.error({
                message: 'Something went wrong.',
              });
            }
            return true;
          })
          .catch(() => notification.error({
            message: 'Something went wrong.',
          }));
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    if (!reload && projNo !== '') {
      const assetDt = [];
      const onsiteasset = [];
      const inuseasset = [];
      const spareasset = [];
      const senttotulsaasset = [];
      const senttoedmondonasset = [];
      assetsData.forEach((dt) => {
        if (dt.status && dt.status === 'Onsite') {
          onsiteasset.push(dt);
        } else if (dt.status && dt.status === 'In Use') {
          inuseasset.push(dt);
        } else if (dt.status && dt.status === 'Spare') {
          spareasset.push(dt);
        } else if (dt.status && dt.status === 'Sent to Tulsa') {
          senttotulsaasset.push(dt);
        } else if (dt.status && dt.status === 'Sent to Edmonton') {
          senttoedmondonasset.push(dt);
        } else if (dt.status === null) {
          onsiteasset.push(dt);
        }
        assetDt.push(dt);
      });
      setAssetData(assetDt);
      setAssets(assetDt);
      setOnsiteAssetData(onsiteasset);
      setInUseAssetData(inuseasset);
      setSpareAssetData(spareasset);
      setSentToTulsaAssetData(senttotulsaasset);
      setSentToEdmontonAssetData(senttoedmondonasset);
    } else {
      setAssetData(assets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsData]);

  const onAssetUpdate = async (
    desc,
    serialNumber,
    projectAssetId,
    assetStatus,
    orderQuantity
  ) => {
    if (projectAssetId) {
      setAssetModalLoading(true);
    } else {
      setAssetStatusModalLoading(true);
    }

    const value = {
      serial_number: serialNumber || selectedAssetsSerial,
      material_description: desc || '',
      project_asset_id: projectAssetId || selectedAssets,
    };
    if (assetStatus) value.asset_status = assetStatus;
    if (orderQuantity) value.order_quantity = orderQuantity;
    const res = await updateProjectAssets(projNo, value, projectAssetId);
    setAssetModalLoading(false);
    setAssetStatusModalLoading(false);
    if (res) {
      setIsModalVisible(false);
      setIsStatusModalVisible(false);
      setIsEdit(false);
      setIsStatusEdit(false);
      setSelectedAssetRows([]);
      setSelectedRowKey([]);
      onGetProjectAssets({ projectNo: projNo });
      if (projectAssetId) {
        notification.success({
          message: 'Asset Updated',
          duration: 1.5,
        });
      } else {
        notification.success({
          message: 'Status Updated',
          duration: 1.5,
        });
      }

      addAssetForm.resetFields();
    } else {
      notification.error({
        message: `Asset already exist with asset number : ${serialNumber}`,
        duration: 1.2,
      });
    }
  };

  const showAssetStatusModal = async () => {
    const assetIds = [];
    const assetSerialNums = [];
    let assetStatus = '';
    selectedAssetRows.forEach((dt) => {
      assetIds.push(dt.projectAssetid);
      assetSerialNums.push(dt.serialNo);
      assetStatus = dt.status;
    });
    setSelectedAssets(assetIds);
    setSelectedAssetsSerial(assetSerialNums);
    let assetDatas = {};
    assetDatas = {
      status: assetStatus,
    };
    updateAssetStatusForm.setFieldsValue(assetDatas);
    setIsStatusEdit(true);
    setIsStatusModalVisible(true);
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedRowKey,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedAssetRows(selectedRows);
      setSelectedRowKey(selectedRowKeys);
    },
  };

  const searchAsset = (value) => {
    const assetDt = [];
    const assetStatus = [];
    const onsiteasset = [];
    const inuseasset = [];
    const spareasset = [];
    const senttotulsaasset = [];
    const senttoedmondonasset = [];
    assetsData.forEach((dt) => {
      if (
        dt.serialNo.toLowerCase().includes(value.toLowerCase())
        || (dt.materialDescription && dt.materialDescription.toLowerCase().includes(value.toLowerCase()))
      ) {
        if (!assetStatus.includes(dt.status)) {
          assetStatus.push(dt.status);
        }
        if (dt.status && dt.status === 'Onsite') {
          onsiteasset.push(dt);
        } else if (dt.status && dt.status === 'In Use') {
          inuseasset.push(dt);
        } else if (dt.status && dt.status === 'Spare') {
          spareasset.push(dt);
        } else if (dt.status && dt.status === 'Sent to Tulsa') {
          senttotulsaasset.push(dt);
        } else if (dt.status && dt.status === 'Sent to Edmonton') {
          senttoedmondonasset.push(dt);
        } else if (dt.status === null) {
          onsiteasset.push(dt);
        }
        assetDt.push(dt);
      }
    });
    setAssetData(assetDt);
    setAssets(assetDt);
    setOnsiteAssetData(onsiteasset);
    setInUseAssetData(inuseasset);
    setSpareAssetData(spareasset);
    setSentToTulsaAssetData(senttotulsaasset);
    setSentToEdmontonAssetData(senttoedmondonasset);
    setAssetStatusLength(assetStatus.length);
  };

  const editBtnHandler = (record) => {
    setIsEdit(true);
    setIsModalVisible(true);
    addAssetForm.setFieldsValue({
      description: record?.materialDescription,
      serial_number: record?.serialNo,
      project_asset_id: record?.projectAssetid,
      order_quantity: record?.orderQuantity
    });
  };

  const columns = [
    {
      title: 'Asset Number',
      dataIndex: 'serialNo',
      key: 'serialNo',
      align: 'center',
    },
    {
      title: 'Description',
      dataIndex: 'materialDescription',
      key: 'materialDescription',
      align: 'center',
    },
    {
      title: 'Order Quantity',
      dataIndex: 'orderQuantity',
      key: 'orderQuantity',
      align: 'center',
    },
    {
      title: '',
      render: (record) => {
        if (record) {
          return (
            <div>
              <Tooltip
                arrowPointAtCenter
                placement="topLeft"
                title="Update Asset"
              >
                <EditOutlined
                  className="edit-project-detail"
                  onClick={() => {
                    editBtnHandler(record);
                  }}
                />

              </Tooltip>
            </div>
          );
        }
        return <div>-</div>;
      },
      align: 'right',
    },
    {
      title: '',
      render: (record) => {
        if (record) {
          return (
            <div>
              <Tooltip
                arrowPointAtCenter
                placement="topLeft"
                title={
                  projectStatus === 'Archived'
                    ? 'Cannot Delete Assets from Archived Project'
                    : 'Delete Asset'
                }
              >
                {projectStatus === 'Archived' ? (
                  <DeleteIcon
                    style={{ marginRight: '30px', fontSize: '16px' }}
                  />
                ) : (
                  <DeleteIcon
                    style={{ marginRight: '30px', fontSize: '16px' }}
                    onClick={() => handleDeletion(record)}
                  />
                )}
              </Tooltip>
            </div>
          );
          // eslint-disable-next-line brace-style
        }
        // eslint-disable-next-line no-else-return
        else {
          return <div>-</div>;
        }
      },
      align: 'right',
    },
  ];

  const getOnsiteHeader = (className, dataLength) => (
    <div className="d-flex align-items-center">
      Onsite
      {' '}
      <span className={className} />
      {' '}
      (
      {dataLength}
      )
    </div>
  );

  const onAssetChange = (event) => {
    if (!event.target.value) {
      const assetDt = [];
      const onsiteasset = [];
      const inuseasset = [];
      const spareasset = [];
      const senttotulsaasset = [];
      const senttoedmondonasset = [];
      assetsData.forEach((dt) => {
        if (dt.status && dt.status === 'Onsite') {
          onsiteasset.push(dt);
        } else if (dt.status && dt.status === 'In Use') {
          inuseasset.push(dt);
        } else if (dt.status && dt.status === 'Spare') {
          spareasset.push(dt);
        } else if (dt.status && dt.status === 'Sent to Tulsa') {
          senttotulsaasset.push(dt);
        } else if (dt.status && dt.status === 'Sent to Edmonton') {
          senttoedmondonasset.push(dt);
        } else if (dt.status === null) {
          onsiteasset.push(dt);
        }
        assetDt.push(dt);
      });
      setAssetData(assetDt);
      setAssets(assetDt);
      setOnsiteAssetData(onsiteasset);
      setInUseAssetData(inuseasset);
      setSpareAssetData(spareasset);
      setSentToTulsaAssetData(senttotulsaasset);
      setSentToEdmontonAssetData(senttoedmondonasset);
      setAssetStatusLength(1);
    }
  };
  return (
    <div className=" h-100 assetSpin">
      <Spin spinning={showAssetLoader} className="h-100 ">
        <>
          <div
            className="d-flex pb-2 align-items-center justify-content-between"
            style={{ height: '49px' }}
          >
            <h3>
              Total Number of Assets :
              {' '}
              {assetData.length > 0 ? assetData.length : 0}
            </h3>
            {' '}
            <div
              className="d-flex asset-search justify-content-center mb-3"
              // style={{ marginTop: '29px', marginLeft: '585px' }}
            >
              <Search
                // size='def'
                className="sbd-search-wrapper"
                placeholder="Enter Asset No./Desc "
                allowClear
                enterButton
                onSearch={searchAsset}
                disabled={assetsData && assetsData.length === 0}
                onChange={onAssetChange}
              />
              {projNo !== '' ? (
                <>
                  {selectedAssetRows && selectedAssetRows.length > 0 && (
                    <Button
                      key="submit"
                      shape="round"
                      type="primary"
                      className="ms-3"
                      // style={{ 'margin-right': '1.1rem', height: '32px' }}
                      onClick={() => showAssetStatusModal()}
                    >
                      Edit Status
                    </Button>
                  )}

                  <Tooltip
                    placement="topLeft"
                    title={
                      projectStatus === 'Archived'
                        ? 'Cannot Add Assets to Archived Project'
                        : ''
                    }
                  >
                    <Button
                      key="submit"
                      shape="round"
                      type="primary"
                      className="ms-3"
                      disabled={projectStatus === 'Archived' || showAssetLoader}
                      onClick={() => setIsModalVisible(true)}
                      // style={{ height: '32px' }}
                    >
                      Add Assets
                    </Button>
                  </Tooltip>
                </>
              ) : null}
            </div>
          </div>

          <div className="sbd-assets overflow-y-auto overflow-x-auto">
            {assetData && assetData.length > 0 ? (
              <Collapse
                accordion
                bordered={false}
                className={assetStatusLength === 1 ? 'asset-collapse-single' : 'asset-collapse'}
                onChange={() => {
                  setSelectedAssetRows([]);
                  setSelectedRowKey([]);
                }}
              >
                {onsiteAssetData && onsiteAssetData.length && (
                  <Panel header={getOnsiteHeader('green_dot ms-1 me-1', onsiteAssetData.length)} className="project-sub-title">
                    <Table
                      rowSelection={{
                        ...rowSelection,
                      }}
                      pagination={false}
                      columns={columns}
                      dataSource={onsiteAssetData && onsiteAssetData}
                      className="table-with-button-assets asset-table"
                      style={{ height: '100%' }}
                      scroll={{
                        x: onsiteAssetData.length > 0 ? 600 : 0,
                        y: '100%',
                      }}
                    />
                  </Panel>
                )}
                {inUseAssetData && inUseAssetData.length && (
                  <Panel header={getOnsiteHeader('red_dot ms-1 me-1', inUseAssetData.length)} className="project-sub-title">
                    <Table
                      rowSelection={{
                        ...rowSelection,
                      }}
                      pagination={false}
                      columns={columns}
                      dataSource={inUseAssetData && inUseAssetData}
                      className="table-with-button-assets"
                      style={{ height: '103%' }}
                      scroll={{
                        x: inUseAssetData.length > 0 ? 600 : 0,
                        y: '100%',
                      }}
                    />
                  </Panel>
                )}
                {spareAssetData && spareAssetData.length && (
                  <Panel header={`Spare (${spareAssetData.length})`} className="project-sub-title">
                    <Table
                      rowSelection={{
                        ...rowSelection,
                      }}
                      pagination={false}
                      columns={columns}
                      dataSource={spareAssetData && spareAssetData}
                      className="table-with-button-assets"
                      style={{ height: '103%' }}
                      scroll={{
                        x: spareAssetData.length > 0 ? 600 : 0,
                        y: '100%',
                      }}
                    />
                  </Panel>
                )}
                {sentToTulsaAssetData && sentToTulsaAssetData.length && (
                  <Panel header={`Sent to Tulsa (${sentToTulsaAssetData.length})`} className="project-sub-title">
                    <Table
                      rowSelection={{
                        ...rowSelection,
                      }}
                      pagination={false}
                      columns={columns}
                      dataSource={sentToTulsaAssetData && sentToTulsaAssetData}
                      className="table-with-button-assets"
                      style={{ height: '103%' }}
                      scroll={{
                        x: sentToTulsaAssetData.length > 0 ? 600 : 0,
                        y: '100%',
                      }}
                    />
                  </Panel>
                )}
                {sentToEdmontonAssetData && sentToEdmontonAssetData.length && (
                  <Panel
                    header={`Send to Edmonton (${sentToEdmontonAssetData.length})`}
                    className="project-sub-title"
                  >
                    <Table
                      rowSelection={{
                        ...rowSelection,
                      }}
                      pagination={false}
                      columns={columns}
                      dataSource={
                        sentToEdmontonAssetData && sentToEdmontonAssetData
                      }
                      className="table-with-button-assets"
                      style={{ height: '103%' }}
                      scroll={{
                        x: sentToEdmontonAssetData.length > 0 ? 600 : 0,
                        y: '100%',
                      }}
                    />
                  </Panel>
                )}
              </Collapse>
            ) : (
              <Table />
            )}
          </div>

          {isModalVisible ? (
            <AddAssetModal
              setIsModalVisible={setIsModalVisible}
              isModalVisible={isModalVisible}
              assetModalLoading={assetModalLoading}
              setAssetModalLoading={setAssetModalLoading}
              addAssetForm={addAssetForm}
              assetList={assetData}
              onSave={onAssetSave}
              projectNo={projNo}
              isEdit={isEdit}
              isStatusEdit={isStatusEdit}
              setIsStatusEdit={setIsStatusEdit}
              setIsEdit={setIsEdit}
              onUpdate={onAssetUpdate}
              awsConfigDt={awsConfigDt}
              projectType={projectType}
            />
          ) : null}
          {isStatusModalVisible ? (
            <EditAssetStatusModal
              setIsStatusModalVisible={setIsStatusModalVisible}
              isStatusModalVisible={isStatusModalVisible}
              assetStatusModalLoading={assetStatusModalLoading}
              setAssetStatusModalLoading={setAssetStatusModalLoading}
              updateAssetStatusForm={updateAssetStatusForm}
              projectNo={projNo}
              isStatusEdit={isStatusEdit}
              setIsStatusEdit={setIsStatusEdit}
              onUpdate={onAssetUpdate}
            />
          ) : null}
        </>
      </Spin>
    </div>
  );
};
Assets.propTypes = {
  onGetProjectAssets: func.isRequired,
  assetsData: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showAssetLoader: bool.isRequired,
};

const mapStateToProps = ({ projectAssetRequests }) => {
  const assetsData = get(projectAssetRequests, 'data', []);
  const showAssetLoader = get(projectAssetRequests, 'isLoading', false);
  return {
    assetsData,
    showAssetLoader,
  };
};

const mapDispatchToProps = {
  onGetProjectAssets: getProjectAssets,
};

export default connect(mapStateToProps, mapDispatchToProps)(Assets);
