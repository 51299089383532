/* eslint-disable no-const-assign */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable dot-notation */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  func, arrayOf, objectOf, oneOfType, string, number,
  array, bool
} from 'prop-types';
import 'antd/dist/antd.dark.css';
import {
  Button, Table, Form, Modal, Spin, notification,Tooltip
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  CheckOutlined
} from '@ant-design/icons';
import AddEditEmailModal from './AddEditEmailModal';
import { getAnalysis } from '../state/action/analysisAction';
import { addEmail,removeEmail,updateEmail } from '../services/emailConfig';
import { authenticateCurrentUser } from '../services/auth';

const AnalysisPage = ({
  showLoader, onGetAnalysis, data, reload, group, setGroup
}) => {
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [emailModalLoading, setEmailModalLoading] = useState(false);
  const [isEdit,setIsEdit] = useState(false);
  const [analysisData, setAnalysisData] = useState([]);

  const { Column, ColumnGroup } = Table;

  useEffect(() => {
    if (group) {
      onGetAnalysis();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[reload]);

  useEffect(() => {
    authenticateCurrentUser()
      .then((dt) => {
        if (dt.signInUserSession.accessToken.payload["cognito:groups"] && dt.signInUserSession.accessToken.payload["cognito:groups"].includes('crc-admin')) {
          onGetAnalysis();
          setGroup(true);
        } else {
          notification.info({
            message: 'User created successfully. You are not added to any group. Please contact Vinay.Baburao@sbdinc.com.'
          });
          setGroup(false);
        }
      })
      .catch((error) => {
        setGroup(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    data.sort((a,b) => a.project_number.localeCompare(b.project_number));
    setAnalysisData(data);
  },[data]);

  return (
    <>
      <div className="h-100 overflow-y-hidden overflow-x-auto">
        {
          group && (
            <Table
              scroll={{ x: data.length > 0 ? 1600 : 0, y: '100%' }}
              pagination={false}
              dataSource={analysisData && analysisData}
              className="table-with-button group-table-title"
              loading={{
                indicator: <Spin />,
                spinning: showLoader
              }}
              locale={{
                triggerDesc: 'Click to sort by descending',
                triggerAsc: 'Click to sort by ascending'
              }}
            >
              <ColumnGroup>
                <Column
                  title="Project Name"
                  dataIndex="project_name"
                  key="project_name"
                  align="left"
                  width="300px"
                  sorter={(a,b) => a.project_name.localeCompare(b.project_name)}
                  defaultSortOrder="descend"
                />
              </ColumnGroup>
              <ColumnGroup>
                <Column
                  title="Project Number"
                  dataIndex="project_number"
                  key="project_number"
                  align="left"
                  width="300px"
                  defaultSortOrder="descend"
                  sorter={(a,b) => a.project_number.localeCompare(b.project_number)}
                />
              </ColumnGroup>
              <ColumnGroup>
                <Column
                  title="Lead Technician Name"
                  dataIndex="leadTech"
                  key="leadTech"
                  align="left"
                  width="300px"
                  defaultSortOrder="descend"
                  sorter={(a,b) => a.leadTech.localeCompare(b.leadTech)}
                />
              </ColumnGroup>
              <ColumnGroup>
                <Column
                  title="Daily Weld Count Entered"
                  dataIndex="dailyWeldEntered"
                  key="dailyWeldEntered"
                  align="left"
                  width="300px"
                  defaultSortOrder="descend"
                  sorter={(a,b) => a.dailyWeldEntered.localeCompare(b.dailyWeldEntered)}
                />
              </ColumnGroup>
              <ColumnGroup>
                <Column
                  title="Daily Weld Count"
                  dataIndex="dailyWeld"
                  key="dailyWeld"
                  align="left"
                  width="300px"
                  defaultSortOrder="descend"
                  sorter={(a, b) => a.dailyWeld - b.dailyWeld}
                />
              </ColumnGroup>
              <ColumnGroup>
                <Column
                  title="Weld Count Till Date"
                  dataIndex="weldToDate"
                  key="weldToDate"
                  align="left"
                  width="300px"
                  defaultSortOrder="descend"
                  sorter={(a, b) => a.weldToDate - b.weldToDate}
                />
              </ColumnGroup>
              <ColumnGroup>
                <Column
                  title="Daily Repairs"
                  dataIndex="dailyRepair"
                  key="dailyRepair"
                  align="left"
                  width="300px"
                  defaultSortOrder="descend"
                  sorter={(a, b) => a.dailyRepair - b.dailyRepair}
                />
              </ColumnGroup>
              <ColumnGroup>
                <Column
                  title="Repairs Till Date"
                  dataIndex="repairToDate"
                  key="repairToDate"
                  align="left"
                  width="300px"
                  defaultSortOrder="descend"
                  sorter={(a, b) => a.repairToDate - b.repairToDate}
                />
              </ColumnGroup>
              <ColumnGroup>
                <Column
                  title="Project Repair Rate"
                  dataIndex="repairRate"
                  key="repairRate"
                  align="left"
                  width="300px"
                  defaultSortOrder="descend"
                  sorter={(a, b) => {
                    let rate1 = parseFloat(a.repairRate.split(' ')[0]);
                    let rate2 = parseFloat(b.repairRate.split(' ')[0]);
                    return rate1 - rate2;
                  }}
                />
              </ColumnGroup>
            </Table>
          )

        }

      </div>
    </>

  );
};
AnalysisPage.propTypes = {
  onGetAnalysis: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showLoader: bool.isRequired,
};

const mapStateToProps = ({ analysisRequests }) => {
  const data = get(analysisRequests, 'data', []);
  const showLoader = get(analysisRequests, 'isLoading', false);
  return {
    data,
    showLoader
  };
};

const mapDispatchToProps = {
  onGetAnalysis: getAnalysis
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisPage);
