import get from 'lodash/get';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { GET_COATING_REPORTS, SET_COATING_REPORTS, SET_COATING_REPORT_FAILURE } from '../types';
import { calcReportingPeriod } from '../../services/calendar';

const initialState = {
  isLoading: false,
};

const calcParams = (data, entryData) => {
  let value = 0;
  if (data && entryData) {
    value = data + entryData;
  } else if (!data && entryData) {
    value = entryData;
  } else if (data) {
    value = data;
  } else {
    value = 0;
  }
  return value;
};

const calculateTimeDiff = (start, end) => {
  let totMins = 0;
  if ((start && end) && (start.length === end.length)) {
    for (let i = 0; i < start.length; i += 1) {
      const totalTime = moment.utc(moment(end[i], 'HH:mm').diff(moment(start[i], 'HH:mm'))).format('H:mm');
      const splitVal = totalTime.split(':');
      totMins += parseInt(splitVal[0], 10) * 60 + parseInt(splitVal[1], 10);
    }
  }
  return totMins;
};

const coatingProdLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COATING_REPORTS: {
      const pReports = get(action, ['response', 'items'], []);
      const data = [];
      for (let i = 0; i < pReports.length; i += 1) {
        const pData = get(pReports[i], 'data', {});
        const reportingPeriod = calcReportingPeriod(pData.period);

        let productionFJCompleted;
        let qualityIncidents;
        let qualityFJRepairs;
        let downtimeMinutes;
        let equipmentBreakdowns;
        let safetyIncidents;
        const start = [];
        const end = [];

        const fields = ['Shift', 'Pipe Size', 'Start Weld', 'Finish Weld',
          'Production FJ Completed', 'Production Comments', 'Quality Incidents',
          'Quality FJ Repairs', 'Quality Comments', 'Equipment Breakdowns', 'Equipment Comments',
          'Safety Incidents', 'Safety Comments', 'Personnel Comments', 'Downtime'];

        values(pData.entries).forEach((entry) => {
          productionFJCompleted = calcParams(productionFJCompleted, entry.production_fj_completed);
          qualityIncidents = calcParams(qualityIncidents, entry.quality_incidents);
          qualityFJRepairs = calcParams(qualityFJRepairs, entry.quality_fj_repairs);
          equipmentBreakdowns = calcParams(equipmentBreakdowns, entry.equipment_breakdowns);
          safetyIncidents = calcParams(safetyIncidents, entry.safety_incidents);
          if (!isEmpty(entry.downtime)) {
            values(entry.downtime).map((value) => {
              if (value.startTime && value.endTime) {
                start.push(value.startTime);
                end.push(value.endTime);
                downtimeMinutes = calculateTimeDiff(start, end);
              }
              return downtimeMinutes;
            });
          }
        });

        data[i] = {
          reportId: get(pReports[i], 'id', '-'),
          type: get(pReports[i], 'type', '-'),
          period: get(pData, 'period', '-'),
          reportingPeriod,
          projectNo: get(pData, 'project_number', '-'),
          projectName: get(pData, 'project_name', '-'),
          lead: get(pData, 'lead_tech', '-'),
          author: get(pReports[i], 'author', '-'),
          lastUpdatedAt: get(pReports[i], 'updatedAt', '-'),
          contractor: get(pData, 'contractor', '-'),
          email: get(pReports[i], 'email', '-'),
          createdAt: get(pReports[i], 'createdAt', '-'),
          entries: pData.entries,
          fields,
          totalProductionFJCompleted: productionFJCompleted || 0,
          totalQualityIncidents: qualityIncidents || 0,
          totalQualityFJRepairs: qualityFJRepairs || 0,
          totalDowntimeMinutes: downtimeMinutes || 0,
          totalEquipmentBreakdowns: equipmentBreakdowns || 0,
          totalSafetyIncidents: safetyIncidents || 0,
        };
      }
      return {
        ...state,
        data,
        isLoading: false,
      };
    }

    case GET_COATING_REPORTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_COATING_REPORT_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default coatingProdLogReducer;
