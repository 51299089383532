/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {
  Button, Form, Input, notification
} from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { func } from 'prop-types';
import get from 'lodash/get';
import { signin } from '../state/action/authAction';
import * as constants from '../utils/constants';

const LoginForm = ({ onSignIn, showLoader, authChallenge }) => {
  const [loginForm] = Form.useForm();
  const history = useHistory();
  const [credential, setCredentials] = useState();
  const validateCreds = () => {
    loginForm
      .validateFields()
      .then(async (creds) => {
        await login(creds);
      });
  };
  const login = async (credentials) => {
    setCredentials(credentials);
    try {
      await onSignIn(credentials);
    } catch (error) {
      notification.destroy();
      notification.error({
        message: error.message
      });
    }
  };

  useEffect(() => {
    if (authChallenge !== '') {
      if (authChallenge === constants.CHALLENGE_NAME) {
        history.push('/reset-password', {
          email: credential ? credential.username.toLowerCase() : '',
          isRedirectedFromLogin: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authChallenge]);

  return (
    <div className="login-form">
      <h1 className="mb-5 text-center">Sign in to Your Account</h1>
      <Form
        form={loginForm}
        name="normal_login"
        initialValues={{
          remember: true,
        }}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: 'Please enter your Email.',
            },
            {
              pattern: true
                ? constants.EMAIL_PATTERN
                : undefined,
              message: 'Provide a valid Email.',
            }
          ]}
        >
          <Input size="large" placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please enter your Password.',
            }
          ]}
        >
          <Input.Password
            size="large"
            className="password-icon"
            placeholder="Password"
            iconRender={(visibility) => (visibility ? <EyeOutlined style={{ color: 'grey' }} /> : <EyeInvisibleOutlined style={{ color: 'grey' }} />)}
          />
        </Form.Item>
        <div className="d-flex justify-content-between pb-4">
          <Form.Item noStyle>

            <a>
              <Link className="login-form-forgot fw-bold" to="/forgot-password">
                Forgot Password ?
              </Link>

            </a>

          </Form.Item>
        </div>
        <Form.Item>
          <Button
            size="large"
            shape="round"
            block
            htmlType="submit"
            className="login-form-button"
            onClick={validateCreds}
            disabled={showLoader}
            loading={showLoader}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

LoginForm.propTypes = {
  onSignIn: func.isRequired,
};

const mapStateToProps = ({ auth }) => {
  const showLoader = get(auth, 'isLoading', false);
  const authChallenge = get(auth, 'challengeName', '');
  return {
    showLoader,
    authChallenge
  };
};

const mapDispatchToProps = {
  onSignIn: signin,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
