import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { setNotifications, setNotificationsFailure } from '../state/action/notification';
import { GET_NOTIFICATIONS } from '../state/types';
import fetch from '../services/fetch';
import { BASE_URL } from '../config';

function* getNotifications({ projectNo }) {
  try {
    const projNo = encodeURIComponent(projectNo.projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = yield fetch(`${BASE_URL}/report/notification/${projNo}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      throw new Error('Unauthorized');
    }
    const response = yield res.json();
    yield put(setNotifications({ response }));
  } catch (error) {
    yield put(setNotificationsFailure());
    notification.error({
      message: 'There was a problem fetching the report details.',
      description: `${error}`,
    });
  }
}

function* notificationsWatcher() {
  yield takeLatest(GET_NOTIFICATIONS, getNotifications);
}

function* notificationsSagas() {
  yield all([
    call(notificationsWatcher),
  ]);
}

export default notificationsSagas;
