/* eslint-disable array-callback-return */
import get from 'lodash/get';
import {
  FETCH_TEAM_MEMBERS, SET_TEAM_MEMBERS, SET_TEAM_MEMBERS_FAILURE
} from '../types';

const initialState = {
  isLoading: false,
};

const teamMembersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEAM_MEMBERS: {
      const teammembers = get(action, ['userDetails'], []);
      const members = [];
      Object.values(teammembers).map((team) => {
        Object.values(team).map((value) => {
          members.push(value);
        });
      });
      const data = [];
      for (let i = 0; i < members.length; i += 1) {
        data[i] = {
          key: i,
          size: teammembers?.data[i]?.size,
          name: teammembers?.data[i]?.name,
          email: teammembers?.data[i]?.email,
          userId: teammembers?.data[i]?.user_id,
          id: teammembers?.data[i]?.id,
          role: teammembers?.data[i]?.role,
          isNonCognitoUser: teammembers?.data[i]?.isNonCognitoUser,
          accessToLogs: teammembers?.data[i]?.accessToLogs,
        };
      }
      return {
        ...state,
        data,
        isLoading: false,
      };
    }

    case FETCH_TEAM_MEMBERS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_TEAM_MEMBERS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default teamMembersReducer;
