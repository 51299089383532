/* eslint-disable linebreak-style */
import {
  FETCH_PROJECTS,
  SET_PROJECTS,
  SET_PROJECT_FAILURE
} from '../types';

const fetchProject = () => ({
  type: FETCH_PROJECTS,
});

const setProjects = (payload) => ({
  type: SET_PROJECTS, ...payload,
});

const setProjectFailure = () => ({
  type: SET_PROJECT_FAILURE,
});

export {
  fetchProject,
  setProjects,
  setProjectFailure
};
