/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import {
  Button, Form, Input, notification, Row, Col,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { resetPasswordSubmit } from '../services/auth';

const ResetPasswordForm = () => {
  const [passForm] = Form.useForm();
  const history = useHistory();
  const onFinish = (data) => {
  };

  const changeTempPass = async (data) => {
    try {
      if (data.password === data.password_confirm) {
        if (data.lastName) {
          data.fullName = `${data.firstName} ${data.lastName}`;
        } else {
          data.fullName = `${data.firstName}`;
        }
        await resetPasswordSubmit(data.password, data.fullName);
        history.push('./login');
      } else {
        notification.warning({
          message: 'Passwords do not match',
        });
      }
    } catch (err) {
      notification.warning({
        message: 'Something went wrong.',
      });
    }
  };

  const validatePass = () => {
    passForm
      .validateFields()
      .then(async (data) => {
        await changeTempPass(data);
      });
  };

  useEffect(() => {
    passForm.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="login-form">
      <h1 className="mb-3 text-center">Change Password</h1>
      <Form
        name="normal_login"
        initialValues={{
          remember: true,
        }}
        form={passForm}
        onFinish={onFinish}
      >
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please enter New Password.',
            },
          ]}
        >
          <Input.Password
            size="large"
            className="password-icon"
            placeholder="New Password"
            iconRender={(visibility) => (visibility ? <EyeOutlined style={{ color: 'grey' }} /> : <EyeInvisibleOutlined style={{ color: 'grey' }} />)}
          />
        </Form.Item>
        <Form.Item
          name="password_confirm"
          rules={[
            {
              required: true,
              message: 'Please confirm your password.',
            },
          ]}
        >
          <Input.Password
            size="large"
            className="password-icon"
            placeholder="Confirm New Password"
            iconRender={(visibility) => (visibility ? <EyeOutlined style={{ color: 'grey' }} /> : <EyeInvisibleOutlined style={{ color: 'grey' }} />)}
          />
        </Form.Item>
        <Row gutter={16}>
          <Col lg={12}>
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Please enter your First Name.',
                },
              ]}
            >
              <Input size="large" placeholder="First Name" maxLength={25} />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              name="lastName"
              rules={[
                {
                  required: false,
                  message: 'Please enter your Last Name.',
                },
              ]}
            >

              <Input size="large" placeholder="Last Name" maxLength={25} />
            </Form.Item>
          </Col>

        </Row>

        <Form.Item>
          <Button
            size="large"
            shape="round"
            block
            htmlType="submit"
            className="login-form-button"
            onClick={validatePass}
          >
            Change Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResetPasswordForm;
