import { notification } from 'antd';
import fetch from './fetch';
import { BASE_URL } from '../config';

export const sendESignEmail = async (data, projectNo) => {
  try {
    const res = await fetch(`${BASE_URL}/docusign/sign`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return res.status;
    }
    return null;
  } catch (error) {
    return null;
  }
};
