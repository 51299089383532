/* eslint-disable array-callback-return */
/* eslint-disable linebreak-style */
import get from 'lodash/get';
import {
  FETCH_PROJECT_INVENTORY,
  SET_PROJECT_INVENTORY,
  SET_PROJECT_INVENTORY_FAILURE
} from '../types';

const initialState = {
  isProjectLoading: false,
};

const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT_INVENTORY: {
      const assets = get(action, ['response'], []);
      const asset = assets.data;
      const data = [];
      for (let i = 0; i < asset.length; i += 1) {
        data[i] = {
          key: i,
          projectAssetid: assets?.data[i]?.project_asset_id,
          materialDescription: assets?.data[i]?.material_description,
          serialNo: assets?.data[i]?.serial_number,
          orderQuantity: assets?.data[i]?.order_quantity,
          currentQuantity: assets?.data[i]?.current_quantity
        };
      }
      return {
        ...state,
        data,
        isLoading: false,
      };
    }

    case FETCH_PROJECT_INVENTORY: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_PROJECT_INVENTORY_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default inventoryReducer;
