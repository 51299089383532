/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
import React from 'react';
import {
  Modal
} from 'antd';

const ViewNotificationModal = (props) => {
  const {
    isModalVisible, setIsModalVisible, messageData
  } = props;

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        className="quality-modal"
        title={messageData.title}
        centered
        visible={isModalVisible}
        onOk={() => handleOk(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div className="d-flex align-items-center">
          <b style={{ whiteSpace: 'pre-line' }}>{messageData.message}</b>
        </div>
      </Modal>
    </>
  );
};

export default ViewNotificationModal;
