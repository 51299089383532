import {
  GET_COATING_REPORTS,
  SET_COATING_REPORTS,
  SET_COATING_REPORT_FAILURE,
} from '../types';

const getCoatingReports = (reportType) => ({
  type: GET_COATING_REPORTS,
  reportType,
});

const setCoatingReports = (payload) => ({
  type: SET_COATING_REPORTS, ...payload,
});

const setCoatingReportFailure = () => ({
  type: SET_COATING_REPORT_FAILURE,
});

export {
  getCoatingReports,
  setCoatingReports,
  setCoatingReportFailure,
};
