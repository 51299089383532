/* eslint-disable linebreak-style */
import {
  FETCH_ANALYSIS,
  SET_ANALYSIS,
  SET_ANALYSIS_FAILURE
} from '../types';

const getAnalysis = () => ({
  type: FETCH_ANALYSIS
});

const setAnalysis = (payload) => ({
  type: SET_ANALYSIS, ...payload,
});

const setAnalysisFailure = () => ({
  type: SET_ANALYSIS_FAILURE,
});

export {
  getAnalysis,
  setAnalysis,
  setAnalysisFailure
};
