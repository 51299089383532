/* eslint-disable  */
import React, { useState, useEffect } from 'react';
import {
  Modal, Button, Table, notification, Spin, Tabs, Tooltip
} from 'antd';
import moment from 'moment';
import { getPipeline, getPersonnelData } from '../services/pipeline';
import { getRoles } from '../services/teamMembers';
import { connect } from 'react-redux';
import {
  func, arrayOf, objectOf, oneOfType, string, number,
  array, bool
} from 'prop-types';
import { getTeamMembers } from '../state/action/teamMembers';
import get from 'lodash/get';

const { TabPane } = Tabs
const { Column, ColumnGroup } = Table

const getColumnCount = (array) => {
  const merged = array.reduce((r, {
    date, role_id
  }) => {
    const key = `${role_id}-${date}`;
    r[key] = r[key] || {
      role_id, date, count: 0
    };
    r[key].count += 1
    return r;
  }, {});
  const columnCount = {}
  Object.keys(merged).forEach((item) => {
    columnCount[merged[item].role_id] = Math.max(columnCount[merged[item].role_id] || 0, merged[item].count || 0)
  });
  return columnCount;
};

const groupItemByRole = (array) => {
  const merged = array.reduce((r, {
    role_id, ...rest
  }) => {
    const key = `${role_id}`;
    r[key] = r[key] || {
      role_id, entries: []
    };
    r[key].entries.push(rest);
    return r;
  }, {});
  return merged;
};

const groupItemByDate = (array) => {
  const merged = array.reduce((r, {
    date, ...rest
  }) => {
    const key = `${date}`;
    r[key] = r[key] || {
      date, entries: []
    };
    r[key].entries.push(rest);
    return r;
  }, {});
  return merged;
};

const CreateTableColumn = (masterRoles, personnelEntriesData) => {

  if (personnelEntriesData) {
    const columnCount = getColumnCount(personnelEntriesData);

    return (
      <>
        {Object.keys(columnCount).map((item) => {
          const masterColumn = masterRoles.find((element) => element.id.toString() === item);
          const columns = [];
          for (let i = 0; i < columnCount[item]; i++) {
            columns.push(
              <ColumnGroup key={`${masterColumn.id}-${i}`} title={masterColumn.role} align="center">
                <Column
                  title="User"
                  dataIndex={`${masterColumn.id}-${i}_user`}
                  key={`${masterColumn.id}-${i}_user`}
                  />
                <Column
                  title="Status"
                  dataIndex={`${masterColumn.id}-${i}_status`}
                  key={`${masterColumn.id}-${i}_status`} 
                  />
                <Column
                  title="Hour"
                  dataIndex={`${masterColumn.id}-${i}_hour`}
                  key={`${masterColumn.id}-${i}_hour`}
                  />
              </ColumnGroup>
            );
          }
          return columns;
        })}
      </>
    );
  } else {
    return <></>; 
  }
};

const createDataSource = (dateSorted, user) => {
  const dataSource = [];
  Object.keys(dateSorted).sort((a, b) => new Date(b) - new Date(a)).forEach((item) => {
    const rowDatas = {
      date: item
    }
    const roleSorted = groupItemByRole(dateSorted[item].entries)
    Object.keys(roleSorted).forEach((rowData) => {
      roleSorted[rowData].entries.forEach((entry, index) => {
        // rowDatas[`${rowData}-${index}_user`] = entry.status !== "Leave" ?  user.get(entry.user_project_id) : ''
        // rowDatas[`${rowData}-${index}_status`] = entry.status !== "Leave" ? entry.status : ''
        // rowDatas[`${rowData}-${index}_hour`] = entry.status !== "Leave" ?  entry.shift_hour || 0  : 0
        rowDatas[`${rowData}-${index}_user`] = user.get(entry.user_project_id)
        rowDatas[`${rowData}-${index}_status`] = entry.status
        rowDatas[`${rowData}-${index}_hour`] = entry.shift_hour || 0
      })
    })
    dataSource.push(rowDatas)
  })
  return dataSource
}

const PersonnelModal = (props) => {
  const {
    visible, setVisible, projNo, isToday,
    onGetTeamMembers, data, showLoader, shift
  } = props;
  const [pipelineData, setPipelineData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState('');
  const [masterRoles, setMasterToles] = useState([]);
  const [personnelEntries, setPersonnelEntries] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [userDetails, setUserDetails] = useState(new Map());

  useEffect(() => {
    if (data.length) {
      const user = new Map();
      data.forEach(item => {
        user.set(item.id, item.name)
      })
      setUserDetails(user)
    }
  }, [data])


  const getPersonnelEntries = async (pipelineId) => {
    setLoading(true);
    try {
      const date = isToday ? moment(new Date()).format('YYYY-MM-DD') : '';
      const res = await getPersonnelData(projNo, pipelineId, date, 'Leave', shift);
      if (res?.personnelEntriesData && res?.personnelEntriesData.length > 0) {
        setPersonnelEntries(res?.personnelEntriesData)

        const dateSorted = groupItemByDate(res?.personnelEntriesData)

        const dataSourceArray = createDataSource(dateSorted, userDetails);
        setDataSource(dataSourceArray)
      } else {
        setPersonnelEntries([])
        setDataSource([])
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };

  const getTableColumns = async () => {
    setLoading(true);
    try {
      const res = await getPipeline(projNo);
      if (res?.pipelineData && res?.pipelineData.length > 0) {
        const pipelineFilteredData = res?.pipelineData.filter((item) => item.is_deleted === 0);
        setPipelineData(pipelineFilteredData);
        setTabValue(pipelineFilteredData[0].id);
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };

  const getRolesList = async () => {
    const response = await getRoles();
    if (response && response.masterPersonnelData.length) {
      setMasterToles(response.masterPersonnelData);
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  useEffect(() => {
    if (tabValue && masterRoles.length > 0 && userDetails.size > 0) {
      getPersonnelEntries(tabValue);
    }
  }, [tabValue, masterRoles, userDetails]);

  useEffect(() => {
    if (projNo && visible) {
      getTableColumns();
      getRolesList()
      onGetTeamMembers({ projectNo: projNo });
    }
  }, [projNo, visible]);

  useEffect(() => {
    return () => {
      setTabValue('')
      setPipelineData([])
      setDataSource([])
    }
  }, [])


  const onTabChange = (activeKey) => {
    setTabValue(activeKey);
  };

  return (
    <>
      <Modal
        title="Personnel"
        className="welds-modal"
        centered
        visible={visible}
        width={1300}
        footer={[
          <Button key="submit" type="primary" onClick={() => {
            setTabValue('')
            setPipelineData([])
            setDataSource([])
            setVisible(false);
          }}>
            OK
          </Button>
        ]}
      >
        <div>
          <div className="flex-fill overflow-auto">
            {
              pipelineData && pipelineData.length > 0 && (
                <Tabs disabled={loading || showLoader} activeKey={tabValue.toString()} onChange={onTabChange} className="custom-tab h-100">
                  {
                    pipelineData.map((item) => (
                      <TabPane tab={item.name} key={item.id} value={item.id} />
                    ))
                  }
                </Tabs>
              )
            }
          </div>
          <Table
            pagination={false}
            dataSource={dataSource}
            style={{ whiteSpace: 'nowrap' }}
            scroll={{ x: true }}
            className="table-with-button-assets fsa-personal"
            loading={{
              indicator: <Spin />,
              spinning: loading || showLoader
            }}
          >
            {CreateTableColumn(masterRoles, personnelEntries)}
          </Table>
        </div>
      </Modal>
    </>

  );
};

PersonnelModal.propTypes = {
  onGetTeamMembers: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showLoader: bool.isRequired,
};

const mapStateToProps = ({ teamMembers }) => {
  const data = get(teamMembers, 'data', []);
  const showLoader = get(teamMembers, 'isLoading', false);
  return {
    data,
    showLoader,
  };
};

const mapDispatchToProps = {
  onGetTeamMembers: getTeamMembers
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonnelModal);