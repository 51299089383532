/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/plots';

const TimesheetModalGraph = (props) => {
  const { timesheetData } = props;
  const [data, setData] = useState([]);
  const graph = [];

  useEffect(() => {
    if (timesheetData) {
      Object.keys(timesheetData).map((val) => {
        graph.push(timesheetData[val]);
      });
      setData(graph);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesheetData]);

  const config = {
    autoFit: true,
    data,
    xField: 'date',
    yField: 'hoursWorked',
    seriesField: 'value',
    tooltip: {
      showMarkers: true
    },
    point: {
      shape: 'breath-point'
    },
    yAxis: {
      grid: { line: { style: { stroke: '' } } }
    },
    color: '#FF0000',
    legend: {
      position: 'bottom-left',
    },
  };

  return (
    <div>
      <Line {...config} />
    </div>

  // eslint-disable jsx-props-no-spreading

  // eslint-disable-next-line react/jsx-props-no-spreading
  );
};
export default TimesheetModalGraph;
