/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
/* eslint-disable prefer-template */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Table, Spin } from 'antd';
import get from 'lodash/get';
import { connect } from 'react-redux';
import {
  func, arrayOf, objectOf, oneOfType, string, number,
  array, bool
} from 'prop-types';
import { getPartsReports } from '../state/action/partsAction';
import PartsModal from './PartsModal';
import * as constants from '../utils/constants';

const columns = [
  {
    title: 'Requested By',
    dataIndex: 'author',
    key: 'author',
    align: 'center'
  },
  {
    title: 'Created Time',
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center'
  },
  {
    title: 'Report',
    key: 'pdf',
    dataIndex: 'pdf',
    render: (record) => {
      if (record) {
        return (
          <div>
            {' '}
            <a
              className="export-link"
              title="Download csv report"
              target="_blank"
              rel="noopener noreferrer"
              href={record}
              // eslint-disable-next-line react/jsx-closing-bracket-location
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <span className="icon-16 mr-2">
                <svg>
                  <use
                    xlinkHref="#export"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </svg>
              </span>
            </a>
          </div>
        );
        // eslint-disable-next-line brace-style
      }
      // eslint-disable-next-line no-else-return
      else {
        return <div>-</div>;
      }
    },
    align: 'center'
  },

];

const PartsOrdering = (props) => {
  const {
    onGetPartsOrderingReports, data, projNo, showLoader,
    reload, setPartsOrderRep, partsOrderReport, refresh
  } = props;
  const [partsData, setPartsData] = useState([]);
  const [partsModalVisible, setPartsModalVisible] = useState(false);
  const [createDate, setCreateDate] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [totalReports, setTotalReports] = useState(0);

  useEffect(() => {
    // refresh(false);
    // if (!reload) {
    if (projNo !== '') {
      onGetPartsOrderingReports(projNo);
    } else {
      setPartsData([]);
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo]);

  useEffect(() => {
    if (reload) {
      if (projNo) {
        onGetPartsOrderingReports(projNo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    if (!reload) {
      const parts = [];
      const date = [];
      if (data !== []) {
        data.map((item) => {
          item.createdAt = item.createdAt.replaceAll('/', '-');
          if (!date.includes(moment(item.createdAt).format(constants.DATETIMESEC))) {
            date.push(moment(item.createdAt).format(
              constants.DATETIMESEC
            ));
            parts.push(item);
          }
        });
        setPartsData(parts);
      }
      setTotalReports(parts.length);
      setPartsOrderRep(parts);
    } else {
      setPartsData(partsOrderReport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const hideModal = () => {
    setPartsModalVisible(false);
  };

  const handleTableChnage = (pagination,
    filters,
    sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  return (
    <>
      <div className={partsData.length > 0 ? 'h-85 overflow-y-hidden overflow-x-auto has-pointer' : 'h-85 overflow-y-hidden overflow-x-auto'}>
        <Table
          pagination={totalReports > 10 ? tableParams.pagination : false}
          columns={columns}
          dataSource={partsData}
          className="h-85"
          scroll={{ x: partsData.length > 0 ? 1200 : 0, y: '100%' }}
          rowKey={(record) => record.reportId}
          loading={{
            indicator: <Spin />,
            spinning: showLoader
          }}
          onRow={(record) => ({
            onClick: (e) => {
              e.stopPropagation();
              setPartsModalVisible(true);
              setCreateDate(record.createdAt);
            },
          })}
          onChange={handleTableChnage}
        />
      </div>
      <PartsModal
        modalVisible={partsModalVisible}
        hideModal={hideModal}
        createDate={createDate}
        data={data}
      />
    </>

  );
};

PartsOrdering.propTypes = {
  onGetPartsOrderingReports: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showLoader: bool.isRequired,
};

const mapStateToProps = ({ partRequests }) => {
  const data = get(partRequests, 'pdata', []);
  const showLoader = get(partRequests, 'isLoading', false);
  return {
    data,
    showLoader
  };
};

const mapDispatchToProps = {
  onGetPartsOrderingReports: getPartsReports
};

export default connect(mapStateToProps, mapDispatchToProps)(PartsOrdering);
