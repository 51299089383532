/* eslint-disable linebreak-style */
import {
  FETCH_EMAILS,
  SET_EMAILS,
  SET_EMAIL_FAILURE
} from '../types';

const getEmails = (projectNumber) => ({
  type: FETCH_EMAILS,
  projectNumber
});

const setEmail = (payload) => ({
  type: SET_EMAILS, ...payload,
});

const setEmailFailure = () => ({
  type: SET_EMAIL_FAILURE,
});

export {
  getEmails,
  setEmail,
  setEmailFailure
};
