/* eslint-disable linebreak-style */
import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { setEmail, setEmailFailure } from '../state/action/emailConfigAction';
import {
  FETCH_EMAILS
} from '../state/types';
import fetch from '../services/fetch';

import { BASE_URL } from '../config';

function* getEmails(data) {
  try {
    const res = yield fetch(`${BASE_URL}/reportEmailConfig/getEmailList?projectNumber=${data.projectNumber || ''}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      throw new Error('Unauthorized');
    }
    const response = yield res.json();
    yield put(setEmail({ response }));
  } catch (error) {
    yield put(setEmailFailure());
    notification.error({
      message: 'There was a problem fetching the assets.',
      description: `${error}`,
    });
  }
}

function* emailConfigWatcher() {
  yield takeLatest(FETCH_EMAILS, getEmails);
}

function* emailConfigSagas() {
  yield all([
    call(emailConfigWatcher),
  ]);
}

export default emailConfigSagas;
