import {
  SIGNOUT, SIGNIN, SIGNINSUCCESS, SIGNINFAILURE,
} from '../types';

const signin = (credentials) => ({
  type: SIGNIN,
  credentials
});

const signinsuccess = (payload) => ({
  type: SIGNINSUCCESS,
  ...payload
});

const signfailure = () => ({
  type: SIGNINFAILURE
});

const signout = () => ({
  type: SIGNOUT,
});

export {
  signout,
  signin,
  signinsuccess,
  signfailure,
};
