/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable eol-last */

import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Layout, Menu, Avatar, Button, notification
} from 'antd';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import Logo from '../images/fsa_logo_withtext.svg';
import LogoBlack from '../images/fsa_logo_withtext_black.svg';
import LogoSmall from '../images/fsa_logo.png';
import PoweredBy from '../images/smart-lync.png';
import history from '../services/history';
import { authenticateCurrentUser, userSignOut } from '../services/auth';

import { signout } from '../state/action/authAction';

const { Sider } = Layout;
const Sidebar = (props) => {
  const {
    onSignout
  } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [switchtheme, setSwitchtheme] = useState(false);
  const [userName, setUserName] = useState('');
  const [userGroup, setUserGroup] = useState([]);
  const onCollapse = (collapse) => {
    setCollapsed(collapse);
  };

  const location = useLocation();

  const active = useMemo(() => {
    let key = '1';
    if (location.pathname === '/analysis') {
      key = '2';
    } else if (location.pathname === '/notifications') {
      key = '3';
    } else if (location.pathname === '/admin') {
      key = '4';
    }
    return key;
  }, [location]);

  useEffect(() => {
    authenticateCurrentUser()
      .then((data) => {
        setUserName(data.attributes.name);
        setUserGroup(data.signInUserSession.accessToken.payload['cognito:groups']);
      })
      .catch((error) => {
        console.log('error', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const element = document.getElementById('body-wrapper');
    const theme = localStorage.getItem('theme');
    if (theme === 'True') {
      element.classList.remove('light-theme');
    } else {
      element.classList.add('light-theme');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchtheme]);

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
      <div className="d-flex h-100 flex-column">
        <div className="flex-auto logo-container">
          <div className="logo-wrapper">
            <a href="main" className="aside-logo-large">
              <img className="logo-white" src={Logo} alt="logo" />
              <img className="logo-black" src={LogoBlack} alt="logo" />
            </a>
            <a href="main" className="aside-logo-small">
              <img src={LogoSmall} alt="logo" />
            </a>
          </div>
          <div className="py-4 user-name d-flex justify-content-center">
            <div className="text-center">
              <Avatar style={{ backgroundColor: '#fff' }} size={44}>
                <span className="icon-18 mr-2">
                  <svg>
                    <use xlinkHref="#user" />
                  </svg>
                </span>
              </Avatar>
              <p className="pt-3 full-name">{userName}</p>
              <p className="pt-3 first-last-letter">{userName}</p>
            </div>
          </div>
        </div>
        <div className="flex-fill  overflow-auto">
          <Menu
            className="border-0 pb-4 left-menu"
            defaultSelectedKeys={['1']}
            mode="inline"
            selectedKey={active}
          >
            <Menu.Item
              key="1"
              onClick={() => {
                history.push('/main');
              }}
            >
              <Button
                type="link"
              >
                <span className="icon-14 me-2">
                  <svg>
                    <use xlinkHref="#dashboard" />
                  </svg>
                </span>
                <span className="menu-content"> Dashboard</span>
              </Button>
            </Menu.Item>
            <Menu.Item
              key="2"
              onClick={() => {
                history.push('/analysis');
              }}
            >
              <Button
                type="link"
              >
                <span className="icon-14 me-2">
                  <svg>
                    <use xlinkHref="#analysis" />
                  </svg>
                </span>
                <span className="menu-content">Analysis</span>
              </Button>
            </Menu.Item>
            <Menu.Item
              key="3"
              onClick={() => {
                history.push('/notifications');
              }}
            >
              <Button
                type="link"
              >
                <span className="icon-14 me-2">
                  <svg>
                    <use xlinkHref="#notifications" />
                  </svg>
                </span>
                <span className="menu-content">Notifications</span>
              </Button>
            </Menu.Item>
            {
                  userGroup && userGroup.length > 0 && userGroup.includes('admin') && (
                    <Menu.Item
                      key="4"
                      onClick={() => {
                        history.push('/admin');
                      }}
                    >
                      {' '}
                      <Button
                        type="link"
                      >
                        <span className="icon-14 me-2">
                          <svg>
                            <use xlinkHref="#useradmin" />
                          </svg>
                        </span>
                        <span className="menu-content">Admin</span>
                      </Button>
                    </Menu.Item>
                  )
                }

          </Menu>
        </div>
        <div className="flex-auto">
          <Menu
            className="border-0 pb-4 left-menu"
            defaultSelectedKeys={['']}
            mode="inline"
          >
            <Menu.Item
              key="5"
              onClick={() => {
                const element = document.getElementById('body-wrapper');
                if (localStorage.getItem('theme') === 'True') {
                  localStorage.setItem('theme', 'False');
                  element.classList.remove('light-theme');
                } else {
                  localStorage.setItem('theme', 'True');
                  element.classList.add('light-theme');
                }
                setSwitchtheme(!switchtheme);
              }}
            >
              <Button
                type="link"
              >
                <span className="icon-14 me-2">
                  <svg>
                    <use xlinkHref="#Theme" />
                  </svg>
                </span>
                <span className="menu-content">Switch Theme</span>
              </Button>
            </Menu.Item>
            <Menu.Item
              key="6"
              onClick={() => {
                onSignout();
                userSignOut();
                notification.destroy();
                notification.success({
                  duration: 2,
                  message: 'Successfully Logged Out.',
                  description: '',
                });
              }}
            >
              <Button
                type="link"
              >
                <span className="icon-14 me-2">
                  <svg>
                    <use xlinkHref="#logout" />
                  </svg>
                </span>
                <span className="menu-content"> Logout</span>
              </Button>
            </Menu.Item>
          </Menu>
          <div className="poweredby d-flex justify-content-center">
            <div className="powered-by-large">
              <p className="small mb-1">Pioneered by</p>
              <img height={30} src={PoweredBy} alt="logo" />
            </div>
          </div>
        </div>
      </div>

    </Sider>
  );
};

Sidebar.propTypes = {
  onSignout: func.isRequired,
};

const mapDispatchToProps = {
  onSignout: signout,
};

export default connect(null, mapDispatchToProps)(Sidebar);