/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable operator-assignment */
/* eslint-disable no-unneeded-ternary */
import {
  Button, DatePicker, Spin, notification, Modal, Col, Row, Table
} from 'antd';
import React, { useState, useEffect } from 'react';
import {
  CheckOutlined
} from '@ant-design/icons';
import moment from 'moment';
import get from 'lodash/get';
import { connect } from 'react-redux';
import {
  func, arrayOf, objectOf, oneOfType, string, number,
  array, bool
} from 'prop-types';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { uploadFile } from 'react-s3';
import { getTeamMembers } from '../state/action/teamMembers';
import {
  writeOffshoreTimesheetWorkSheet,
  getOrdinalDaysArray, prepareOffshoreTimesheetDataForExcel
} from '../utils/utility';
import { addOffshoreTimeSheetReport } from '../services/teamMembers';
import { getPipeline, getPersonnelData } from '../services/pipeline';
import { authenticateCurrentUser } from '../services/auth';
import { getAwsConfigData } from '../services/awsConfigData';
import { getTimesheet } from '../state/action/timesheetAction';

const uuid = require('uuid');

const { RangePicker } = DatePicker;

const TimesheetOffshore = (props) => {
  const {
    data, projNo, selectedProjectDetail, dates, setDates, onGetTeamMembers, onGetTimesheet,
    reportData, showReportLoader
  } = props;

  const [disableRangeDataFetchBtn, setDisableRangeDataFetchBtn] = useState(true);
  const [userDetails, setUserDetails] = useState(new Map());
  const [showGenerateReportModal, setShowGenerateReportModal] = useState(false);
  const [defaultDateRange, setDefaultDateRange] = useState([moment().startOf('month'), moment().endOf('month')]);
  const [sendReportModalLoading, setSendReportModalLoading] = useState(false);
  const [pipelineData, setPipelineData] = useState([]);
  const [awsConfigDt, setAwsConfigDt] = useState([]);

  let config = {};

  if (awsConfigDt.length > 0) {
    const S3_BUCKET = 'parts-data';
    const REGION = 'us-west-2';
    const ACCESS_KEY = awsConfigDt[0].access_key_id;
    const SECRET_ACCESS_KEY = awsConfigDt[0].secret_access_key;

    config = {
      bucketName: S3_BUCKET,
      region: REGION,
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
    };
  }

  const columns = [
    {
      title: 'Created Time',
      dataIndex: 'submitted_at',
      key: 'submitted_at',
      render: (text, record) => (record.submitted_at ? moment(record.submitted_at).format('DD-MMM-YYYY, hh:mm A') : '-'),
      align: 'center'
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      render: (text, record) => {
        const [startDateString, endDateString] = record.period.split(' to ');
        return (
          `${moment(startDateString, 'YYYY-MM-DD').format('DD-MMM-YYYY')} to ${moment(endDateString, 'YYYY-MM-DD').format('DD-MMM-YYYY')}`
        );
      },
      align: 'center'
    },
    {
      title: 'Timesheet',
      dataIndex: 'excel_url',
      key: 'excel_url',
      render: (text, record) => {
        if (record) {
          return (
            <div>
              {' '}
              <a
                className="export-link"
                title="Reports"
                target="_blank"
                rel="noopener noreferrer"
                href={record.excel_url}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span className="icon-16 mr-2">
                  <svg>
                    <use
                      xlinkHref="#export"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </svg>
                </span>
              </a>
            </div>
          );
        }
        return <div>-</div>;
      },
      align: 'center'
    }
  ];

  const getAwsConfigDatas = async () => {
    const awsConfigData = await getAwsConfigData();
    setAwsConfigDt(awsConfigData.data);
  };

  useEffect(() => {
    if (data.length) {
      const user = new Map();
      data.forEach((item) => {
        user.set(item.id, item.name);
      });
      setUserDetails(user);
    }
  }, [data]);

  const getPipelineList = async () => {
    const res = await getPipeline(projNo);
    if (res?.pipelineData && res?.pipelineData.length > 0) {
      const pipeline = new Map();
      res.pipelineData.forEach((item) => {
        pipeline.set(item.id, item.name);
      });
      setPipelineData(pipeline);
    }
    // else {
    //   notification.error({
    //     message: 'Something went wrong.',
    //   });
    // }
  };

  const getTimesheetData = async (startDt = '', endDt = '') => {
    onGetTimesheet(startDt, endDt, projNo, selectedProjectDetail.projectType);
  };

  useEffect(() => {
    getPipelineList();
    onGetTeamMembers({ projectNo: projNo });
    getAwsConfigDatas();
    getTimesheetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo]);

  const getDates = (startDt, endDt) => {
    const start = (moment.utc(`${startDt}`, 'YYYY-MM-DD').local()).toDate();
    const end = (moment.utc(`${endDt}`, 'YYYY-MM-DD').local()).toDate();

    const dts = [];

    for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
      dts.push(moment(date).format('YYYY-MM-DD'));
    }
    return dts;
  };

  const createDataSource = (dateSorted, users, pipelines) => {
    const merged = [];
    dateSorted.forEach((rowData) => {
      if (rowData.status === 'Onsite') {
        rowData.status = 1;
      } else if (rowData.status === 'Travel') {
        rowData.status = 'T';
      } else {
        rowData.status = '';
      }
      rowData.shift = rowData.shift === 0 ? 'Day' : 'Night';
      merged.push({
        ...rowData,
        name: users.get(rowData.user_project_id),
        pipeline: pipelines.get(rowData.project_pipelines_id)
      });
    });

    return merged;
  };

  const handleCancel = () => {
    // Reset state when modal is closed
    // setSendReportModalLoading(false);
    setShowGenerateReportModal(false);
    const startOfMonth = moment().startOf('month');
    const endOfMonth = moment().endOf('month');
    setDefaultDateRange([startOfMonth, endOfMonth]);
  };

  const getFinalData = async (dataSourceArray, dateArray) => {
    const aggregatedData = {};
    dataSourceArray.forEach((item) => {
      const key = `${item.user_project_id}_${item.shift}_${item.project_pipelines_id}`;
      if (aggregatedData[key]) {
        const existingItem = aggregatedData[key][0];
        if (
          existingItem.user_project_id === item.user_project_id
          && existingItem.shift === item.shift
          && existingItem.project_pipelines_id === item.project_pipelines_id
        ) {
          existingItem.days += item.status === 1 ? 1 : 0;

          existingItem.date[item.date] = item.status;
        } else {
          const daysTotal = item.status === 1 ? 1 : 0;

          item.date = { [item.date]: item.status };

          item.days = daysTotal;

          aggregatedData[key] = [item];
        }
      } else {
        const daysTotal = item.status === 1 ? 1 : 0;

        item.date = { [item.date]: item.status };

        item.days = daysTotal;

        aggregatedData[key] = [item];
      }
    });
    const result = Object.values(aggregatedData).map((arr) => arr[0]);

    const finalResult = [];

    result.forEach((item) => {
      dateArray.forEach((date) => {
        if (!item.date.hasOwnProperty(date)) {
          item.date[date] = '';
        }
      });

      const drray = Object.entries(item.date);
      drray.sort(([dateA], [dateB]) => dateA.localeCompare(dateB));
      item.date = Object.fromEntries(drray);
      const value = {
        pipeline: item.pipeline,
        name: item.name,
        role: item.role,
        shift: item.shift,
        ...item.date,
        days: item.days
      };

      finalResult.push(value);
    });
    return finalResult;
  };

  const exportToExcel = async (startDt, endDt, final) => {
    try {
      const daysCount = getOrdinalDaysArray(startDt, endDt);
      const dateArr = getDates(startDt, endDt);
      const cols = prepareOffshoreTimesheetDataForExcel(dateArr);
      const workbook = new Excel.Workbook();
      const workSheetName = `${startDt} to ${endDt}`;
      const fileName = `Timesheet__${uuid()}_${startDt}_to_${endDt}_${selectedProjectDetail.projectName}_${selectedProjectDetail.projectNo}`;
      let worksheet = workbook.addWorksheet(workSheetName);
      worksheet.columns = cols;

      worksheet = writeOffshoreTimesheetWorkSheet(worksheet, daysCount, final,
        selectedProjectDetail.projectName,
        selectedProjectDetail.projectNo,
        selectedProjectDetail.leadTech,
        selectedProjectDetail.contractor,
        moment(startDt).format('DD-MMM-YYYY'),
        moment(endDt).format('DD-MMM-YYYY'),);

      for (let i = 8; i <= worksheet.lastRow.number; i++) {
        const row = worksheet.getRow(i);

        row.eachCell({ includeEmpty: true }, ((cell) => {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }));
      }
      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
      const file = new File([buf], `${fileName}.xlsx`, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const userD = await authenticateCurrentUser();
      uploadFile(file, config)
        .then(async (fileData) => {
          const res = await addOffshoreTimeSheetReport(
            fileData.location,
            userD.attributes.name,
            projNo,
            selectedProjectDetail.projectName,
            `${startDt} to ${endDt}`
          );
          if (res) {
            await getTimesheetData();
          }
        })
        .catch((err) => console.error('err', err));
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  const getPersonnelEntries = async () => {
    setSendReportModalLoading(true);
    try {
      const firstdate = moment(defaultDateRange[0]).format('YYYY-MM-DD');
      const lastdate = moment(defaultDateRange[1]).format('YYYY-MM-DD');

      const res = await getPersonnelData(projNo, '', '', '', '', firstdate, lastdate);
      if (res?.personnelEntriesData && res?.personnelEntriesData.length > 0) {
        const dateArray = getDates(firstdate, lastdate);
        const dataSourceArray = createDataSource(
          res?.personnelEntriesData, userDetails, pipelineData
        );
        const final = await getFinalData(dataSourceArray, dateArray);
        await exportToExcel(firstdate, lastdate, final);
      } else {
        notification.error({
          message: 'No log in this date range.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Please choose any date range...',
      });
    } finally {
      setSendReportModalLoading(false);
      handleCancel();
    }
  };

  useEffect(() => {
    if (dates.length === 2 && dates[0] !== '' && dates[1] !== '') {
      setDisableRangeDataFetchBtn(false);
    } else {
      getTimesheetData();
      setDisableRangeDataFetchBtn(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  const currentMonthStartEndDate = () => {
    const firstdate = moment(dates[0]).format('YYYY-MM-DD');
    const lastdate = moment(dates[1]).format('YYYY-MM-DD');
    getTimesheetData(firstdate, lastdate);
  };

  return (
    <>
      <div
        className="d-flex pb-2 align-items-center justify-content-between"
        style={{ height: '49px' }}
      >
        <div className="d-flex edit-session justify-content-center">
          <b className="mx-2">Filter:</b>
          <RangePicker
            className="rangePicker_separator"
            showToday
            separator="to"
            format="YYYY-MMM-DD"
            placeholder={['Start Date', 'End Date']}
            defaultValue={dates}
            onCalendarChange={(val, dateString) => { setDates(dateString); }}
          />
          {}
          <Button
            type="primary"
            disabled={disableRangeDataFetchBtn ? true : false}
            onClick={() => { currentMonthStartEndDate(); }}
          >
            <CheckOutlined />
            {' '}
          </Button>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            type="primary"
            size="medium"
            shape="round"
            className="timesheetButton"
            onClick={() => {
              setShowGenerateReportModal(true);
            }}
          >
            Generate Timesheet
          </Button>
        </div>
      </div>
      <>
        <div className={data.length > 0 ? 'h-85 overflow-y-hidden overflow-x-auto has-pointer' : 'h-85 overflow-y-hidden overflow-x-auto'}>
          <Table
            pagination={false}
            className="h-85"
            scroll={{ x: data.length > 0 ? 1200 : 0, y: '100%' }}
            rowKey={(record) => record.id}
            columns={columns}
            style={{ marginTop: '20px' }}
            dataSource={reportData}
            loading={{
              indicator: <Spin />,
              spinning: showReportLoader
            }}
          />
        </div>
      </>
      <Modal
        className="quality-modal"
        title="Generate Timesheet"
        centered
        visible={showGenerateReportModal}
        destroyOnClose
        footer={[
          <>
            <Button
              onClick={() => handleCancel()}
              disabled={sendReportModalLoading}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={sendReportModalLoading}
              loading={sendReportModalLoading}
              onClick={() => {
                getPersonnelEntries();
              }}
            >
              Generate Timesheet
            </Button>
          </>
        ]}
      >
        <div>
          <Row gutter={16}>
            <Col lg={24}>
              <RangePicker
                className="rangePicker_separator"
                showToday
                separator="to"
                format="DD-MMM-YYYY"
                placeholder={['Start Date', 'End Date']}
                defaultValue={defaultDateRange}
                onCalendarChange={(dateString) => { setDefaultDateRange(dateString); }}
              />
            </Col>
          </Row>
        </div>

      </Modal>
    </>
  );
};

TimesheetOffshore.propTypes = {
  onGetTeamMembers: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  onGetTimesheet: func.isRequired,
  reportData: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showReportLoader: bool.isRequired,
  // showLoader: bool.isRequired,
};

const mapStateToProps = ({ teamMembers, timesheetRequests }) => {
  const data = get(teamMembers, 'data', []);
  const showLoader = get(teamMembers, 'isLoading', false);
  const reportData = get(timesheetRequests, 'tdata', []);
  const showReportLoader = get(timesheetRequests, 'isLoading', false);
  return {
    data,
    showLoader,
    reportData,
    showReportLoader
  };
};

const mapDispatchToProps = {
  onGetTeamMembers: getTeamMembers,
  onGetTimesheet: getTimesheet
};

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetOffshore);
