/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-trailing-spaces */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import {
  Modal, Form, Input, Row, Col, notification, Button
} from 'antd';
import { read, utils } from 'xlsx';
import { uploadFile } from 'react-s3';
import * as utility from '../utils/utility';
import {
 offshoreshoreAssetWebElements, offshoreInventoryWebElements,
  onshoreEmeaInventoryWebElements, onshoreEmeaAssetWebElements, projectType as projectTypeConstant 
} from '../utils/constants';

const { offshore, onshoreEmea } = projectTypeConstant;
const AddAssetModal = (props) => {
  const {
    isModalVisible, setIsModalVisible, assetModalLoading, 
    addAssetForm, isEdit, setIsEdit, awsConfigDt, projectNo, onSave, onUpdate, 
    setAssetModalLoading, projectType
  } = props;

  const [assetsFileData, setAssetFileData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filename, setFilename] = useState('');
  const [fileUploaded, setFileUploaded] = useState(false);

  const assetKeyword = [];
  const inventoryKeyword = [];

  if (projectType === onshoreEmea || projectType === offshore) {
    assetKeyword.push('AW REFURB COST');
    assetKeyword.push('AW NON RENTAL OBJECTS - HOLLAND');
    inventoryKeyword.push('AW CONSIGNMENT HWC');
    //  TODO replace these values  based on on shore and off shore 
    // add condition for both 
    if (projectType === onshoreEmea) {
      assetKeyword.push(...onshoreEmeaAssetWebElements);
      inventoryKeyword.push(...onshoreEmeaInventoryWebElements);  
    } else {
      assetKeyword.push(...offshoreshoreAssetWebElements);
      inventoryKeyword.push(...offshoreInventoryWebElements);
    }
  } 
  assetKeyword.push('Rental');
  inventoryKeyword.push('Consigned');

  const handleCancel = () => {
    setIsModalVisible(false);
    addAssetForm.resetFields();
    setIsEdit(false);
  };

  const S3_BUCKET = 'parts-data';
  const REGION = 'us-west-2';
  const ACCESS_KEY = awsConfigDt[0].access_key_id;
  const SECRET_ACCESS_KEY = awsConfigDt[0].secret_access_key;

const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
};

  const handleFileInput = (event) => {
    const { files } = event.target;
if (files.length) {
    const file = files[0];
    const fileTypeArr = file.name.split('.');
    const extention = fileTypeArr.pop();
    const typeArr = ['xls', 'xlsx'];
    if (!typeArr.includes(extention.toLowerCase())) {
      notification.warning({
        message: 'Invalid file format. Please select a file with valid format (.xls , .xlsx)',
        duration: 1.5
      });
    } else {
     setFilename(file.name);
     const reader = new FileReader();
     reader.onload = (event) => {
         const wb = read(event.target.result);
         const sheets = wb.SheetNames;
         if (sheets.length) {
             const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
             setAssetFileData(rows);
         }
     };
     reader.readAsArrayBuffer(file);
    }
}
    setSelectedFile(event.target.files[0]);
  };

  const checkKeywordExist = (projectKeyword, matchKeyword) => {
    const keywordExist = projectKeyword.some((element1) =>
    matchKeyword.some((element2) => {
      const cleanElement1 = element1.replace(/[^\w\s]/g, '').toLowerCase();
      const cleanElement2 = element2.replace(/[^\w\s]/g, '').toLowerCase();
      return cleanElement1.includes(cleanElement2) || cleanElement2.includes(cleanElement1);
    }));
    return keywordExist;
  };

  const handleUpload = (file) => {
    if (assetsFileData && assetsFileData.length > 0) {
      const pjctnum = [];
      const pjctType = [];
        assetsFileData.forEach((dt) => {
          if (!pjctnum.includes(dt['Project Definition'])) {
            pjctnum.push(dt['Project Definition'].toString());
          }
          if (projectType === onshoreEmea || projectType === offshore) {
            if (!pjctType.includes(dt['WBS Element'])) {
              pjctType.push(dt['WBS Element']);
            }
          }
          if (!pjctType.includes(dt['WBS Name'])) {
              pjctType.push(dt['WBS Name']);
            }
        });

        const containsAssetKeyword = checkKeywordExist(pjctType, assetKeyword);
        const containsInventoryKeyword = checkKeywordExist(pjctType, inventoryKeyword);
        
        if (!containsAssetKeyword && !containsInventoryKeyword) {
          if (projectType === onshoreEmea || projectType === offshore) {
            notification.warning({
              message: `Data for the WB Name or WB Element of project number ${projectNo} does not matching with ${projectType} project type`,
              duration: 1.5
            }); 
          } else {
            notification.warning({
              message: `Data for the WB Name of project number ${projectNo} does not matching with ${projectType} project type`,
              duration: 1.5
            });
          }
        } else {
          setAssetModalLoading(true);
          uploadFile(file, config)
            .then((data) => { console.log('data', data); setFileUploaded(true); })
            .catch((err) => console.error('err', err));
        }
    }
  };

  useEffect(() => {
    if (assetsFileData && assetsFileData.length > 0) {
      const pjctnum = [];
      const pjctType = [];
        assetsFileData.forEach((dt) => {
          if (!pjctnum.includes(dt['Project Definition'])) {
            pjctnum.push(dt['Project Definition'].toString());
          }
          if (projectType === onshoreEmea || projectType === offshore) {
            if (!pjctType.includes(dt['WBS Element'])) {
              pjctType.push(dt['WBS Element']);
            }
          }
          if (!pjctType.includes(dt['WBS Name'])) {
              pjctType.push(dt['WBS Name']);
            }
        });

        const containsAssetKeyword = checkKeywordExist(pjctType, assetKeyword);
        const containsInventoryKeyword = checkKeywordExist(pjctType, inventoryKeyword);

        if (!pjctnum.includes(projectNo)) {
          notification.warning({
            message: `Data for the project number ${projectNo} does not exist in uploaded file`,
            duration: 1.5
          });
        }

        if (!containsAssetKeyword && !containsInventoryKeyword) {
          if (projectType === onshoreEmea || projectType === offshore) {
            notification.warning({
              message: `Data for the WB Name or WB Element of project number ${projectNo} does not matching with ${projectType} project type`,
              duration: 1.5
            }); 
          } else {
            notification.warning({
              message: `Data for the WB Name of project number ${projectNo} does not matching with ${projectType} project type`,
              duration: 1.5
            });
          }
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsFileData]);

  useEffect(() => {
    if (fileUploaded) {
          onSave(filename);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploaded]);

  const handleUpdate = () => {
    addAssetForm
      .validateFields()
      .then((data) => {
        const quantity = data.order_quantity && data.order_quantity > 0 ? data.order_quantity : 1;
          onUpdate(data.description, data.serial_number,
              data.project_asset_id, '', parseInt(quantity, 10));
      });
  };

  const onlyNumberKey = (event) => {
    // Only ASCII character in that range allowed
    const ASCIICode = (event.which) ? event.which : event.keyCode;
    if (ASCIICode >= 48 && ASCIICode <= 57) {
      return true;
    }
    event.preventDefault();
    return false;
  };

  return (
    <>
      <Modal
        className="quality-modal"
        title={isEdit ? 'Update Asset' : 'Add Assets'}
        centered
        visible={isModalVisible}
        onOk={() => handleUpload(selectedFile)}
        footer={isEdit ? [
          <Button 
            key="submit" 
            type="primary" 
            onClick={() => handleCancel(false)} 
            disabled={assetModalLoading}
          >
            Cancel
          </Button>,
          <Button
            key="submit" 
            type="primary" 
            onClick={() => handleUpdate()} 
            disabled={assetModalLoading} 
            loading={assetModalLoading}
          >
            Update
          </Button>
        ] : [
          <Button 
            key="submit" 
            type="primary" 
            onClick={() => handleCancel(false)} 
            disabled={assetModalLoading}
          >
            Cancel
          </Button>,
          <Button
            key="submit" 
            type="primary" 
            onClick={() => handleUpload(selectedFile)} 
            disabled={assetModalLoading || assetsFileData.length === 0} 
            loading={assetModalLoading}
          >
            Add
          </Button>
        ]}
      >
        <div>
          {
            !isEdit ? (
              <Input 
                type="file" 
                name="file" 
                id="inputGroupFile" 
                required 
                onChange={handleFileInput}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            ) : (
              <Form
                id="create-project-form"
                name="basic"
                layout="vertical"
                autoComplete="off"
                form={addAssetForm}
                onFinish=""
              >
                <Row gutter={16}>
                  <Col lg={12}>
                    <Form.Item
                      label="Asset Number"
                      name="serial_number"
                      rules={[
                    {
                      required: true,
                      message: 'Please enter the Asset Number.',
                    },
                  ]}
                      normalize={(value) => utility.trimSpacesInValues(value)}
                    >
                      <Input size="large" placeholder="Enter Serial Number" maxLength={100} />
                    </Form.Item>
                  </Col> 
                  <Col lg={12}>
                    <Form.Item
                      label="Description"
                      name="description"
                      rules={[
                    {
                      required: true,
                      message: 'Please enter the Asset Description.',
                    },
                  ]}
                      normalize={(value) => utility.trimSpacesInValues(value)}
                    >
                      <Input size="large" placeholder="Enter Description" maxLength={255} />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item name="project_asset_id" style={{ display: 'none' }} />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col lg={12}>
                    <Form.Item
                      label="Order Quantity"
                      name="order_quantity"
                      normalize={(value) => utility.trimSpacesInValues(value)}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the quantity.',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Order Quantity"
                        maxLength={6}
                        onKeyPress={(event) => onlyNumberKey(event)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )
          }
        </div>
        
      </Modal>
    </>
  );
};

export default AddAssetModal;
