/* eslint-disable no-unused-expressions */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Modal, Input, Tooltip
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const SearchModal = (props) => {
  const {
    onSearch, searchText, setValue, searchData, cancelAction, group
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const searchInputRef = useRef();

  const showModal = () => {
    localStorage.setItem('cancelValue', false);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setValue('');
    cancelAction('searchCancelClick');
  };

  const handleOk = () => {
    setValue(searchText);
    setIsModalVisible(false);
    searchData();
  };

  useEffect(() => {
    searchInputRef.current && searchInputRef.current.focus();
  });

  return (
    <>
      <Tooltip placement="topLeft" title="Search project number/name">
        <Button className={group ? 'search-top' : ''} shape="circle" onClick={showModal} disabled={!group}>

          <SearchOutlined />
        </Button>
      </Tooltip>
      <Modal title="Search Projects" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <div className="d-flex position-relative align-items-center">
          <Input
            ref={searchInputRef}
            size="large"
            placeholder="Enter Project Name/Number"
            className="search-input"
            onChange={onSearch}
            value={searchText}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                localStorage.setItem('cancelValue', false);
                setValue(searchText);
                setIsModalVisible(false);
                searchData();
              }
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default SearchModal;
