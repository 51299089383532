import React from 'react';
import { Input } from 'antd';
import get from 'lodash/get';
import moment from 'moment';
import 'antd/dist/antd.dark.css';

export const getProjectInfo = (modalData) => (
  <div>
    <div style={{
      display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', margin: 5,
    }}
    >
      <div style={{ flex: 1 }}>Month</div>
      <div style={{ flex: 1 }}>
        <Input size="small" value={modalData && get(modalData[0], 'period', '')} />
      </div>
      <div style={{ flex: 1, marginLeft: 20 }}>Name</div>
      <div style={{ flex: 1 }}>
        <Input size="small" value={modalData && get(modalData[0], 'author', '')} />
      </div>
    </div>
    <div style={{
      display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', margin: 5,
    }}
    >
      <div style={{ flex: 1 }}>Contractor</div>
      <div style={{ flex: 1 }}>
        <Input size="small" value={modalData && get(modalData[0], 'contractor', '')} />
      </div>
      <div style={{ flex: 1, marginLeft: 20 }}>Email</div>
      <div style={{ flex: 1 }}>
        <Input size="small" value={modalData && get(modalData[0], 'email', '')} />
      </div>
    </div>
    <div style={{
      display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', margin: 5,
    }}
    >
      <div style={{ flex: 1 }}>Project No.</div>
      <div style={{ flex: 1 }}>
        <Input size="small" value={modalData && get(modalData[0], 'projectNo', '')} />
      </div>
      <div style={{ flex: 1, marginLeft: 20 }}>Created Date</div>
      <div style={{ flex: 1 }}>
        <Input size="small" value={moment(modalData && get(modalData[0], 'createdAt', '')).format('YYYY-MM-DD, hh:mm:ss A')} />
      </div>
    </div>
    <div style={{
      display: 'flex', flex: 1, flexDirection: 'row', margin: 5, marginBottom: 20,
    }}
    >
      <div style={{ flex: 0.5 }}>Lead Tech</div>
      <div style={{ flex: 0.5 }}>
        <Input size="small" value={modalData && get(modalData[0], 'lead', '')} />
      </div>
      <div style={{ flex: 1, marginLeft: 20 }} />
    </div>
  </div>
);

export const getProdLogDetails = (modalData) => (
  <div>
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
      <div style={{
        display: 'flex', flex: 1, flexDirection: 'row', margin: 5,
      }}
      >
        <div style={{ flex: 0.5 }}>Total Welds</div>
        <div style={{ flex: 0.5 }}>
          <Input size="small" value={modalData && get(modalData[0], 'totalWelds', '')} />
        </div>
        <div style={{ flex: 1 }} />
      </div>
      <div style={{
        display: 'flex', flex: 1, flexDirection: 'row', margin: 5,
      }}
      >
        <div style={{ flex: 0.5 }}>Total Repairs</div>
        <div style={{ flex: 0.5 }}>
          <Input size="small" value={modalData && get(modalData[0], 'totalRepairs', '')} />
        </div>
        <div style={{ flex: 1 }} />
      </div>
      <div style={{
        display: 'flex', flex: 1, flexDirection: 'row', margin: 5,
      }}
      >
        <div style={{ flex: 0.5 }}>Repair Rate</div>
        <div style={{ flex: 0.5 }}>
          <Input size="small" value={modalData && get(modalData[0], 'repairRate', '')} />
        </div>
        <div style={{ flex: 1 }} />
      </div>
    </div>
  </div>
);

export const getTechLogDetails = (modalData) => (
  <div>
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
      <div style={{
        display: 'flex', flex: 1, flexDirection: 'row', margin: 5,
      }}
      >
        <div style={{ flex: 0.5 }}>Total Days On Site</div>
        <div style={{ flex: 0.5 }}>
          <Input size="small" value={modalData && get(modalData[0], 'totalDaysOnsite', '')} />
        </div>
        <div style={{ flex: 1 }} />
      </div>
      <div style={{
        display: 'flex', flex: 1, flexDirection: 'row', margin: 5,
      }}
      >
        <div style={{ flex: 0.5 }}>Total Travel Days</div>
        <div style={{ flex: 0.5 }}>
          <Input size="small" value={modalData && get(modalData[0], 'totalTravelDays', '')} />
        </div>
        <div style={{ flex: 1 }} />
      </div>
      <div style={{
        display: 'flex', flex: 1, flexDirection: 'row', margin: 5,
      }}
      >
        <div style={{ flex: 0.5 }}>Total Work Days</div>
        <div style={{ flex: 0.5 }}>
          <Input size="small" value={modalData && get(modalData[0], 'totalWorkDays', '')} />
        </div>
        <div style={{ flex: 1 }} />
      </div>
    </div>
  </div>
);

export const getCoatingProdLogDetails = (modalData) => (
  <div>
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
      <div style={{
        display: 'flex', flex: 1, flexDirection: 'row', margin: 5,
      }}
      >
        <div style={{ flex: 0.5 }}>Total Production FJ Completed</div>
        <div style={{ flex: 0.5 }}>
          <Input size="small" value={modalData && get(modalData[0], 'totalProductionFJCompleted', '')} />
        </div>
        <div style={{ flex: 1 }} />
      </div>
      <div style={{
        display: 'flex', flex: 1, flexDirection: 'row', margin: 5,
      }}
      >
        <div style={{ flex: 0.5 }}>Total Quality Incidents</div>
        <div style={{ flex: 0.5 }}>
          <Input size="small" value={modalData && get(modalData[0], 'totalQualityIncidents', '')} />
        </div>
        <div style={{ flex: 1 }} />
      </div>
      <div style={{
        display: 'flex', flex: 1, flexDirection: 'row', margin: 5,
      }}
      >
        <div style={{ flex: 0.5 }}>Total Quality FJ Repairs</div>
        <div style={{ flex: 0.5 }}>
          <Input size="small" value={modalData && get(modalData[0], 'totalQualityFJRepairs', '')} />
        </div>
        <div style={{ flex: 1 }} />
      </div>
      <div style={{
        display: 'flex', flex: 1, flexDirection: 'row', margin: 5,
      }}
      >
        <div style={{ flex: 0.5 }}>Total Downtime Minutes</div>
        <div style={{ flex: 0.5 }}>
          <Input size="small" value={modalData && get(modalData[0], 'totalDowntimeMinutes', '')} />
        </div>
        <div style={{ flex: 1 }} />
      </div>
      <div style={{
        display: 'flex', flex: 1, flexDirection: 'row', margin: 5,
      }}
      >
        <div style={{ flex: 0.5 }}>Total Equipment Breakdowns</div>
        <div style={{ flex: 0.5 }}>
          <Input size="small" value={modalData && get(modalData[0], 'totalEquipmentBreakdowns', '')} />
        </div>
        <div style={{ flex: 1 }} />
      </div>
      <div style={{
        display: 'flex', flex: 1, flexDirection: 'row', margin: 5,
      }}
      >
        <div style={{ flex: 0.5 }}>Total Safety Incidents</div>
        <div style={{ flex: 0.5 }}>
          <Input size="small" value={modalData && get(modalData[0], 'totalSafetyIncidents', '')} />
        </div>
        <div style={{ flex: 1 }} />
      </div>
    </div>
  </div>
);
