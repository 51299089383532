/* eslint-disable array-callback-return */
/* eslint-disable linebreak-style */
import get from 'lodash/get';
import {
  FETCH_ANALYSIS,
  SET_ANALYSIS,
  SET_ANALYSIS_FAILURE
} from '../types';

const initialState = {
  isProjectLoading: false,
};

const analysisRequests = (state = initialState, action) => {
  switch (action.type) {
    case SET_ANALYSIS: {
      const analysis = get(action, ['response'], []);
      const analysisData = analysis.data;
      return {
        ...state,
        data: analysisData,
        isLoading: false,
      };
    }

    case FETCH_ANALYSIS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_ANALYSIS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default analysisRequests;
