/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import {
  Modal, Button, notification, Switch, Tabs, Tooltip, Table, Typography
} from 'antd';
import Excel from 'exceljs';
import { prepareDataForEMEADPRExcelOverall, writeWorkSheetForEMEADPROverall } from '../utils/utility';
import DprEmeaProdGragh from '../layout/dprEmeaProdGragh';
import DprEmeaDownTimeGragh from '../layout/dprEmeaDownTimeGragh';
import { calcDowntime } from '../services/calendar';

const { TabPane } = Tabs;
const { Title } = Typography;

const EMEAWeldesOverallModal = (props) => {
  const {
    currentReport, visible, setVisible, projNo
  } = props;
  const [newData, setNewData] = useState(false);
  const [tablehtml, setTablehtml] = useState('');
  const [showGraph, setShowGraph] = useState(false);

  const tableData = () => {
    let tableval = null;
    const columns = [
      {
        title: 'Month',
        dataIndex: 'period',
        key: 'period',
        align: 'center',
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        align: 'center',
      },
      {
        title: 'Welds',
        dataIndex: 'weld_count_accepted',
        key: 'weld_count_accepted',
        align: 'center',
        render: (text) => <>{text || 0}</>
      },
      {
        title: 'Repairs',
        dataIndex: 'weld_count_repairs',
        key: 'weld_count_repairs',
        align: 'center',
        render: (text) => <>{text || 0}</>
      },
      {
        title: 'Repair Rate',
        dataIndex: 'repair_rate',
        key: 'repair_rate',
        align: 'center',
        render: (text) => <>{text || 0}</>
      },
      {
        title: 'Comments',
        dataIndex: 'comments',
        key: 'comments',
        align: 'center',
      },
      {
        title: 'Report',
        dataIndex: 'pdf_url',
        key: 'pdf_url',
        align: 'center',
        render: (record) => {
          if (record) {
            return (
              <div>
                {' '}
                <a
                  className="export-link"
                  title="Download pdf report"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={record}
                  // eslint-disable-next-line react/jsx-closing-bracket-location
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <span className="icon-16 mr-2">
                    <svg>
                      <use xlinkHref="#export" />
                    </svg>
                  </span>
                </a>
              </div>
            );
            // eslint-disable-next-line brace-style
          }
          // eslint-disable-next-line no-else-return
          else {
            return <div>-</div>;
          }
        },
      },
    ];
    const downtimeColumns = [
      {
        title: 'Month',
        dataIndex: 'period',
        key: 'period',
        align: 'center',
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        align: 'center',
      },
      {
        title: 'Contractor',
        dataIndex: 'downtime_contractor',
        key: 'downtime_contractor',
        align: 'center',
        render: (text) => <>{calcDowntime(text)}</>
      },
      {
        title: 'CRCE Equipment',
        dataIndex: 'downtime_crce_equipment',
        key: 'downtime_crce_equipment',
        align: 'center',
        render: (text) => <>{calcDowntime(text)}</>
      },
      {
        title: 'CRCE Personnel',
        dataIndex: 'downtime_crce_personnel',
        key: 'downtime_crce_personnel',
        align: 'center',
        render: (text) => <>{calcDowntime(text)}</>
      },
      {
        title: 'Total',
        dataIndex: 'downtime',
        key: 'downtime',
        align: 'center',
        render: (text) => <>{(text)}</>
      },
    ];
    if (!showGraph) {
      tableval = (
        <Tabs className="custom-tab h-95">
          <TabPane tab="Production" key={1}>
            <Table
              pagination={false}
              scroll={{ x: newData?.length > 0 ? 600 : 0, y: '100%' }}
              dataSource={newData}
              columns={columns}
            />
          </TabPane>
          <TabPane tab="Down Time" key={2}>
            <Table
              pagination={false}
              scroll={{ x: newData?.length > 0 ? 600 : 0, y: '100%' }}
              dataSource={newData}
              columns={downtimeColumns}
            />
          </TabPane>
        </Tabs>
      );
    } else {
      tableval = (
        <Tabs className="custom-tab h-95">
          <TabPane tab="Production" key={1}>
            <div style={{ height: '300px' }}>
              <DprEmeaProdGragh currentMonthData={newData} />
            </div>
          </TabPane>
          <TabPane tab="Downtime" key={2}>
            <div style={{ height: '300px' }}>
              <DprEmeaDownTimeGragh currentMonthData={newData} />
            </div>
          </TabPane>
        </Tabs>
      );
    }
    setTablehtml(tableval);
  };

  useEffect(() => {
    if (visible === false) {
      setTablehtml('');
      return;
    }
    tableData();
    if (currentReport) {
      currentReport.sort((a, b) => new Date(a.date) - new Date(b.date));
      setNewData(currentReport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport, visible]);

  useEffect(() => {
    if (newData !== []) {
      tableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);

  const exportToExcel = async () => {
    try {
      const { columns } = prepareDataForEMEADPRExcelOverall();
      const workbook = new Excel.Workbook();
      const workSheetName = 'Sheet1';
      const fileName = `${projNo}_monthly_production`;
      let worksheet = workbook.addWorksheet(workSheetName);
      worksheet.columns = columns;
      worksheet = writeWorkSheetForEMEADPROverall(worksheet, currentReport);

      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.log('error', error);
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  useEffect(() => {
    if (showGraph) {
      tableData(showGraph);
    } else {
      tableData(showGraph);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showGraph]);

  return (
    <>
      <Button className="hidden-view-btn" onClick={() => setVisible(true)}>v</Button>
      <Modal
        title={(
          <>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '53%', fontSize: '14px' }}>
                <Title level={4}>Overall Production Log Report</Title>
              </div>

              {
                currentReport && (
                  <>
                    <Switch
                      className="modal-switch"
                      checkedChildren="Graphical"
                      unCheckedChildren="Tabular"
                      checked={showGraph}
                      onChange={(checked) => {
                        if (checked) {
                          setShowGraph(checked);
                        } else {
                          setShowGraph(checked);
                        }
                      }}
                    />
                    <Button
                      size="small"
                      shape="round"
                      type="primary"
                      className="export-excel"
                      onClick={exportToExcel}
                    >
                      <Tooltip title="Download excel report" placement="topRight">
                        <span className="icon-16 mr-2">
                          <svg>
                            <use xlinkHref="#export" />
                          </svg>
                        </span>
                      </Tooltip>

                    </Button>
                  </>

                )
              }
            </div>
            <div>
              <span style={{ color: '#F33E4C', fontWeight: 'bold' }}>Total Weld :  </span>
              {currentReport && currentReport[0]?.overallWeldCount}
              {', '}
              <span style={{ color: '#F33E4C', fontWeight: 'bold' }}>Total Repair :  </span>
              {currentReport && currentReport[0]?.overallrepairCount}
              {', '}
              <span style={{ color: '#F33E4C', fontWeight: 'bold' }}>Repair Rate :  </span>
              {currentReport && currentReport[0]?.overallRpairRate}
              {', '}
              <span style={{ color: '#F33E4C', fontWeight: 'bold' }}>Total Downtime :  </span>
              { currentReport && currentReport[0]?.overallDowntime}
            </div>
          </>
        )}
        className="welds-modal"
        centered
        visible={visible}
        width={1009}
        footer={[
          <Button key="submit" type="primary" onClick={() => { setVisible(false); setShowGraph(false); }}>
            OK
          </Button>
        ]}
      >
        <div className="ant-table scroll-table">
          <div className="ant-table-container h-100">
            <div className="ant-table-content h-100">

              {tablehtml}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EMEAWeldesOverallModal;
