import React from 'react';
import { Layout } from 'antd';
import LoginForm from '../../Components/LoginForm';
import Logo from '../../images/fsa_logo_withtext.svg';

const LoginPage = () => (
  <Layout.Content className="h-100">
    <div className="d-flex h-100 w-100 overflow-hidden">
      <div className="d-fill overflow-auto w-50 login-bg">
        <div className="pt-5 ps-5 position-relative">
          <a href="aside-logo" className="login-logo">
            <img src={Logo} alt="logo" />
          </a>
        </div>
      </div>
      <div className="d-fill w-50 overflow-auto">
        <LoginForm />
      </div>
    </div>
  </Layout.Content>
);

export default LoginPage;
