/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import {
  Modal, Button, Table, notification, Spin, Tabs
} from 'antd';
import moment from 'moment';
import { getInventoryData } from '../services/summary';
import { getPipeline } from '../services/pipeline';

const { TabPane } = Tabs;

const columns = [
  {
    title: 'Part Number',
    dataIndex: 'serial_number',
    key: 'serial_number',
    align: 'center'
  },
  {
    title: 'Description',
    dataIndex: 'material_description',
    key: 'material_description',
    align: 'center'
  },
  {
    title: 'Order Quantity',
    dataIndex: 'order_quantity',
    key: 'order_quantity',
    align: 'center'
  },
  {
    title: 'Current Quantity',
    dataIndex: 'current_quantity',
    key: 'current_quantity',
    align: 'center'
  },
  {
    title: 'Consumed Quantity',
    dataIndex: 'consumedQuantity',
    key: 'consumedQuantity',
    align: 'center'
  },
];

const InventoryDPRToday = (props) => {
  const {
    visible, setVisible, projNo, shift
  } = props;
  const [loading, setLoading] = useState(false);
  const [pipelineFetchLoading, setPipelineFetchLoading] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [pipelineData, setPipelineData] = useState([]);
  const [tabValue, setTabValue] = useState('');
  const getData = async () => {
    setLoading(true);
    try {
      const date = moment(new Date()).format('YYYY-MM-DD');
      const projectPipelinesId = tabValue;
      const res = await getInventoryData(projNo, date, shift, projectPipelinesId);
      setInventoryData(res.data);
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };

  const getTableColumns = async () => {
    setPipelineFetchLoading(true);
    try {
      const res = await getPipeline(projNo);
      if (res?.pipelineData && res?.pipelineData.length > 0) {
        const pipelineFilteredData = res?.pipelineData.filter((item) => item.is_deleted === 0);
        setPipelineData(pipelineFilteredData);
        setTabValue(pipelineFilteredData[0] && pipelineFilteredData[0]?.id ? pipelineFilteredData[0]?.id : '');
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setPipelineFetchLoading(false);
    }
  };

  useEffect(() => {
    if (visible) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, tabValue]);

  const onTabChange = (activeKey) => {
    setTabValue(activeKey);
  };

  useEffect(() => () => {
    setTabValue('');
    setPipelineData([]);
  }, []);

  useEffect(() => {
    if (projNo && visible) {
      getTableColumns();
    }
  }, [projNo, visible]);

  return (
    <>
      <Modal
        title="Inventory"
        className="welds-modal"
        centered
        visible={visible}
        destroyOnClose
        width={1009}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setVisible(false);
              setTabValue('');
              setPipelineData([]);
              setInventoryData([]);
            }}
          >
            OK
          </Button>
        ]}
      >
        <div>
          <div className="flex-fill overflow-auto">
            {
              pipelineData && pipelineData.length > 0 && (
                <Tabs disabled={loading} activeKey={tabValue.toString()} onChange={onTabChange} className="custom-tab h-100">
                  {
                    pipelineData.map((item) => (
                      <TabPane tab={item.name} key={item.id} value={item.id} />
                    ))
                  }
                </Tabs>
              )
            }
          </div>
          <Table
            pagination={false}
            columns={columns}
            dataSource={!pipelineFetchLoading && !loading ? inventoryData : []}
            className="table-with-button-assets"
            loading={{
              indicator: <Spin />,
              spinning: loading
            }}
            scroll={{ x: true }}
            style={{ whiteSpace: 'nowrap' }}
          />
        </div>
      </Modal>
    </>
  );
};

export default InventoryDPRToday;
