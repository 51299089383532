import {
  GET_WIRE_USAGE_REPORTS, SET_WIRE_USAGE_REPORTS,
  SET_WIRE_USAGE_REPORTS_FAILURE
} from '../types';

const getWireUsageReports = (projectNo) => ({
  type: GET_WIRE_USAGE_REPORTS,
  projectNo,
});

const setWireUsageReports = (payload) => ({
  type: SET_WIRE_USAGE_REPORTS, ...payload,
});

const setWireUsageReportFailure = () => ({
  type: SET_WIRE_USAGE_REPORTS_FAILURE,
});

export {
  getWireUsageReports,
  setWireUsageReports,
  setWireUsageReportFailure,
};
