/* eslint-disable import/no-cycle */
import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import {
  signfailure, signinsuccess
} from '../state/action/authAction';
import { SIGNIN } from '../state/types';
import { userSignIn } from '../services/auth';
import { parseJwt } from '../utils/utility';

function* signIn({ credentials }) {
  try {
    const res = yield userSignIn(credentials.username?.trim().toLowerCase(),
    credentials.password?.trim());
    notification.destroy();
    if (res && !res.challengeName) {
      notification.success({ message: 'Login Successful.' });
    }
    yield put(signinsuccess({ res }));
  } catch (error) {
    yield put(signfailure());
    notification.destroy();
    if (error.message === 'Network error.') {
      notification.error({
        message: error.message
      });
    } else {
      notification.error({
        message: 'Incorrect email or password.',
      });
    }
  }
}

export const getCurrentUser = () => {
  const token = localStorage.getItem('ap_idToken') ?? '';
  if (token) {
    const userPayload = parseJwt(token);
    if (userPayload.exp < new Date().getTime() / 1000) {
      return null;
    }
    return parseJwt(token);
  }
  return null;
};

function* authWatcher() {
  yield takeLatest(SIGNIN, signIn);
}

function* authSagas() {
  yield all([
    call(authWatcher),
  ]);
}

export default authSagas;
