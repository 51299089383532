import { notification } from 'antd';
import fetch from './fetch';
import { BASE_URL } from '../config';

export const getDPREMEA = async (projectNo) => {
  try {
    const res = await fetch(`${BASE_URL}/reports?projectNumber=${projectNo}&type=ProductionLog`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    if (res.status === 200) {
      const safetyData = await res.json();
      return safetyData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getDPREMEAReport = async (projectNo, date) => {
  try {
    const res = await fetch(`${BASE_URL}/projectsData/dpr-emea/report?projectNo=${projectNo}&date=${date}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    if (res.status === 200) {
      const safetyData = await res.json();
      return safetyData;
    }
    return null;
  } catch (error) {
    return null;
  }
};
