/* eslint-disable array-callback-return */
/* eslint-disable linebreak-style */
import get from 'lodash/get';
import {
  FETCH_EMAILS,
  SET_EMAILS,
  SET_EMAIL_FAILURE
} from '../types';

const initialState = {
  isProjectLoading: false,
};

const assetReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMAILS: {
      const emails = get(action, ['response'], []);
      const asset = emails.data;
      const data = [];
      for (let i = 0; i < asset.length; i += 1) {
        data[i] = {
          key: i,
          id: emails?.data[i]?.id,
          email: emails?.data[i]?.email,
          reportType: emails?.data[i]?.report_type
        };
      }
      return {
        ...state,
        data,
        isLoading: false,
      };
    }

    case FETCH_EMAILS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_EMAIL_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default assetReducer;
