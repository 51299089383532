/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Icon from '@ant-design/icons';

const EyeOpenSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 25 25">
    <g id="Layer_2" data-name="Layer 2" transform="translate(0 0.375)">
      <g id="eye">
        <rect id="Rectangle_1628" data-name="Rectangle 1628" width="25" height="25" transform="translate(0 -0.375)" fill="currentColor" opacity="0" />
        <path id="Path_608" data-name="Path 608" d="M22.085,11.571C21.439,10.448,17.88,4.818,11.836,5c-5.59.142-8.825,5.054-9.7,6.57a1.011,1.011,0,0,0,0,1.011c.637,1.1,4.043,6.57,10,6.57h.253c5.59-.142,8.835-5.054,9.7-6.57A1.011,1.011,0,0,0,22.085,11.571Zm-9.755,5.56c-4.357.1-7.2-3.629-8.087-5.054,1.011-1.627,3.649-4.953,7.693-5.054,4.337-.111,7.187,3.629,8.087,5.054-1.041,1.627-3.649,4.953-7.693,5.054Z" transform="translate(0.022 0.054)" fill="currentColor" />
        <path id="Path_609" data-name="Path 609" d="M12.038,8.5a3.538,3.538,0,1,0,3.538,3.538A3.538,3.538,0,0,0,12.038,8.5Zm0,5.054a1.516,1.516,0,1,1,1.516-1.516A1.516,1.516,0,0,1,12.038,13.554Z" transform="translate(0.092 0.092)" fill="currentColor" />
      </g>
    </g>
  </svg>

);
export const EyeOpenIcon = (props) => (
  <Icon component={EyeOpenSvg} {...props} />
);
