import {
  GET_DAMAGE_REPORTS, SET_DAMAGE_REPORTS,
  SET_DAMAGE_REPORTS_FAILURE
} from '../types';

const getDamageReports = (projectNo) => ({
  type: GET_DAMAGE_REPORTS,
  projectNo,
});

const setDamageReports = (payload) => ({
  type: SET_DAMAGE_REPORTS, ...payload,
});

const setDamageReportFailure = () => ({
  type: SET_DAMAGE_REPORTS_FAILURE,
});

export {
  getDamageReports,
  setDamageReports,
  setDamageReportFailure,
};
