/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import {
  Table, Spin, Tag, Button, notification, Tooltip, Form, Modal, Input
} from 'antd';
import {
  EditOutlined
} from '@ant-design/icons';
import { DeleteIcon } from './svgs/deleteIcon';
import {
  getDocument, getDocumentTag, addDocument, updateDocumentHistoryTag, deleteDocument
} from '../services/document';
import AddDocumentModal from './AddDocumentModal';
import { authenticateCurrentUser } from '../services/auth';

const Document = ({ projNo }) => {
  const [loading, setLoading] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [addDocumentModal, setAddDocumentModal] = useState(false);
  const [documentTagData, setdocumentTagData] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [fileUloaded, setIsFileUploaded] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [documentForm] = Form.useForm();
  const { Search } = Input;

  const getDocumentTagData = async () => {
    setLoading(true);
    try {
      const res = await getDocumentTag();
      if (res?.data) {
        const data = res?.data;
        setdocumentTagData(data);
      } else setdocumentTagData([]);
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };

  const getDocumentData = async (searchVal = '') => {
    setLoading(true);
    try {
      const res = await getDocument(projNo, searchVal);
      if (res?.data) {
        const data = res?.data;
        setDocumentData(data);
      } else setDocumentData([]);
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDocumentData();
    getDocumentTagData();
    // eslint-disable-next-line
  }, [projNo]);

  const onSave = async (values) => {
    try {
      setLoading(true);
      const {
        name, documentUrl, tag, attachmentsData
      } = values;
      const userDetails = await authenticateCurrentUser();
      const formData = {
        name,
        document_url: documentUrl,
        uploaded_by: userDetails.attributes.name,
        tag,
        project_number: projNo,
        attachments_data: attachmentsData
      };
      const res = await addDocument(formData);
      await getDocumentData();
      setLoading(false);
      if (!res.err) {
        documentForm.resetFields();
        setAddDocumentModal(false);
        setIsFileUploaded(false);
      } else {
        notification.error({
          message: res.message || 'Something went wrong.',
        });
      }
    } catch (error) {
      notification.error({
        message: error.message || 'Something went wrong.',
      });
    }
  };

  const onUpdate = async (values) => {
    try {
      setLoading(true);
      const {
        name, tag, documentUrl, attachmentsData
      } = values;
      const formData = {
        name,
        document_id: recordData.document_id,
        tag,
        project_number: projNo,
        document_url: documentUrl,
        attachments_data: attachmentsData
      };
      const res = await updateDocumentHistoryTag(formData);
      await getDocumentData();
      setLoading(false);
      if (!res.err) {
        setAddDocumentModal(false);
        documentForm.resetFields();
        setRecordData([]);
        setIsEdit(false);
        setIsFileUploaded(false);
      } else {
        notification.error({
          message: res.message || 'Something went wrong.',
        });
      }
    } catch (error) {
      notification.error({
        message: error.message || 'Something went wrong.',
      });
      setAddDocumentModal(false);
      documentForm.resetFields();
      setRecordData([]);
      setIsEdit(false);
      setIsFileUploaded(false);
    }
  };

  const handleTableChnage = (pagination,
    filters,
    sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const editBtnHandler = (record) => {
    setRecordData(record);
    if (record) {
      setIsEdit(true);
      // const documentTagIds = record?.document_history.map((item) => item.document_tag_id);

      const documentTagIds = record?.document_history.map((item) => ({
        value: item.document_tag_id,
        key: item.document_tag_id,
        label: item.tag
      }));

      documentForm.setFieldsValue({
        name: record?.document_name,
        tag: documentTagIds,
        // documentUrl: record?.document_url
      });
    } else {
      setIsEdit(false);
      setIsFileUploaded(false);
    }
    setAddDocumentModal(true);
  };

  const handleDeletion = (record) => {
    Modal.confirm({
      title: `Are you sure you want to remove ${record.document_name} Document?`,
      className: 'custom-text-clr',
      async onOk() {
        return deleteDocument(record.document_id).then((res) => {
          if (res) {
            getDocumentData();
          } else {
            notification.error({
              message: 'Something went wrong.',
            });
          }
          return true;
        }).catch(() => notification.error({
          message: 'Something went wrong.',
        }));
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'Document Name',
      dataIndex: 'document_name',
      key: 'document_name',
      align: 'left',
      width: '30%'
    },
    {
      title: 'Tags',
      dataIndex: 'document_history',
      key: 'document_history',
      align: 'left',
      width: '50%',
      render: (record) => (
        <span>
          {
          record.map((tag) => (
            <Tag round className="document-tag" key={tag.document_tag_id}>
              {tag.tag}
            </Tag>
          ))
          }
        </span>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'document_url',
      key: 'document_url',
      align: 'left',
      width: '20%',
      render: (text, record) => {
        if (record) {
          return (
            <div>
              {' '}
              <a
                className="export-link"
                title="Reports"
                target="_blank"
                rel="noopener noreferrer"
                href={record.document_url}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span className="icon-16 mr-2">
                  <svg>
                    <use
                      xlinkHref="#export"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </svg>
                </span>
              </a>
              <Tooltip
                arrowPointAtCenter
                placement="topLeft"
                title="Edit Document"
              >
                <EditOutlined
                  style={{ marginLeft: '30px', fontSize: '16px' }}
                  className="edit-project-detail"
                  onClick={() => {
                    editBtnHandler(record);
                  }}
                />
              </Tooltip>
              <Tooltip
                arrowPointAtCenter
                placement="topRight"
                title="Delete Document"
              >
                <DeleteIcon
                  style={{ marginLeft: '30px', fontSize: '16px' }}
                  onClick={() => handleDeletion(record)}
                />
              </Tooltip>
            </div>
          );
        }
        return <div>-</div>;
      }
    },

  ];

  const searchDoc = async (value) => {
    getDocumentData(value);
  };

  return (
    <>
      <div className="h-100">
        <div
          className="d-flex pb-2 align-items-center justify-content-between"
        >
          <div className="d-flex edit-session justify-content-center">
            <Search
                // size='def'
              style={{ width: '250px' }}
              className="sbd-search-wrapper"
              placeholder="Enter Document Name / Tag Name"
              allowClear
              enterButton
              onSearch={searchDoc}
              title="Enter Document Name / Tag Name"
            />
          </div>
          <div className="d-flex justify-content-center">
            <Tooltip
              placement="topLeft"
            >
              <Button
                key="submit"
                size="medium"
                shape="round"
                type="primary"
                disabled={loading}
                onClick={() => {
                  setAddDocumentModal(true);
                }}
              >
                Add Document
              </Button>
            </Tooltip>
          </div>
        </div>
        <Table
          pagination={documentData.length > 10 ? tableParams.pagination : false}
          columns={columns}
          dataSource={documentData}
          className="table-with-button-assets"
          height={80}
          scroll={{ x: documentData.length > 0 ? 1000 : 0, y: '100%' }}
          loading={{
            indicator: <Spin />,
            spinning: loading
          }}
          onChange={handleTableChnage}
        />
      </div>
      <AddDocumentModal
        addDocumentModal={addDocumentModal}
        setAddDocumentModal={setAddDocumentModal}
        documentTagData={documentTagData}
        loading={loading}
        setLoading={setLoading}
        onSave={onSave}
        documentForm={documentForm}
        recordData={recordData}
        setRecordData={setRecordData}
        isEdit={isEdit}
        onUpdate={onUpdate}
        getDocumentTagData={getDocumentTagData}
        setIsEdit={setIsEdit}
        fileUloaded={fileUloaded}
        setIsFileUploaded={setIsFileUploaded}
      />
    </>
  );
};

export default Document;
