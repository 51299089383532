/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

import React, { useState, useEffect } from 'react';
import {
  Modal, Button, Tabs, Table, Collapse
} from 'antd';

const { TabPane } = Tabs;

const LemModal = (props) => {
  const {
    modalVisible, hideModal, assets, members
  } = props;
  const [assetData, setAssetData] = useState([]);
  const [tablehtml, setTablehtml] = useState('');
  const [onsiteAssetData, setOnsiteAssetData] = useState([]);
  const [inUseAssetData, setInUseAssetData] = useState([]);
  const [spareAssetData, setSpareAssetData] = useState([]);
  const [sentToTulsaAssetData, setSentToTulsaAssetData] = useState([]);
  const [sentToEdmontonAssetData, setSentToEdmontonAssetData] = useState([]);

  const { Panel } = Collapse;

  const assetColumn = [
    {
      title: 'Asset Number',
      dataIndex: 'serial_number',
      key: 'serial_number',
      align: 'center',
    },
    {
      title: 'Description',
      dataIndex: 'material_description',
      key: 'material_description',
      align: 'center',
    },
    {
      title: 'Order Quantity',
      dataIndex: 'order_quantity',
      key: 'order_quantity',
      align: 'center',
    },
  ];

  const userColumn = [
    {
      title: 'Employee Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      align: 'center',
    },
    {
      title: 'Cost Code',
      dataIndex: 'cost_code',
      key: 'cost_code',
      align: 'center',
    },
  ];
  const getOnsiteHeader = (className, dataLength) => (
    <div className="d-flex align-items-center">
      Onsite
      {' '}
      <span className={className} />
      {' '}
      (
      {dataLength}
      )
    </div>
  );
  const tableData = () => {
    const tableval = (
      <Tabs className="custom-tab h-95">
        <TabPane tab="Assets" key={1}>
          <h3>
            Total Number of Assets :
            {' '}
            {assetData.length > 0 ? assetData.length : 0}
          </h3>
          <div className="sbd-assets-lem overflow-y-auto overflow-x-auto">
            {assetData && assetData.length > 0 ? (
              <Collapse
                accordion
                bordered={false}
                className="asset-collapse"
              >
                {onsiteAssetData && onsiteAssetData.length && (
                  <Panel header={getOnsiteHeader('green_dot ms-1 me-1', onsiteAssetData.length)} className="project-sub-title">
                    <Table
                      pagination={false}
                      columns={assetColumn}
                      dataSource={onsiteAssetData && onsiteAssetData}
                      className="table-with-button-assets asset-table"
                      style={{ height: '100%' }}
                      scroll={{
                        x: onsiteAssetData.length > 0 ? 600 : 0,
                        y: '100%',
                      }}
                    />
                  </Panel>
                )}
                {inUseAssetData && inUseAssetData.length && (
                  <Panel header={getOnsiteHeader('red_dot ms-1 me-1', inUseAssetData.length)} className="project-sub-title">
                    <Table
                      pagination={false}
                      columns={assetColumn}
                      dataSource={inUseAssetData && inUseAssetData}
                      className="table-with-button-assets"
                      style={{ height: '103%' }}
                      scroll={{
                        x: inUseAssetData.length > 0 ? 600 : 0,
                        y: '100%',
                      }}
                    />
                  </Panel>
                )}
                {spareAssetData && spareAssetData.length && (
                  <Panel header={`Spare (${spareAssetData.length})`} className="project-sub-title">
                    <Table
                      pagination={false}
                      columns={assetColumn}
                      dataSource={spareAssetData && spareAssetData}
                      className="table-with-button-assets"
                      style={{ height: '103%' }}
                      scroll={{
                        x: spareAssetData.length > 0 ? 600 : 0,
                        y: '100%',
                      }}
                    />
                  </Panel>
                )}
                {sentToTulsaAssetData && sentToTulsaAssetData.length && (
                  <Panel header={`Sent to Tulsa (${sentToTulsaAssetData.length})`} className="project-sub-title">
                    <Table
                      pagination={false}
                      columns={assetColumn}
                      dataSource={sentToTulsaAssetData && sentToTulsaAssetData}
                      className="table-with-button-assets"
                      style={{ height: '103%' }}
                      scroll={{
                        x: sentToTulsaAssetData.length > 0 ? 600 : 0,
                        y: '100%',
                      }}
                    />
                  </Panel>
                )}
                {sentToEdmontonAssetData && sentToEdmontonAssetData.length && (
                  <Panel
                    header={`Send to Edmonton (${sentToEdmontonAssetData.length})`}
                    className="project-sub-title"
                  >
                    <Table
                      pagination={false}
                      columns={assetColumn}
                      dataSource={
                        sentToEdmontonAssetData && sentToEdmontonAssetData
                      }
                      className="table-with-button-assets"
                      style={{ height: '103%' }}
                      scroll={{
                        x: sentToEdmontonAssetData.length > 0 ? 600 : 0,
                        y: '100%',
                      }}
                    />
                  </Panel>
                )}
              </Collapse>
            ) : (
              <Table />
            )}
          </div>
        </TabPane>
        <TabPane tab="Team Members" key={2}>
          <Table
            pagination={false}
            columns={userColumn}
            dataSource={members && members}
            className="mh-100 modal-pagination"
            scroll={{ x: members.length > 0 ? 600 : 0, y: '100%' }}
          />
        </TabPane>
      </Tabs>
    );
    setTablehtml(tableval);
  };

  useEffect(() => {
    tableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetData, members]);

  useEffect(() => {
    const assetDt = [];
    const onsiteasset = [];
    const inuseasset = [];
    const spareasset = [];
    const senttotulsaasset = [];
    const senttoedmondonasset = [];
    assets.forEach((dt) => {
      if (dt.status && dt.status === 'Onsite') {
        onsiteasset.push(dt);
      } else if (dt.status && dt.status === 'In Use') {
        inuseasset.push(dt);
      } else if (dt.status && dt.status === 'Spare') {
        spareasset.push(dt);
      } else if (dt.status && dt.status === 'Sent to Tulsa') {
        senttotulsaasset.push(dt);
      } else if (dt.status && dt.status === 'Sent to Edmonton') {
        senttoedmondonasset.push(dt);
      } else if (dt.status === null) {
        onsiteasset.push(dt);
      }
      assetDt.push(dt);
    });
    setAssetData(assetDt);
    setOnsiteAssetData(onsiteasset);
    setInUseAssetData(inuseasset);
    setSpareAssetData(spareasset);
    setSentToTulsaAssetData(senttotulsaasset);
    setSentToEdmontonAssetData(senttoedmondonasset);
  }, [assets]);

  return (
    <>
      <Modal
        title="LEM Report"
        className="welds-modal"
        centered
        visible={modalVisible}
        width={1000}
        footer={[
          <Button key="submit" type="primary" onClick={() => hideModal()}>
            OK
          </Button>,
        ]}
      >
        <div>
          <div className="ant-table scroll-table">
            <div className="ant-table-container h-100">
              <div className="ant-table-content h-100">{tablehtml}</div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LemModal;
