/* eslint-disable no-param-reassign */
/* eslint-disable linebreak-style */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import {
  func, arrayOf, objectOf, oneOfType, string, number, array
} from 'prop-types';
import { notification, Modal } from 'antd';
import moment from 'moment';
import { authenticateCurrentUser } from '../../services/auth';
import ProjectListHeader from '../../layout/projectListHeader';
import TabList from '../../layout/tab';
import { fetchProject } from '../../state/action/projectAction';
import { fetchProjectList } from '../../state/action/projectListAction';
import 'antd/dist/antd.dark.css';
import { updateProjectDetail } from '../../services/projectList';
import ComingSoon from '../../Components/Comingsoon';
import { getApprovalReqCount } from '../../services/advisory';

const HomePage = (props) => {
  const {
    onGetQualityReports, qualityData, showProjectListLoader, onGetProjectsList, projectListData
  } = props;
  const [projNo, setProjNo] = useState('');
  const [projectList, setProjectList] = useState([]);
  const [completedWeldsCount,setCompletedWeldCount] = useState(0);
  const [reload, setReload] = useState(false);
  const [proj, setProj] = useState([]);
  const [curProdReport, setCurProdReport] = useState([]);
  const [prevProdReport, setPrevProdReport] = useState([]);
  const [curTechReport, setCurTechReport] = useState([]);
  const [prevTechReport, setPrevTechReport] = useState([]);
  const [damageReport, setDamageRep] = useState([]);
  const [qualReport,setQualRep] = useState([]);
  const [partsOrderReport, setPartsOrderRep] = useState([]);
  const [lemReport, setLemRep] = useState([]);
  const [reloadDates, setReloadDates] = useState([]);
  const [timesheet, setTimesheet] = useState([]);
  const [wireUsageReport, setWireUsageRep] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [assets, setAssets] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [tab, setTab] = useState();
  const [index,setIndex] = useState(0);
  const [group, setGroup] = useState(false);
  const [disableSelection, setDisableSelection] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedProjectDetail, setSelectedProjectDetail] = useState([]);
  const [dates, setDates] = useState([]);
  const history = useHistory();

  const getApprovalReq = async () => {
    try {
      const response = await getApprovalReqCount();

      if (response.approvalRequestCount > 0) {
        Modal.confirm({
          title: 'Do you want to view the pending request for the advisory?',
          className: 'custom-text-clr',
          async onOk() {
            localStorage.setItem('approvalConfirmViewed', true);
            history.push({
              pathname: '/admin',
              search: `?tabValue=4`
            });
            return true;
          },
          async onCancel() {
            localStorage.setItem('approvalConfirmViewed', true);
            onGetProjectsList();
            return true;
          },
        });
      } else {
        onGetProjectsList();
      }
    } catch (error) {
      notification.error({
        message: error.message || 'Something went wrong.',
      });
    }
  };

  useEffect(() => {
    // setReload(false);
    authenticateCurrentUser()
      .then((data) => {
        if (data.signInUserSession.accessToken.payload["cognito:groups"] && data.signInUserSession.accessToken.payload["cognito:groups"].includes('crc-admin')) {
          const isApprovalViewed = localStorage.getItem('approvalConfirmViewed');
          if (isApprovalViewed !== 'true') getApprovalReq();
          else onGetProjectsList();
          setGroup(true);
        } else {
          notification.info({
            message: 'User created successfully. You are not added to any group. Please contact Vinay.Baburao@sbdinc.com.'
          });
          setGroup(false);
        }
      })
      .catch((error) => {
        setGroup(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    let projectListObj = [];
    if (projectListData) {
      projectListData.map((item) => {
        projectListObj.push(item);
      });
      setProjectList(projectListObj);
    }
  },[projectListData]);

  const getReportListByProject = (projectNo) => {
    setProjNo(projectNo);
  };

  useEffect(() => {
    localStorage.setItem('searchVal', '');
    localStorage.setItem('pjctStatus', 'false');
  },[]);

  const reloadNewData = () => {
    if (group) {
      onGetProjectsList();
    }
  };

  const onUpdate = async (startDt, endDt, plannedWelds, projectManagerEmail, projectNo,
    projectStatus, docuSign, projectLeadTech) => {
    setIsUpdate(false);
    const value = {
      project_number: projectNo,
      start_date: startDt ? moment(startDt).format('YYYY-MM-DD hh:mm:ss') : null,
      end_date: endDt ? moment(endDt).format('YYYY-MM-DD hh:mm:ss') : null,
      planned_welds: plannedWelds,
      contractor_email: projectManagerEmail,
      project_status: projectStatus,
      esign_required: docuSign,
      lead_tech: projectLeadTech
    };
    const res = await updateProjectDetail(value);
    if (res) {
      notification.success({
        message: 'Project updated Successfully.',
      });
      setDisableSelection(false); // to be added in notification menu
      onGetProjectsList();
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  const setCompletedWeldsCount = (totalCompletedWelds) => {
    setCompletedWeldCount(totalCompletedWelds);
  };

  return (

    <div className="h-100 d-flex flex-column">
      <div className="flex-auto">
        <ProjectListHeader
          projectList={projectList}
          getReportListByProject={getReportListByProject}
          completedWeldsCount={completedWeldsCount}
          reloadData={reloadNewData}
          reload={reload}
          refresh={(isRefresh) => setReload(isRefresh)}
          setProject={(value) => setProj(value)}
          project={proj}
          setIndex={(value) => setIndex(value)}
          index={index}
          group={group}
          onUpdate={onUpdate}
          disableSelection={disableSelection} // to be added in notification menu
          setDisableSelection={(value) => setDisableSelection(value)}
          setIsUpdate={(value) => setIsUpdate(value)}
          setSelectedProjectDetail={(value) => setSelectedProjectDetail(value)}
          setDates={(value) => setDates(value)}
          showProjectListLoader={showProjectListLoader}
          isUpdate={isUpdate}
        />
      </div>
      {
        projNo ? (
          <div className="flex-fill overflow-auto">
            <TabList
              projectList={projectList}
              qualityData={qualityData}
              projNo={projNo}
              setCompletedWeldsCount={setCompletedWeldsCount}
              onGetQualityReports={onGetQualityReports}
              reload={reload}
              setCurProdRep={(value) => setCurProdReport(value)}
              setPrevProdRep={(value) => setPrevProdReport(value)}
              curProdReport={curProdReport}
              prevProdReport={prevProdReport}
              setCurTechRep={(value) => setCurTechReport(value)}
              setPrevTechRep={(value) => setPrevTechReport(value)}
              curTechReport={curTechReport}
              prevTechReport={prevTechReport}
              setDamageRep={(value) => setDamageRep(value)}
              damageReport={damageReport}
              setQualRep={(value) => setQualRep(value)}
              qualReport={qualReport}
              setPartsOrderRep={(value) => setPartsOrderRep(value)}
              partsOrderReport={partsOrderReport}
              setLemRep={(value) => setLemRep(value)}
              lemReport={lemReport}
              setReloadDates={(value) => setReloadDates(value)}
              reloadDates={reloadDates}
              setTimesheet={(value) => setTimesheet(value)}
              timesheet={timesheet}
              setWireUsageRep={(value) => setWireUsageRep(value)}
              wireUsageReport={wireUsageReport}
              setTeamMembers={(value) => setTeamMembers(value)}
              teamMembers={teamMembers}
              setAssets={(value) => setAssets(value)}
              assets={assets}
              setInventories={(value) => setInventories(value)}
              inventories={inventories}
              tab={tab}
              setTab={(value) => setTab(value)}
              refresh={(isRefresh) => setReload(isRefresh)}
              selectedProjectDetail={selectedProjectDetail}
              dates={dates}
              setDates={(value) => setDates(value)}
            />
          </div>
        ) : <ComingSoon title="Select a project to view report details" />
      }

    </div>
  );
};

HomePage.propTypes = {
  onGetProjectsList: func.isRequired,
  projectListData: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
};

const mapStateToProps = ({
  projectRequests, projectListRequests
}) => {
  const projectListData = get(projectListRequests, 'projectListData', []);
  const showProjectListLoader = get(projectListRequests, 'isProjectListLoading', false);
  return {
    projectListData,
    showProjectListLoader,
  };
};

const mapDispatchToProps = {
  onGetProjects: fetchProject,
  onGetProjectsList: fetchProjectList
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
