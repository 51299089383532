/* eslint-disable camelcase */
import React, { useState } from 'react';
import {
  Modal, Spin, Input, Row, Col
} from 'antd';

const AddPipelineModal = (props) => {
  const {
    isModalVisible, setIsModalVisible, onSave,
    pipelineModalLoading
  } = props;
  const [selectedValue, setSelectedValue] = useState('');
  const [requiredHeader, setRequiredHeader] = useState('');

  const handleOk = () => {
    onSave(selectedValue, requiredHeader);
    setSelectedValue('');
    setRequiredHeader('');
  };

  const handleCancel = () => {
    setSelectedValue('');
    setRequiredHeader('');
    setIsModalVisible(false);
  };
  const onChange = (value) => {
    setSelectedValue(value.target.value);
  };

  const onHeaderChange = (value) => {
    setRequiredHeader(value.target.value);
  };

  const onlyNumberKey = (event) => {
    // Only ASCII character in that range allowed
    const ASCIICode = (event.which) ? event.which : event.keyCode;
    if (ASCIICode >= 48 && ASCIICode <= 57) {
      return true;
    }
    event.preventDefault();
    return false;
  };

  return (
    <>
      <Modal
        className="quality-modal"
        title="Add Project Pipeline"
        centered
        visible={isModalVisible}
        onOk={handleOk}
        okText="Add"
        onCancel={handleCancel}
        okButtonProps={{
          disabled: pipelineModalLoading
            || selectedValue.length === 0 || requiredHeader < 0
        }}
        cancelButtonProps={{ disabled: pipelineModalLoading }}
      >
        {pipelineModalLoading ? <Spin />
          : (
            <Row gutter={16}>
              <Col lg={12}>
                <Input
                  size="large"
                  placeholder="Enter Project Pipeline"
                  maxLength={100}
                  onChange={onChange}
                  value={selectedValue}
                />
              </Col>
              <Col lg={12}>
                <Input
                  size="large"
                  placeholder="Enter Welds Required"
                  maxLength={6}
                  onKeyPress={(event) => onlyNumberKey(event)}
                  onChange={onHeaderChange}
                  value={requiredHeader}
                />
              </Col>
            </Row>
          )}
      </Modal>
    </>
  );
};

export default AddPipelineModal;
