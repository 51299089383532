/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  EditOutlined, CheckOutlined, CloseOutlined
} from '@ant-design/icons';
import {
  Col, Input, Form, notification
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import * as utility from '../utils/utility';
import { updateMachinetype } from '../services/advisory';

function TypeCardTitle(props) {
  const {
    id, name, onClick, machineTypes, onGetMachineType, currentEditId,
    setCurrentEditId, setIsLoading, isLoading
  } = props;
  const [form] = useForm();

  const onEditHandler = () => {
    form.setFieldsValue({ name });
    setCurrentEditId(id);
  };

  const onSave = async (value) => {
    setIsLoading(true);
    const res = await updateMachinetype(value);
    if (!res.err) {
      onGetMachineType();
      form.resetFields();
      setCurrentEditId('');
    } else {
      notification.error({
        message: res.message || 'Something went wrong.',
      });
    }
    setIsLoading(false);
  };

  const handleOk = async () => {
    if (!isLoading) {
      const data = await form.validateFields();
      const isNameAlreadyExists = machineTypes.find((
        item
      ) => item.name.toLowerCase() === data.name.toLowerCase() && item.id !== id);
      if (isNameAlreadyExists) {
        return notification.error({
          message: 'Type already exists.',
        });
      }
      data.id = id;
      onSave(data);
      return '';
    }
    return '';
  };

  const onClose = () => {
    if (!isLoading) {
      form.resetFields();
      setCurrentEditId('');
    }
  };

  const onCardHandler = () => {
    if (!isLoading) onClick();
  };
  return (
    <Col key={id} sm={12} md={8} lg={6} xl={4} className="ps-2">
      <div
        className="custom-card type-card-list cursor-pointer d-flex flex-column align-items-center justify-content-center position-relative p-2"
      >
        {!currentEditId || currentEditId !== id
          ? (
            <>
              {' '}
              <span onClick={onCardHandler} className="menu-content  pt-2">
                {name}
              </span>
              <div disabled={isLoading} onClick={onEditHandler} className="close-type">
                <EditOutlined />
              </div>
            </>
          ) : (
            <>
              <span className="menu-content pt-3">
                <Form
                  layout="vertical"
                  name="update-status"
                  form={form}
                >
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a name.',
                      },
                    ]}
                    normalize={(value) => utility.trimSpacesInValues(value)}
                  >
                    <Input disabled={isLoading} className="ps-3 pt-2" showCount size="large" placeholder="Enter a name" maxLength={50} />
                  </Form.Item>
                </Form>
              </span>
              <span onClick={handleOk} className="edit-type">
                <CheckOutlined />
              </span>
              <span onClick={onClose} className="close-type">
                <CloseOutlined />
              </span>
            </>
          )}
      </div>
    </Col>
  );
}

export default TypeCardTitle;
