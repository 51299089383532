/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import {
  Modal, Button, Table, notification, Spin, Tabs
} from 'antd';
import moment from 'moment';
import { getDownTimeData } from '../services/summary';
import { getPipeline } from '../services/pipeline';

const { TabPane } = Tabs;

const column = [
  {
    title: 'Customer Downtime Today (Minutes)',
    dataIndex: 'gps_downtime',
    key: 'gps_downtime',
    align: 'center'
  },
  {
    title: 'CRCE Downtime Today (Minutes)',
    dataIndex: 'crce_downtime',
    key: 'crce_downtime',
    align: 'center'
  },
  {
    title: 'Comments',
    dataIndex: 'downtime_comments',
    key: 'downtime_comments',
    align: 'center'
  },
];

const DownTimeModal = (props) => {
  const {
    visible, setVisible, projNo, shift
  } = props;
  const [loading, setLoading] = useState(false);
  const [pipelineFetchLoading, setPipelineFetchLoading] = useState(false);
  const [columns, setColumns] = useState(column);
  const [downtimeData, setDownTimeData] = useState([]);
  const [pipelineData, setPipelineData] = useState([]);
  const [tabValue, setTabValue] = useState('');

  const getData = async () => {
    setLoading(true);
    try {
      const date = moment(new Date()).format('YYYY-MM-DD');
      const res = await getDownTimeData(projNo, date, shift, false, tabValue);
      if (res?.downtimeEntriesData) {
        const sortedData = res.downtimeEntriesData.sort((
          a, b
        ) => new Date(b.date) - new Date(a.date));
        setDownTimeData(sortedData);
      } else setDownTimeData([]);
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };

  const getTableColumns = async () => {
    setPipelineFetchLoading(true);
    try {
      const res = await getPipeline(projNo);
      if (res?.pipelineData && res?.pipelineData.length > 0) {
        const pipelineFilteredData = res?.pipelineData.filter((item) => item.is_deleted === 0);
        setPipelineData(pipelineFilteredData);
        setTabValue(pipelineFilteredData[0] && pipelineFilteredData[0]?.id ? pipelineFilteredData[0]?.id : '');
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setPipelineFetchLoading(false);
    }
  };

  useEffect(() => {
    if (visible) getData();
    return () => {
      setColumns(column);
    };
  }, [visible, tabValue]);

  useEffect(() => () => {
    setTabValue('');
    setPipelineData([]);
  }, []);

  const onTabChange = (activeKey) => {
    setTabValue(activeKey);
  };

  useEffect(() => {
    if (projNo && visible) {
      getTableColumns();
    }
  }, [projNo, visible]);

  return (
    <>
      <Modal
        title="DownTime"
        className="welds-modal"
        centered
        visible={visible}
        width={1009}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setVisible(false);
              setTabValue('');
              setPipelineData([]);
              setDownTimeData([]);
            }}
          >
            OK
          </Button>
        ]}
      >
        <div>
          <div className="flex-fill overflow-auto">
            {
              pipelineData && pipelineData.length > 0 && (
                <Tabs disabled={loading} activeKey={tabValue.toString()} onChange={onTabChange} className="custom-tab h-100">
                  {
                    pipelineData.map((item) => (
                      <TabPane tab={item.name} key={item.id} value={item.id} />
                    ))
                  }
                </Tabs>
              )
            }
          </div>
          <Table
            pagination={false}
            columns={columns}
            dataSource={!pipelineFetchLoading && !loading ? downtimeData : []}
            className="table-with-button-assets"
            loading={{
              indicator: <Spin />,
              spinning: loading
            }}
            scroll={{ x: true }}
            style={{ whiteSpace: 'nowrap' }}
          />
        </div>
      </Modal>
    </>
  );
};

export default DownTimeModal;
