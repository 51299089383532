import {
  GET_QUALITY_REPORTS,
  SET_QUALITY_REPORTS,
  SET_QUALITY_REPORT_FAILURE,
  SET_STATUS,
  SUBMIT_STATUS,
} from '../types';

const getQualityReports = (projectNo) => ({
  type: GET_QUALITY_REPORTS,
  projectNo
});

const setQualityReports = (payload) => ({
  type: SET_QUALITY_REPORTS, ...payload,
});

const setQualityReportFailure = () => ({
  type: SET_QUALITY_REPORT_FAILURE,
});

const setStatus = (payload) => ({
  type: SET_STATUS, ...payload,
});

const submitStatus = (payload, projectNo) => ({
  type: SUBMIT_STATUS, ...payload,
});

export {
  getQualityReports,
  setQualityReports,
  setQualityReportFailure,
  setStatus,
  submitStatus,
};
