/* eslint-disable linebreak-style */
import {
  FETCH_PROJECT_ASSETS,
  SET_PROJECT_ASSETS,
  SET_PROJECT_ASSET_FAILURE
} from '../types';

const getProjectAssets = (projectNo) => ({
  type: FETCH_PROJECT_ASSETS,
  projectNo,
});

const setProjectAsset = (payload) => ({
  type: SET_PROJECT_ASSETS, ...payload,
});

const setProjectAssetFailure = () => ({
  type: SET_PROJECT_ASSET_FAILURE,
});

export {
  getProjectAssets,
  setProjectAsset,
  setProjectAssetFailure
};
