import get from 'lodash/get';
import moment from 'moment';
import { GET_TECH_REPORTS, SET_TECH_REPORTS, SET_TECH_REPORT_FAILURE } from '../types';
import { calcReportingPeriod } from '../../services/calendar';
import * as constants from '../../utils/constants';

const initialState = {
  isLoading: false,
};

const calcStatus = (status, value) => {
  let result = value;
  if (status) {
    result = value ? value + 1 : 1;
  }
  return result;
};

const techLogReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TECH_REPORTS: {
      const reposnseData = get(action, ['response', 'items'], []);
      const tReports = reposnseData.sort((a, b) => moment.utc(b.period) - moment.utc(a.period));
      const data = [];
      const travelDays = []; const onSiteDays = []; const
        totalWorkDays = []; const rainOutDays = [];

      for (let i = 0; i < tReports.length; i += 1) {
        const tData = get(tReports[i], 'data', {});
        const reportingPeriod = calcReportingPeriod(tData.period);
        const splitDate = reportingPeriod.split(' - ');
        const startDate = new Date(splitDate[0]);
        const endDate = new Date(splitDate[1]);
        const formatReportingPeriod = `${moment(startDate).format(constants.DATE)} to ${moment(endDate).format(constants.DATE)}`;
        const entries = [];
        const techs = [];

        if (tData.members) {
          Object.values(tData.members).map((tech) => (
            techs.push({ name: tech.name, member_id: tech.member_id })
          ));
        }

        if (tData.entries) {
          for (let x = 0; x < tData.entries.length; x += 1) {
            entries[x] = [];
          }
        }

        if (tData.entries) {
          Object.values(tData.entries).map((entry, index) => {
            if (entry.member_details) {
              entries[index] = {
                date: entry.date,
                comments: entry.comments,
              };

              Object.values(entry.member_details).map((d, y) => {
                if (new Date(entry.date) <= new Date()) {
                  travelDays[i] = calcStatus(d.status === 'Travel', travelDays[i] ? travelDays[i] : 0);
                  onSiteDays[i] = calcStatus(d.status === 'Onsite', onSiteDays[i] ? onSiteDays[i] : 0);
                  rainOutDays[i] = calcStatus(d.status === 'Rain Out', rainOutDays[i] ? rainOutDays[i] : 0);
                }
                entries[index][y] = {
                  techName: d.name,
                  member_id: d.member_id,
                  status: d.status,
                };
                return d;
              });
            } else {
              travelDays[i] = 0;
              onSiteDays[i] = 0;
              rainOutDays[i] = 0;
            }

            return entry;
          });
        }
        if (travelDays[i] || onSiteDays[i] || rainOutDays[i]) {
          totalWorkDays[i] = travelDays[i] + onSiteDays[i] + rainOutDays[i];
        } else {
          totalWorkDays[i] = 0;
        }

        data[i] = {
          reportId: get(tReports[i], 'id', '-'),
          type: get(tReports[i], 'type', '-'),
          period: get(tData, 'period', ''),
          guid: get(tData, 'guid', ''),
          reportingPeriod,
          formatReportingPeriod,
          projectNo: get(tData, 'project_number', '-'),
          projectName: get(tData, 'project_name', '-'),
          lead: get(tData, 'lead_tech', '-'),
          author: get(tReports[i], 'author', '-'),
          lastUpdatedAt: get(tReports[i], 'updatedAt', '-'),
          pdf: get(tReports[i], 'pdf_url', ''),
          totalDaysOnsite: onSiteDays[i] ? onSiteDays[i] : 0,
          totalTravelDays: travelDays[i] ? travelDays[i] : 0,
          totalRainOutDays: rainOutDays[i] ? rainOutDays[i] : 0,
          totalWorkDays: totalWorkDays[i] ? totalWorkDays[i] : 0,
          contractor: get(tData, 'contractor', '-'),
          email: get(tReports[i], 'email', '-'),
          createdAt: get(tReports[i], 'createdAt', '-'),
          entries,
          techs,
        };
      }
      return {
        ...state,
        data,
        isLoading: false,
      };
    }

    case GET_TECH_REPORTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_TECH_REPORT_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default techLogReportReducer;
