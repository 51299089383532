import React from 'react';
import {
  Modal,
} from 'antd';

function AttachmentsViewer(props) {
  const { isVisible, onOk, attachments } = props;
  return (
    <Modal
      className="quality-modal"
      title="Download attachments"
      centered
      visible={isVisible}
      okText="Close"
      onOk={onOk}
      destroyOnClose
      width={450}
      cancelButtonProps={{ style: { display: 'none' } }}
    >

      <div className="ant-modal-body-adv">
        {attachments.map((attachment) => (
          <a
            className="export-link"
            title="Download attachments"
            target="_blank"
            rel="noopener noreferrer"
            href={attachment.url ? attachment.url : ''}
          >
            <span className="icon-16 mr-2">
              <svg>
                <use xlinkHref="#export" />
              </svg>
            </span>
            <span className="ps-2">{attachment.name}</span>
          </a>
        ))}
      </div>

    </Modal>
  );
}

export default AttachmentsViewer;
