/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  Modal, Button, Empty, notification, Switch, Tabs, Tooltip
} from 'antd';
import Excel from 'exceljs';
import { datesInPeriod } from '../services/calendar';
import * as constants from '../utils/constants';
import { prepareDataForExcel, writeWorkSheet } from '../utils/utility';
import ModalGraph from './ModalGraph';

const { TabPane } = Tabs;

const WeldesModal = (props) => {
  const {
    currentReport, visible, setVisible, totalWelds, totalRepairs, repairRate
  } = props;
  const [newData, setNewData] = useState(false);
  const [tablehtml, setTablehtml] = useState('');
  const [showGraph, setShowGraph] = useState(false);

  const tableData = () => {
    let tableval = null;
    const crewDetails = currentReport && currentReport.crews && (currentReport.crews);
    if (!showGraph) {
      tableval = (
        <table className="logreport report-table h-100">
          {
            currentReport && currentReport.crews ? (
              <>
                <thead className="ant-table-thead">
                  <tr>
                    <th rowSpan="3" className="ant-table-cell align-bottom text-center">Date</th>
                    {
              crewDetails.map((crew) => (
                <>
                  <th className="ant-table-cell text-center" colSpan="2" key={crew.id}>
                    <div className="main-title py-2 me-2">
                      {crew.name && crew.name.length > 0 ? crew.name : '(No Name)'}
                    </div>
                  </th>

                </>

              ))
            }

                    <th rowSpan="2" className="ant-table-cell align-bottom nowrap-normal comments-cell text-center">Comments</th>
                    <th rowSpan="2" className="ant-table-cell align-bottom nowrap-normal comments-cell text-center">Total Welds</th>
                    <th rowSpan="2" className="ant-table-cell align-bottom nowrap-normal comments-cell text-center">Total Repairs</th>
                  </tr>
                  <tr>

                    {
              crewDetails.map((crew) => (
                <>
                  <th className="ant-table-cell text-center" key={crew.id}>
                    Welds
                  </th>
                  <th rowSpan="2" className="ant-table-cell text-center">Repairs</th>
                </>

              ))
            }
                  </tr>
                </thead>
                <tbody className="ant-table-tbody">
                  {
            newData
              && newData.map((e) => {
                const entryDetails = Object.values(e.dummyCrew ? e.dummyCrew : e)
                  .filter((item) => item && typeof (item) === 'object');
                const isCrewIdPresent = crewDetails.find((crew) => crew.id);
                return (
                  <tr key={e.date} className="ant-table-row ant-table-row-level-0">
                    <td className="rTableCell nowrap text-center">{moment(e.date).format(constants.DATE).replaceAll('/', '-')}</td>
                    {
                    crewDetails.map((crew, y) => {
                      let displayEntry = [];
                      if (isCrewIdPresent) {
                        displayEntry = entryDetails?.find((
                          item
                        ) => item.crewId === crew.id);
                      } else {
                        displayEntry = entryDetails?.find((
                          item
                        ) => item.crewName === crew.name);
                      }
                      return (
                        <Fragment key={(e.date && displayEntry && displayEntry.crewId) ? `${e.date}${displayEntry.crewId}` : y}>
                          <td className="rTableCell text-center">{displayEntry && displayEntry.welds ? displayEntry.welds : ''}</td>
                          <td className="rTableCell text-center">{displayEntry && displayEntry.repairs ? displayEntry.repairs : ''}</td>
                        </Fragment>
                      );
                    })
                }
                    <td className="rTableCell nowrap-normal  comments-cell text-center">{e.comments}</td>
                    <td className="rTableCell nowrap-normal  comments-cell text-center">{e.totalWelds}</td>
                    <td className="rTableCell nowrap-normal  comments-cell text-center">{e.totalRepairs}</td>
                  </tr>
                );
              })
          }
                </tbody>
              </>

            ) : <Empty />
          }

        </table>
      );
    } else {
      tableval = (
        <Tabs className="custom-tab h-95">
          {
            currentReport && currentReport.crews
            && (currentReport.crews).map((crew, i) => (
              <TabPane tab={(crew.name && crew.name.length > 0) ? crew.name : '(No Name)'} key={i}>
                <ModalGraph currentReport={currentReport} crewName={crew.name || ''} />
              </TabPane>
            ))
          }
          <TabPane tab="Show Total">
            {currentReport ? <ModalGraph currentReport={currentReport} /> : <Empty />}
          </TabPane>
        </Tabs>
      );
    }

    setTablehtml(tableval);
  };

  useEffect(() => {
    if (visible === false) {
      setTablehtml('');
      return;
    }
    const dates = datesInPeriod(currentReport.period);
    const { entries } = currentReport;
    const newdata = [];

    let recordFound = false;
    if (dates) {
      dates.forEach((day) => {
        for (let i = 0; i < entries.length; i += 1) {
          if (new Date(entries[i].date) <= new Date()) {
            if (entries[i].date === day) {
              let totalwelds = 0;
              let totalrepairs = 0;
              Object.keys(entries[i]).map((data) => {
                if (entries[i][data] && typeof entries[i][data] === 'object') {
                  if (!entries[i][data].welds) {
                    entries[i][data].welds = 0;
                  }
                  if (!entries[i][data].repairs) {
                    entries[i][data].repairs = 0;
                  }
                  totalwelds += entries[i][data].welds;
                  totalrepairs += entries[i][data].repairs;
                }
              });
              entries[i].totalWelds = totalwelds;
              entries[i].totalRepairs = totalrepairs;
              newdata.push(entries[i]);
              recordFound = true;
              break;
            }
          }
        }
        if (!recordFound) {
          let dummyCrew = [];
          if (currentReport) {
            dummyCrew = currentReport.crews.map((crew) => ({
              crewId: crew.id, crewName: crew.name, welds: undefined, repairs: undefined,
            }));
          }
          newdata.push({ date: day, ...dummyCrew, comments: undefined });
          recordFound = false;
        }
        recordFound = false;
      });
    }
    setNewData(newdata);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport, visible]);

  useEffect(() => {
    if (newData !== []) {
      tableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);

  const exportToExcel = async () => {
    try {
      const { data, columns } = prepareDataForExcel(currentReport.crews, newData);
      const workbook = new Excel.Workbook();
      const workSheetName = currentReport.reportId.toString();
      const fileName = currentReport.reportId;
      let worksheet = workbook.addWorksheet(workSheetName);
      worksheet.columns = columns;
      worksheet = writeWorkSheet(worksheet, currentReport.crews, data, currentReport.projectName,
        currentReport.projectNo, currentReport.lead, currentReport.contractor);

      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  useEffect(() => {
    if (showGraph) {
      tableData(showGraph);
    } else {
      tableData(showGraph);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showGraph]);

  return (
    <>
      <Button className="hidden-view-btn" onClick={() => setVisible(true)}>v</Button>
      <Modal
        title={(
          <div style={{ display: 'flex' }}>
            <div style={{ 'max-width': '50%' }}>{`Production Log Report | Total Weld : ${totalWelds} , Total Repair : ${totalRepairs} , Repair Rate : ${repairRate}`}</div>

            {
                currentReport && currentReport.crews && (
                  <>
                    <Switch
                      className="modal-switch"
                      checkedChildren="Graphical View"
                      unCheckedChildren="Tabular View"
                      checked={showGraph}
                      onChange={(checked) => {
                        if (checked) {
                          setShowGraph(checked);
                        } else {
                          setShowGraph(checked);
                        }
                      }}
                    />
                    <Button
                      size="small"
                      shape="round"
                      type="primary"
                      className="export-excel"
                      onClick={exportToExcel}
                    >
                      <Tooltip title="Download excel report" placement="topRight">
                        <span className="icon-16 mr-2">
                          <svg>
                            <use xlinkHref="#export" />
                          </svg>
                        </span>
                      </Tooltip>

                    </Button>
                  </>

                )
              }
          </div>
        )}
        className="welds-modal"
        centered
        visible={visible}
        width={1009}
        footer={[
          <Button key="submit" type="primary" onClick={() => { setVisible(false); setShowGraph(false); }}>
            OK
          </Button>
        ]}
      >
        <div className="ant-table scroll-table">
          <div className="ant-table-container h-100">
            <div className="ant-table-content h-100">

              {tablehtml}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WeldesModal;
