import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { setTeamMembers, setTeamMembersFailure } from '../state/action/teamMembers';
import { FETCH_TEAM_MEMBERS } from '../state/types';
import fetch from '../services/fetch';
import { getMembers, getRolesToAdd } from '../services/teamMembers';
import { BASE_URL } from '../config';

function* getTeamMembers({ projectNo }) {
  try {
    const projNo = encodeURIComponent(projectNo.projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = yield fetch(`${BASE_URL}/project/users?project_numbers=${projNo}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      throw new Error('Unauthorized');
    }
    const response = yield res.json();
    let userD = [];
    let roleDetails = [];
    let userDetails = [];
    if (response.data.length > 0) {
      userD = yield getMembers(response.data);
      if (projectNo.projectType === 'offshore') {
        roleDetails = yield getRolesToAdd(projNo);
        userDetails = userD.data.map((users) => {
          let selectedReviewdata = '';
          roleDetails.personnelRoleData.map((roles) => {
            if (String(users.user_id) === String(roles.user_id)) {
              selectedReviewdata = roles.role;
              return selectedReviewdata;
            }
            return null;
          });
          return { ...users, role: selectedReviewdata };
        });
        userDetails = { data: userDetails };
      } else {
        userDetails = userD;
      }
    }

    yield put(setTeamMembers({ userDetails }));
  } catch (error) {
    yield put(setTeamMembersFailure());
    notification.error({
      message: 'There was a problem fetching the team members.',
      description: `${error}`,
    });
  }
}

function* teamMembersWatcher() {
  yield takeLatest(FETCH_TEAM_MEMBERS, getTeamMembers);
}

function* teamMembersSagas() {
  yield all([
    call(teamMembersWatcher),
  ]);
}

export default teamMembersSagas;
