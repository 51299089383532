import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Drawer, Button } from 'antd';
import {
  object, oneOfType, objectOf, string, symbol, func
} from 'prop-types';
import history from '../services/history';
import 'antd/dist/antd.dark.css';
import SignoutButton from './SignoutButton';

const HomeLayout = (props) => {
  const { children } = props;
  const [showDrawer, setDrawer] = useState(false);

  return (
    <div className="h-100">

      {children}

      <Drawer
        placement="left"
        onClose={() => setDrawer(false)}
        visible={showDrawer}
        closable
      >
        <div className="ant-drawer-logo" />
        <div className="header-line">
          <hr />
        </div>
        <div>
          <Button
            type="link"
            onClick={() => {
              history.push('/main');
            }}
          >
            Dashboard
          </Button>
          <Button
            type="link"
            onClick={() => {
              history.push('/main');
            }}
          >
            Quality
          </Button>
          <Button
            type="link"
            onClick={() => {
              history.push('/log-report');
            }}
          >
            Weld Log Reports
          </Button>
          <Button
            type="link"
            onClick={() => {
              history.push('/coating-log-report');
            }}
          >
            Coating Log reports
          </Button>
          <Button
            type="link"
            onClick={() => {
              history.push('/parts-request');
            }}
          >
            Parts Requests
          </Button>
          <Button
            type="link"
            onClick={() => {
              history.push('/users-activity');
            }}
          >
            User Activity
          </Button>
          <Button
            type="link"
            onClick={() => {
              history.push('/upload-material');
            }}
          >
            Upload Material Data
          </Button>
          <SignoutButton />
        </div>
      </Drawer>
    </div>
  );
};

HomeLayout.propTypes = {
  children: objectOf(oneOfType([object, string, symbol, func])),
};

HomeLayout.defaultProps = {
  children: {},
};

export default withRouter(HomeLayout);
