/* eslint-disable  */
import React, { useState, useEffect } from 'react';
import {
  Modal, Button, Tabs, Table, Spin, notification, Switch
} from 'antd';
import { getPipeline, getPipelineIssue, getQualityData } from '../services/pipeline';
import { shift } from '../utils/constants';
import { toNumber } from '../utils/utility';
import ModalOffshoreSummaryGraph from './ModalOffshoreSummaryGraph';

const { TabPane } = Tabs;
const { Column, ColumnGroup } = Table;

const QualityModalOverall = (props) => {
  const {
    visible, setVisible, projNo,
  } = props;
  const [tabValue, setTabValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [isIssueLoading, setIsIssueLoading] = useState(false);
  const [isEntryLoading, setIsEntryLoading] = useState(false);
  const [pipelineIssue, setPipelineIssue] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [summaryDataSource, setSummaryDataSource] = useState([]);
  const [objectFormat, setObjectFormat] = useState({});
  const [pipelinesData, setPipelinesData] = useState([]);
  const [showGraph, setShowGraph] = useState(false);

  useEffect(() => {
    if (pipelineIssue.length > 0
      && Object.keys(objectFormat).length > 0
    ) { getQualityEntries(pipelineIssue[0].key, objectFormat); }
  }, [pipelineIssue, objectFormat]);

  useEffect(() => {
    if (visible) {
      getIssueTabs();
      getTableColumns();
    }
    return () => {
      setPipelinesData([])
      setPipelineIssue([]);
      setDataSource([]);
      setTabValue('')
    };
  }, [visible]);

  
  function groupArrayByField(arr, field) {
    const groups = {};

    arr.forEach((obj) => {
      const value = obj[field];
      if (groups[value]) {
        groups[value].push(obj);
      } else {
        groups[value] = [obj];
      }
    });
    return groups;
  }

  const getQualityEntries = async (issueId, dummyObject) => {
    setIsEntryLoading(true);
    try {
      if (projNo) {
        const res = await getQualityData(projNo, issueId, '', '');
        if (res.pipelineEntryData.length > 0) {
          const groupedByDate = res.pipelineEntryData.reduce((groups, obj) => {
            const date = obj.date;
            if (!groups[date]) {
              groups[date] = { ...dummyObject, date: obj.date };
            }
            const value = obj.project_pipelines_id;
            const shiftType = obj.shift === shift.day ? 'day' : 'night';
          
            groups[date][`${value}_${shiftType}`] = toNumber(obj.value) || 0;
            groups[date][`${value}_overall`] = (toNumber(groups[date][`${value}_overall`]) || 0) + toNumber(obj.value);
          
            return groups;
          }, {});
          const finalData = Object.values(groupedByDate)
          .sort((a, b) => new Date(b.date) - new Date(a.date));
        
          setDataSource(finalData);
        } else {
          setDataSource([]);
        }
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setIsEntryLoading(false);
    }
  };

  const getProductiveComments = async (tab) => {
    try {
      if (projNo) {
        const currentTab = tab || tabValue;
        const res = await getQualityData(projNo, currentTab, '', '');
        if (res?.summaryCommentData) {
          const comments = res?.summaryCommentData
          .filter((item) => item.quality_comments)

          const groupedByDate = groupArrayByField(comments, 'date');
          const commentData = Object.keys(groupedByDate).map((
            item
          ) => { 
            const tempObject = { date: item };
            const dayData = groupedByDate[item].find((element) => element.shift === shift.day) || {};
            const nightData = groupedByDate[item].find((element) => element.shift === shift.night) || {};
            tempObject.comments_day = dayData.quality_comments || '';
            tempObject.comments_night = nightData.quality_comments || '';
            return tempObject
          })
          setSummaryDataSource(commentData.sort((a,b) => new Date(b.date) - new Date(a.date)));
        } else {
          setSummaryDataSource([])
        }
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  const getIssueTabs = async () => {
    setIsIssueLoading(true);
    try {
      const tabItems = [];
      if (projNo) {
        const res = await getPipelineIssue(projNo);
        if (res?.pipelineData) {
          const pipelineData = res?.pipelineData.filter((item) => item.is_deleted === 0);
          pipelineData.forEach((item, index) => {
            if (index === 0) setTabValue(pipelineData[0].id);
            tabItems.push({
              name: item.name,
              key: item.id,
            });
          });
          setPipelineIssue(tabItems);
        }
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setIsIssueLoading(false);
    }
  };

  const getTableColumns = async () => {
    setLoading(true);
    try {
      const dummyObject = {};
      dummyObject.date = '';
      if (projNo) {
        const res = await getPipeline(projNo);
        if (res?.pipelineData) {
          const pipelineData = res?.pipelineData.filter((item) => item.is_deleted === 0);
          pipelineData.forEach((item) => {
            dummyObject[`${item.id.toString()}_day`] = '0';
            dummyObject[`${item.id.toString()}_night`] = '0';
            dummyObject[`${item.id.toString()}_overall`] = '0';
          });
          setPipelinesData(pipelineData)
          setObjectFormat(dummyObject);
          getProductiveComments();
        }
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };


  const onTabChange = (activeKey) => {
    setTabValue(activeKey);
    if (activeKey !== 'Comments') getQualityEntries(activeKey, objectFormat);
  };

  const onOk = () => {
    setVisible(false);
  };
  return (
    <>
      <Modal
        title="Quality"
        className="welds-modal"
        centered
        visible={visible}
        width={1009}
        footer={[
          <Button key="submit" type="primary" onClick={onOk}>
            OK
          </Button>
        ]}
      >
        <div>
          <div className="flex-fill overflow-auto">
            {
               pipelineIssue && pipelineIssue.length > 0 && (
               <Tabs disabled={loading || isIssueLoading || isEntryLoading} onChange={onTabChange} activeKey={tabValue.toString()} className="custom-tab h-100">
                 {
                pipelineIssue.map((item) => (
                  <TabPane tab={item.name} key={item.key} value={item.key} />
                ))
              }
                 <TabPane tab="Comments" key="Comments" value="Comments" />
               </Tabs>
               )
            }
          </div>
          {tabValue !== 'Comments' ? (
            <>
            <div className='d-flex flex-column align-items-end py-2'>
            <Switch
              className="modal-switch"
              checkedChildren="Graphical View"
              unCheckedChildren="Tabular View"
              onChange={(checked) => {
                if (checked) {
                  setShowGraph(checked);
                } else {
                  setShowGraph(checked);
                }
              }}
            />
          </div>
          { !showGraph ? (
            <Table
              pagination={false}
              dataSource={dataSource}
              style={{ whiteSpace: 'nowrap' }}
              scroll={{ x: true }}
              className="table-with-button-assets"
              loading={{
                indicator: <Spin />,
                spinning: loading || isIssueLoading || isEntryLoading
              }}
            >
            <Column title="Date" dataIndex="date" key="date" />
            {pipelinesData.length && pipelinesData.map((item) => 
            <ColumnGroup title={item?.name} align="center">
              <Column title="Day" dataIndex={`${item.id.toString()}_day`} key={`${item.id.toString()}_day`} />
              <Column title="Night" dataIndex={`${item.id.toString()}_night`} key={`${item.id.toString()}_night`} />
              <Column title="Overall" dataIndex={`${item.id.toString()}_overall`} key={`${item.id.toString()}_overall`} />
            </ColumnGroup>
            )}
            </Table>
            ):(
              <Tabs className="custom-tab h-95">
                  {pipelinesData.length && pipelinesData.map((item,i) => 
                    <TabPane tab={item?.name} key={i}>
                      <ModalOffshoreSummaryGraph 
                      Reportdata={dataSource}
                      coloumnType={item.id.toString()} />
                    </TabPane>
                  )}
                </Tabs>
            )
          }
            </>
          )
            : (
              <Table
                pagination={false}
                dataSource={summaryDataSource}
                style={{ whiteSpace: 'nowrap' }}
                scroll={{ x: true }}
                className="table-with-button-assets"
                loading={{
                  indicator: <Spin />,
                  spinning: loading || isIssueLoading || isEntryLoading
                }}
              >
             <Column title="Date" dataIndex="date" key="date" />
              <ColumnGroup title="Comments" align="center">
               <Column title="Day" dataIndex="comments_day" key="comments_day" />
               <Column title="Night" dataIndex="comments_night" key="comments_night" />
             </ColumnGroup>
             </Table>
            )}
        </div>
      </Modal>
    </>

  );
};

export default QualityModalOverall;
