/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Modal, Table, notification, Button
} from 'antd';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';
import { getApprovalHistory } from '../services/advisory';
import { capitalizeFirstLetter } from '../utils/utility';
import { ADV_DATE_TABLE } from '../utils/constants';
import AddMachine from './AddMachine';
import AttachmentsViewer from './AttachmentsViewer';

const itemsPerPage = 10;
const defaultPage = 0;

function ApprovalHistory(props) {
  const { isVisible, onOk, advisoryId, title } = props;

  const [form] = useForm();

  const [isLoading, setIsLoading] = useState();
  const [approvalRequests, setApprovalRequests] = useState([]);
  const [pageNumber, setPageNumber] = useState(defaultPage);
  const [total, setTotal] = useState(0);
  const [isViewVisible, setIsViewVisible] = useState(false);
  const [attachmentsData, setAttachmentsData] = useState([]);
  const [isAttachmentModalVisible, setisAttachmentModalVisible] = useState(false);

  const showAttachmentModal = (attachmentData) => {
    setAttachmentsData(attachmentData);
    setisAttachmentModalVisible(true);
  };

  const columns = [
    // {
    //   title: 'Advisory ID',
    //   dataIndex: 'code',
    //   key: 'code',
    // },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'Business Unit',
      dataIndex: 'businessUnit',
      key: 'businessUnit',
    },
    {
      title: 'Effective Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
    },
    {
      title: 'Approval Status',
      dataIndex: 'approvalStatusData',
      key: 'approvalStatusData',
    },
    {
      title: 'Requested On',
      dataIndex: 'requested_on',
      key: 'requested_on',
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'Attachment',
      key: 'attachments',
      dataIndex: 'attachments',
      render: (record) => {
        if (!record || record.length === 0) return '-';
        if (record.length === 1) {
          return (
            <div>
              {' '}
              <a
                className="export-link"
                title="Download attachments"
                target="_blank"
                rel="noopener noreferrer"
                href={record[0].url ? record[0].url : ''}
                  // eslint-disable-next-line react/jsx-closing-bracket-location
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span className="icon-16 mr-2">
                  <svg>
                    <use xlinkHref="#export" />
                  </svg>
                </span>
              </a>
            </div>
          );
        }
        return (
          <Button
            className="b-0 export-link border-0 px-0"
            shape="round"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              showAttachmentModal(record);
            }}
          >
            <span className="icon-16 mr-2">
              <svg>
                <use xlinkHref="#export" />
              </svg>
            </span>
            {' '}
            <span className="mx-1">
              {record.length}
            </span>
          </Button>
        );
      }
    },
  ];

  const getApprovalHistoryData = async (page) => {
    setIsLoading(true);
    try {
      const res = await getApprovalHistory(advisoryId, page, itemsPerPage);
      if (res?.approvalRequests) {
        const tableData = res?.approvalRequests.map((item) => ({
          key: item.id,
          id: item.id,
          type: item.name,
          description: item.description,
          title: item.title,
          date: moment(item.date).format(ADV_DATE_TABLE),
          attachments: item.attachments,
          parent: item.parent_id,
          createdBy: item.createdBy,
          priority: Number(item.priority),
          dateOriginal: item.date,
          typeId: item.type,
          businessUnit: item.business_unit,
          code: item.code,
          approvalStatusData: item.status ? capitalizeFirstLetter(item.status) : 'Approved',
          created_by: capitalizeFirstLetter(item.createdBy),
          requested_on: moment(item.createdAt).format(ADV_DATE_TABLE),
          comment: item?.comment || '-'
        }));
        setApprovalRequests(tableData);
        setTotal(res?.total);
      } else {
        setApprovalRequests([]);
        setTotal(0);
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onPaginationChange = (page) => {
    setPageNumber(page - 1);
    getApprovalHistoryData(page - 1);
  };

  useEffect(() => {
    if (isVisible) getApprovalHistoryData(pageNumber || defaultPage);
  }, [isVisible]);

  const onEditAdvisory = (record) => {
    form.setFieldsValue({
      ...record,
      type: record.typeId,
      date: moment(record.dateOriginal),
      priority: Number(record.priority),
      businessUnit: record.businessUnit
    });
    setIsViewVisible(true);
  };

  return (
    <Modal
      className="quality-modal"
      title={title}
      centered
      visible={isVisible}
      okText="Close"
      onOk={onOk}
      destroyOnClose
      width={1050}
      cancelButtonProps={{ style: { display: 'none' } }}
    >

      <div className="ant-modal-body-adv">
        <Table
          columns={columns}
          dataSource={approvalRequests}
          loading={isLoading}
          pagination={{
            defaultPageSize: itemsPerPage,
            showSizeChanger: false,
            pageSizeOptions: ['10'],
            onChange: onPaginationChange,
            total,
            current: pageNumber + 1,
            showLessItems: true,
            hideOnSinglePage: true,
          }}
          onRow={(record) => ({
            onClick: () => { onEditAdvisory(record); }
          })}
        />
      </div>
      <AddMachine
        isLoading={false}
        isVisible={isViewVisible}
        onCancel={() => setIsViewVisible(false)}
        form={form}
        title="View Advisory"
        key="View"
        disabled
        isApproval
        cancelText="Close"
      />
      <AttachmentsViewer
        isVisible={isAttachmentModalVisible}
        onOk={() => setisAttachmentModalVisible(false)}
        attachments={attachmentsData}
      />
    </Modal>
  );
}

export default ApprovalHistory;
