/* eslint-disable  */
import React, { useState, useEffect } from 'react';
import {
  Modal, Button, Tabs, Table, Spin, notification
} from 'antd';
import moment from 'moment';
import { getPipeline, getPipelineIssue, getQualityData } from '../services/pipeline';

const { TabPane } = Tabs;

const commentColumn = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Comment',
    dataIndex: 'quality_comments',
    key: 'quality_comments',
  },
];

const QualityModal = (props) => {
  const {
    visible, setVisible, projNo, isToday, shift

  } = props;
  const [tabValue, setTabValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [isIssueLoading, setIsIssueLoading] = useState(false);
  const [isEntryLoading, setIsEntryLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [commentColumns, setCommentColumns] = useState([]);
  const [pipelineIssue, setPipelineIssue] = useState([]);
  const [tableDataindex, setTableDataindex] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [summaryDataSource, setSummaryDataSource] = useState([]);


  useEffect(() => {
    if(tabValue === 'Comments'){
        if(!isToday) setCommentColumns(commentColumn)
        else setCommentColumns([
          {
            title: 'Comment',
            dataIndex: 'quality_comments',
            key: 'quality_comments',
          },
        ])
    }
  }, [tabValue])
  

  function groupArrayByField(arr, field) {
    const groups = {};

    arr.forEach((obj) => {
      const value = obj[field];
      if (groups[value]) {
        groups[value].push(obj);
      } else {
        groups[value] = [obj];
      }
    });
    return groups;
  }

  const getQualityEntries = async (issueId) => {
    setIsEntryLoading(true);
    try {
      if (projNo) {
        const date = isToday ? moment(new Date()).format('YYYY-MM-DD') : '';
        const res = await getQualityData(projNo, issueId, date, shift);
        if (res.pipelineEntryData.length > 0) {
          const finalData = [];
          if (!isToday) {
            const groupedData = groupArrayByField(res?.pipelineEntryData, 'date');
            Object.keys(groupedData).sort((a,b) => new Date(b) - new Date(a)).forEach((key) => {
              const tempObject = { ...tableDataindex };
              const value = groupedData[key];
              value.forEach((item) => { tempObject[item.project_pipelines_id] = item.value || 0; });
              tempObject.date = key;
              finalData.push(tempObject);
            });
            setDataSource(finalData);
          } else {
            const tempObject = { ...tableDataindex };
            if (res.pipelineEntryData.length > 0) {
              res.pipelineEntryData.forEach((
                item
              ) => { tempObject[item.project_pipelines_id] = item.value; });
              finalData.push(tempObject);
              setDataSource(finalData);
            } else {
              setDataSource([]);
            }
          }
        } else {
          setDataSource([]);
        }
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setIsEntryLoading(false);
    }
  };

  const getProductiveComments = async (tab) => {
    try {
      if (projNo) {
        const currentTab = tab || tabValue;
        const date = isToday ? moment(new Date()).format('YYYY-MM-DD') : '';
        const res = await getQualityData(projNo, currentTab, date, shift);
        if (res?.summaryCommentData) {
          const filteredComments = res.summaryCommentData.filter((item) => item.quality_comments);
          setSummaryDataSource(filteredComments);
        }
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  const getIssueTabs = async () => {
    setIsIssueLoading(true);
    try {
      const tabItems = [];
      if (projNo) {
        const res = await getPipelineIssue(projNo);
        if (res?.pipelineData) {
          const pipelineData = res?.pipelineData.filter((item) => item.is_deleted === 0);
          pipelineData.forEach((item, index) => {
            if (index === 0) setTabValue(pipelineData[0].id);
            tabItems.push({
              name: item.name,
              key: item.id,
            });
          });
          setPipelineIssue(tabItems);
        }
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setIsIssueLoading(false);
    }
  };

  const getTableColumns = async () => {
    setLoading(true);
    try {
      const tempColumns = [];
      const tempDataIndex = { };
      if (!isToday) {
        tempDataIndex.date = '';
        tempColumns.push({
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          align: 'center'
        });
      }
      if (projNo) {
        const res = await getPipeline(projNo);
        if (res?.pipelineData) {
          const pipelineData = res?.pipelineData.filter((item) => item.is_deleted === 0);
          pipelineData.forEach((item) => {
            tempColumns.push({
              title: item.name,
              dataIndex: item.id,
              key: item.id,
              align: 'center'
            });
            tempDataIndex[item.id] = 0;
          });
          setColumns(tempColumns);
          setTableDataindex(tempDataIndex);
          getProductiveComments();
        }
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pipelineIssue.length > 0
      && Object.keys(tableDataindex).length > 0
    ) { getQualityEntries(pipelineIssue[0].key); }
  }, [pipelineIssue, tableDataindex]);

  useEffect(() => {
    if (visible) {
      getIssueTabs();
      getTableColumns();
    }
    return () => {
      setColumns([]);
      setPipelineIssue([]);
      setDataSource([]);
      setTabValue('')
    };
  }, [visible]);

  const onTabChange = (activeKey) => {
    setTabValue(activeKey);
    if (activeKey !== 'Comments') getQualityEntries(activeKey);
  };

  const onOk = () => {
    setColumns([]);
    setVisible(false);
  };
  return (
    <>
      <Modal
        title="Quality"
        className="welds-modal"
        centered
        visible={visible}
        width={1009}
        footer={[
          <Button key="submit" type="primary" onClick={onOk}>
            OK
          </Button>
        ]}
      >
        <div>
          <div className="flex-fill overflow-auto">
            {
               pipelineIssue && pipelineIssue.length > 0 && (
               <Tabs disabled={loading || isIssueLoading || isEntryLoading} onChange={onTabChange} activeKey={tabValue.toString()} className="custom-tab h-100">
                 {
                pipelineIssue.map((item) => (
                  <TabPane tab={item.name} key={item.key} value={item.key} />
                ))
              }
                 <TabPane tab="Comments" key="Comments" value="Comments" />
               </Tabs>
               )
}
          </div>
          {tabValue !== 'Comments' ? (
            <Table
              pagination={false}
              columns={columns}
              dataSource={dataSource}
              style={{ whiteSpace: 'nowrap' }}
              scroll={{ x: true }}
              className="table-with-button-assets"
              loading={{
                indicator: <Spin />,
                spinning: loading || isIssueLoading || isEntryLoading
              }}
            />
          )
            : (
              <Table
                pagination={false}
                columns={commentColumns}
                dataSource={summaryDataSource}
                style={{ whiteSpace: 'nowrap' }}
                scroll={{ x: true }}
                className="table-with-button-assets"
                loading={{
                  indicator: <Spin />,
                  spinning: loading || isIssueLoading || isEntryLoading
                }}
              />
            )}
        </div>
      </Modal>
    </>

  );
};

export default QualityModal;
