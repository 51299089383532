/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  func, arrayOf, objectOf, oneOfType, string, number,
  array, bool
} from 'prop-types';
import {
  Table, Spin, Button, Tooltip, Modal, notification, Form, Input
} from 'antd';
import {
  EditOutlined,
} from '@ant-design/icons';
import { DeleteIcon } from './svgs/deleteIcon';
import { getProjectInventory } from '../state/action/projectInventoryAction';
import {
  addProjectInventory, removeProjectInventory, updateProjectInventory, getInventoryReports
} from '../services/projectInventory';
import { getAwsConfigData } from '../services/awsConfigData';
import AddInventoryModal from './AddInventoryModal';
import ViewInventoryReportModal from './ViewInventoryReportModal';

const Inventory = ({
  projNo, projectStatus, reload, refresh, onGetProjectInventories,
  inventories, setInventories, inventoriesData, showInventoryLoader, projectType
}) => {
  const [inventoryData, setInventoryData] = useState([]);
  const [inventoryDataCopy, setInventoryDataCopy] = useState([]);
  const [awsConfigDt, setAwsConfigDt] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);
  const [inventoryModalLoading, setInventoryModalLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [inventoryReportDt, setInventoryReportDt] = useState([]);
  const [addInventoryForm] = Form.useForm();
  const { Search } = Input;

  const onInventorySave = async (filename) => {
    const res = await addProjectInventory(projNo, filename);
    setInventoryModalLoading(false);
    if (res) {
      setIsModalVisible(false);
      addInventoryForm.resetFields();
      setIsEdit(false);
      onGetProjectInventories({ projectNo: projNo });
      notification.success({
        message: 'Parts Added',
        duration: 1.5
      });
    }
  };

  const editBtnHandler = (record) => {
    setIsEdit(true);
    setIsModalVisible(true);
    addInventoryForm.setFieldsValue({
      description: record?.materialDescription,
      serial_number: record?.serialNo,
      project_asset_id: record?.projectAssetid,
      order_quantity: record?.orderQuantity
    });
  };
  const getAwsConfigDatas = async () => {
    const awsConfigData = await getAwsConfigData();
    setAwsConfigDt(awsConfigData.data);
  };

  const getInventoryReportList = async (projectNo) => {
    const inventoryReportList = await getInventoryReports(projectNo);
    setInventoryReportDt(inventoryReportList.data);
  };

  useEffect(() => {
    refresh(false);
    if (!reload) {
      if (projNo !== '') {
        onGetProjectInventories({ projectNo: projNo });
        getInventoryReportList({ projectNo: projNo });
        getAwsConfigDatas();
      } else {
        setInventoryData([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo]);

  const handleDeletion = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this part from the project?',
      className: 'custom-text-clr',
      async onOk() {
        return removeProjectInventory(projNo, record.projectAssetid).then((res) => {
          if (res) {
            notification.success({
              message: 'Part Deleted',
            });
            onGetProjectInventories({ projectNo: projNo });
          } else {
            notification.error({
              message: 'Something went wrong.',
            });
          }
          return true;
        }).catch(() => notification.error({
          message: 'Something went wrong.',
        }));
      },
      onCancel() {},
    });
  };

  const hideModal = () => {
    setIsReportModalVisible(false);
  };

  useEffect(() => {
    if (!reload && projNo !== '') {
      const inventoryDt = [];
      inventoriesData.forEach((dt) => {
        inventoryDt.push(dt);
      });
      setInventoryData(inventoryDt);
      setInventoryDataCopy(inventoryDt);
      setInventories(inventoryDt);
    } else {
      setInventoryData(inventories);
      setInventoryDataCopy(inventories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoriesData]);

  const onInventoryUpdate = async (desc, serialNumber, projectAssetId, orderQuantity) => {
    setInventoryModalLoading(true);
    const value = {
      serial_number: serialNumber,
      description: desc,
      project_asset_id: projectAssetId,
      isQuantityUpdate: true,
      order_quantity: orderQuantity
    };
    const res = await updateProjectInventory(projNo, value, projectAssetId);
    setInventoryModalLoading(false);
    if (res) {
      setIsModalVisible(false);
      setIsEdit(false);
      onGetProjectInventories({ projectNo: projNo });
      notification.success({
        message: 'Part Updated',
        duration: 1.5
      });
      addInventoryForm.resetFields();
    } else {
      notification.error({
        message: `Part already exist with part number : ${serialNumber}`,
        duration: 1.2
      });
    }
  };

  const searchInventory = (value) => {
    const inventoryDt = inventoryDataCopy.filter((
      dt
    ) => dt.serialNo.toLowerCase().includes(value.toLowerCase())
             || (dt.materialDescription
       && dt.materialDescription.toLowerCase().includes(value.toLowerCase())));
    setInventoryData(inventoryDt);
    setInventories(inventoryDt);
  };

  const columns = [
    {
      title: 'Part Number',
      dataIndex: 'serialNo',
      key: 'serialNo',
      align: 'center'
    },
    {
      title: 'Description',
      dataIndex: 'materialDescription',
      key: 'materialDescription',
      align: 'center'
    },
    {
      title: 'Order Quantity',
      dataIndex: 'orderQuantity',
      key: 'orderQuantity',
      align: 'center'
    },
    {
      title: 'Current Quantity',
      dataIndex: 'currentQuantity',
      key: 'currentQuantity',
      align: 'center'
    },
    {
      title: '',
      render: (record) => {
        if (record) {
          return (
            <div>
              <Tooltip
                arrowPointAtCenter
                placement="topLeft"
                title="Update Asset"
              >
                <EditOutlined
                  className="edit-project-detail"
                  onClick={() => {
                    editBtnHandler(record);
                  }}
                />

              </Tooltip>
            </div>
          );
        }
        return <div>-</div>;
      },
      align: 'right',
    },
    {
      title: '',
      render: (record) => {
        if (record) {
          return (
            <div>
              <Tooltip
                arrowPointAtCenter
                placement="topLeft"
                title={projectStatus === 'Archived'
                  ? 'Cannot Delete Parts from Archived Project' : 'Delete Part'}
              >
                {projectStatus === 'Archived'
                  ? <DeleteIcon style={{ marginRight: '30px', fontSize: '16px' }} />
                  : (
                    <DeleteIcon
                      style={{ marginRight: '30px', fontSize: '16px' }}
                      onClick={() => handleDeletion(record)}
                    />
                  )}
              </Tooltip>
            </div>
          );
          // eslint-disable-next-line brace-style
        }
        // eslint-disable-next-line no-else-return
        else {
          return <div>-</div>;
        }
      },
      align: 'right',
    },
  ];

  const inventoryChangeHandle = (event) => {
    if (!event.target.value) {
      const inventoryDt = [];
      inventoriesData.forEach((dt) => {
        inventoryDt.push(dt);
      });
      setInventoryData(inventoryDt);
      setInventoryDataCopy(inventoryDt);
      setInventories(inventoryDt);
    }
  };

  return (
    <>
      <div className="h-100 overflow-y-hidden overflow-x-auto">
        <div className="d-flex align-items-center justify-content-between w-100">
          <h3>
            Total Number of Parts :
            {' '}
            {inventoryData.length > 0 ? inventoryData.length : 0}

          </h3>
          <Search
            style={{ width: '250px' }}
            className="sbd-search-wrapper"
            placeholder="Enter Part No./Desc "
            allowClear
            enterButton
            onSearch={searchInventory}
            onChange={inventoryChangeHandle}
          />
        </div>
        <Table
          pagination={false}
          columns={columns}
          dataSource={inventoryData && inventoryData}
          className="table-with-button-assets"
          scroll={{ x: inventoryData.length > 0 ? 600 : 0, y: '100%' }}
          loading={{
            indicator: <Spin />,
            spinning: showInventoryLoader
          }}
        />
        {' '}
        {projNo !== ''
          ? (
            <div className="text-right mt-3 mb-2">
              {projectType === 'onshore' && (
              <Tooltip
                placement="topLeft"
                title={projectStatus === 'Archived'
                  ? 'Cannot Add Parts to Archived Project' : ''}
              >
                <Button
                  key="submit"
                  size="small"
                  shape="round"
                  type="primary"
                  style={{ 'margin-right': '1.1rem' }}
                  disabled={showInventoryLoader}
                  onClick={() => setIsReportModalVisible(true)}
                >
                  View Reports
                </Button>
              </Tooltip>
              )}
              <Button
                key="submit"
                size="small"
                shape="round"
                type="primary"
                className=""
                disabled={projectStatus === 'Archived' || showInventoryLoader}
                onClick={() => setIsModalVisible(true)}
              >
                Add Parts
              </Button>
            </div>
          )
          : null}
      </div>

      {isModalVisible
        ? (
          <AddInventoryModal
            setIsModalVisible={setIsModalVisible}
            isModalVisible={isModalVisible}
            inventoryModalLoading={inventoryModalLoading}
            setInventoryModalLoading={setInventoryModalLoading}
            addInventoryForm={addInventoryForm}
            inventoryList={inventoryData}
            onSave={onInventorySave}
            projectNo={projNo}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            onUpdate={onInventoryUpdate}
            awsConfigDt={awsConfigDt}
            projectType={projectType}
          />
        )
        : null}
      {isReportModalVisible
        ? (
          <ViewInventoryReportModal
            setIsReportModalVisible={setIsReportModalVisible}
            isReportModalVisible={isReportModalVisible}
            inventoryReportDt={inventoryReportDt}
            projectNo={projNo}
            hideModal={hideModal}
          />
        )
        : null}
    </>
  );
};
Inventory.propTypes = {
  onGetProjectInventories: func.isRequired,
  inventoriesData: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showInventoryLoader: bool.isRequired,
};

const mapStateToProps = ({ projectInventoryRequests }) => {
  const inventoriesData = get(projectInventoryRequests, 'data', []);
  const showInventoryLoader = get(projectInventoryRequests, 'isLoading', false);
  return {
    inventoriesData,
    showInventoryLoader
  };
};

const mapDispatchToProps = {
  onGetProjectInventories: getProjectInventory
};

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
