import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { setMachineType, setMachineTypeFailure, setMachineSubType } from '../state/action/advisoryAction';
import { GET_MACHINE_TYPE } from '../state/types';
import fetch from '../services/fetch';
import { BASE_URL } from '../config';

function* getMachineType(data) {
  let status = 0;
  try {
    const res = yield fetch(`${BASE_URL}/advisory/machine-types?parent_id=${data.typeId || ''}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      status = res.status;
      throw new Error('Unauthorized');
    }
    const response = yield res.json();
    if (!data.typeId) yield put(setMachineType({ response }));
    if (data.typeId) yield put(setMachineSubType({ response }));
  } catch (error) {
    yield put(setMachineTypeFailure());
    if (status !== 403) {
      notification.error({
        message: 'There was a problem fetching the report details.',
        description: `${error}`,
      });
    }
  }
}

function* advisoryWatcher() {
  yield takeLatest(GET_MACHINE_TYPE, getMachineType);
}

function* advisorySagas() {
  yield all([
    call(advisoryWatcher),
  ]);
}

export default advisorySagas;
