/* eslint-disable camelcase */
import React from 'react';
import {
  Modal, Form,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import * as utility from '../utils/utility';
import { advReqStatus } from '../utils/constants';

const ApproveRequestModal = (props) => {
  const {
    isModalVisible, setIsModalVisible, onSave, form, isReject,
    isLoading
  } = props;

  const handleOk = async () => {
    const data = await form.validateFields();
    data.status = isReject ? advReqStatus.reject : advReqStatus.approve;
    onSave(data);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const rules = isReject ? {
    required: true,
    message: 'Please enter a comment.',
  } : {};
  return (
    <>
      <Modal
        className="quality-modal"
        title={isReject ? 'Reject Request' : 'Approve Request'}
        centered
        visible={isModalVisible}
        onOk={handleOk}
        okText="Yes"
        cancelText="No"
        onCancel={handleCancel}
        okButtonProps={{
          disabled: isLoading
        }}
        cancelButtonProps={{ disabled: isLoading }}
      >

        <Form
          layout="vertical"
          name="update-status"
          form={form}
        >
          <>
            {isReject ? (
              <div className="pb-2 approve-modal">Are you sure you want to reject this request ?</div>
            ) : <div className="pb-2 approve-modal">Are you sure you want to approve this request ?</div>}
            <Form.Item
              name="comment"
              label="Comment:"
              rules={[rules]}
              normalize={(value) => utility.trimSpacesInValues(value)}
            >

              <TextArea
                showCount
                size="large"
                placeholder="Enter a comment"
                maxLength={1000}
                rows={4}
              />
            </Form.Item>
          </>
          <Form.Item style={{ display: 'none' }} name="approval_id" />
        </Form>
      </Modal>
    </>
  );
};

export default ApproveRequestModal;
