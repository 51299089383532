import { notification } from 'antd';
import fetch from './fetch';
import { BASE_URL } from '../config';

export const addAdvisory = async (body) => {
  try {
    const res = await fetch(`${BASE_URL}/advisory/machine`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return { err: true };
    }
    if (res.status === 200) {
      return { err: false };
    }
    const response = await res.json();
    return { err: true, message: response.message };
  } catch (error) {
    return { err: true };
  }
};

export const addMachineTypes = async (body) => {
  try {
    const res = await fetch(`${BASE_URL}/advisory/machine-types`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const updateAdvisory = async (body, id) => {
  try {
    const res = await fetch(`${BASE_URL}/advisory/machine?id=${id}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return { err: true };
    } if (res.status === 200) {
      return { err: false };
    }
    const response = await res.json();
    return { err: true, message: response.message };
  } catch (error) {
    return { err: true };
  }
};

export const removeAdvisory = async (id, deletedDate) => {
  try {
    const res = await fetch(`${BASE_URL}/advisory/delete?deleted_date=${deletedDate}&id=${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const resendNotification = async (id) => {
  try {
    const res = await fetch(`${BASE_URL}/advisory/resendNotification?id=${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getSignedUrl = async (body) => {
  try {
    const res = await fetch(`${BASE_URL}/advisory/getSignedUrl`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
      },
    });
    const response = await res.json();
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return response;
    }
    return response;
  } catch (error) {
    return null;
  }
};

export const getApprovalReqCount = async () => {
  try {
    const res = await fetch(`${BASE_URL}/advisory/approval/count`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    const response = await res.json();
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return response;
    }
    return response;
  } catch (error) {
    return null;
  }
};

export const updateApprovalRequest = async (body) => {
  try {
    const res = await fetch(`${BASE_URL}/advisory/updateApprovalStatus`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const updateMachinetype = async (body) => {
  try {
    const res = await fetch(`${BASE_URL}/advisory/machine-types`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return { err: true };
    }
    if (res.status === 200) {
      return { err: false };
    }
    const response = await res.json();
    return { err: true, message: response.message };
  } catch (error) {
    return { err: true };
  }
};

export const getApprovalHistory = async (advisoryId, page, limit) => {
  try {
    const res = await fetch(`${BASE_URL}/advisory/approval/request?advisory_id=${advisoryId}&page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    const response = await res.json();
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return response;
    }
    return response;
  } catch (error) {
    return null;
  }
};
