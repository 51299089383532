import { notification } from 'antd';
import fetch from './fetch';
import { BASE_URL } from '../config';

export const removeEmail = async (email) => {
  try {
    const res = await fetch(`${BASE_URL}/reportEmailConfig/deleteEmail`, {
      method: 'DELETE',
      body: JSON.stringify(email),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getEmails = async () => {
  try {
    const res = await fetch(`${BASE_URL}/reportEmailConfig/getEmailList`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const emailsData = await res.json();
      return emailsData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const addEmail = async (email) => {
  try {
    const res = await fetch(`${BASE_URL}/reportEmailConfig/addEmail`, {
      method: 'POST',
      body: JSON.stringify(email),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const updateEmail = async (emailData) => {
  try {
    const res = await fetch(`${BASE_URL}/reportEmailConfig/updateEmail`, {
      method: 'POST',
      body: JSON.stringify(emailData),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};
