import { notification } from 'antd';
import fetch from './fetch';
import { BASE_URL } from '../config';

export const removeProjectInventory = async (projectNo, assetId) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = await fetch(`${BASE_URL}/projectsData/deleteInventoryAssets?project_numbers=${projNo}&id=${assetId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const addProjectInventory = async (projectNo, filename) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = await fetch(`${BASE_URL}/projectData/${projNo}/inventory`, {
      method: 'POST',
      body: filename,
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const updateProjectInventory = async (projectNo, inventory, assetId) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = await fetch(`${BASE_URL}/projectData/${projNo}/inventory/update`, {
      method: 'POST',
      body: JSON.stringify(inventory),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getInventoryReports = async ({ projectNo }) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = await fetch(`${BASE_URL}/projectsInventoryData/getReports?project_numbers=${projNo}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const reportData = await res.json();
      return reportData;
    }
    return null;
  } catch (error) {
    notification.error({
      message: 'There was a problem fetching the data.',
      description: `${error}`,
    });
    return null;
  }
};
