/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Icon from '@ant-design/icons';

const NewMessageSvg = () => (
  <svg id="Group_6286" data-name="Group 6286" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 26.333 26.333">
    <path id="Path_610" data-name="Path 610" d="M0,0H26.333V26.333H0Z" fill="none" />
    <path id="Path_611" data-name="Path 611" d="M12.969,23.9A2.2,2.2,0,0,0,15.164,21.7H10.775A2.2,2.2,0,0,0,12.969,23.9Zm6.583-6.583V11.826c0-3.368-1.788-6.188-4.938-6.934V4.146a1.646,1.646,0,1,0-3.292,0v.746c-3.138.746-4.938,3.555-4.938,6.934v5.486L4.192,19.507v1.1H21.747v-1.1Zm-2.194,1.1H8.58V11.826c0-2.721,1.657-4.938,4.389-4.938s4.389,2.216,4.389,4.938ZM8.12,4.234,6.551,2.665A11.439,11.439,0,0,0,2.03,11.278H4.224A9.267,9.267,0,0,1,8.12,4.234Zm13.595,7.044h2.194a11.508,11.508,0,0,0-4.521-8.613L17.83,4.234A9.321,9.321,0,0,1,21.714,11.278Z" transform="translate(0.197 0.243)" fill="currentColor" />
  </svg>
);
export const NewMessageIcon = (props) => (
  <Icon component={NewMessageSvg} {...props} />
);
