/* eslint-disable react/prop-types */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */

import React, { useEffect, useState } from 'react';
import { Table, Form, Spin } from 'antd';
import { connect } from 'react-redux';
import {
  func, arrayOf, objectOf, oneOfType, string, number, array
} from 'prop-types';
import get from 'lodash/get';
import StatusModal from './StatusModal';
import { submitStatus, getQualityReports } from '../state/action/qualityAction';

const QualityReports = (props) => {
  const {
    onGetQualityReports, qualityData,
    projNo, onSubmitStatus, reload, setQualRep, refresh, showQualityLoader
  } = props;
  const [qualityReport, setQualityReport] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editStatusForm] = Form.useForm();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [totalReports, setTotalReports] = useState(0);

  useEffect(() => {
    if (projNo) {
      onGetQualityReports(projNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo]);

  useEffect(() => {
    if (reload) {
      if (projNo) {
        onGetQualityReports(projNo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const showModal = (record) => {
    let editData = null;
    if (record != null) {
      editData = {
        notes: record.notes,
        guid: record.guid,
        status: `${record?.status?.replace(/\s/g, '')?.toLowerCase() === 'todo'
          ? 'To Do' : record?.status?.replace(/\s/g, '')?.toLowerCase() === 'inprogress'
            ? 'In Progress' : record?.status?.replace(/\s/g, '')?.toLowerCase() === 'done'
              ? 'Done' : record?.status?.replace(/\s/g, '')?.toLowerCase() === 'review'
                ? 'Review' : ''}`
      };
    }
    editStatusForm.setFieldsValue(editData);
    setIsModalVisible(true);
  };
  const columns = [
    {
      title: 'Project Status',
      dataIndex: 'projectStatus',
      key: 'projectStatus'
    },
    {
      title: 'Serial Number',
      dataIndex: 'serialNo',
      key: 'serialNo',
      align: 'center'
    },
    {
      title: 'Equipment',
      dataIndex: 'equipment',
      key: 'equipment',
      align: 'center'
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      align: 'center'
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
      align: 'center'
    },
    {
      title: 'Overall Status',
      dataIndex: 'status',
      key: 'status',
      render: (record) => (
        <div className="theme-color">
          {record?.replace(/\s/g, '')?.toLowerCase() === 'todo'
            ? 'To Do' : record?.replace(/\s/g, '')?.toLowerCase() === 'inprogress'
              ? 'In Progress' : record?.replace(/\s/g, '')?.toLowerCase() === 'done'
                ? 'Done' : record?.replace(/\s/g, '')?.toLowerCase() === 'review' ? 'Review' : null}
        </div>
      ),
      align: 'center'
    },
    {
      title: 'Report',
      key: 'pdf',
      dataIndex: 'pdf',
      render: (record) => {
        if (record) {
          return (
            <div>
              {' '}
              <a
                className="export-link"
                title="Download pdf report"
                target="_blank"
                rel="noopener noreferrer"
                href={record}
                // eslint-disable-next-line react/jsx-closing-bracket-location
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span className="icon-16 mr-2">
                  <svg>
                    <use
                      xlinkHref="#export"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </svg>
                </span>
              </a>
            </div>
          );
          // eslint-disable-next-line brace-style
        }
        // eslint-disable-next-line no-else-return
        else {
          return <div>-</div>;
        }
      },
      align: 'center'
    },
    {

      key: 'action',
      dataIndex: 'action',
      // eslint-disable-next-line consistent-return
      render: (text, record) => {
        return (
          <div>
            <span
              className="icon-16 mr-2 cursor-pointer"
            >
              <svg onClick={() => {
                showModal(record);
              }}
              >
                <use
                  xlinkHref="#edit"
                />
              </svg>
            </span>
          </div>
        );
      },
      align: 'center'
    },
  ];

  const onSave = async (id, status, notes) => {
    await onSubmitStatus({
      data: {
        id,
        feedback_status: status,
        feedback_notes: notes,
      },
      projectNo: projNo
    });
  };

  const setQualReport = () => {
    const qualList = [];
    if (projNo !== '') {
      // eslint-disable-next-line array-callback-return
      Object.keys(qualityData).map((items) => {
        if (qualityData[items].projectNo.toLowerCase() === projNo.toLowerCase()) {
          if (qualityData[items].equipment === '') {
            qualityData[items].equipment = 'N/A';
          }
          if (qualityData[items].comments === '') {
            qualityData[items].comments = 'N/A';
          }
          qualList.push(qualityData[items]);
        }
      });
      setTotalReports(qualList.length);
      setQualityReport(qualList);
      setQualRep(qualList);
    } else {
      setQualityReport([]);
    }
  };

  const handleTableChnage = (pagination,
    filters,
    sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  useEffect(() => {
    refresh(false);

    if (!reload) {
      setQualReport();
    } else {
      setQualReport();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo, qualityData]);
  return (
    <div className="h-85 overflow-y-hidden overflow-x-auto">
      <Table
        scroll={{ x: qualityReport.length > 0 ? 1200 : 0, y: '100%' }}
        pagination={totalReports > 10 ? tableParams.pagination : false}
        columns={columns}
        dataSource={qualityReport}
        className="h-85"
        onChange={handleTableChnage}
        loading={{
          indicator: <Spin />,
          spinning: showQualityLoader
        }}
      />
      <StatusModal
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        editStatusForm={editStatusForm}
        onSave={onSave}
      />
    </div>
  );
};

QualityReports.propTypes = {
  onGetQualityReports: func.isRequired,
  qualityData: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  onSubmitStatus: func.isRequired,
};

const mapStateToProps = ({
  qualityReports
}) => {
  const data = get(qualityReports, 'data', []);
  const showLoader = get(qualityReports, 'isLoading', false);
  const qualityData = get(qualityReports, 'data', []);
  const showQualityLoader = get(qualityReports, 'isLoading', false);
  return {
    data,
    showLoader,
    qualityData,
    showQualityLoader
  };
};

const mapDispatchToProps = {
  onGetQualityReports: getQualityReports,
  onSubmitStatus: submitStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(QualityReports);
