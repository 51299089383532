/* eslint-disable linebreak-style */
import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { setAwsConfigData, setAwsConfigDataFailure } from '../state/action/awsConfigDataAction';
import {
  FETCH_ASSETS
} from '../state/types';
import fetch from '../services/fetch';

import { BASE_URL } from '../config';

function* getAwsConfigData() {
  try {
    const res = yield fetch(`${BASE_URL}/dataMigration/awsConfigData`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      throw new Error('Unauthorized');
    }
    const response = yield res.json();
    yield put(setAwsConfigData({ response }));
  } catch (error) {
    yield put(setAwsConfigDataFailure());
    notification.error({
      message: 'There was a problem fetching the data.',
      description: `${error}`,
    });
  }
}

function* awsConfigWatcher() {
  yield takeLatest(FETCH_ASSETS, getAwsConfigData);
}

function* awsConfigSagas() {
  yield all([
    call(awsConfigWatcher),
  ]);
}

export default awsConfigSagas;
