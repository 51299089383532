import {
  GET_NOTIFICATIONS, SET_NOTIFICATIONS, SET_NOTIFICATIONS_FAILURE
} from '../types';

const getNotifications = (projectNo) => ({
  type: GET_NOTIFICATIONS,
  projectNo
});
const setNotifications = (payload) => ({
  type: SET_NOTIFICATIONS, ...payload,
});
const setNotificationsFailure = () => ({
  type: SET_NOTIFICATIONS_FAILURE,
});
export {
  getNotifications,
  setNotifications,
  setNotificationsFailure
};
