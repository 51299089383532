/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

import React from 'react';
import {
  Modal, DatePicker, Button, Table, Form, notification
} from 'antd';
import {
  CheckOutlined
} from '@ant-design/icons';
import moment from 'moment';

const { RangePicker } = DatePicker;

const DateSelectionModal = (props) => {
  const columns = [
    {
      title: 'Date',
      dataIndex: 'selected_date',
      key: 'selected_date',
      align: 'center'
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      align: 'center'
    },
    {
      title: 'Created Time',
      dataIndex: 'date_and_time_of_submission',
      key: 'date_and_time_of_submission',
      align: 'center'
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
      align: 'center'
    },
    {
      title: 'Report',
      key: 'pdf_url',
      dataIndex: ['pdf_url', 'pdf_signed'],
      render: (text, record) => {
        if (record) {
          return (
            <div>
              {' '}
              <a
                className="export-link"
                title={record.pdf_signed === 1 ? 'Signed Report' : 'Client signature pending'}
                target="_blank"
                rel="noopener noreferrer"
                href={record.pdf_url}
                // eslint-disable-next-line react/jsx-closing-bracket-location
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span className="icon-16 mr-2">
                  <svg>
                    <use
                      xlinkHref="#export"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      color={record.pdf_signed === 1 && '#4ded18'}
                    />
                  </svg>
                </span>
              </a>
            </div>
          );
          // eslint-disable-next-line brace-style
        }
        // eslint-disable-next-line no-else-return
        else {
          return <div>-</div>;
        }
      },
      align: 'center'
    },

  ];

  const {
    modalVisible, hideModal, pdfDates, setPdfDates, sendMailForSignature, esignMailModalLoading,
    lemData, projectContractor, projectManagerEmail, dailyReportData, setDailyReportData, dateForm,
    selectedWeeklyReportRows, setSelectedWeeklyReportRows, selectedRowKey, setSelectedRowKey
  } = props;

  const handleOk = async () => {
    const finalData = [];
    if (projectManagerEmail) {
      selectedWeeklyReportRows.map((dt) => {
        finalData.push({
          contractor: projectContractor,
          contractor_email: projectManagerEmail,
          guid: dt.guid,
          pdf_url: dt.pdf_url
        });
      });
      await sendMailForSignature(finalData, pdfDates);
    } else {
      notification.error({
        message: 'Please set the project manager email',
        duration: 1.2
      });
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKey,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedWeeklyReportRows(selectedRows);
      setSelectedRowKey(selectedRowKeys);
    },
    getCheckboxProps: (record) => {
      return {
        disabled: record.pdf_signed === 1
      };
    }
  };

  const showReportDetails = () => {
    const startDate = moment(pdfDates[0]).format('DD-MMM-YYYY');
    const endDate = moment(pdfDates[1]).format('DD-MMM-YYYY');
    const weeklyData = [];
    lemData.map((data) => {
      if (new Date(data.selected_date) >= new Date(startDate)
      && new Date(data.selected_date) <= new Date(endDate)) {
        weeklyData.push(data);
      }
    });
    setDailyReportData(weeklyData);
  };

  // useEffect(() => {
  //   dateForm.resetFields();
  // }, []);

  return (
    <>
      <Modal
        title="eSign Reports"
        className="quality-modal sbd-scroll-modal"
        centered
        width="887px"
        visible={modalVisible}
        onOk={() => handleOk(false)}
        okText="Send pdf for signature"
        okButtonProps={{ disabled: !(pdfDates && pdfDates[0] && pdfDates[1]) }}
        onCancel={() => hideModal()}
        footer={[
          <Button key="submit" onClick={() => hideModal()} loading={esignMailModalLoading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type={selectedWeeklyReportRows && selectedWeeklyReportRows.length ? 'primary' : 'default'}
            disabled={!(selectedWeeklyReportRows && selectedWeeklyReportRows.length > 0)}
            onClick={() => handleOk(false)}
            loading={esignMailModalLoading}
          >
            Send pdf for signature
          </Button>
        ]}
      >
        <Form
          id="lem-report-esign-form"
          name="basic"
          autoComplete="off"
          form={dateForm}
        >
          <Form.Item name="esignDateFilter">
            <div className="d-flex lem-date-filter" style={{ maxWidth: '500px' }}>
              <RangePicker
                // className="lem-signature-rangePicker_separator"
                defaultValue={pdfDates}
                separator="to"
                format="DD-MMM-YYYY"
                placeholder={['Start Date', 'End Date']}
                onCalendarChange={(val) => setPdfDates(val)}
              />
              <Button
                type="primary"
                disabled={!(pdfDates && (pdfDates[0] && pdfDates[1]))}
                onClick={() => showReportDetails()}
              >
                <CheckOutlined />
                {' '}
              </Button>
            </div>
          </Form.Item>
        </Form>

        <div className={dailyReportData.length > 0 ? 'h-85 overflow-y-hidden overflow-x-auto has-pointer' : 'h-85 overflow-y-hidden overflow-x-auto'}>
          <Table
            rowSelection={{
              ...rowSelection,
            }}
            rowKey={(record) => record.reportId}
            scroll={{ x: dailyReportData.length > 0 ? 1200 : 0, y: '100%' }}
            pagination={false}
            columns={columns}
            dataSource={dailyReportData && dailyReportData}
          />
        </div>

      </Modal>
    </>
  );
};

export default DateSelectionModal;
