/* eslint-disable linebreak-style */
import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import {
  FETCH_PROJECT_INVENTORY
} from '../state/types';
import fetch from '../services/fetch';

import { BASE_URL } from '../config';
import { setProjectInvetory, setProjectInvetoryFailure } from '../state/action/projectInventoryAction';

function* getProjectInventory({ projectNo }) {
  try {
    const projNo = encodeURIComponent(projectNo.projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = yield fetch(`${BASE_URL}/projectsData/getInventoryAssets?project_numbers=${projNo}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      throw new Error('Unauthorized');
    }
    const response = yield res.json();
    yield put(setProjectInvetory({ response }));
  } catch (error) {
    yield put(setProjectInvetoryFailure());
    notification.error({
      message: 'There was a problem fetching the inventory assets.',
      description: `${error}`,
    });
  }
}

function* projectInventoryWatcher() {
  yield takeLatest(FETCH_PROJECT_INVENTORY, getProjectInventory);
}

function* projectInventorySagas() {
  yield all([
    call(projectInventoryWatcher),
  ]);
}

export default projectInventorySagas;
