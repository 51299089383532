import { notification } from 'antd';
import fetch from './fetch';
import { BASE_URL } from '../config';

export const getUsersToAdd = async (projectNo) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = await fetch(`${BASE_URL}/projects/${projNo}/users/list`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const usersData = await res.json();
      return usersData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getUsers = async () => {
  try {
    const res = await fetch(`${BASE_URL}/user`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const usersData = await res.json();
      return usersData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getMembers = async (userData) => {
  try {
    const res = await fetch(`${BASE_URL}/users/details`, {
      method: 'POST',
      body: JSON.stringify({ userData }),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const usersData = await res.json();
      return usersData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const addTeamMembers = async (projectNo, users) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = await fetch(`${BASE_URL}/project/user/add?project_numbers=${projNo}`, {
      method: 'POST',
      body: JSON.stringify({ userId: users }),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const removeTeamMember = async (projectNo, userId) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = await fetch(`${BASE_URL}/project/user/delete?project_numbers=${projNo}&user_id=${userId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getRoles = async () => {
  try {
    const res = await fetch(`${BASE_URL}/project/summary/personnel-master-role`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const usersData = await res.json();
      return usersData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getRolesToAdd = async (projectNo) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = await fetch(`${BASE_URL}/project/summary/get-personnel-role?project_id=${projNo}&isWeb=${true}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const usersData = await res.json();
      return usersData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const addRoles = async (selectedRole, accessToLogs, userIds) => {
  try {
    const res = await fetch(`${BASE_URL}/project/summary/add-personnel-role`, {
      method: 'POST',
      body: JSON.stringify({
        entries: [{
          role_id: selectedRole,
          user_project_id: userIds,
          accessToLogs
        }]
      }),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const addNonCognitoUser = async (userData) => {
  try {
    const res = await fetch(`${BASE_URL}/project/non-cognito-user/add`, {
      method: 'POST',
      body: JSON.stringify({ ...userData }),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getNonCognitoUsers = async (userData) => {
  try {
    const res = await fetch(`${BASE_URL}/project/non-cognito-user`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const nonCognitoUsersUsers = await res.json();
      return nonCognitoUsersUsers;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const addPersonalMasterRoles = async (newRole) => {
  try {
    const res = await fetch(`${BASE_URL}/project/summary/personnel-master-role`, {
      method: 'POST',
      body: JSON.stringify({ role: newRole }),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getProdLogReportData = async (
  startDate, endDate, projectNo, username, signatureURL) => {
  try {
    const signParams = `signatureURL=${encodeURIComponent(signatureURL)}`;
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');

    const res = await fetch(`${BASE_URL}/reportDataMigration?opCode=1&startDate=${startDate}&endDate=${endDate}&projectNo=${projNo}&${signParams}&signedBy=${username}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const nonCognitoUsersUsers = await res.json();
      return nonCognitoUsersUsers;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const addMonthlySignature = async (projectNo, signatureURL, username) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = await fetch(`${BASE_URL}/project/monthly-signature/add?project_numbers=${projNo}`, {
      method: 'POST',
      body: JSON.stringify({ signatureURL, user_id: username }),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getMonthlySignature = async (projectNo, userId) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');

    const res = await fetch(`${BASE_URL}/project/monthly-signature?project_numbers=${projNo}&userId=${userId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const signature = await res.json();
      return signature;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const addOffshoreTimeSheetReport = async (url, author, projectNo, projectName, period) => {
  try {
    const res = await fetch(`${BASE_URL}/project/offshore-timesheet-report/add`, {
      method: 'POST',
      body: JSON.stringify({
        excel_url: url,
        author,
        project_number: projectNo,
        project_name: projectName,
        period
      }),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};
