/* eslint-disable linebreak-style */
import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import {
  FETCH_PROJECT_ASSETS
} from '../state/types';
import fetch from '../services/fetch';

import { BASE_URL } from '../config';
import { setProjectAsset, setProjectAssetFailure } from '../state/action/projectAssetAction';

function* getProjectAssets({ projectNo }) {
  try {
    const projNo = encodeURIComponent(projectNo.projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = yield fetch(`${BASE_URL}/projectsData/getAssets?project_numbers=${projNo}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      throw new Error('Unauthorized');
    }
    const response = yield res.json();
    yield put(setProjectAsset({ response }));
  } catch (error) {
    yield put(setProjectAssetFailure());
    notification.error({
      message: 'There was a problem fetching the assets.',
      description: `${error}`,
    });
  }
}

function* projectAssetWatcher() {
  yield takeLatest(FETCH_PROJECT_ASSETS, getProjectAssets);
}

function* projectAssetSagas() {
  yield all([
    call(projectAssetWatcher),
  ]);
}

export default projectAssetSagas;
