/* eslint-disable linebreak-style */
import React from 'react';

const ComingSoon = ({ title }) => (
  <div className="h-100 d-flex align-items-center justify-content-center">
    <div className="coming-soon">
      { title ? (
        <h2>
          {title}
        </h2>
      ) : (
        <h1>
          Coming Soon
          <span className="dot">.</span>
        </h1>
      )}
    </div>
  </div>
);
export default ComingSoon;
