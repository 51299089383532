import {
  FETCH_TEAM_MEMBERS, SET_TEAM_MEMBERS, SET_TEAM_MEMBERS_FAILURE
} from '../types';

const getTeamMembers = (projectNo, projectType) => ({
  type: FETCH_TEAM_MEMBERS,
  projectNo,
  projectType
});
const setTeamMembers = (payload) => ({
  type: SET_TEAM_MEMBERS, ...payload,
});
const setTeamMembersFailure = () => ({
  type: SET_TEAM_MEMBERS_FAILURE,
});
export {
  getTeamMembers,
  setTeamMembers,
  setTeamMembersFailure
};
