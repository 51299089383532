import React, { useState, useEffect } from 'react';
import {
  Table, Button, Tooltip, Modal, notification, Spin
} from 'antd';
import moment from 'moment';
import AddPipelineModal from './AddPipelineModal';
import { DeleteIcon } from './svgs/deleteIcon';
import { addProjectPipelines, getPipeline, removeProjectPipeline } from '../services/pipeline';

const Pipelines = ({
  projNo,
  showLoader,
  projectStatus
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pipelineModalLoading, setPipelineModalLoading] = useState(false);
  const [type, setType] = useState('quality');
  const [pipelineData, setPipelineData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await getPipeline(projNo);
      if (res?.pipelineData) {
        const data = res?.pipelineData.filter((item) => item.is_deleted === 0);
        setPipelineData(data);
      } else setPipelineData([]);
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (projNo) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo]);

  const onSave = async (name, requiredHeader) => {
    setPipelineModalLoading(true);
    const res = await addProjectPipelines(projNo, name, requiredHeader);
    setPipelineModalLoading(false);
    if (res) {
      setIsModalVisible(false);
      await getData();
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  const handleDeletion = (record) => {
    const date = moment(new Date()).format('YYYY-MM-DD');
    Modal.confirm({
      title: 'Are you sure you want to remove this Pipeline from the project?',
      className: 'custom-text-clr',
      async onOk() {
        return removeProjectPipeline(date, record.id).then((res) => {
          if (res) {
            getData();
          } else {
            notification.error({
              message: 'Something went wrong.',
            });
          }
          return true;
        }).catch(() => notification.error({
          message: 'Something went wrong.',
        }));
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'Pipeline',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Welds Required for Project',
      dataIndex: 'required_for_header',
      key: 'required_for_header',
    },
    {
      title: '',
      render: (record) => {
        if (record) {
          return (
            <div>
              <Tooltip
                arrowPointAtCenter
                placement="topRight"
                title={projectStatus === 'Archived'
                  ? 'Cannot Delete Pipeline from Archived Project' : 'Delete Pipeline'}
              >
                {projectStatus === 'Archived'
                  ? <DeleteIcon style={{ marginRight: '30px', fontSize: '16px' }} />
                  : (
                    <DeleteIcon
                      style={{ marginRight: '30px', fontSize: '16px' }}
                      onClick={() => handleDeletion(record)}
                    />
                  )}
              </Tooltip>
            </div>
          );
          // eslint-disable-next-line brace-style
        }
        // eslint-disable-next-line no-else-return
        else {
          return <div>-</div>;
        }
      },
      align: 'Right',
    },

  ];

  return (
    <>
      <div className="h-100 overflow-y-hidden overflow-x-auto">
        <div className="text-right mb-1">
          <Tooltip
            placement="topLeft"
            title={projectStatus === 'Archived'
              ? 'Cannot Add Members to Archived Project' : ''}
          >
            <Button
              key="submit"
              shape="round"
              style={{ marginRight: 'auto' }}
              type="primary"
              className="ms-3"
              disabled={projectStatus === 'Archived' || showLoader}
              onClick={() => setIsModalVisible(true)}
            >
              Add Project Pipeline
            </Button>
          </Tooltip>
        </div>
        <div>
          <Table
            pagination={false}
            columns={columns}
            dataSource={pipelineData}
            className="table-with-button-assets asset-table"
            style={{ height: '100%' }}
            scroll={{ y: '40vh' }}
            loading={{
              indicator: <Spin />,
              spinning: loading
            }}
          />
        </div>
      </div>
      <AddPipelineModal
        type={type}
        setType={setType}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        pipelineModalLoading={pipelineModalLoading}
        setPipelineModalLoading={setPipelineModalLoading}
        projectNo={projNo}
        onSave={onSave}
      />
    </>
  );
};

export default Pipelines;
