/* eslint-disable array-callback-return */
/* eslint-disable linebreak-style */
import get from 'lodash/get';
import {
  FETCH_ASSETS,
  SET_ASSETS,
  SET_ASSET_FAILURE
} from '../types';

const initialState = {
  isProjectLoading: false,
};

const assetReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ASSETS: {
      const assets = get(action, ['response'], []);
      const asset = assets.data;
      const data = [];
      for (let i = 0; i < asset.length; i += 1) {
        data[i] = {
          key: i,
          projectAssetid: assets?.data[i]?.project_asset_id,
          assetid: assets?.data[i]?.asset_id,
          materialDescription: assets?.data[i]?.material_description,
          plant: assets?.data[i]?.plant,
          serialNo: assets?.data[i]?.serial_number,
          assetCode: assets?.data[i]?.asset_code,
          productHeirarchy: assets?.data[i]?.product_heirarchy
        };
      }
      return {
        ...state,
        data,
        isLoading: false,
      };
    }

    case FETCH_ASSETS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_ASSET_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default assetReducer;
