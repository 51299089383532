/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Icon from '@ant-design/icons';

const DeleteSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16.25 21.19">
    <path id="Path_605" data-name="Path 605" d="M21.6,10.891l-.341,10.33a3.4,3.4,0,0,1-3.413,3.291H11.36a3.4,3.4,0,0,1-3.413-3.288L7.606,10.891a.854.854,0,0,1,1.706-.056l.341,10.333a1.706,1.706,0,0,0,1.706,1.641h6.485a1.706,1.706,0,0,0,1.706-1.644l.341-10.329a.854.854,0,1,1,1.706.056Zm1.129-3.438a.853.853,0,0,1-.853.853H7.331a.853.853,0,1,1,0-1.706H9.976a1.089,1.089,0,0,0,1.086-.98,2.552,2.552,0,0,1,2.546-2.3H15.6a2.552,2.552,0,0,1,2.546,2.3,1.089,1.089,0,0,0,1.086.98h2.645a.853.853,0,0,1,.853.853ZM12.544,6.6h4.119a2.816,2.816,0,0,1-.218-.805.853.853,0,0,0-.848-.768H13.609a.853.853,0,0,0-.848.768,2.816,2.816,0,0,1-.218.805ZM13.4,19.528V12.262a.853.853,0,0,0-1.706,0v7.269a.853.853,0,0,0,1.706,0Zm4.107,0V12.262a.853.853,0,0,0-1.706,0v7.269a.853.853,0,0,0,1.706,0Z" transform="translate(-6.478 -3.322)" fill="currentColor" />
  </svg>
);
export const DeleteIcon = (props) => (
  <Icon component={DeleteSvg} {...props} />
);
