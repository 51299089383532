/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import {
  Modal, Button, Switch, Table, Tooltip, notification
} from 'antd';
import moment from 'moment';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import TimesheetModalGraph from './TimesheetModalGraph';
import { prepareUserWiseTimesheetDataForExcel, writeUserTimesheetWorkSheet } from '../utils/utility';

const TimesheetModal = (props) => {
  const {
    modalVisible, hideModal, modalTimesheetData, startDate, endDate, userName, selectedProjectDetail
  } = props;

  const [tablehtml, setTablehtml] = useState('');
  const [showGraph, setShowGraph] = useState(false);
  const [newData, setNewData] = useState(false);
  const [graph, setGraph] = useState([]);

  const detailedTimesheet = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center'
    },
    {
      title: 'Hours Worked',
      dataIndex: 'hoursWorked',
      key: 'hoursWorked',
      align: 'center'
    }
  ];

  const tableData = () => {
    let tableval = null;
    if (!showGraph) {
      tableval = (
        <Table
          pagination={false}
          columns={detailedTimesheet}
          dataSource={newData}
          className="mh-100"
        />
      );
    } else {
      tableval = (
        <TimesheetModalGraph timesheetData={graph} userName={userName} />
      );
    }
    setTablehtml(tableval);
  };

  useEffect(() => {
    if (newData !== []) {
      tableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);

  useEffect(() => {
    if (showGraph) {
      tableData(showGraph);
    } else {
      tableData(showGraph);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showGraph]);

  const getDates = (startDt, endDt) => {
    const date = new Date(startDt.getTime());

    const dates = [];

    while (date <= endDt) {
      dates.push(moment(date).format('DD-MMM-YY'));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  };

  useEffect(() => {
    const datesArray = getDates(new Date(startDate), new Date(endDate));
    const data = [];
    const graphData = [];
    const date = [];
    datesArray.forEach((dtArr) => {
      modalTimesheetData.forEach((dt) => {
        if (dtArr === dt.date) {
          if (!date.includes(dtArr)) {
            date.push(dtArr);
          }
          data.push({ date: dtArr, name: dt.name, hoursWorked: dt.hrs_spent !== '' ? dt.hrs_spent.toFixed(2) : '-' });
          if (dt.hrs_spent !== '') {
            graphData.push({ date: dtArr, hoursWorked: dt.hrs_spent.toFixed(2), value: 'Hours Worked' });
          }
        }
      });
    });
    graphData.sort((a, b) => ((a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0)));
    setGraph(graphData);
    datesArray.forEach((arr) => {
      if (!date.includes(arr)) {
        date.push(arr);
        data.push({ date: arr, name: userName, hoursWorked: '-' });
      }
    });
    data.sort((a, b) => new Date(a.date) - new Date(b.date));
    setNewData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalTimesheetData]);

  const exportToUserWiseTimesheetExcel = async () => {
    try {
      const { cols } = prepareUserWiseTimesheetDataForExcel();
      const workbook = new Excel.Workbook();
      const startDt = moment(startDate).format('DD-MMM-YYYY');
      const endDt = moment(endDate).format('DD-MMM-YYYY');
      const workSheetName = `${startDt} to ${endDt}`;
      const fileName = `${userName}_Timesheet_${startDt} to ${endDt}`;
      let worksheet = workbook.addWorksheet(workSheetName);
      worksheet.columns = cols;
      worksheet = writeUserTimesheetWorkSheet(worksheet, newData,
        selectedProjectDetail.projectName,
        selectedProjectDetail.projectNo, selectedProjectDetail.leadTech,
        selectedProjectDetail.contractor);
      // Loop through all table's row
      for (let i = 5; i <= worksheet.lastRow.number; i++) {
        const row = worksheet.getRow(i);

        // Now loop through every row's cell and finally set alignment
        row.eachCell({ includeEmpty: true }, ((cell) => {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }));
      }
      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  return (
    <Modal
      title={(
        <div style={{ display: 'flex' }}>
          <div style={{ 'max-width': '100%' }}>Timesheet</div>
          <>
            <Switch
              className="timesheet-modal-switch"
              checkedChildren="Graphical View"
              unCheckedChildren="Tabular View"
              checked={showGraph}
              onChange={(checked) => {
                if (checked) {
                  setShowGraph(checked);
                } else {
                  setShowGraph(checked);
                }
              }}
            />
            <Button
              size="small"
              shape="round"
              type="primary"
              className="export-excel"
              onClick={exportToUserWiseTimesheetExcel}
            >
              <Tooltip title="Download excel report" placement="topRight">
                <span className="icon-16 mr-2">
                  <svg>
                    <use xlinkHref="#export" />
                  </svg>
                </span>
              </Tooltip>

            </Button>
          </>
        </div>
        )}
      className="welds-modal"
      centered
      visible={modalVisible}
      width={1000}
      footer={[
        <Button key="submit" type="primary" onClick={() => { hideModal(); setShowGraph(false); }}>
          OK
        </Button>
      ]}
    >
      <div>
        <div className="ant-table scroll-table">
          <div className="ant-table-container h-100">
            <div className="ant-table-content h-100">
              {tablehtml}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TimesheetModal;
