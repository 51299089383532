import React, { useState } from 'react';
import {
  Typography, Spin
} from 'antd';

import SafetySummaryToday from './SafetySummaryToday';
import SafetySummaryOverall from './SafetySummaryOverall';

const { Title } = Typography;

const Summary = ({
  projNo, reload, projectType
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const isOffshore = projectType === 'offshore';

  if (isOffshore) {
    return (
      <div className="fsa-summary h-100">
        <Spin spinning={isLoading}>

          <div className="d-flex h-100 fsa-summary">

            <div className="flex-fill custom-fill overflow-auto">
              <div className="d-flex flex-column h-100">
                <div className="flex-auto">
                  <div className="d-flex mb-3 align-items-center">
                    <Title className="mb-0" level={4}>
                      Today
                    </Title>
                  </div>

                  <div className="custom-line" />
                </div>

                <div className="flex-fill border-right overflow-auto px-2">

                  <SafetySummaryToday
                    projNo={projNo}
                    reload={reload}
                    setIsLoading={(state) => setIsLoading(state)}
                  />

                </div>
              </div>
            </div>
            <div className="flex-fill custom-fill overflow-auto">
              <div className="d-flex flex-column h-100">
                <div
                  className="flex-auto w-100"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Title className="mb-3 ps-4" level={4}>
                    Overall
                  </Title>

                </div>
                <div className="custom-line" />
                <div className="flex-fill overflow-auto px-2">
                  <SafetySummaryOverall projNo={projNo} reload={reload} />
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }

  return <></>;
};
export default Summary;
