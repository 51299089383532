/* eslint-disable array-callback-return */
/* eslint-disable linebreak-style */
import get from 'lodash/get';
import {
  FETCH_AWS_CONFIG_DATA,
  SET_AWS_CONFIG_DATA,
  SET_AWS_CONFIG_DATA_FAILURE
} from '../types';

const initialState = {
  isawsConfigDataLoading: false,
};

const awsConfigDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AWS_CONFIG_DATA: {
      const awsConfig = get(action, ['response'], []);
      const config = awsConfig.data;
      const data = [];
      for (let i = 0; i < config.length; i += 1) {
        data[i] = {
          key: i,
          username: awsConfig?.data[i]?.username,
          accessKeyId: awsConfig?.data[i]?.access_key_id,
          secretAccessKey: awsConfig?.data[i]?.secret_access_key
        };
      }
      return {
        ...state,
        data,
        isLoading: false,
      };
    }

    case FETCH_AWS_CONFIG_DATA: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_AWS_CONFIG_DATA_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default awsConfigDataReducer;
