/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Button, DatePicker, Table, Tooltip, Form, Modal, notification, Spin,
  Typography
} from 'antd';
import {
  CheckOutlined,
  DeleteOutlined,
  EyeOutlined,
  HistoryOutlined
} from '@ant-design/icons';
import {
  func, arrayOf, objectOf, oneOfType, string, number, bool,
  array,
} from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import AddMachine from './AddMachine';
import AttachmentsViewer from './AttachmentsViewer';
import AdvCommentsViewer from './AdvCommentsViewer';
import { getAdvisory } from '../state/action/advisoryAction';
import { removeAdvisory, resendNotification, updateAdvisory } from '../services/advisory';
import { NewMessageIcon } from './svgs/newMessageIcon';
import { ADV_DATE_TABLE, ADV_DATE, advApprovalStatus, advStatus } from '../utils/constants';
import { capitalizeFirstLetter } from '../utils/utility';
import ApprovalHistory from './ApprovalHistory'

const { RangePicker } = DatePicker;
const itemsPerPage = 10;
const defaultPage = 0;
const { Title } = Typography;

function Advisory(props) {
  const {
    goToSubType, onGetAdvisory, data, subTypeId, showLoader, total
  } = props;

  const [pageNumber, setPageNumber] = useState(defaultPage);
  const [isModalLoading, setModalIsLoading] = useState(false);
  const [isAttachmentModalVisible, setisAttachmentModalVisible] = useState(false);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [attachmentsData, setAttachmentsData] = useState([]);
  const [commentsData, setCommentData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [advisoryId, setAdvisoryId] = useState();
  const [dates, setDates] = useState([]);
  const [isAdvDisabled, setisAdvDisabled] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [advisoryData, setAdvisoryData] = useState({});
  const [isAdvViewMode, setisAdvViewMode] = useState(false);

  const [form] = Form.useForm();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const machineType = queryParams.get('type');
  const machineSubType = queryParams.get('subType');

  useEffect(() => {
    onGetAdvisory(subTypeId, pageNumber, itemsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showAttachmentModal = (attachmentData) => {
    setAttachmentsData(attachmentData);
    setisAttachmentModalVisible(true);
  };

  const showCommentModal = (attachmentData) => {
    setCommentData(attachmentData);
    setIsCommentModalVisible(true);
  };

  const handleDeletion = (record) => {
    const date = moment(new Date()).format('YYYY-MM-DD');
    Modal.confirm({
      title: 'Are you sure you want to remove this Advisory?',
      className: 'custom-text-clr',
      async onOk() {
        return removeAdvisory(record.id, date).then((res) => {
          if (res) {
            onGetAdvisory(subTypeId);
          } else {
            notification.error({
              message: 'Something went wrong.',
            });
          }
          return true;
        }).catch(() => notification.error({
          message: 'Something went wrong.',
        }));
      },
      onCancel() { },
    });
  };

  const sendNotification = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to send a notification to all users?',
      className: 'custom-text-clr',
      async onOk() {
        return resendNotification(record.id).then((res) => {
          if (!res) {
            notification.error({
              message: 'Something went wrong.',
            });
          }
          return true;
        }).catch(() => notification.error({
          message: 'Something went wrong.',
        }));
      },
      onCancel() { },
    });
  };

  const onEditAdvisory = (record, disabled, status) => {
    setAdvisoryId(record.id);
    form.setFieldsValue({
      ...record,
      type: record.typeId,
      date: moment(record.dateOriginal),
      priority: Number(record.priority),
      businessUnit: record.businessUnit
    });
    setIsVisible(true);
    setisAdvDisabled(!!disabled)
    setisAdvViewMode(status === advStatus.closed)
  };

  const historyHandler = (record) => {
    setAdvisoryId(record.id);
    setAdvisoryData(record.code)
    setShowHistoryModal(true)
  }

  const columns = [
    {
      title: 'Advisory ID',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'Business Unit',
      dataIndex: 'businessUnit',
      key: 'businessUnit',
    },
    {
      title: 'Effective Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
    },
    {
      title: 'Approval Status',
      dataIndex: 'approvalStatusData',
      key: 'approvalStatusData',
    },
    {
      title: 'User Comments',
      key: 'userComment',
      dataIndex: 'userComment',
      render: (record, tableData) => {
        if (!record || record.length === 0 || Object.keys(record).length === 0) return '-';
        return (
          <span onClick={(event) => {
            event.stopPropagation();
            showCommentModal(record)
          }}>
            <EyeOutlined
              className="edit-project-detail"

            />
            <span className="mx-1">
              {record.length}
            </span>
          </span>
        );
      },
      align: 'center'
    },
    {
      title: 'Attachment',
      key: 'attachments',
      dataIndex: 'attachments',
      render: (record) => {
        if (!record || record.length === 0) return '-';
        if (record.length === 1) {
          return (
            <div>
              {' '}
              <a
                className="export-link"
                title="Download attachments"
                target="_blank"
                rel="noopener noreferrer"
                href={record[0].url ? record[0].url : ''}
                // eslint-disable-next-line react/jsx-closing-bracket-location
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span className="icon-16 mr-2">
                  <svg>
                    <use xlinkHref="#export" />
                  </svg>
                </span>
              </a>
            </div>
          );
        }
        return (
          <Button className="b-0 export-link border-0 px-0" shape="round" size="small" onClick={(event) => {
            event.stopPropagation();
            showAttachmentModal(record)
          }}>
            <span className="icon-16 mr-2">
              <svg>
                <use xlinkHref="#export" />
              </svg>
            </span>
            {' '}
            <span className="mx-1">
              {record.length}
            </span>
          </Button>
        );
      }
    },
    {
      title: '',
      render: (record, tableData) => {
        let iconStyles = {
          marginRight: '30px',
          fontSize: '16px'
        };
        if (tableData.status === advStatus.closed || tableData.approvalStatus === advApprovalStatus.pending) {
          iconStyles = {
            ...iconStyles,
            pointerEvents: 'none',
            color: 'gray',
            cursor: 'not-allowed'
          };
        }
        if (record) {
          return (
            <div>
              <Tooltip
                arrowPointAtCenter
                placement="topLeft"
                title="Approval History"
              >
                <HistoryOutlined
                  style={{ marginRight: '30px', fontSize: '16px' }}
                  className="edit-project-detail"
                  onClick={(event) => {
                    event.stopPropagation()
                    historyHandler(record)
                  }}
                />
              </Tooltip>
              <Tooltip
                arrowPointAtCenter
                placement="topRight"
                title="Send Notification"
              >
                <NewMessageIcon
                  onClick={(event) => {
                    if (tableData.status !== advStatus.closed && tableData.approvalStatus !== advApprovalStatus.pending) {
                      event.stopPropagation()
                      sendNotification(record);
                    }
                  }}
                  style={iconStyles}
                />
              </Tooltip>
              <Tooltip
                arrowPointAtCenter
                placement="topRight"
                title="Delete Advisory"
              >
                <DeleteOutlined
                  style={{ marginRight: '30px', fontSize: '16px' }}
                  className="edit-project-detail"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDeletion(record)
                  }}
                />

              </Tooltip>
            </div>
          );
        }
        return <div>-</div>;
      },
      align: 'right',
    },
  ];

  const checkStatus = (status, approvalStatus) =>{
    if(status === advStatus.closed) return 'Rejected'
    else return approvalStatus ? capitalizeFirstLetter(approvalStatus) : 'Approved'
  }

  const tableData = data?.map((item) => ({
    key: item.id,
    id: item.id,
    type: item.name,
    description: item.description,
    title: item.title,
    date: moment(item.date).format(ADV_DATE_TABLE),
    attachments: item.attachments,
    parent: item.parent_id,
    createdBy: item.createdBy,
    priority: Number(item.priority),
    dateOriginal: item.date,
    typeId: item.type,
    businessUnit: item.businessUnit,
    code: item.code,
    userComment: item.userComment,
    approvalMode: item.approvalMode,
    approvalStatus: item.approvalStatus,
    approvalStatusData: checkStatus(item?.advCurrentStatus, item?.approvalStatus),
    approverId: item.approverId,
    created_by: capitalizeFirstLetter(item.createdBy),
    status: item.advCurrentStatus
  }));

  const onSave = async (values) => {
    setModalIsLoading(true);
    const {
      title, description, type, date, attachments, priority, createdBy, businessUnit, approverId
    } = values;
    const formData = {
      title,
      description,
      type,
      date: moment(date).format(ADV_DATE),
      attachments,
      createdBy,
      priority,
      businessUnit,
      approverId
    };
    const res = await updateAdvisory(formData, advisoryId);
    onGetAdvisory(subTypeId);
    setModalIsLoading(false);
    if (!res.err) {
      form.resetFields();
      setIsVisible(false);
    } else {
      notification.error({
        message: res.message || 'Something went wrong.',
      });
    }
  };

  const onPaginationChange = (page) => {
    setPageNumber(page - 1);

    const startDate = dates.length ? dates[0] : '';
    const endDate = dates.length ? dates[1] : '';

    onGetAdvisory(subTypeId, page - 1, itemsPerPage, startDate, endDate);
  };

  const onCalendarChange = (val, dateString) => {
    setDates(dateString);
    if (!val) {
      setPageNumber(defaultPage);
      onGetAdvisory(subTypeId, defaultPage, itemsPerPage);
    }
  };

  const filterByDate = () => {
    const startDate = dates[0];
    const endDate = dates[1];
    setPageNumber(defaultPage);
    onGetAdvisory(subTypeId, defaultPage, itemsPerPage, startDate, endDate);
  };

  return (
    <Spin spinning={showLoader} className="h-100">
      <div className="d-flex h-100 flex-column">
        <div className="flex-auto d-flex pb-2 align-items-center justify-content-between">
          <Button
            className="text-right mb-2 back-advisory border-0"
            onClick={() => goToSubType()}
            key="submit"
            type="primary"
            shape="round"
          >
            Back

          </Button>
          <div className="breadcrumb">
            {/* <span className="cursor-pointer" onClick={goToType}>
              {machineType}
              &gt;
            </span> */}
            <Title className="mb-3 ps-4 breadcrumb" level={4}>
              {machineSubType}
            </Title>
            {/* <span className="cursor-pointer" onClick={() => goToSubType()}>
              {machineSubType}
            </span> */}
          </div>
          <div className="lem-date-filter">
            <RangePicker
              showToday
              separator="to"
              format="YYYY-MM-DD"
              placeholder={['From Date', 'To Date']}
              defaultValue={dates}
              onCalendarChange={onCalendarChange}
            />
            <Button
              type="primary"
              onClick={filterByDate}
              disabled={!(dates && dates.length)}
            >
              <CheckOutlined />
              {' '}
            </Button>
            {' '}

          </div>
        </div>
        <div className="flex-fill fsa-advisory-table">
          <Table
            columns={columns}
            dataSource={tableData}
            rowClassName='cursor-pointer'
            pagination={{
              defaultPageSize: itemsPerPage,
              showSizeChanger: false,
              pageSizeOptions: ['10'],
              onChange: onPaginationChange,
              total,
              current: pageNumber + 1,
              showLessItems: true,
              hideOnSinglePage: true
            }}
            onRow={(record) => {
              return {
                onClick: () => { onEditAdvisory(record, record.approvalStatus === advApprovalStatus.pending, record.status); }
              };
            }}
          />
        </div>
      </div>
      <AddMachine
        isLoading={isModalLoading || showLoader}
        isVisible={isVisible}
        onCancel={() => setIsVisible(false)}
        onOk={onSave}
        form={form}
        title={isAdvViewMode ? "View Advisory": "Update Advisory"}
        setIsLoading={setModalIsLoading}
        key="Update"
        disabled={isAdvDisabled}
        isApproval={isAdvViewMode}
      />
      <AttachmentsViewer
        isVisible={isAttachmentModalVisible}
        onOk={() => setisAttachmentModalVisible(false)}
        attachments={attachmentsData}
      />
      <AdvCommentsViewer
        isVisible={isCommentModalVisible}
        onOk={() => setIsCommentModalVisible(false)}
        comments={commentsData}
      />
      <ApprovalHistory
        isVisible={showHistoryModal}
        advisoryId={advisoryId}
        onOk={() => setShowHistoryModal(false)}
        title={advisoryData}
      />
    </Spin>
  );
}

Advisory.propTypes = {
  onGetAdvisory: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showLoader: bool.isRequired,
};

const mapStateToProps = ({ advisoryData }) => {
  const data = get(advisoryData, 'advisory', []);
  const showLoader = get(advisoryData, 'isAdvLoading', false);
  const total = get(advisoryData, 'total', 0);
  return {
    data,
    showLoader,
    total
  };
};

const mapDispatchToProps = {
  onGetAdvisory: getAdvisory
};

export default connect(mapStateToProps, mapDispatchToProps)(Advisory);
