/* eslint-disable arrow-body-style */
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { getPartsReports } from '../../state/action/partsAction';
import 'antd/dist/antd.dark.css';
import ComingSoon from '../../Components/Comingsoon';

const PartsRequest = () => {
  return (
    <ComingSoon />
  );
};

PartsRequest.propTypes = {
};

const mapStateToProps = ({ partRequests }) => {
  const data = get(partRequests, 'data', []);
  const showLoader = get(partRequests, 'isLoading', false);
  return {
    data,
    showLoader,
  };
};

const mapDispatchToProps = {
  onGetPartsReports: getPartsReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartsRequest);
