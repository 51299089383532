import { HomeLayout } from '../layout';
import LogReport from '../pages/log-report';
import CoatingLogReport from '../pages/coating-log-report';
import PartsRequest from '../pages/parts-request';
import UsersActivity from '../pages/users-activity';
import UploadMaterial from '../pages/upload-material';
import Home from '../pages/home-page';
import Notifications from '../pages/notifications';
import AdminPage from '../pages/admin';
import Analysis from '../pages/analysis';

export default [
  {
    path: '/',
    exact: true,
    layout: HomeLayout,
    component: Home,
  },
  {
    path: '/main',
    layout: HomeLayout,
    component: Home,
  },
  {
    path: '/log-report',
    layout: HomeLayout,
    component: LogReport,
  },
  {
    path: '/coating-log-report',
    layout: HomeLayout,
    component: CoatingLogReport,
  },
  {
    path: '/parts-request',
    layout: HomeLayout,
    component: PartsRequest,
  },
  {
    path: '/notifications',
    layout: HomeLayout,
    component: Notifications,
  },
  {
    path: '/users-activity',
    layout: HomeLayout,
    component: UsersActivity,
  },
  {
    path: '/upload-material',
    layout: HomeLayout,
    component: UploadMaterial,
  },
  {
    path: '/admin',
    layout: HomeLayout,
    component: AdminPage,
  },
  {
    path: '/analysis',
    layout: HomeLayout,
    component: Analysis,
  }
];
