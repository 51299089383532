/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import Production from '../Components/Production';
import DPREMEA from '../Components/DPREMEA';
import Technician from '../Components/Technician';
import QualityReports from '../Components/QualityReports';
import DamageReport from '../Components/DamageReport';
import PartsOrdering from '../Components/PartsOrdering';
import WireUsage from '../Components/WireUsage';
import TeamMembers from '../Components/TeamMembers';
import Pipelines from '../Components/Pipelines';
import Assets from '../Components/Assets';
import ComingSoon from '../Components/Comingsoon';
import LemReport from '../Components/LemReport';
import Timesheet from '../Components/Timesheet';
import Inventory from '../Components/Inventory';
import Summary from '../Components/Summary';
import DprReport from '../Components/DprReport';
import ReportingEmailPage from '../Components/ReportingEmails';
import TimesheetOffshore from '../Components/TimesheetOffshore';
import TimesheetEMEA from '../Components/TimesheetEMEA';
import { projectType as projectTypeConstant } from '../utils/constants';
import Document from '../Components/Document';

const { TabPane } = Tabs;
const { offshore, onshore, onshoreEmea } = projectTypeConstant;

const TabList = (props) => {
  const {
    projectList, projectData, projNo, setCompletedWeldsCount, techLogDetails,
    qualityData, reload, setCurProdRep, setPrevProdRep,
    curProdReport, prevProdReport, refresh, setCurTechRep, setPrevTechRep,
    curTechReport, prevTechReport, setDamageRep, damageReport, setQualRep, qualReport,
    setPartsOrderRep, partsOrderReport, setWireUsageRep, wireUsageReport, tab, setTab,
    setTeamMembers, teamMembers, setLemRep, lemReport, setPipeline, pipelines,
    setAssets, assets, setTimesheet, timesheet, selectedProjectDetail, setInventories, inventories,
    dates, setDates, setReloadDates, reloadDates
  } = props;

  const [activeTab, setActiveTab] = useState('1');
  const [projectStatus, setProjectStatus] = useState('');
  const [projectContractor, setProjectContractor] = useState('');
  const [projectManagerEmail, setProjectManagerEmail] = useState('');
  const [projectDocSignRequired, setProjectDocSignRequired] = useState('');
  const [projectType, setProjectType] = useState('');
  const onShoreProjects = [onshore, onshoreEmea];

  const changeTab = (value) => {
    refresh(false);
    setActiveTab(value);
    setTab(value);
    setDates([]);
  };

  useEffect(() => {
    refresh(false);
    if (!reload) {
      if (projectList.length > 0) {
        projectList.map((data) => {
          if (data.projectNo === projNo) {
            setProjectStatus(data.projectStatus);
            setProjectContractor(data.contractor);
            setProjectManagerEmail(data.contractorEmail);
            setProjectDocSignRequired(data.docusignRequired);
            setProjectType(data.projectType);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo, reload]);

  useEffect(() => {
    refresh(false);
    if (!reload) {
      if (projectList.length > 0) {
        projectList.map((data) => {
          if (data.projectNo === projNo) {
            setProjectDocSignRequired(data.docusignRequired);
            setProjectType(data.projectType);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectList]);

  useEffect(() => {
    if (reload) {
      setActiveTab(tab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const showTabs = () => (
    <Tabs className="custom-tab h-100" activeKey={activeTab} onChange={changeTab}>
      {onShoreProjects.includes(projectType)
        ? (
          projectType === onshoreEmea
            ? (
              <TabPane tab="Production" key="1">
                <DPREMEA
                  projectData={projectData}
                  projNo={projNo}
                  setCompletedWeldsCount={setCompletedWeldsCount}
                  reload={reload}
                  setCurProdRep={setCurProdRep}
                  setPrevProdRep={setPrevProdRep}
                  curProdReport={curProdReport}
                  prevProdReport={prevProdReport}
                  refresh={refresh}
                  projectStatus={projectStatus}
                />
              </TabPane>
            ) : (
              <TabPane tab="Production" key="1">
                <Production
                  projectData={projectData}
                  projNo={projNo}
                  setCompletedWeldsCount={setCompletedWeldsCount}
                  reload={reload}
                  setCurProdRep={setCurProdRep}
                  setPrevProdRep={setPrevProdRep}
                  curProdReport={curProdReport}
                  prevProdReport={prevProdReport}
                  refresh={refresh}
                  projectStatus={projectStatus}
                />
              </TabPane>
            )
        )
        : (
          <TabPane tab="Summary" key="1">
            <Summary
              projectData={projectData}
              projNo={projNo}
              setCompletedWeldsCount={setCompletedWeldsCount}
              reload={reload}
              setCurProdRep={setCurProdRep}
              setPrevProdRep={setPrevProdRep}
              curProdReport={curProdReport}
              prevProdReport={prevProdReport}
              refresh={refresh}
              projectStatus={projectStatus}
              projectType={projectType}
            />
          </TabPane>
        )}
      {projectType === offshore
        && (
          <TabPane tab="Pipeline Sections" key="16">
            <Pipelines
              projNo={projNo}
              reload={reload}
              refresh={refresh}
              setPipeline={setPipeline}
              pipelines={pipelines}
              projectStatus={projectStatus}
            />
          </TabPane>
        )}
      {projectType === offshore
        && (
          <TabPane tab="DPR Report" key="15">
            <DprReport
              projNo={projNo}
              projectType={projectType}
              reload={reload}
            />
          </TabPane>
        )}
      {onShoreProjects.includes(projectType)
        && (
          <TabPane tab="Technician" key="2">
            <Technician
              projNo={projNo}
              techLogDetails={techLogDetails}
              reload={reload}
              setCurTechRep={setCurTechRep}
              setPrevTechRep={setPrevTechRep}
              curTechReport={curTechReport}
              prevTechReport={prevTechReport}
              refresh={refresh}
              projectStatus={projectStatus}
              projectType={projectType}
            />
          </TabPane>
        )}
      {
        onShoreProjects.includes(projectType) && (
          <TabPane tab="Quality Reports" key="3">
            <QualityReports
              qualityData={qualityData}
              projNo={projNo}
              reload={reload}
              setQualRep={setQualRep}
              qualReport={qualReport}
              refresh={refresh}
              projectStatus={projectStatus}
            />
          </TabPane>
        )
      }

      {onShoreProjects.includes(projectType)
        && (
          <TabPane tab="Damage Report" key="4">
            <DamageReport
              projNo={projNo}
              reload={reload}
              setDamageRep={setDamageRep}
              damageReport={damageReport}
              refresh={refresh}
              projectStatus={projectStatus}
            />
          </TabPane>
        )}
      {onShoreProjects.includes(projectType)
        && (
          <TabPane tab="Parts Ordering" key="5">
            <PartsOrdering
              projNo={projNo}
              reload={reload}
              setPartsOrderRep={setPartsOrderRep}
              partsOrderReport={partsOrderReport}
              refresh={refresh}
              projectStatus={projectStatus}
            />
          </TabPane>
        )}
      {onShoreProjects.includes(projectType)
        && (
          <TabPane tab="Wire Usage" key="6">
            <WireUsage
              projNo={projNo}
              reload={reload}
              setWireUsageRep={setWireUsageRep}
              wireUsageReport={wireUsageReport}
              refresh={refresh}
              projectStatus={projectStatus}
            />
          </TabPane>
        )}
      {projectType === onshore
        && (
          <TabPane tab="LEM" key="7">
            <LemReport
              projNo={projNo}
              projectContractor={projectContractor}
              projectManagerEmail={projectManagerEmail}
              projectDocSignRequired={projectDocSignRequired}
              reload={reload}
              setLemRep={setLemRep}
              lemReport={lemReport}
              setReloadDates={setReloadDates}
              reloadDates={reloadDates}
              refresh={refresh}
              projectStatus={projectStatus}
              dates={dates}
              setDates={setDates}
            />
          </TabPane>
        )}
      {projectType === offshore && (
      <TabPane tab="Timesheet" key="7">
        <TimesheetOffshore
          projNo={projNo}
          reload={reload}
          setTimesheet={setTimesheet}
          timesheet={timesheet}
          refresh={refresh}
          projectStatus={projectStatus}
          selectedProjectDetail={selectedProjectDetail}
          dates={dates}
          setDates={setDates}
        />
      </TabPane>
      )}
      <TabPane tab="Team Members" key="8">
        <TeamMembers
          projNo={projNo}
          reload={reload}
          refresh={refresh}
          setTeamMembers={setTeamMembers}
          teamMembers={teamMembers}
          projectStatus={projectStatus}
          projectType={projectType}
        />
      </TabPane>
      <TabPane tab="Assets" key="9">
        <Assets
          projNo={projNo}
          reload={reload}
          refresh={refresh}
          setAssets={setAssets}
          assets={assets}
          projectStatus={projectStatus}
          projectType={projectType}
        />
      </TabPane>
      <TabPane tab="Inventory" key="10">
        <Inventory
          projNo={projNo}
          reload={reload}
          refresh={refresh}
          setInventories={setInventories}
          inventories={inventories}
          projectStatus={projectStatus}
          projectType={projectType}
        />
      </TabPane>
      {(projectType === onshoreEmea || projectType === offshore)
        && (
          <TabPane tab="Reporting Email" key="14">
            <ReportingEmailPage
              projectNumber={projNo}
              isOnshoreEmea={projectType === onshoreEmea}
              isOffshore={projectType === offshore}
            />
          </TabPane>
        )}
      {projectType === onshore
        ? (
          <TabPane tab="Timesheet" key="11">
            <Timesheet
              projNo={projNo}
              reload={reload}
              setTimesheet={setTimesheet}
              timesheet={timesheet}
              refresh={refresh}
              projectStatus={projectStatus}
              selectedProjectDetail={selectedProjectDetail}
              dates={dates}
              setDates={setDates}
            />
          </TabPane>
        )
        : projectType === onshoreEmea ? (
          <TabPane tab="Timesheet" key="11">
            <TimesheetEMEA
              projNo={projNo}
              reload={reload}
              setTimesheet={setTimesheet}
              timesheet={timesheet}
              refresh={refresh}
              projectStatus={projectStatus}
              selectedProjectDetail={selectedProjectDetail}
              dates={dates}
              setDates={setDates}
              projectType={projectType}
            />
          </TabPane>
        )
          : (
            <TabPane tab="Down Time" key="11">
              <ComingSoon />
            </TabPane>
          )}
      <TabPane tab="Documents" key="17">
        <Document projNo={projNo} />
      </TabPane>
      {projectType === offshore
        && (
          <TabPane tab="Operational" key="13">
            <ComingSoon />
          </TabPane>
        )}
      <TabPane tab="Data Logs" key="12">
        <ComingSoon />
      </TabPane>
    </Tabs>
  );

  return (
    <>
      {
        showTabs()
      }
    </>
  );
};

export default TabList;
