/* eslint-disable react/no-unknown-property */
/* eslint-disable no-constant-condition */
/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
/* eslint-disable no-trailing-spaces */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import {
  Modal, Form, Input, Row, Col, notification, Button, Tooltip, Tree,
} from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import * as utility from '../utils/utility';
import * as constants from '../utils/constants';

const treeData = [
  {
    title: 'Admin',
    key: 'AdminSection',
    children: [
      {
        title: 'Web Page',
        key: 'crc-admin',
      },
      {
        title: 'Configuration',
        key: 'admin',
      },
    ],
  },
  {
    title: 'Field Service',
    key: 'fsa',
    children: [
      {
        title: 'CRCA Technician',
        key: 'crc-a',
      },
      {
        title: 'Inspection Technician',
        key: 'inspection',
      },
      {
        title: 'Documentation',
        key: 'technician',
      },
      {
        title: 'Sales',
        key: 'sales',
      },
    ],
  },
  {
    title: 'PipeFitter',
    key: 'pipeFitter',
    children: [
      {
        title: 'Admin',
        key: 'pipefitter-admin',
      },
      {
        title: 'Customer',
        key: 'pipefitter-customer',
      },
      {
        title: 'Project Manager',
        key: 'pipefitter-project-manager',
      },
      {
        title: 'Technician',
        key: 'pipefitter-technician',
      },
    ],
  },
  {
    title: 'Weld Configurator',
    key: 'weldConfig',
    children: [
      {
        title: 'CRC Engineers',
        key: 'weldconfigurator-crc-engineers',
      },
      {
        title: 'CRC Technicians',
        key: 'weldconfigurator-crc-technicians',
      },
      {
        title: 'CRC Weld Engineers',
        key: 'weldconfigurator-crc-weld-engineers',
      },
      {
        title: 'Customers',
        key: 'weldconfigurator-customers',
      },
      {
        title: 'Inspectors',
        key: 'weldconfigurator-inspectors',
      },
      {
        title: 'Welders',
        key: 'weldconfigurator-welders',
      },
    ],
  },
  {
    title: 'SmartLync',
    key: 'SmartLync',
    children: [
      {
        title: 'Calibration Manager',
        key: 'calibration-manager',
      },
      {
        title: 'License Manager',
        key: 'license-manager',
      },
      {
        title: 'Spare parts generator',
        key: 'Spare-parts-generator',
        children: [
          {
            title: 'Admin',
            key: 'spare-parts-admin',
          },
          {
            title: 'Normal User',
            key: 'spare-parts-normal-user',
          },
        ]
      },
    ],
  },
];

const AddUserModal = (props) => {
  const {
    userModalVisible, setUserModalVisible, onSave, userForm,
    setUserModalLoading, disableSendEmail, OnSendEmail, setDisableSendEmail,
    createUserLoader, setCreateUserLoader, setIsEdit, isEdit, OnUpdateUserAccess,
    userAccessData, setUserAccessData
  } = props;

  const [sendEmailLoader, setSendEmailLoader] = useState(false);
  const [newPwdFocused, setNewPwdFocused] = React.useState(false);
  const [confirmPwdFocused, setConfirmPwdFocused] = React.useState(false);
  const [showToolTip, setShowToolTip] = useState(true);
  const searchInput = React.useRef(null);

  const handlePassword = (e) => {
    const password = e.target.value;
    const regexPattern = /^(?=.*[-\#\$\.\%\&\@\!\+\=\\*])(?=.*[a-zA-Z])(?=.*\d).{8,12}$/;
    if (password.match(regexPattern)) {
      setShowToolTip(false);
    } else { setShowToolTip(true); }
  };

  const handleCancel = () => {
    setIsEdit(false);
    setUserModalVisible(false);
    setDisableSendEmail(false);
    userForm.resetFields();
    setUserAccessData([]);
  };

  const changePass = (data) => {
    try {
      if (data.newPassword === data.confirmPassword) {
        setCreateUserLoader(true);
        if (data.lastName) {
          data.fullName = `${data.firstName} ${data.lastName}`;
        } else {
          data.fullName = `${data.firstName}`;
        }
        onSave(data.email.toLowerCase(), data.fullName, data.newPassword, data.access);
      } else {
        notification.warning({
          message: 'Passwords do not match',
        });
      }
    } catch (err) {
      notification.warning({
        message: 'Something went wrong.',
      });
    }
  };

  const validatePass = () => {
    userForm
      .validateFields()
      .then(async (data) => {
        setUserModalLoading(true);
        await changePass(data);
      });
  };

  const sendEmail = () => {
    setSendEmailLoader(true);
    userForm
      .validateFields()
      .then(async (data) => {
        if (data.lastName) {
          data.fullName = `${data.firstName} ${data.lastName}`;
        } else {
          data.fullName = `${data.firstName}`;
        }
        await OnSendEmail(data.email.toLowerCase(), data.fullName, data.newPassword);
      });
  };

  const updateUserAccess = () => {
    userForm
      .validateFields()
      .then(async (data) => {
        setCreateUserLoader(true);
        setUserModalLoading(true);
        if (data.lastName) {
          data.fullName = `${data.firstName} ${data.lastName}`;
        } else {
          data.fullName = `${data.firstName}`;
        }
        await OnUpdateUserAccess(data.email.toLowerCase(), data.fullName, data.access);
      });
  };

  const handleTreeSelect = (selectedKeys) => {
    setUserAccessData(selectedKeys);
    userForm.setFieldsValue({ access: selectedKeys });
  };

  return (
    <>
      <Modal
        width={750}
        className="quality-modal"
        title={isEdit ? 'Edit User Access' : 'Create User'}
        centered
        visible={userModalVisible}
        footer={isEdit ? [
          <Button key="submit1" type="primary" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit2" loading={createUserLoader} type="primary" disabled={createUserLoader} onClick={() => updateUserAccess()}>
            Update
          </Button>
        ] : [
          <Button key="submit3" type="primary" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit4" loading={!disableSendEmail && createUserLoader} type={disableSendEmail ? 'default' : 'primary'} disabled={disableSendEmail} onClick={() => validatePass()}>
            Create
          </Button>,
          <Button key="submit5" loading={sendEmailLoader} type={!disableSendEmail ? 'default' : 'primary'} disabled={!disableSendEmail} onClick={() => sendEmail()}>
            Email login details
          </Button>
        ]}
      >
        <div className="signup-form">
          <Form
            id="create-user-form"
            name="basic"
            layout="vertical"
            autoComplete="off"
            form={userForm}
            initialValues={{
              remember: true,
            }}
            onFinish=""
          >
            <Row gutter={16}>
              <Col lg={12}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your First Name.',
                    },
                  ]}
                  normalize={(value) => utility.trimSpacesInValues(value)}
                >
                  <Input size="large" placeholder="Enter First Name" maxLength={25} disabled={disableSendEmail || isEdit} />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: false,
                      message: 'Please enter your Last Name.',
                    },
                  ]}
                  normalize={(value) => utility.trimSpacesInValues(value)}
                >

                  <Input size="large" placeholder="Enter Last Name" maxLength={25} disabled={disableSendEmail || isEdit} />
                </Form.Item>
              </Col>

            </Row>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Email.',
                },
                {
                  pattern: true
                    ? constants.EMAIL_PATTERN
                    : undefined,
                  message: 'Provide a valid Email.',
                }
              ]}
              normalize={(value) => utility.trimSpacesInValues(value)}
            >
              <Input size="large" placeholder="Enter Email" disabled={disableSendEmail || isEdit} />
            </Form.Item>
            {!isEdit && (
              <>
                <Tooltip
                  visible={newPwdFocused && showToolTip}
                  title={(
                    <>
                      <p align="left"><b>Requirements:</b></p>
                      <ul align="left">
                        <li align="left">8-character minimum length</li>
                        <li align="left">Contains at least 1 number</li>
                        <li align="left">Contains at least 1 lowercase letter</li>
                        <li align="left">Contains at least 1 uppercase letter</li>
                        <li align="left">Contains at least 1 special character from the following set, or a non-leading, non-trailing space character.</li>
                        <p align="left">{'^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < \' : ; | _ ~ ` + ='}</p>
                      </ul>

                    </>
                  )}
                  placement="topLeft"
                >
                  <Form.Item
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter New Password.',
                      },
                      {
                        pattern: true
                          ? constants.PASSWORD_VALIDATION
                          : undefined,
                        message: 'Password does not meet password policy rules',
                      }
                    ]}
                  >

                    <Input.Password
                      className="password-icon"
                      size="large"
                      placeholder="New Password"
                      iconRender={(visibility) => (visibility ? <EyeOutlined style={{ color: 'grey' }} /> : <EyeInvisibleOutlined style={{ color: 'grey' }} />)}
                      autocomplete="new-password"
                      disabled={disableSendEmail}
                      ref={searchInput}
                      onChange={(e) => handlePassword(e)}
                      onFocus={() => setNewPwdFocused(true)}
                      onBlur={() => setNewPwdFocused(false)}
                    />

                  </Form.Item>
                </Tooltip>
                <Tooltip
                  visible={confirmPwdFocused && showToolTip}
                  title={(
                    <>
                      <p align="left"><b>Requirements:</b></p>
                      <ul align="left">
                        <li align="left">8-character minimum length</li>
                        <li align="left">Contains at least 1 number</li>
                        <li align="left">Contains at least 1 lowercase letter</li>
                        <li align="left">Contains at least 1 uppercase letter</li>
                        <li align="left">Contains at least 1 special character from the following set, or a non-leading, non-trailing space character.</li>
                        <p align="left">{'^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < \' : ; | _ ~ ` + ='}</p>
                      </ul>

                    </>
                  )}
                  placement="topLeft"
                >
                  <Form.Item
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password.',
                      },
                      {
                        pattern: true
                          ? constants.PASSWORD_VALIDATION
                          : undefined,
                        message: 'Password does not meet password policy rules',
                      }
                    ]}
                  >
                    <Input.Password
                      size="large"
                      className="password-icon"
                      placeholder="Confirm New Password"
                      iconRender={(visibility) => (visibility ? <EyeOutlined style={{ color: 'grey' }} /> : <EyeInvisibleOutlined style={{ color: 'grey' }} />)}
                      autocomplete="new-password"
                      disabled={disableSendEmail}
                      onChange={(e) => handlePassword(e)}
                      onFocus={() => setConfirmPwdFocused(true)}
                      onBlur={() => setConfirmPwdFocused(false)}
                    />

                  </Form.Item>
                </Tooltip>
              </>
            )}

            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="mb-0"
              label="Access"
              name="access"
              rules={[
                {
                  required: true,
                  message: 'At least one Access is required.',
                },
              ]}
            >
              <Tree
                checkable
                treeData={treeData}
                onCheck={handleTreeSelect}
                defaultCheckedKeys={userAccessData}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddUserModal;
