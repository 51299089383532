import get from 'lodash/get';
import moment from 'moment';
import * as constants from '../../utils/constants';
import { GET_PARTS_REPORTS, SET_PARTS_REPORTS, SET_PARTS_REPORT_FAILURE } from '../types';

const initialState = {
  isLoading: false,
};

const partsRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARTS_REPORTS: {
      const pReports = get(action, ['response', 'data'], []);
      const pdata = [];
      for (let i = 0; i < pReports.length; i += 1) {
        let date;
        if (pReports[i].createdAt) {
          date = moment(pReports[i].createdAt).format(constants.DATETIMESEC);
        } else {
          date = null;
        }
        pdata[i] = {
          reportId: get(pReports[i], 'id', '-'),
          pdf: get(pReports[i], 'report_url', ''),
          author: get(pReports[i], 'author', '-'),
          createdAt: date,
          projectNo: get(pReports[i], 'project_number', '-'),
          part_number: get(pReports[i], 'part_number', '-'),
          quantity: get(pReports[i], 'quantity', '-')
        };
      }
      return {
        ...state,
        pdata,
        isLoading: false,
      };
    }

    case GET_PARTS_REPORTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_PARTS_REPORT_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default partsRequestReducer;
