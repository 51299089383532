import React, { useState } from 'react';
import {
  notification, Form, Upload, Table,
} from 'antd';

import { InboxOutlined } from '@ant-design/icons';
import { BASE_URL } from '../../config';
import { getJwtToken } from '../../services/auth';

import 'antd/dist/antd.dark.css';
import './material.scss';

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const UploadMaterial = () => {
  const [materialData, setMaterialData] = useState([]);

  const request = async (options) => {
    try {
      const authToken = await getJwtToken();
      const formData = new FormData();
      formData.append('File', options.file);
      const res = await fetch(`${BASE_URL}/excelUploads`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: authToken,
        },
      });

      const response = await res.json();
      if (res.status === 200) {
        setMaterialData(response, options.file);
        options.onSuccess(res);
        notification.success({
          message: 'Material Uploaded',
          description: 'File Upload Successfully',
        });
      } else {
        options.onError(response.error);
        notification.error({
          message: 'Upload unsuccessful',
          description: response.error,
        });
      }
    } catch (err) {
      options.onError(err);
      notification.error({
        message: 'Upload UnSuccessfull',
        description: err,
      });
    }
  };

  const columns = [
    { title: 'Material', dataIndex: 'part_number', key: 'part_number' },
    { title: 'Type', dataIndex: 'type', key: 'type' },
    { title: 'Unit', dataIndex: 'unit', key: 'unit' },
    { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
  ];

  return (
    <div>
      <Form>
        <Form.Item>
          <Form.Item
            name="dragger"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle
          >
            <Upload.Dragger
              name="files"
              customRequest={request}
              multiple={false}
              className="draggerWidth"
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">Support for a material excel file only.</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
      <Table
        pagination={{ hideOnSinglePage: true }}
        columns={columns}
        bordered
        dataSource={materialData}
      />
    </div>
  );
};

export default UploadMaterial;
