import {
  GET_PROD_REPORTS,
  SET_PROD_REPORTS,
  SET_PROD_REPORT_FAILURE,
} from '../types';

const getProdReports = (projectNo) => ({
  type: GET_PROD_REPORTS,
  projectNo
});

const setProdReports = (payload) => ({
  type: SET_PROD_REPORTS, ...payload,
});

const setProdReportFailure = () => ({
  type: SET_PROD_REPORT_FAILURE,
});

export {
  getProdReports,
  setProdReports,
  setProdReportFailure,
};
