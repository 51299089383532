import React, { useState, useEffect } from 'react';
import { Table, Spin } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  func, arrayOf, objectOf, oneOfType, string, number,
  array, bool
} from 'prop-types';
import ViewNotificationModal from './ViewNotificationModal';
import { EyeOpenIcon } from './svgs/eyeOpen';
import { getNotifications } from '../state/action/notification';

const Notifications = ({
  projNo, reload, refresh, onGetNotifications, notifications, data, setNotification, showLoader
}) => {
  const [notificationData, setNotificationData] = useState([]);
  const [messageData, setMessageData] = useState({});
  const [isNotificationModalVisible, setNotificationModalVisible] = useState(false);
  const [totalReports, setTotalReports] = useState(0);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    // refresh(false);
    if (projNo !== '') {
      onGetNotifications({ projectNo: projNo });
    } else {
      setNotificationData([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo]);

  useEffect(() => {
    if (reload) {
      if (projNo) {
        onGetNotifications({ projectNo: projNo });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    // refresh(false);
    if (!reload) {
      if (projNo !== '') {
        setTotalReports(data.length);
        setNotification(data);
        setNotificationData(data);
      } else {
        setNotification([]);
        setNotificationData([]);
      }
    } else {
      setNotificationData(notifications);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSave = () => {
  };

  const viewNotification = (item) => {
    setNotificationModalVisible(true);
    setMessageData(item);
  };

  const handleTableChnage = (pagination,
    filters,
    sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'left'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'left'
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      align: 'left'
    },
    {
      title: 'Sent From',
      dataIndex: 'from',
      key: 'from',
      align: 'left'
    },
    {
      title: '',
      render: (record) => {
        if (record && record.message) {
          return (
            <div>
              <EyeOpenIcon style={{ marginRight: '30px', fontSize: '16px' }} onClick={() => viewNotification(record)} />
            </div>
          );
        }
        return <div />;
      },
      align: 'right',
    },

  ];

  return (
    <>
      <div className="h-95 overflow-y-hidden overflow-x-auto">
        <Table
          pagination={totalReports > 10 ? tableParams.pagination : false}
          columns={columns}
          dataSource={notificationData}
          className="h-95"
          scroll={{ x: notificationData?.length > 0 ? 600 : 0, y: '100%' }}
          loading={{
            indicator: <Spin />,
            spinning: showLoader
          }}
          onChange={handleTableChnage}
        />
        {' '}
      </div>
      <ViewNotificationModal
        setIsModalVisible={setNotificationModalVisible}
        isModalVisible={isNotificationModalVisible}
        messageData={messageData}
        onSave={onSave}
      />
    </>
  );
};

Notifications.propTypes = {
  onGetNotifications: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showLoader: bool.isRequired,
};

const mapStateToProps = ({ notifications }) => {
  const data = get(notifications, 'data', []);
  const showLoader = get(notifications, 'isLoading', false);
  return {
    data,
    showLoader,
  };
};

const mapDispatchToProps = {
  onGetNotifications: getNotifications
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
