/* eslint-disable react/no-unknown-property */
/* eslint-disable no-constant-condition */
/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
/* eslint-disable no-trailing-spaces */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import {
  Modal, Select, Form, notification, Spin, Input, Tooltip
} from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { getUsers } from '../services/teamMembers';
import * as constants from '../utils/constants';

const { Option } = Select;
 
const ResetPasswordModal = (props) => {
  const {
    setTeamModalLoading, 
    teamModalLoading, resetPasswordModalVisible, setResetPasswordModalVisible, resetPasswordForm,
    resetPasswordModalLoading, setResetPasswordModalLoading, onResetPassword
  } = props;
  const [selectedValue, setSelectedValue] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [newPwdFocused, setNewPwdFocused] = React.useState(false);
  const [confirmPwdFocused, setConfirmPwdFocused] = React.useState(false);
const [showToolTip, setShowToolTip] = useState(true);
const handlePassword = (e) => {
   const password = e.target.value;
   const regexPattern = /^(?=.*[-\#\$\.\%\&\@\!\+\=\\*])(?=.*[a-zA-Z])(?=.*\d).{8,12}$/;
     if (password.match(regexPattern)) {
    setShowToolTip(false);
    } else { setShowToolTip(true); }
    };

  const handleCancel = () => {
    setSelectedValue([]);
    setResetPasswordModalVisible(false);
    resetPasswordForm.resetFields();
  };

  const onChange = (value, option) => {
    setSelectedValue(value);
  };

  const getUsersList = async () => {
    setTeamModalLoading(true);
    const response = await getUsers();
    if (response && response.Users.length) {
      // eslint-disable-next-line consistent-return
      let usersForProject = response.Users.map((obj) => {
          const details = { user_id: obj.Username };
          obj.Attributes.forEach((element) => {
            if (element.Name === 'name') {
              details.name = element.Value;
            } else if (element.Name === 'email') {
              details.email = element.Value;
            }
          });
          if (details.name && details.email) {
            return details;
          }
      });
      usersForProject = usersForProject.filter((el) => el);
      setUserOptions(usersForProject);
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
    setTeamModalLoading(false);
  };

  const changeTempPass = async (data) => {
    try {
      if (data.password === data.password_confirm) {
        setResetPasswordModalLoading(true);
        await onResetPassword(data.password, selectedValue.value.toLowerCase());
      } else {
        notification.warning({
          message: 'Passwords do not match',
        });
      }
    } catch (err) {
      notification.warning({
        message: 'Something went wrong.',
      });
    }
  };

  const validatePass = () => {
    resetPasswordForm
      .validateFields()
      .then(async (data) => {
        await changeTempPass(data);
      });
  };

  useEffect(() => {
    getUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    
    <Modal
      className="quality-modal"
      title="Reset Password"
      centered
      visible={resetPasswordModalVisible}
      onOk={() => validatePass()}
      okText="Reset Password"
      onCancel={() => handleCancel(false)}
      okButtonProps={{ disabled: resetPasswordModalLoading || selectedValue.length === 0 }}
      cancelButtonProps={{ disabled: resetPasswordModalLoading }}
    >
      <div className="signup-form">
        <Spin spinning={teamModalLoading}>
          <Form
            name="normal_login"
            initialValues={{
          remember: true,
        }}
            form={resetPasswordForm}
          >
          
            <Form.Item
              name="fullName"
            >
              <Select
                className="select-dropdown-signup"
                placeholder="Select User"
                showArrow
                onChange={onChange}
                value={selectedValue}
                labelInValue
                showSearch
                autoClearSearchValue
                optionFilterProp="children"
                filterOption={(input, option) => 
                (option.children[0]).toLowerCase()
                  .includes(input.toLowerCase())}
              >
                {userOptions.map((user) => (
                  <Option
                    value={user.email}
                    key={user.user_id}
                  >
                    {user.name} 
                    {' '}
                    (
                    {user.email}
                    )
                  </Option>
            ))}
              </Select>
            
            </Form.Item>
            <Tooltip
              visible={newPwdFocused && showToolTip}
              title={(
                <>
                  <p align="left"><b>Requirements:</b></p>
                  <ul align="left">
                    <li align="left">8-character minimum length</li>
                    <li align="left">Contains at least 1 number</li>
                    <li align="left">Contains at least 1 lowercase letter</li>
                    <li align="left">Contains at least 1 uppercase letter</li>
                    <li align="left">Contains at least 1 special character from the following set, or a non-leading, non-trailing space character.</li>
                    <p align="left">{'^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < \' : ; | _ ~ ` + ='}</p>
                  </ul>
                  
                </>
              )}
              placement="topLeft"
            > 
              <Form.Item
                name="password"
                rules={[
            {
              required: true,
              message: 'Please enter New Password.',
            },
            {
              pattern: true
                    ? constants.PASSWORD_VALIDATION
                    : undefined,
                  message: 'Password does not meet password policy rules',
            }
          ]}
              >
                <Input.Password
                  size="large"
                  className="password-icon"
                  placeholder="New Password"
                  iconRender={(visibility) => (visibility ? <EyeOutlined style={{ color: 'grey' }} /> : <EyeInvisibleOutlined style={{ color: 'grey' }} />)}
                  onFocus={() => setNewPwdFocused(true)}
                  onBlur={() => setNewPwdFocused(false)}
                  onChange={(e) => handlePassword(e)}
                />
              </Form.Item>
            </Tooltip>
            <Tooltip
              visible={confirmPwdFocused && showToolTip}
              title={(
                <>
                  <p align="left"><b>Requirements:</b></p>
                  <ul align="left">
                    <li align="left">8-character minimum length</li>
                    <li align="left">Contains at least 1 number</li>
                    <li align="left">Contains at least 1 lowercase letter</li>
                    <li align="left">Contains at least 1 uppercase letter</li>
                    <li align="left">Contains at least 1 special character from the following set, or a non-leading, non-trailing space character.</li>
                    <p align="left">{'^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < \' : ; | _ ~ ` + ='}</p>
                  </ul>
                  
                </>
              )}
              placement="topLeft"
             
            > 
              <Form.Item
                name="password_confirm"
                rules={[
            {
              required: true,
              message: 'Please confirm your password.',
            },
            {
              pattern: true
                    ? constants.PASSWORD_VALIDATION
                    : undefined,
                  message: 'Password does not meet password policy rules',
            }
          ]}
              >
                <Input.Password
                  size="large"
                  className="password-icon"
                  placeholder="Confirm New Password"
                  iconRender={(visibility) => (visibility ? <EyeOutlined style={{ color: 'grey' }} /> : <EyeInvisibleOutlined style={{ color: 'grey' }} />)}
                  onFocus={() => setConfirmPwdFocused(true)}
                  onBlur={() => setConfirmPwdFocused(false)}
                  onChange={(e) => handlePassword(e)}
                />
              </Form.Item>
            </Tooltip>
          </Form>
        </Spin>
      </div>
    </Modal>
    
  );
};

export default ResetPasswordModal;
