/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
import { Auth } from '@aws-amplify/auth';
import { notification } from 'antd';
import history from './history';

const normalizeUsername = (username) => username?.toLowerCase();

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

const authenticateCurrentUser = async () => {
  const currentUser = await Auth.currentAuthenticatedUser();
  return currentUser;
};

const userSignOut = async () => {
  const isSignedOut = await Auth.signOut();
  return isSignedOut;
};

const userSignIn = async (username, password) => {
  const userData = await Auth.signIn(normalizeUsername(username), password);
  return userData;
};

const getJwtToken = async () => {
  const sessionBefore = await Auth.currentSession();
  return sessionBefore.getIdToken().getJwtToken();
};

const setTokens = async (data) => {
  const idToken = data.signInUserSession.idToken.jwtToken;
  const refreshToken = data?.signInUserSession.refreshToken.token;
  localStorage.setItem('ap_idToken', idToken);
  localStorage.setItem('ap_rToken', refreshToken);
  history.push('/main');
};

const clearTokens = async () => {
  localStorage.setItem('ap_idToken', '');
  localStorage.setItem('ap_rToken', '');
};

const forgotPassword = (email) => {
  try {
    return new Promise((resolve, reject) => {
      Auth.forgotPassword(email)
        .then((data) => {
          notification.destroy();
          notification.success({
            message: `Verification code sent to ${data.CodeDeliveryDetails.Destination}`,
          });
          resolve(data.CodeDeliveryDetails.Destination);
        })
        .catch((err) => {
          notification.destroy();
          if (err.message === 'Network error') {
            notification.error({
              message: err.message
            });
          } else if (err.message === 'Attempt limit exceeded, please try after some time.') {
            notification.error({
              message: 'Attempt limit exceeded, please try after some time.',
            });
          } else {
            notification.error({
              message: 'User not found.',
            });
          }
          reject(err);
        });
    });
  } catch (error) {
    notification.destroy();
    notification.error({
      message: 'Unable to fetch user.',
    });
  }
};

const forgotPasswordSubmit = (email, code, newPass) => new Promise((resolve, reject) => {
  Auth.forgotPasswordSubmit(email, code, newPass)
    .then(() => {
      notification.destroy();
      notification.success({
        message: 'Password changed successfully.',
      });
      history.push('./login');
      resolve('');
    })
    .catch((err) => {
      notification.destroy();
      if (err.code === 'InvalidParameterException' || err.code === 'InvalidPasswordException') {
        notification.error({
          message: 'Passwords must be at least 8 characters in length including one number, special character, uppercase and lowercase.',
        });
      } else if (err.code === 'CodeMismatchException' || err.code === 'ExpiredCodeException') {
        notification.error({
          message: 'Mismatch in verification code.',
        });
      } else if (err.code === 'UserNotFoundException') {
        notification.error({
          message: 'User not Found.',
        });
      } else if (err.message === 'Network error') {
        notification.error({
          message: err.message
        });
      } else {
        notification.error({
          message: err.message,
        });
        reject(err);
      }
    });
});

const resetPasswordSubmit = (newPass, fullName) => new Promise((resolve, reject) => {
  const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  };

  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const userData = {
    Username: fullName,
    Pool: userPool,
  };

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

  Auth.completeNewPassword(cognitoUser, newPass)
    .then(() => {
      if (cognitoUser) {
        notification.destroy();
        notification.success({
          message: 'Password changed successfully',
        });
        resolve('');
        return true;
      }
    })
    .catch((err) => {
      notification.destroy();
      if (err.code === 'InvalidParameterException' || err.code === 'InvalidPasswordException') {
        notification.error({
          message: 'Passwords must be at least 8 characters in length including one number, special character, uppercase and lowercase.',
        });
      } else if (err.code === 'CodeMismatchException' || err.code === 'ExpiredCodeException') {
        notification.error({
          message: 'Mismatch in verification code ',
        });
      } else if (err.code === 'UserNotFoundException') {
        notification.error({
          message: 'User not Found ',
        });
      } else if (err.message === 'Network error') {
        notification.error({
          message: err.message
        });
      } else {
        notification.error({
          message: err.message,
        });
        reject(err);
      }
    });
});

export {
  authenticateCurrentUser,
  userSignOut,
  userSignIn,
  getJwtToken,
  setTokens,
  clearTokens,
  forgotPassword,
  forgotPasswordSubmit,
  resetPasswordSubmit
};
