/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Modal, Button
} from 'antd';
import { datesInPeriod } from '../services/calendar';
import * as constants from '../utils/constants';

const TechCrewModal = (props) => {
  const {
    currentReport, modalVisible, hideModal, crew
  } = props;
  const [newData, setNewData] = useState(false);
  const [data, setData] = useState([]);
  const crewData = [];
  const [tablehtml, setTablehtml] = useState('');

  const tableData = () => {
    const tableval = (
      <table className="logreport report-table">
        <thead className="ant-table-thead">
          <tr>
            <th rowSpan="2" className="ant-table-cell align-bottom">Date</th>
            <th rowSpan="2" className="ant-table-cell text-center">
              <div className="main-title py-2 me-2">
                ONSITE
              </div>
            </th>
            <th rowSpan="2" className="ant-table-cell text-center">
              <div className="main-title py-2 me-2">
                TRAVEL
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="ant-table-tbody">
          {
                  newData
                  && newData.map((e) => (
                    <tr key={e.date} className="ant-table-row ant-table-row-level-0">
                      <td className="rTableCell">{moment(e.date).format(constants.DATE).replaceAll('/', '-')}</td>
                      {
                        Object.keys(data).map((x) => {
                          if (data[x].date === e.date) {
                            return (
                              <>
                                <td className="rTableCell text-center">{data[x].Onsite}</td>
                                <td className="rTableCell text-center">{data[x].Travel}</td>
                              </>
                            );
                          }
                        })
                      }
                    </tr>
                  ))
                }
        </tbody>
      </table>
    );
    setTablehtml(tableval);
  };

  useEffect(() => {
    if (modalVisible === false) {
      setTablehtml('');
      return;
    }
    const dates = datesInPeriod(currentReport.period);
    const { entries } = currentReport;
    const newData = [];
    let recordFound = false;
    if (dates) {
      dates.forEach((day) => {
        for (let i = 0; i < entries.length; i += 1) {
          if (new Date(entries[i].date) <= new Date()) {
            if (entries[i].date === day) {
              newData.push(entries[i]);
              recordFound = true;
              break;
            }
          }
        }
        if (!recordFound) {
          let dummyMem = [];
          if (currentReport) {
            dummyMem = currentReport.techs.map((tech) => ({
              techName: tech.name, status: undefined,
            }));
          }
          newData.push({ date: day, ...dummyMem });
          recordFound = false;
        }
        recordFound = false;
      });
    }
    setNewData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport, modalVisible]);

  useEffect(() => {
    if (newData && newData.length > 0) {
      // eslint-disable-next-line array-callback-return
      newData.map((e) => {
        // eslint-disable-next-line array-callback-return
        Object.values(e).map((x) => {
          if (typeof (x) === 'object') {
            x.techName = x.techName ? x.techName : '(No Name)';
            if ((e.date && x.techName && x.techName === crew)) {
              const crewWiseData = {};
              crewWiseData.date = e.date;
              // eslint-disable-next-line no-nested-ternary
              crewWiseData.Onsite = x.status === undefined ? '' : x.status === 'Onsite' ? 'ONSITE' : '';
              // eslint-disable-next-line no-nested-ternary
              crewWiseData.Travel = x.status === undefined ? '' : x.status === 'Travel' ? 'TRAVEL' : '';
              crewData.push(crewWiseData);
            }
          }
        });
      });
    }
    const cdata = crewData.filter((param, index, arr) => arr.findIndex((t) => ['date'].every((k) => t[k] === param[k])) === index);
    setData(cdata);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crew, newData]);

  useEffect(() => {
    if (data !== []) {
      tableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Modal
        title="Tech Log Report"
        className="welds-modal"
        centered
        visible={modalVisible}
        width={1000}
        footer={[
          <Button key="submit" type="primary" onClick={() => hideModal()}>
            OK
          </Button>
        ]}
      >
        <div>
          <div className="ant-table scroll-table">
            <div className="ant-table-container">
              <div className="ant-table-content">
                {tablehtml}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TechCrewModal;
