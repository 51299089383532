import get from 'lodash/get';
import { SET_USERS, FETCH_USERS, SET_USER_FAILURE } from '../types';

const initialState = {
  isLoading: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS: {
      const { Users } = action.response;
      const data = [];
      for (let i = 0; i < Users.length; i += 1) {
        const userDetail = Users[i].Attributes;
        const userName = get(userDetail.find((obj) => obj.Name === 'name'), 'Value', '');
        const userEmail = get(userDetail.find((obj) => obj.Name === 'email'), 'Value', '');
        if (userName !== '') {
          data[i] = {
            id: i,
            key: i,
            userName,
            userEmail,
          };
        }
      }

      return {
        ...state,
        data,
        isLoading: false,
      };
    }

    case FETCH_USERS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_USER_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
