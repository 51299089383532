import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { setAdvisory, setAdvisoryFailure } from '../state/action/advisoryAction';
import { GET_ADVISORY } from '../state/types';
import fetch from '../services/fetch';
import { BASE_URL } from '../config';

function* getAdvisory({
  typeId = '', page = '', limit = '', startDate = '', endDate = ''
}) {
  let status = 0;
  try {
    const res = yield fetch(`${BASE_URL}/advisory/list?type=${typeId}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      status = res.status;
      throw new Error('Unauthorized');
    }
    const response = yield res.json();
    yield put(setAdvisory({ response }));
  } catch (error) {
    yield put(setAdvisoryFailure());
    if (status !== 403) {
      notification.error({
        message: 'There was a problem fetching the report details.',
        description: `${error}`,
      });
    }
  }
}

function* advisoryWatcher() {
  yield takeLatest(GET_ADVISORY, getAdvisory);
}

function* advisoryListSagas() {
  yield all([
    call(advisoryWatcher),
  ]);
}

export default advisoryListSagas;
