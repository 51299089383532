/* eslint-disable  */
import React, { useState, useEffect } from 'react';
import {
  Modal, Button, Tabs, Table, Spin, notification
} from 'antd';
import moment from 'moment';
import { getPipeline } from '../services/pipeline';
import { getProductivityData } from '../services/summary';

const { TabPane } = Tabs;

const commentColumn = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    align: 'center'
  },
  {
    title: 'Comments',
    dataIndex: 'productivity_comments',
    key: 'productivity_comments',
    align: 'center'
  },
];

const ProductivityModal = (props) => {
  const {
    visible, setVisible, projNo, isToday, shift
  } = props;
  const [tabValue, setTabValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [objectFormat, setObjectFormat] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [summaryDataSource, setSummaryDataSource] = useState([]);
  const [commentColumns, setCommentColumns] = useState([]);

  useEffect(() => {
    if(tabValue === 'Comments'){
        if(!isToday) setCommentColumns(commentColumn)
        else setCommentColumns([
          {
            title: 'Comment',
            dataIndex: 'productivity_comments',
            key: 'productivity_comments',
          },
        ])
    }
  }, [tabValue])

  useEffect(() => {
    if (visible) {
      setTabValue('Completed');
      getTableColumns();
    }
    return () => {
      setColumns([]);
      setDataSource([]);
      setTabValue('')
    };
  }, [visible]);

  const getTableColumns = async () => {
    setLoading(true);
    try {
      const tempColumns = [];
      const dummyObject = {};
      if (!isToday) dummyObject.date = '';
      if (projNo) {
        const res = await getPipeline(projNo);
        if (res?.pipelineData) {
          const pipelineData = res?.pipelineData.filter((item) => item.is_deleted === 0);
          if (!isToday) {
            tempColumns.push({
              title: 'Date',
              dataIndex: 'date',
              key: 'date',
              align: 'center'
            });
          }
          pipelineData.forEach((item, index) => {
            tempColumns.push({
              title: item.name,
              dataIndex: item.id.toString(),
              key: item.id.toString(),
              align: 'center'
            });
            dummyObject[item.id] = '0';
          });
          setObjectFormat(dummyObject);
          setColumns(tempColumns);
          getProductiveComments('Completed');
          getProductiveData('Completed', dummyObject);
        }
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  function groupArrayByField(arr, field) {
    const groups = {};

    arr.forEach((obj) => {
      const value = obj[field];
      if (groups[value]) {
        groups[value].push(obj);
      } else {
        groups[value] = [obj];
      }
    });

    return groups;
  }

  const getProductiveData = async (tab, dummyObject) => {
    setLoading(true);
    try {
      if (projNo) {
        const finalData = [];
        const currentTab = tab || tabValue;
        const date = isToday ? moment(new Date()).format('YYYY-MM-DD') : '';
        const res = await getProductivityData(projNo, date, currentTab, shift);
        if (res?.pipelineEntryData) {
          if (!isToday) {
            const groupedByDate = groupArrayByField(res.pipelineEntryData, 'date');
            Object.keys(groupedByDate).sort((a,b) => new Date(b) - new Date(a)).forEach((key) => {
              const tempObject = { ...dummyObject };
              const value = groupedByDate[key];
              value.forEach((
                item
              ) => tempObject[item.project_pipelines_id] = item.value);
              tempObject.date = key;
              finalData.push(tempObject);
            });
            setDataSource(finalData);
          } else {
            const tempObject = { ...dummyObject };
            if (res.pipelineEntryData[0]) {
              res.pipelineEntryData.forEach((item) => tempObject[item.project_pipelines_id] = item.value);
              finalData.push(tempObject);
              setDataSource(finalData);
            } else {
              setDataSource([]);
            }
          }
        }
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };

  const getProductiveComments = async (tab) => {
    try {
      if (projNo) {
        const currentTab = tab || tabValue;
        const date = isToday ? moment(new Date()).format('YYYY-MM-DD') : '';
        const res = await getProductivityData(projNo, date, currentTab, shift);
        if (res?.summaryCommentData) {
          const comments = res?.summaryCommentData.filter((item) => item.productivity_comments);
          setSummaryDataSource(comments);
        }
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };

  const onTabChange = (activeKey) => {
    setTabValue(activeKey);
    if (activeKey !== 'Comments') getProductiveData(activeKey, objectFormat);
  };

  const onOk = () => {
    setColumns([]);
    setDataSource([]);
    setSummaryDataSource([]);
    setVisible(false);
  };

  return (
    <>
      <Modal
        title="Productivity"
        className="welds-modal"
        centered
        visible={visible}
        width={1009}
        footer={[
          <Button key="submit" type="primary" onClick={onOk}>
            OK
          </Button>
        ]}
      >
        <div>
          <div className="flex-fill overflow-auto">
            <Tabs onChange={onTabChange} activeKey={tabValue} className="custom-tab h-100">
              <TabPane tab="Completed" key="Completed" value="Completed" checked />
              <TabPane tab="Accepted" key="Accepted" value="Accepted" />
              <TabPane tab="Hold Items" key="Hold Items" value="Hold Items" />
              <TabPane tab="Comments" key="Comments" value="Comments" />
            </Tabs>
          </div>
          {
        tabValue !== 'Comments'
          ? (
            <Table
              pagination={false}
              columns={columns}
              dataSource={dataSource}
              style={{ whiteSpace: 'nowrap' }}
              scroll={{ x: true }}
              className="table-with-button-assets"
              loading={{
                indicator: <Spin />,
                spinning: loading
              }}
            />
          )
          : (
            <Table
              pagination={false}
              columns={commentColumns}
              dataSource={summaryDataSource}
              style={{ whiteSpace: 'nowrap' }}
              scroll={{ x: true }}
              className="table-with-button-assets"
              loading={{
                indicator: <Spin />,
                spinning: loading
              }}
            />
          )
        }
        </div>
      </Modal>
    </>

  );
};

export default ProductivityModal;
