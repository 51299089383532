/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import {
  Table, Spin, Button, notification, Tooltip, Form, Modal, Checkbox
} from 'antd';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  func, arrayOf, objectOf, oneOfType, string, number,
  array, bool
} from 'prop-types';
import {
  EditOutlined,
} from '@ant-design/icons';
import AddMemberModal from './AddMemberModal';
import NewMessageModal from './NewMessageModal';
import { DeleteIcon } from './svgs/deleteIcon';
import { NewMessageIcon } from './svgs/newMessageIcon';
import { getTeamMembers } from '../state/action/teamMembers';
import { addTeamMembers, removeTeamMember, addRoles } from '../services/teamMembers';
import { addNotification } from '../services/notifications';

const TeamMembers = ({
  projNo, reload, refresh,
  showLoader, onGetTeamMembers,
  teamMembers, setTeamMembers,
  projectStatus, data, projectType
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [teamMemberData, setTeamMemberData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [teamModalLoading, setTeamModalLoading] = useState(false);
  const [messageModalLoading, setMessageModalLoading] = useState(false);
  const [isMessageModalVisible, setMessageModalVisible] = useState(false);
  const [receiverName, setReceiverName] = useState('');
  const [userIds, setUserIds] = useState('');
  const [accessToLogs, setAccessToLogs] = useState(false);
  const [recordData, setRecordData] = useState([]);

  const [messageForm] = Form.useForm();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [totalReports, setTotalReports] = useState(0);

  useEffect(() => {
    refresh(false);
    if (!reload) {
      if (projNo !== '' && projectType) {
        onGetTeamMembers({ projectNo: projNo, projectType });
      } else {
        setTeamMemberData([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo, projectType]);

  useEffect(() => {
    if (!reload && projNo !== '') {
      const member = [];
      data.map((dt) => {
        if (dt.email || dt.isNonCognitoUser === 1) {
          member.push(dt);
        }
      });
      member.sort((a, b) => a.name.localeCompare(b.name));
      setTotalReports(member.length);
      setTeamMemberData(member);
      setTeamMembers(member);
    } else {
      setTeamMemberData(teamMembers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onMemberSave = async (value) => {
    setTeamModalLoading(true);
    const res = await addTeamMembers(projNo, value);
    setAccessToLogs(false);
    setTeamModalLoading(false);
    if (res) {
      setIsModalVisible(false);
      onGetTeamMembers({ projectNo: projNo, projectType });
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  const onRoleSave = async (value) => {
    setTeamModalLoading(true);
    const res = await addRoles(value, accessToLogs, userIds);
    setAccessToLogs(false);
    setTeamModalLoading(false);
    if (res) {
      setIsModalVisible(false);
      onGetTeamMembers({ projectNo: projNo, projectType });
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
    setRecordData([]);
  };

  const handleTableChnage = (pagination,
    filters,
    sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const handleDeletion = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this user from the project?',
      className: 'custom-text-clr',
      async onOk() {
        return removeTeamMember(projNo, record.userId).then((res) => {
          if (res) {
            onGetTeamMembers({ projectNo: projNo, projectType });
          } else {
            notification.error({
              message: 'Something went wrong.',
            });
          }
          return true;
        }).catch(() => notification.error({
          message: 'Something went wrong.',
        }));
      },
      onCancel() {},
    });
  };

  const showMessageModal = (record) => {
    const messageData = {
      toUserId: record.userId,
      toUserName: record.name,
    };
    setReceiverName(record.name);
    messageForm.setFieldsValue(messageData);
    setMessageModalVisible(true);
  };

  const onMessageSave = async (msgdata) => {
    setMessageModalLoading(true);
    const res = await addNotification(msgdata);
    setMessageModalLoading(false);
    if (res) {
      messageForm.resetFields();
      setMessageModalVisible(false);
      setIsEdit(false);
    } else {
      notification.error({
        message: 'Something went wrong',
      });
    }
  };

  const editBtnHandler = (record) => {
    setUserIds(record?.id);
    setRecordData(record);
    setAccessToLogs(record?.accessToLogs === 1);
    if (record) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center'
    },
    projectType === 'offshore'
      ? {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        align: 'center',
        hidden: true,
        render: (record) => {
          if (record) {
            return (record);
          }
          return '-';
        }
      } : {},
    projectType === 'offshore' || projectType === 'onshore'
      ? {
        title: projectType === 'offshore' ? 'To be listed in DPR' : 'To be Listed in LEM & Timesheet',
        dataIndex: 'accessToLogs',
        key: 'accessToLogs',
        align: 'center',
        render: (text, record) => (
          <Checkbox
          // disabled
            checked={record.accessToLogs}
          />
        )
      } : {},
    {
      title: '',
      render: (record) => {
        if (record) {
          return (
            <div>

              <Tooltip
                arrowPointAtCenter
                placement="topLeft"
                title="Assign Role"
              >
                {
                  // projectType === 'offshore' ? (
                  <EditOutlined
                    style={{ marginRight: '30px', fontSize: '16px' }}
                    className="edit-project-detail"
                    onClick={() => {
                      editBtnHandler(record);
                    }}
                  />
                  // ) : ''
                }
              </Tooltip>
              {!record.isNonCognitoUser ? (
                <Tooltip
                  arrowPointAtCenter
                  placement="topRight"
                  title={projectStatus === 'Archived'
                    ? 'Cannot Send Notification in Archived Project' : 'Send Notification'}
                >
                  {projectStatus === 'Archived'
                    ? <NewMessageIcon style={{ marginRight: '30px', fontSize: '16px' }} />
                    : (
                      <NewMessageIcon
                        style={{ marginRight: '30px', fontSize: '16px' }}
                        onClick={() => showMessageModal(record)}
                      />
                    )}
                </Tooltip>
              ) : ''}
              <Tooltip
                arrowPointAtCenter
                placement="topRight"
                title={projectStatus === 'Archived'
                  ? 'Cannot Delete Members from Archived Project' : 'Delete Member'}
              >
                {projectStatus === 'Archived'
                  ? <DeleteIcon style={{ marginRight: '30px', fontSize: '16px' }} />
                  : (
                    <DeleteIcon
                      style={{ marginRight: '30px', fontSize: '16px' }}
                      onClick={() => handleDeletion(record)}
                    />
                  )}
              </Tooltip>
            </div>
          );
        }

        return <div>-</div>;
      },
      align: 'right',
    },

  ];

  return (
    <>
      <div className="h-100 overflow-y-hidden overflow-x-auto">
        <Table
          pagination={totalReports > 10 ? tableParams.pagination : false}
          columns={columns}
          dataSource={teamMemberData}
          className="table-with-button"
          scroll={{ x: teamMemberData.length > 0 ? 600 : 0, y: '100%' }}
          loading={{
            indicator: <Spin />,
            spinning: showLoader
          }}
          onChange={handleTableChnage}
        />
        {' '}
        {projNo !== ''
          ? (
            <div className="text-right mt-2 mb-2">
              <Tooltip
                placement="topLeft"
                title={projectStatus === 'Archived'
                  ? 'Cannot Add Members to Archived Project' : ''}
              >
                <Button
                  key="submit"
                  size="small"
                  shape="round"
                  type="primary"
                  disabled={projectStatus === 'Archived' || showLoader}
                  onClick={() => {
                    setIsModalVisible(true);
                    setIsEdit(false);
                  }}

                >
                  Add Team Members
                </Button>
              </Tooltip>
            </div>
          )
          : null}
      </div>

      {isModalVisible
        ? (
          <AddMemberModal
            setIsModalVisible={setIsModalVisible}
            isModalVisible={isModalVisible}
            teamModalLoading={teamModalLoading}
            setTeamModalLoading={setTeamModalLoading}
            teamMemberList={teamMemberData}
            rolesList={teamMemberData}
            onSave={onMemberSave}
            onUpdate={onRoleSave}
            setIsEdit={setIsEdit}
            projectNo={projNo}
            isEdit={isEdit}
            userIds={userIds}
            projectType={projectType}
            accessToLogs={accessToLogs}
            setAccessToLogs={setAccessToLogs}
            recordData={recordData}
            setRecordData={setRecordData}
          />
        )
        : null }
      <NewMessageModal
        setIsModalVisible={setMessageModalVisible}
        isModalVisible={isMessageModalVisible}
        messageModalLoading={messageModalLoading}
        onSave={onMessageSave}
        onUpdate={onMessageSave}
        messageForm={messageForm}
        receiverName={receiverName}
      />
    </>
  );
};

TeamMembers.propTypes = {
  onGetTeamMembers: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showLoader: bool.isRequired,
};

const mapStateToProps = ({ teamMembers }) => {
  const data = get(teamMembers, 'data', []);
  const showLoader = get(teamMembers, 'isLoading', false);
  return {
    data,
    showLoader,
  };
};

const mapDispatchToProps = {
  onGetTeamMembers: getTeamMembers
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamMembers);
