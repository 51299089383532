import get from 'lodash/get';
import moment from 'moment';
import {
  GET_NOTIFICATIONS, SET_NOTIFICATIONS, SET_NOTIFICATIONS_FAILURE
} from '../types';

const initialState = {
  isLoading: false,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS: {
      const notification = get(action, ['response'], []);
      const notificationData = notification.data;
      const data = [];
      for (let i = 0; i < notificationData.length; i += 1) {
        data[i] = {
          key: i,
          title: notification?.data[i]?.title,
          message: notification?.data[i]?.description,
          from: notification?.data[i]?.author,
          date: moment(notification?.data[i]?.createdAt).format('DD/MMM/YY'),
          time: moment(notification?.data[i]?.createdAt).format('hh:mm:ss')
        };
      }
      return {
        ...state,
        data,
        isLoading: false,
      };
    }

    case GET_NOTIFICATIONS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default notificationReducer;
