import { notification } from 'antd';
import fetch from './fetch';
import { BASE_URL } from '../config';

export const removeProjectAsset = async (projectNo, assetId) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = await fetch(`${BASE_URL}/projectsData/deleteAssets?project_numbers=${projNo}&id=${assetId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const addProjectAssets = async (projectNo, filename) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = await fetch(`${BASE_URL}/projectData/${projNo}/assets`, {
      method: 'POST',
      body: filename,
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const updateProjectAssets = async (projectNo, assets, assetId) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = await fetch(`${BASE_URL}/projectData/${projNo}/assets/update`, {
      method: 'POST',
      body: JSON.stringify(assets),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};
