/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
/* eslint-disable prefer-template */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import {
  Table, Spin, DatePicker, Button, Form, notification, Checkbox, Switch, Badge, Space, Text
} from 'antd';
import {
  CheckOutlined, ReloadOutlined
} from '@ant-design/icons';
import get from 'lodash/get';
import { connect } from 'react-redux';
import {
  func, arrayOf, objectOf, oneOfType, string, number,
  array, bool
} from 'prop-types';
import SearchModal from './Search';
import { getLemReports } from '../state/action/lemAction';
import LemModal from './LemModal';
import DateSelectionModal from './DateSelectionModal';
import { sendESignEmail } from '../services/lem';

const { RangePicker } = DatePicker;

const columns = [
  {
    title: 'Date',
    dataIndex: 'selected_date',
    key: 'selected_date',
    align: 'center'
  },
  {
    title: 'Created By',
    dataIndex: 'created_by',
    key: 'created_by',
    align: 'center'
  },
  {
    title: 'Created Time',
    dataIndex: 'date_and_time_of_submission',
    key: 'date_and_time_of_submission',
    align: 'center'
  },
  {
    title: 'Comments',
    dataIndex: 'comments',
    key: 'comments',
    align: 'center'
  },
  {
    title: 'Report',
    key: 'pdf_url',
    dataIndex: ['pdf_url', 'pdf_signed'],
    render: (text, record) => {
      if (record) {
        return (
          <div>
            {' '}
            <a
              className="export-link"
              title={record.pdf_signed === 1 ? 'Signed Report' : 'Client signature pending'}
              target="_blank"
              rel="noopener noreferrer"
              href={record.pdf_url}
              // eslint-disable-next-line react/jsx-closing-bracket-location
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <span className="icon-16 mr-2">
                <svg>
                  <use
                    xlinkHref="#export"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    color={record.pdf_signed === 1 && '#4ded18'}
                  />
                </svg>
              </span>
            </a>
          </div>
        );
        // eslint-disable-next-line brace-style
      }
      // eslint-disable-next-line no-else-return
      else {
        return <div>-</div>;
      }
    },
    align: 'center'
  },

];
const itemsPerPage = 10;
const defaultPage = 0;

const LemReport = (props) => {
  const {
    onGetLemReports, data, projNo, showLoader, total,
    reload, setLemRep, lemReport, refresh, dates, setDates, setReloadDates, reloadDates,
    projectContractor, projectManagerEmail, projectDocSignRequired
  } = props;
  const [lemData, setLemData] = useState([]);
  const [lemDatas, setLemDatas] = useState([]);
  const [lemModalVisible, setLemModalVisible] = useState(false);
  const [assets, setAssets] = useState([]);
  const [members, setMembers] = useState([]);
  const [disableRangeDataFetchBtn, setDisableRangeDataFetchBtn] = useState(true);
  const [dateSelectionModalVisible, setDateSelectionModalVisible] = useState(false);
  const [esignMailModalLoading, setEsignMailModalLoading] = useState(false);
  const [pdfDates, setPdfDates] = useState([]);
  const [dailyReportData, setDailyReportData] = useState([]);
  const [selectedWeeklyReportRows, setSelectedWeeklyReportRows] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState([]);
  const [checkedValuesFilter, setCheckedValuesFilter] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const [dateForm] = Form.useForm();
  useEffect(() => {
    // refresh(false);
    // if (!reload) {
    if (projNo !== '') {
      onGetLemReports(projNo, pageNumber, itemsPerPage);
    } else {
      setLemData([]);
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo]);

  useEffect(() => {
    if (reload) {
      if (projNo) {
        const startDate = reloadDates && reloadDates.length && reloadDates[0].length ? reloadDates[0] : '';
        const endDate = reloadDates && reloadDates.length && reloadDates[0].length ? reloadDates[1] : '';
        onGetLemReports(projNo, pageNumber, itemsPerPage, checkedValuesFilter, startDate, endDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    if (!reload) {
      const lem = [];
      if (data !== []) {
        data.map((item) => {
          item.date_and_time_of_submission = item.date_and_time_of_submission.replaceAll('/', '-');
          if (item.comments === '') {
            item.comments = 'N/A';
          }
          lem.push(item);
        });
        // lem.sort((a, b) => new Date(b.selected_date) - new Date(a.selected_date));
        setLemData(lem);
      }
      // lem.sort((a, b) => new Date(b.selected_date) - new Date(a.selected_date));
      setLemRep(lem);
      setLemDatas(lem);
    } else {
      setLemData(lemReport);
      setDates(reloadDates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const hideModal = () => {
    setLemModalVisible(false);
    if (dateSelectionModalVisible) {
      setDateSelectionModalVisible(false);
      setSelectedWeeklyReportRows([]);
      setSelectedRowKey([]);
      // dateForm.resetFields();
      setPdfDates([]);
      setDailyReportData([]);
    }
  };

  useEffect(() => {
    if (dates.length === 2 && dates[0] !== '' && dates[1] !== '') {
      setDisableRangeDataFetchBtn(false);
    } else {
      setDisableRangeDataFetchBtn(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  const filterLemData = (checkedValues) => {
    const result = [];
    const filterValue = checkedValues;
    setPageNumber(0);
    const startDate = reloadDates && reloadDates.length && reloadDates[0].length ? reloadDates[0] : '';
    const endDate = reloadDates && reloadDates.length && reloadDates[0].length ? reloadDates[1] : '';
    onGetLemReports(projNo, defaultPage, itemsPerPage, filterValue, startDate, endDate);

    setLemData(result);
    setLemRep(result);
  };

  useEffect(() => {
    if (lemData) {
      setLemData(lemData);
    }
  }, [lemData]);

  const showSelectedDateData = () => {
    const startDate = dates[0];
    const endDate = dates[1];
    setPageNumber(0);
    onGetLemReports(projNo, defaultPage, itemsPerPage, checkedValuesFilter, startDate, endDate);
    setReloadDates(dates);
  };

  const sendMailForSignature = async (dt) => {
    const esignData = {
      projectData: dt
    };
    setEsignMailModalLoading(true);
    const response = await sendESignEmail(esignData);
    setEsignMailModalLoading(false);
    if (response === 200) {
      setDateSelectionModalVisible(false);
      setSelectedWeeklyReportRows([]);
      setSelectedRowKey([]);
      setPdfDates([]);
      setDailyReportData([]);
      notification.success({
        message: 'Email for eSign sent successfully',
        duration: 1.2
      });
    }
  };

  const filterReport = (checkedValues) => {
    const value = checkedValues.length === 1 ? checkedValues[0] : '';
    setCheckedValuesFilter(value);
    filterLemData(value);
  };

  const onCalendarChange = (val, dateString) => {
    setDates(dateString);
    setPageNumber(0);
    setReloadDates([]);
    if (!val) {
      setReloadDates([]);
      onGetLemReports(projNo, defaultPage, itemsPerPage, checkedValuesFilter);
    }
  };

  const onPaginationChange = (page) => {
    setPageNumber(page - 1);
    const startDate = reloadDates && reloadDates.length && reloadDates[0].length ? reloadDates[0] : '';
    const endDate = reloadDates && reloadDates.length && reloadDates[0].length ? reloadDates[1] : '';
    onGetLemReports(projNo, page - 1, itemsPerPage, checkedValuesFilter, startDate, endDate);
  };
  return (
    <>

      <div className="d-flex pb-2 align-items-center justify-content-between">

        <div className="d-flex">
          <Checkbox.Group onChange={filterReport} style={{ margin: '3px' }}>
            <Checkbox value={1}>Signed Reports</Checkbox>
            <Checkbox value={0}>Signature Pending Reports</Checkbox>
          </Checkbox.Group>
          <div className="lem-date-filter">
            <RangePicker
  // className="rangePicker_separator"
              showToday
              separator="to"
              format="YYYY-MM-DD"
              placeholder={['Start Date', 'End Date']}
              defaultValue={dates}
              onCalendarChange={onCalendarChange}
            />
            <Button
              type="primary"
              disabled={!!disableRangeDataFetchBtn}
              onClick={() => showSelectedDateData()}
            >
              <CheckOutlined />
              {' '}
            </Button>
            {' '}

          </div>

        </div>
      </div>

      <div className={lemData.length > 0 ? 'h-85 overflow-y-hidden overflow-x-auto has-pointer' : 'h-85 overflow-y-hidden overflow-x-auto'}>
        <Table
          pagination={{
            defaultPageSize: itemsPerPage,
            showSizeChanger: false,
            pageSizeOptions: ['10'],
            onChange: onPaginationChange,
            total,
            current: pageNumber + 1,
            showLessItems: true,
            hideOnSinglePage: true
          }}
          columns={columns}
          dataSource={lemData && lemData}
          className="table-with-button-lem"
          scroll={{ x: lemData.length > 0 ? 1200 : 0, y: '100%' }}
          rowKey={(record) => record.id}
          loading={{
            indicator: <Spin />,
            spinning: showLoader
          }}
          onRow={(record) => ({
            onClick: (e) => {
              e.stopPropagation();
              setLemModalVisible(true);
              setAssets(record.assets);
              setMembers(record.members);
            },
          })}
        />

      </div>
      {
          data && data.length > 0 && (
            // <div className="text-right mt-3 mb-2">
            <div className="d-flex pb-2 pt-3 align-items-center justify-content-between">
              <Space size={16}>
                <div className="d-flex align-items-center sbd-info">
                  <div className="sbd-badge badge-green" />
                  Signed Report
                </div>
                <div className="d-flex align-items-center sbd-info">
                  <div className="sbd-badge badge-white" />
                  Signature Pending Report
                </div>
                <div className="d-flex align-items-center sbd-info">
                  {/* <div className="sbd-badge badge-white" /> */}
                  Total Reports :
                  {' '}
                  {total}
                </div>
              </Space>
              <div className="d-flex">
                <Button
                  key="submit"
                  size="small"
                  shape="round"
                  type="primary"
                  disabled={!(projectDocSignRequired)}
                  onClick={() => { setDateSelectionModalVisible(true); dateForm.resetFields(); }}
                >
                  eSign Reports
                </Button>
              </div>
            </div>
          )
        }
      <LemModal
        modalVisible={lemModalVisible}
        hideModal={hideModal}
        assets={assets}
        members={members}
      />
      <DateSelectionModal
        projectContractor={projectContractor}
        projectManagerEmail={projectManagerEmail}
        modalVisible={dateSelectionModalVisible}
        hideModal={hideModal}
        pdfDates={pdfDates}
        setPdfDates={setPdfDates}
        sendMailForSignature={sendMailForSignature}
        esignMailModalLoading={esignMailModalLoading}
        lemData={lemData}
        dailyReportData={dailyReportData}
        setDailyReportData={setDailyReportData}
        dateForm={dateForm}
        setSelectedWeeklyReportRows={setSelectedWeeklyReportRows}
        selectedWeeklyReportRows={selectedWeeklyReportRows}
        selectedRowKey={selectedRowKey}
        setSelectedRowKey={setSelectedRowKey}
      />
    </>

  );
};

LemReport.propTypes = {
  onGetLemReports: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showLoader: bool.isRequired,
};

const mapStateToProps = ({ lemRequests }) => {
  const data = get(lemRequests, 'ldata', []);
  const showLoader = get(lemRequests, 'isLoading', false);
  const total = get(lemRequests, 'total', 0);
  return {
    data,
    showLoader,
    total
  };
};

const mapDispatchToProps = {
  onGetLemReports: getLemReports
};

export default connect(mapStateToProps, mapDispatchToProps)(LemReport);
