import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { setQualityReports, setQualityReportFailure } from '../state/action/qualityAction';
import { GET_QUALITY_REPORTS, SUBMIT_STATUS } from '../state/types';
import fetch from '../services/fetch';
import { BASE_URL } from '../config';

function* getReports(projectNo) {
  let status = 0;
  try {
    console.log('test', projectNo.projectNo);
    const projNo = encodeURIComponent(projectNo.projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = yield fetch(`${BASE_URL}/reports?projectNumber=${projNo}&type=Quality`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      status = res.status;
      throw new Error('Unauthorized');
    }
    const response = yield res.json();
    yield put(setQualityReports({ response }));
  } catch (error) {
    yield put(setQualityReportFailure());
    if (status !== 403) {
      notification.error({
        message: 'There was a problem fetching the report details.',
        description: `${error}`,
      });
    }
  }
}

function* submitReports({
  data, projectNo
}) {
  try {
    console.log('projectNo', projectNo);
    const res = yield fetch(`${BASE_URL}/report-event`, {
      method: 'POST',
      headers: {},
      body: JSON.stringify(data),
    });
    notification.destroy();
    if (res.status === 200) {
      yield getReports({ projectNo });
      notification.success({
        message: 'Status Updated Successfully.',
      });
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    notification.destroy();
    notification.error({
      message: 'Report Submission Failed.',
      description: `${error}`,
    });
  }
}

function* qualityReportWatcher() {
  yield takeLatest(GET_QUALITY_REPORTS, getReports);
  yield takeLatest(SUBMIT_STATUS, submitReports);
}

function* qualityReportSagas() {
  yield all([
    call(qualityReportWatcher),
  ]);
}

export default qualityReportSagas;
