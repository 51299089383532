import React from 'react';
import './style.scss';
import 'antd/dist/antd.dark.css';
import ComingSoon from '../../Components/Comingsoon';

const LogReport = () => (
  <ComingSoon />

);

export default LogReport;
