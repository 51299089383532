/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import React, { useState, } from 'react';
import {
  Typography, Row, Col,
} from 'antd';
import { connect } from 'react-redux';
import get from 'lodash/get';
import PersonnelModal from './PersonnnelOverall';
import { getTeamMembers } from '../state/action/teamMembers';
import SafetyOverallModal from './SafetyOverallModal';
import ProductivityModalOverall from './ProductivityModalOverall';
import QualityModalOverall from './qualityModalOverall';
import DownTimeModalOverall from './DownTimeModalOverall';
import InventoryDPROverall from './inventoryDPROverall';

const { Text } = Typography;

const SafetySummaryOverall = ({ projNo }) => {
  const [modalVisibility, setmodalVisibility] = useState(false);
  const [proModalVisibility, setProModalVisibility] = useState(false);
  const [quaModalVisibility, setQuaModalVisibility] = useState(false);
  const [downTimeVisibility, setDownTimeVisibility] = useState(false);
  const [invModalVisibility, setInventoryVisibility] = useState(false);

  const [perModalVisibility, setPerModalVisibility] = useState(false);
  const onSafetyOnClick = () => setmodalVisibility(true);
  const onProductivityOnClick = () => setProModalVisibility(true);
  const onQualityOnClick = () => setQuaModalVisibility(true);
  const onPersonnelOnClick = () => setPerModalVisibility(true);
  const onDownTimeOnClick = () => setDownTimeVisibility(true);
  const onInventoryOnClick = () => setInventoryVisibility(true);

  return (

    <>
      <Row gutter={[16, 16]} className="pt-3">
        <Col md={12} lg={8} xl={8} className="px-2">
          <div
            onClick={() => onSafetyOnClick()}
            onKeyDown={() => onSafetyOnClick()}
            role="button"
            tabIndex={0}
            className="custom-card card-height d-flex flex-column
            align-items-center justify-content-center  has-pointer position-relative"
          >
            <Text className="mb-1 theme-color">
              Safety

            </Text>
          </div>
          <SafetyOverallModal
            visible={modalVisibility}
            projNo={projNo}
            setVisible={(value) => {
              setmodalVisibility(false);
            }}
          />

        </Col>
        <Col md={12} lg={8} xl={8} className="px-2">
          <div
            onClick={() => onQualityOnClick()}
            onKeyDown={() => onQualityOnClick()}
            role="button"
            tabIndex={0}
            className="custom-card card-height d-flex flex-column align-items-center justify-content-center position-relative"
          >
            <Text className="mb-1 theme-color">
              Quality

            </Text>
          </div>
          <QualityModalOverall
            visible={quaModalVisibility}
            setVisible={(value) => {
              setQuaModalVisibility(false);
            }}
            projNo={projNo}
          />

        </Col>
        <Col md={12} lg={8} xl={8} className="px-2">
          <div
            onClick={onProductivityOnClick}
            onKeyDown={() => onProductivityOnClick()}
            role="button"
            tabIndex={0}
            className="custom-card card-height d-flex flex-column align-items-center justify-content-center position-relative"
          >
            <Text className="mb-1 theme-color">
              Productivity

            </Text>
          </div>
          <ProductivityModalOverall
            visible={proModalVisibility}
            setVisible={(value) => {
              setProModalVisibility(false);
            }}
            projNo={projNo}
          />

        </Col>
        <Col md={12} lg={8} xl={8} className="px-2">

          <div
            onClick={onPersonnelOnClick}
            onKeyDown={() => onPersonnelOnClick()}
            role="button"
            tabIndex={0}
            className="custom-card card-height d-flex flex-column align-items-center justify-content-center position-relative"
          >

            <Text className="mb-1 theme-color">
              Personnel
            </Text>
          </div>
          <PersonnelModal
            visible={perModalVisibility}
            setVisible={(value) => {
              setPerModalVisibility(false);
            }}
            projNo={projNo}
          />

        </Col>
        <Col md={12} lg={8} xl={8} className="px-2">
          <div
            onClick={onDownTimeOnClick}
            className="custom-card card-height d-flex flex-column align-items-center justify-content-center position-relative"
          >
            <Text className="mb-1 theme-color">
              DownTime
            </Text>
          </div>
          <DownTimeModalOverall
            visible={downTimeVisibility}
            projNo={projNo}
            isToday={false}
            setVisible={(value) => {
              setDownTimeVisibility(false);
            }}
          />
        </Col>
        <Col md={12} lg={8} xl={8} className="px-2">
          <div
            onClick={onInventoryOnClick}
            className="custom-card card-height d-flex flex-column align-items-center justify-content-center position-relative"
          >
            <Text className="mb-1 theme-color">
              Inventory
            </Text>
          </div>
          <InventoryDPROverall
            visible={invModalVisibility}
            projNo={projNo}
            setVisible={(value) => {
              setInventoryVisibility(false);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = ({ teamMembers }) => {
  const data = get(teamMembers, 'data', []);
  const showLoader = get(teamMembers, 'isLoading', false);
  return {
    data,
    showLoader,
  };
};
const mapDispatchToProps = {
  onGetTeamMembers: getTeamMembers
};

export default connect(mapStateToProps, mapDispatchToProps)(SafetySummaryOverall);
