import get from 'lodash/get';
import {
  SIGNINSUCCESS, SIGNINFAILURE, SIGNIN
} from '../types';

const initialState = {
  isLoading: false,
  isLogin: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SIGNINSUCCESS: {
      const authChallenge = get(action, ['res', 'challengeName'], '');
      const challengeName = authChallenge;
      localStorage.setItem('approvalConfirmViewed', false);
      return {
        ...state,
        challengeName,
        isLoading: false,
      };
    }
    case SIGNINFAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default authReducer;
