import { notification } from 'antd';
import fetch from './fetch';
import { BASE_URL } from '../config';

export const updateProjectDetail = async (data) => {
  try {
    const res = await fetch(`${BASE_URL}/project/update-project`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {},
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};
