/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import {
  Typography, Row, Col, notification, Switch
} from 'antd';
import { connect } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import { CheckCircleOutlined } from '@ant-design/icons';
import { getTeamMembers } from '../state/action/teamMembers';
import SafetyModal from './SafetyModal';
import DownTimeModal from './DownTimeModal';
import PersonnelModal from './PersonnelModal';
import InventoryDPRToday from './inventoryDPRToday';

import ProductivityModal from './ProductivityModal';
import QualityModal from './qualityModal';
import { getSummaryData } from '../services/summary';
import { shift as shiftData } from '../utils/constants';

const { Text } = Typography;

const SafetySummaryToday = ({ projNo, reload, setIsLoading }) => {
  const [modalVisibility, setmodalVisibility] = useState(false);

  const [perModalVisibility, setPerModalVisibility] = useState(false);
  const [proModalVisibility, setProModalVisibility] = useState(false);
  const [downTimeVisibility, setDownTimeVisibility] = useState(false);
  const [quaModalVisibility, setQuaModalVisibility] = useState(false);
  const [invModalVisibility, setInventoryVisibility] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const onSafetyOnClick = () => setmodalVisibility(true);
  const onPersonnelOnClick = () => setPerModalVisibility(true);
  const onDownTimeOnClick = () => setDownTimeVisibility(true);
  const onInventoryOnClick = () => setInventoryVisibility(true);
  const onProductivityOnClick = () => setProModalVisibility(true);
  const onQualityOnClick = () => setQuaModalVisibility(true);
  const [shift, setShift] = useState(shiftData.day);

  const getData = async (shiftValue) => {
    setIsLoading(true);
    try {
      const date = moment(new Date()).format('YYYY-MM-DD');
      const res = await getSummaryData(projNo, date, shiftValue);
      setSummaryData(res);
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (projNo) getData(shift);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo]);

  useEffect(() => {
    if (reload) getData(shift);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const onChange = (checked) => {
    const newShift = checked === true ? shiftData.night : shiftData.day;
    setShift(newShift);
    getData(newShift);
  };
  return (
    <>
      <Row gutter={[16, 16]} className="pt-3">
        <Col md={12} lg={8} xl={8} className="px-2">
          <div
            onClick={onSafetyOnClick}
            className="custom-card card-height d-flex flex-column
            align-items-center justify-content-center  has-pointer position-relative"
          >
            <Text className="mb-1 theme-color">
              Safety
            </Text>
            {summaryData && summaryData.safetyData && summaryData.safetyData !== 0 ? (<CheckCircleOutlined style={{ color: '#95de64' }} />
            ) : <></>}
          </div>
          <SafetyModal
            visible={modalVisibility}
            projNo={projNo}
            setVisible={(value) => {
              setmodalVisibility(false);
            }}
            shift={shift}
          />
        </Col>
        <Col md={12} lg={8} xl={8} className="px-2">
          <div
            onClick={() => onQualityOnClick()}
            onKeyDown={() => onQualityOnClick()}
            role="button"
            tabIndex={0}
            className="custom-card card-height d-flex flex-column align-items-center justify-content-center position-relative"
          >
            <Text className="mb-1 theme-color">
              Quality

            </Text>

            {summaryData && summaryData.qualityPipelineEntryData && summaryData.qualityPipelineEntryData !== 0 ? (<CheckCircleOutlined style={{ color: '#95de64' }} />
            ) : <></>}

          </div>
          <QualityModal
            visible={quaModalVisibility}
            setVisible={(value) => {
              setQuaModalVisibility(false);
            }}
            projNo={projNo}
            isToday
            shift={shift}
          />

        </Col>
        <Col md={12} lg={8} xl={8} className="px-2 pe-2">
          <div
            onClick={onProductivityOnClick}
            className="custom-card card-height d-flex flex-column align-items-center justify-content-center position-relative"
          >
            <Text className="mb-1 theme-color">
              Productivity

            </Text>
            {summaryData && summaryData.productivityPipelineEntryData && summaryData.productivityPipelineEntryData !== 0 ? (<CheckCircleOutlined style={{ color: '#95de64' }} />
            ) : <></>}

          </div>
          <ProductivityModal
            visible={proModalVisibility}
            setVisible={(value) => {
              setProModalVisibility(false);
            }}
            projNo={projNo}
            isToday
            shift={shift}
          />

        </Col>
        <Col md={12} lg={8} xl={8} className="px-2">
          <div
            onClick={onPersonnelOnClick}
            className="custom-card card-height d-flex flex-column align-items-center justify-content-center position-relative"
          >
            <Text className="mb-1 theme-color">
              Personnel

            </Text>
            {summaryData && summaryData.personalEntryData && summaryData.personalEntryData !== 0 ? (<CheckCircleOutlined style={{ color: '#95de64' }} />
            ) : <></>}

          </div>
          <PersonnelModal
            visible={perModalVisibility}
            setVisible={(value) => {
              setPerModalVisibility(false);
            }}
            projNo={projNo}
            isToday
            shift={shift}
          />
        </Col>
        <Col md={12} lg={8} xl={8} className="px-2">
          <div
            onClick={onDownTimeOnClick}
            className="custom-card card-height d-flex flex-column align-items-center justify-content-center position-relative"
          >
            <Text className="mb-1 theme-color">
              DownTime

            </Text>
            {summaryData && summaryData.downtimeEntriesData && summaryData.downtimeEntriesData !== 0 ? (<CheckCircleOutlined style={{ color: '#95de64' }} />
            ) : <></>}

          </div>
          <DownTimeModal
            visible={downTimeVisibility}
            projNo={projNo}
            isToday
            setVisible={(value) => {
              setDownTimeVisibility(false);
            }}
            shift={shift}
          />
        </Col>
        <Col md={12} lg={8} xl={8} className="px-2">
          <div
            onClick={onInventoryOnClick}
            className="custom-card card-height d-flex flex-column align-items-center justify-content-center position-relative"
          >
            <Text className="mb-1 theme-color">
              Inventory
            </Text>
            {summaryData && summaryData.inventoryData && summaryData.inventoryData !== 0 ? (<CheckCircleOutlined style={{ color: '#95de64' }} />
            ) : <></>}

          </div>
          <InventoryDPRToday
            visible={invModalVisibility}
            projNo={projNo}
            isToday
            setVisible={(value) => {
              setInventoryVisibility(false);
            }}
            shift={shift}
          />
        </Col>
      </Row>
      <Switch
        defaultChecked
        checkedChildren="Night"
        unCheckedChildren="Day"
        onChange={onChange}
        checked={shift === 1}
      />
    </>
  );
};

const mapStateToProps = ({ teamMembers }) => {
  const data = get(teamMembers, 'data', []);
  const showLoader = get(teamMembers, 'isLoading', false);
  return {
    data,
    showLoader,
  };
};

const mapDispatchToProps = {
  onGetTeamMembers: getTeamMembers
};

export default connect(mapStateToProps, mapDispatchToProps)(SafetySummaryToday);
