/* eslint-disable linebreak-style */
import {
  FETCH_AWS_CONFIG_DATA,
  SET_AWS_CONFIG_DATA,
  SET_AWS_CONFIG_DATA_FAILURE
} from '../types';

const getAwsConfigData = () => ({
  type: FETCH_AWS_CONFIG_DATA
});

const setAwsConfigData = (payload) => ({
  type: SET_AWS_CONFIG_DATA, ...payload,
});

const setAwsConfigDataFailure = () => ({
  type: SET_AWS_CONFIG_DATA_FAILURE,
});

export {
  getAwsConfigData,
  setAwsConfigData,
  setAwsConfigDataFailure
};
