import get from 'lodash/get';
import {
  SET_QUALITY_REPORTS,
  GET_QUALITY_REPORTS,
  SET_QUALITY_REPORT_FAILURE,
  SET_STATUS,
  SUBMIT_STATUS,
} from '../types';

const initialState = {
  isLoading: false,
};

const qualityReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUALITY_REPORTS: {
      const qReports = get(action, ['response', 'items'], []);
      const data = [];
      for (let i = 0; i < qReports.length; i += 1) {
        const qData = get(qReports[i], 'data', {});
        const feedbackStatus = qData.feedback_status ? qData.feedback_status : 'todo';
        data[i] = {
          reportId: get(qReports[i], 'id', '-'),
          type: get(qReports[i], 'type', '-'),
          guid: get(qReports[i], 'guid', '-'),
          pdf: get(qReports[i], 'pdf_url', ''),
          projectNo: get(qData, 'project_number', '-'),
          projectName: get(qData, 'project_name', '-'),
          author: get(qReports[i], 'author', '-'),
          lead: get(qData, 'lead_tech', '-'),
          contractor: get(qData, 'contractor', '-'),
          projectStatus: get(qData, 'project_status', '-'),
          serialNo: get(qData, 'serial', '-'),
          equipment: get(qData, 'description', '-'),
          reason: get(qData, 'inspection_reason', '-'),
          partNo: get(qData, 'part_no', '-'),
          repairHours: get(qData, 'repair_hours', '-'),
          comments: get(qData, 'notes', '-'),
          attachments: get(qData, 'attachments', ''),
          status: feedbackStatus,
          notes: get(qData, 'feedback_notes', ''),
          lastUpdatedAt: get(qReports[i], 'updatedAt', '-'),
        };
        const { issues } = qData;
        const j = issues ? issues.length - 1 : -1;
        if (j >= 0) {
          data[i] = {
            ...data[i],
            serialNo: get(issues[j], 'serial', '-'),
            equipment: get(issues[j], 'description', '-'),
            reason: get(issues[j], 'inspection_reason', '-'),
            partNo: get(issues[j], 'part_no', '-'),
            repairHours: get(issues[j], 'repair_hours', '-'),
            comments: get(issues[j], 'notes', '-'),
            attachments: get(issues[j], 'attachments', '-'),
          };
        }
      }
      return {
        ...state,
        data,
        isLoading: false,
      };
    }

    case GET_QUALITY_REPORTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_QUALITY_REPORT_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case SET_STATUS: {
      const { reportId, updatedData } = action;
      const index = state.data.findIndex((entry) => entry.reportId === reportId);
      const newRecords = state.data;
      newRecords[index] = {
        ...newRecords[index],
        ...updatedData,
      };

      return {
        ...state,
        data: [
          ...newRecords,
        ],
      };
    }

    case SUBMIT_STATUS: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
};

export default qualityReportReducer;
