/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import {
  Modal, Button, Table, notification, Spin, Tabs
} from 'antd';
import { getInventoryData } from '../services/summary';
import { getPipeline } from '../services/pipeline';

const { TabPane } = Tabs;
const columns = [
  { title: 'Date', dataIndex: 'date', key: 'date' }
];

const innerTableColumns = [
  {
    title: 'Part Number',
    dataIndex: 'serial_number',
    key: 'serial_number',
    align: 'center'
  },
  {
    title: 'Description',
    dataIndex: 'material_description',
    key: 'material_description',
    align: 'center'
  },
  {
    title: 'Day',
    dataIndex: 'dayConsumedQuantity',
    key: 'dayConsumedQuantity',
    align: 'center'
  },
  {
    title: 'Night',
    dataIndex: 'nightConsumedQuantity',
    key: 'nightConsumedQuantity',
    align: 'center'
  },
  {
    title: 'Overall',
    dataIndex: 'overallConsumedQuantity',
    key: 'overallConsumedQuantity',
    align: 'center'
  }
];

const InventoryDPRToday = (props) => {
  const {
    visible, setVisible, projNo,
  } = props;
  const [loading, setLoading] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [innerTableData, setinnerTableData] = useState([]);
  const [pipelineData, setPipelineData] = useState([]);
  const [tabValue, setTabValue] = useState('');

  const getData = async () => {
    setLoading(true);
    try {
      const res = await getInventoryData(projNo, '', '', tabValue);
      if (res.data) {
        const inventData = Object.keys(res.data)
          .sort((a, b) => new Date(b) - new Date(a))
          .map((item, index) => ({
            key: index,
            date: item
          }));
        setInventoryData(inventData);
        setinnerTableData(res.data);
      } else {
        setInventoryData([]);
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible) getData();
    return () => {
      setInventoryData([]);
      setinnerTableData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, tabValue]);

  const getTableColumns = async () => {
    try {
      const res = await getPipeline(projNo);
      if (res?.pipelineData && res?.pipelineData.length > 0) {
        const pipelineFilteredData = res?.pipelineData.filter((item) => item.is_deleted === 0);
        setPipelineData(pipelineFilteredData);
        setTabValue(pipelineFilteredData[0] && pipelineFilteredData[0]?.id ? pipelineFilteredData[0]?.id : '');
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  useEffect(() => () => {
    setTabValue('');
    setPipelineData([]);
  }, []);

  const onTabChange = (activeKey) => {
    setTabValue(activeKey);
  };

  useEffect(() => {
    if (projNo && visible) {
      getTableColumns();
    }
  }, [projNo, visible]);

  const expandedRowRender = (record) => {
    const dataSource = innerTableData && innerTableData[record.date] ? innerTableData[record.date]
      .map((element) => {
        const dayData = element.consumedQuantity && element.consumedQuantity.dayCheckOut
          ? element.consumedQuantity.dayCheckOut : 0;
        const nightData = element.consumedQuantity && element.consumedQuantity.nightCheckOut
          ? element.consumedQuantity.nightCheckOut : 0;
        const overallConsumedQuantity = dayData + nightData;
        return {
          dayConsumedQuantity: dayData,
          nightConsumedQuantity: nightData,
          overallConsumedQuantity,
          ...element
        };
      }) : [];

    return (
      <Table
        bordered
        pagination={false}
        columns={innerTableColumns}
        dataSource={dataSource}
        style={{ whiteSpace: 'nowrap' }}
        scroll={{ x: true }}
        className="table-with-button-assets fsa-personal"
        loading={{
          indicator: <Spin />,
          spinning: loading
        }}
      />
    );
  };
  return (
    <>
      <Modal
        title="Inventory"
        className="welds-modal"
        centered
        visible={visible}
        width={1009}
        destroyOnClose
        footer={[
          <Button key="submit" type="primary" onClick={() => { setVisible(false); }}>
            OK
          </Button>
        ]}
      >
        <div>
          <div className="flex-fill overflow-auto">
            {
              pipelineData && pipelineData.length > 0 && (
                <Tabs disabled={loading} activeKey={tabValue.toString()} onChange={onTabChange} className="custom-tab h-100">
                  {
                    pipelineData.map((item) => (
                      <TabPane tab={item.name} key={item.id} value={item.id} />
                    ))
                  }
                </Tabs>
              )
            }
          </div>
          <Table
            columns={columns}
            expandable={{ expandedRowRender }}
            dataSource={inventoryData}
            className="table-with-button-assets"
            pagination={false}
            style={{ whiteSpace: 'nowrap' }}
            scroll={{ x: true }}
            loading={{
              indicator: <Spin />,
              spinning: loading
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default InventoryDPRToday;
