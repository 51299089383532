/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-trailing-spaces */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Modal, Button, Table
} from 'antd';
import * as constants from '../utils/constants';
import { calcReportingPeriod } from '../services/calendar';

const ViewInventoryReportModal = (props) => {
  const {
    isReportModalVisible, inventoryReportDt, hideModal
  } = props;
  const [newData, setNewData] = useState(false);

  const columns = [
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      align: 'center'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center'
    },
    {
      title: 'Created At',
      dataIndex: 'reportCreatedDate',
      key: 'reportCreatedDate',
      align: 'center',
      width: '180px'
    },
    {
      title: 'Report Period',
      dataIndex: 'reportPeriod',
      key: 'reportPeriod',
      align: 'center',
      width: '180px'
    },
    {
      title: 'Excel Report',
      key: 'excelReportUrl',
      dataIndex: 'excelReportUrl',
      render: (record) => {
        if (record) {
          return (
            <div>
              {' '}
              <a
                className="export-link"
                title="Download excel report"
                target="_blank"
                rel="noopener noreferrer"
                href={record}
                // eslint-disable-next-line react/jsx-closing-bracket-location
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span className="icon-16 mr-2">
                  <svg>
                    <use
                      xlinkHref="#export"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </svg>
                </span>
              </a>
            </div>
          );
          // eslint-disable-next-line brace-style
        }
        // eslint-disable-next-line no-else-return
        else {
          return <div>-</div>;
        }
      },
      align: 'center',
      width: '100px'
    },
    {
      title: 'PDF Report',
      key: 'pdfReportUrl',
      dataIndex: 'pdfReportUrl',
      render: (record) => {
        if (record) {
          return (
            <div>
              {' '}
              <a
                className="export-link"
                title="Download pdf report"
                target="_blank"
                rel="noopener noreferrer"
                href={record}
                // eslint-disable-next-line react/jsx-closing-bracket-location
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span className="icon-16 mr-2">
                  <svg>
                    <use
                      xlinkHref="#export"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </svg>
                </span>
              </a>
            </div>
          );
          // eslint-disable-next-line brace-style
        }
        // eslint-disable-next-line no-else-return
        else {
          return <div>-</div>;
        }
      },
      align: 'center',
      width: '100px'
    }
  ];

  useEffect(() => {
    const newData = [];
    inventoryReportDt.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    inventoryReportDt.map((item) => {
      item.createdAt = moment(item.createdAt).format(
        constants.DATETIMESEC
      );
      const reportingPeriod = calcReportingPeriod(item.period);
      const splitDate = reportingPeriod.split(' - ');
        const startDate = new Date(splitDate[0]);
        const endDate = new Date(splitDate[1]);
      const formatReportingPeriod = `${moment(startDate).format(constants.DATE)} to ${moment(endDate).format(constants.DATE)}`;
      item.createdAt = item.createdAt.replaceAll('/', '-');
        newData.push({
          reportCreatedDate: item.createdAt,
          excelReportUrl: item.excel_url,
          pdfReportUrl: item.pdf_url,
          createdBy: item.author,
          email: item.email,
          reportPeriod: formatReportingPeriod.replaceAll('/', '-')
        });
    });
    
    setNewData(newData);
  }, [inventoryReportDt]);

  return (
    <>
      <Modal
        title="Inventory Report"
        className="welds-modal"
        centered
        visible={isReportModalVisible}
        width={1000}
        footer={[
          <Button key="submit" type="primary" onClick={() => hideModal()}>
            OK
          </Button>
        ]}
      >
        <div>
          <div className="ant-table scroll-table">
            <div className="ant-table-container">
              <div className="ant-table-content">
                <Table
                  pagination={false}
                  columns={columns}
                  dataSource={newData && newData}
                  scroll={{ x: newData.length > 0 ? 600 : 0, y: '100%' }}
                />
              </div>
            </div>
          </div>
        </div>
        
      </Modal>
    </>
  );
};

export default ViewInventoryReportModal;
