/* eslint-disable no-constant-condition */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
  Button, Form, Input, notification
} from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { forgotPassword, forgotPasswordSubmit, } from '../services/auth';
import * as constants from '../utils/constants';

const ForgotPasswordForm = () => {
  const [forgotPasswordForm] = Form.useForm();
  const [sendCode, setSendCode] = useState(false);
  const [email, setEmail] = useState('');

  const validateCreds = () => {
    forgotPasswordForm
      .validateFields()
      .then(async (creds) => {
        if (!sendCode) {
          setEmail(creds.email.toLowerCase());
        }
        // eslint-disable-next-line no-unused-vars
        const res = !sendCode
          ? await forgotPassword(creds.email.toLowerCase())
          : await forgotPasswordSubmit(email, creds.verficationCode, creds.newPassword);
        setSendCode(true);
        forgotPasswordForm.resetFields();
      });
  };

  const resendCode = async () => {
    await forgotPassword(email);
  };

  const changePass = (data) => {
    try {
      if (data.newPassword === data.confirmPassword) {
        validateCreds();
      } else {
        notification.warning({
          message: 'Passwords do not match',
        });
      }
    } catch (err) {
      notification.warning({
        message: 'Something went wrong.',
      });
    }
  };

  const validatePass = () => {
    forgotPasswordForm
      .validateFields()
      .then(async (data) => {
        await changePass(data);
      });
  };

  return (
    <div className="login-form">
      {sendCode ? <h1 className="mb-2 text-center"> Reset Password</h1> : <h1 className="mb-5 text-center"> Forgot password</h1>}
      {sendCode ? <p className="resetPassword fw-bold mb-5 text-center">Enter the verification code sent to your email</p> : null}
      <Form
        form={forgotPasswordForm}
        name="normal_login"
        initialValues={{
          remember: true,
        }}
      >
        {!sendCode ? (
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter your Registered Email.',
              },
              {
                pattern: true
                  ? constants.EMAIL_PATTERN
                  : undefined,
                message: 'Provide a valid email.',
              }
            ]}
          >
            <Input size="large" placeholder="Enter your Registered Email" />
          </Form.Item>
        ) : (
          <>
            <Form.Item
              name="verficationCode"
              rules={[
                {
                  required: false,
                  message: 'Please enter the Verfication Code.',
                },
              ]}
            >
              <OtpInput
                className="verificationCode"
                isInputNum
                numInputs={6}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: 'Please enter New Password.',
                },
              ]}
            >
              <Input.Password
                className="password-icon"
                size="large"
                placeholder="New Password"
                iconRender={(visibility) => (visibility ? <EyeOutlined style={{ color: 'grey' }} /> : <EyeInvisibleOutlined style={{ color: 'grey' }} />)}
                autocomplete="new-password"
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password.',
                },
              ]}
            >
              <Input.Password
                size="large"
                className="password-icon"
                placeholder="Confirm New Password"
                iconRender={(visibility) => (visibility ? <EyeOutlined style={{ color: 'grey' }} /> : <EyeInvisibleOutlined style={{ color: 'grey' }} />)}
                autocomplete="new-password"
              />
            </Form.Item>
            <Form.Item>
              {' '}
              <Form.Item name="remember" noStyle>
                {' '}
                <span className="resendMail">Didn't receive the mail?</span>
                {' '}
              </Form.Item>
              {' '}
              <Button
                type="link"
                className="login-form-forgot float-end fw-medium"
                onClick={resendCode}
              >
                Resend

              </Button>
              {' '}
            </Form.Item>
          </>

        )}
        <Form.Item>
          <Button
            size="large"
            shape="round"
            block
            htmlType="submit"
            className="login-form-button"
            onClick={validatePass}
          >
            {sendCode ? 'Submit' : 'Send Code' }
          </Button>
        </Form.Item>
        <Button
          size="large"
          shape="round"
          block
          htmlType="submit"
          className="login-form-button"
        >
          <Link to="/login">Back to Login</Link>
        </Button>
      </Form>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const isLogin = get(auth, 'isLogin', false);
  return {
    isLogin,
  };
};

export default connect(mapStateToProps)(ForgotPasswordForm);
