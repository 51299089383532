/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Line } from '@ant-design/plots';
import * as constants from '../utils/constants';

const ModalGraph = (props) => {
  const { crewName, currentReport } = props;
  const graphData = [];
  const [data, setData] = useState([]);

  const compare = (a, b) => {
    const date1 = new Date(a.date);
    const date2 = new Date(b.date);
    if (date1 < date2) {
      return -1;
    }
    if (date1 > date2) {
      return 0;
    }
    return 0;
  };

  useEffect(() => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < Object.keys(currentReport?.entries || {})?.length; i++) {
      let weld = 0;
      let repair = 0;
      let date = null;
      if (new Date(currentReport?.entries[i]?.date) <= new Date()) {
        date = moment(currentReport?.entries[i]?.date).format(constants.DATE).replaceAll('/', '-');
      }
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < Object.keys(currentReport?.crews || {})?.length; j++) {
        if (crewName && currentReport?.entries[i]?.[j]?.crewName === crewName) {
          if (currentReport?.entries[i]?.[j]?.welds) {
            weld = currentReport?.entries[i]?.[j].welds;
          }
          if (currentReport?.entries[i]?.[j]?.repairs) {
            repair = currentReport?.entries[i]?.[j].repairs;
          }
        }
        if (!crewName) {
          if (currentReport?.entries[i]?.[j]?.welds) {
            weld += currentReport?.entries[i]?.[j].welds;
          }
          if (currentReport?.entries[i]?.[j]?.repairs) {
            repair += currentReport?.entries[i]?.[j].repairs;
          }
        }
      }
      graphData.push({ date, total: weld, value: 'Total Weld' });
      graphData.push({ date, total: repair, value: 'Total Repair' });
    }
    graphData.sort(compare);
    setData(graphData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport]);

  const config = {
    autoFit: true,
    height: 1,
    data,
    xField: Object.keys(currentReport?.entries || {})?.length > 0 ? 'date' : '',
    yField: 'total',
    seriesField: 'value',
    tooltip: {
      showMarkers: true
    },
    point: {
      shape: 'breath-point'
    },
    yAxis: {
      grid: { line: { style: { stroke: '' } } }
    },
    color: ['#FF0000', '#FFDB0A'],
  };

  return (
    <div className="h-100 chart-wrapper">
      {currentReport && currentReport.reportingPeriod

        ? (
          <Line {...config} />
        ) : (
          <Line {...config} />
        )}
    </div>

  // eslint-disable jsx-props-no-spreading

  // eslint-disable-next-line react/jsx-props-no-spreading
  );
};
export default ModalGraph;
