/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
/* eslint-disable linebreak-style */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable semi-spacing */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  func, arrayOf, objectOf, oneOfType, string, number, array
} from 'prop-types';
import moment from 'moment';
import {
  Empty, Typography, Row, Col, Table, Button, notification, Tooltip, Spin
} from 'antd';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import WeldesModal from './WeldesModal';
import ProdGraph from '../layout/graph';
import * as constants from '../utils/constants';
import { datesInPeriod } from '../services/calendar';
import { getProdReports } from '../state/action/prodLogAction';
import {
  prepareDataForCombinedExcel,
  writeWorkSheetForCombined
} from '../utils/utility';

const { Title, Text } = Typography;
const headercolumns = [
  {
    title: 'Reporting Period',
    dataIndex: 'formatReportingPeriod',
    key: 'formatReportingPeriod',
    width: '220px',
  },
  {
    title: 'Welds',
    dataIndex: 'totalWelds',
    key: 'totalWelds',
    align: 'center',
    className: 'truncate',
    render: (text) => <Tooltip title={text}>{text}</Tooltip>
  },
  {
    title: 'Repair',
    dataIndex: 'totalRepairs',
    key: 'totalRepairs',
    align: 'center',
    className: 'truncate',
    render: (text) => <Tooltip title={text}>{text}</Tooltip>
  },
  {
    title: 'Rate',
    dataIndex: 'repairRate',
    key: 'repairRate',
    align: 'center',
    className: 'truncate',
    render: (text) => <Tooltip title={text}>{text}</Tooltip>
  },
  {
    title: 'Report',
    key: 'pdf',
    dataIndex: 'pdf',
    render: (record) => {
      if (record) {
        return (
          <div>
            {' '}
            <a
              className="export-link"
              title="Download pdf report"
              target="_blank"
              rel="noopener noreferrer"
              href={record}
              // eslint-disable-next-line react/jsx-closing-bracket-location
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <span className="icon-16 mr-2">
                <svg>
                  <use xlinkHref="#export" />
                </svg>
              </span>
            </a>
          </div>
        );
        // eslint-disable-next-line brace-style
      }
      // eslint-disable-next-line no-else-return
      else {
        return <div>-</div>;
      }
    },
    align: 'center',
  },
];

const Production = (props) => {
  /* eslint-disable react/prop-types */
  const {
    prodData, onGetProdReports, projNo, setCompletedWeldsCount, reload,
    setCurProdRep, setPrevProdRep, curProdReport, refresh, showProdLoader
  } = props;

  const [masterProjectData, setMasterProjectData] = useState([]);
  const [currentReports, setCurrentReports] = useState([]);
  const [previousReports, setPreviousReports] = useState([]);
  const [prodmodalvisible, setprodmodalvisible] = useState(false);
  const [currentReport, setCurrentReport] = useState({});
  const [prevReport, setPrevReport] = useState({});
  const [projectDetails, setProjectDetails] = useState([]);

  const formatCurrentDate = (currentDate) => {
    let dd = currentDate.getDate();
    let mm = currentDate.getMonth() + 1;
    const yyyy = currentDate.getFullYear();
    if (dd < 10) {
      dd = `0${dd}`;
    }
    if (mm < 10) {
      mm = `0${mm}`;
    }
    return `${yyyy}-${mm}-${dd}`;
  };

  useEffect(() => {
    if (reload) {
      if (projNo) {
        onGetProdReports({ projectNo: projNo });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    if (projectDetails !== []) {
      setMasterProjectData(projectDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetails]);

  useEffect(() => {
    if (projNo) {
      onGetProdReports({ projectNo: projNo });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo]);

  useEffect(() => {
    const projectArrayObj = [];
    if (prodData) {
      prodData.map((item) => {
        projectArrayObj.push(item);
      });
      setProjectDetails(projectArrayObj);
    }
  }, [prodData]);

  const setProductionReport = () => {
    if (projNo !== '') {
      setPrevReport([]);
      setCurrentReports([]);
      setCurrentReport({});
      setPreviousReports([]);
      const project = [];
      // eslint-disable-next-line array-callback-return
      masterProjectData.map((datas) => {
        if (datas.projectNo.toLowerCase() === projNo.toLowerCase()) {
        // eslint-disable-next-line no-param-reassign
          datas.reportingPeriod = datas.reportingPeriod.replace(' - ', ' to ');
          project.push(datas);
        }
      });
      if (project.length > 0) {
        const current = [];
        const today = formatCurrentDate(new Date());
        const curDate = new Date(today);
        const prevRepo = [];
        project.map((item, i) => {
          const splitDate = item.reportingPeriod.split(' to ');
          const startDate = new Date(splitDate[0]);
          const endDate = new Date(splitDate[1]);
          item.formatReportingPeriod = item.formatReportingPeriod.replaceAll('/', '-');
          if (curDate >= startDate && curDate <= endDate) {
            if (current.length > 0) {
              const currentLastUpdateDateTime = new Date(current[0].lastUpdatedAt);
              const itemLastUpdateDateTime = new Date(item.lastUpdatedAt);
              if (currentLastUpdateDateTime < itemLastUpdateDateTime) {
                setCurrentReport(item);
                setCurProdRep(item);
              }
            } else {
              current.push(item);
              setCurrentReport(item);
              setCurProdRep(item);
            }
          } else {
            prevRepo[i] = item;
          }
        });
        if (prevRepo.length > 0) {
          setPreviousReports(prevRepo);
          setPrevProdRep(prevRepo);
        } else {
          setPreviousReports([]);
        }
      } else {
        setCurrentReport({});
        setPreviousReports([]);
      }
      setCurrentReports(project);
    } else {
      setCurrentReports([]);
      setCurrentReport({});
      setPreviousReports([]);
    }
  };

  useEffect(() => {
    refresh(false);
    if (!reload) {
      setProductionReport();
    } else {
      setProductionReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo, masterProjectData]);

  useEffect(() => {
    let compWelds = 0;
    // eslint-disable-next-line array-callback-return
    currentReports.map((item) => {
      compWelds = compWelds + item.totalWelds;
    });
    setCompletedWeldsCount(compWelds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReports]);

  const modal = () => (
    <WeldesModal
      visible={prodmodalvisible}
      currentReport={Object.keys(prevReport).length > 0 ? prevReport : currentReport}
      setVisible={(value) => { setprodmodalvisible(value); setPrevReport([]); }}
      totalWelds={Object.keys(prevReport).length > 0
        ? (prevReport.totalWelds ? prevReport.totalWelds : 0)
        : (currentReport.totalWelds ? currentReport.totalWelds : 0)}
      totalRepairs={Object.keys(prevReport).length > 0
        ? (prevReport.totalRepairs ? prevReport.totalRepairs : 0)
        : (currentReport.totalRepairs ? currentReport.totalRepairs : 0)}
      repairRate={Object.keys(prevReport).length > 0
        ? (prevReport.repairRate ? prevReport.repairRate : 0)
        : (currentReport.repairRate ? currentReport.repairRate : 0)}
    />
  );

  const formatReportData = (report) => {
    const dates = datesInPeriod(report.period);
    const { entries } = report;
    const newdata = [];

    let recordFound = false;
    if (dates) {
      dates.forEach((day) => {
        for (let i = 0; i < entries.length; i += 1) {
          if (new Date(entries[i].date) <= new Date()) {
            if (entries[i].date === day) {
              let totalwelds = 0;
              let totalrepairs = 0;
              Object.keys(entries[i]).map((data) => {
                if (typeof entries[i][data] === 'object') {
                  if (!entries[i][data].welds) {
                    entries[i][data].welds = 0;
                  }
                  if (!entries[i][data].repairs) {
                    entries[i][data].repairs = 0;
                  }
                  totalwelds += entries[i][data].welds;
                  totalrepairs += entries[i][data].repairs;
                }
              });
              entries[i].totalWelds = totalwelds;
              entries[i].totalRepairs = totalrepairs;
              newdata.push({ ...entries[i], reportingPeriod: report.reportingPeriod });
              recordFound = true;
              break;
            }
          }
        }
        if (!recordFound) {
          let dummyCrew = [];
          if (report) {
            dummyCrew = report.crews.map((crew) => ({
              crewId: crew.id, crewName: crew.name, welds: undefined, repairs: undefined,
            }));
          }
          newdata.push({
            date: day, ...dummyCrew, comments: undefined, reportingPeriod: report.reportingPeriod
          });
          recordFound = false;
        }
        recordFound = false;
      });
    }
    return newdata;
  };

  const exportToExcel = async () => {
    try {
      if (Object.keys(currentReport).length > 0) {
        previousReports.unshift(currentReport);
      }
      const totalCrews = [];
      const totalData = [];
      previousReports.forEach((report) => {
        const formattedData = formatReportData(report);
        totalData.push(formattedData);
        totalCrews.push(report.crews);
      });
      const crewData = Array.prototype.concat.apply([], totalCrews);
      const totalFormattedData = Array.prototype.concat.apply([], totalData);
      const uniqueCrews = [...new Set(crewData.map((item) => item.name))];

      const workbook = new Excel.Workbook();
      const { data, columns } = prepareDataForCombinedExcel(uniqueCrews, totalFormattedData);
      let worksheet = workbook.addWorksheet(projNo.toString());
      worksheet.columns = columns;
      worksheet = writeWorkSheetForCombined(worksheet, uniqueCrews, data);

      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${projNo}-prodlog.xlsx`);
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  const TableCell = ({ className, cellData }) => (
    <Text className={className}>{cellData}</Text>
  );

  const TableHeaderCell = ({ className, column }) => (
    <Text className={className}>{column.title}</Text>
  );

  if (showProdLoader) {
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        <Spin size="large" tip="Loading data..." />
      </div>
    );
  }

  return (
    <>
      {currentReport && (
      <div className="d-flex h-100">
        <div className="flex-fill custom-fill overflow-auto">
          <div className="d-flex flex-column h-100">
            <div className="flex-auto">
              {currentReport && (
              <div className="d-flex mb-3 align-items-center">
                <Title className="mb-0" level={4}>
                  Current Reporting Period
                </Title>
                <Text className="ps-3">
                  {currentReport && currentReport.formatReportingPeriod}
                </Text>
              </div>
              )}

              {currentReport && <div className="custom-line" />}
            </div>

            <div className="flex-fill border-right overflow-auto">
              <div className="d-flex flex-column h-100">
                <div className="flex-auto px-3">
                  {currentReport && (
                  <>
                    <Row gutter={[16, 16]} className="pt-3">
                      <Col md={12} lg={8} xl={8} className="ps-0">
                        <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center position-relative">
                          <Text className="mb-1 theme-color">
                            Total Welds
                            {modal()}
                          </Text>
                          <Title
                            onClick={() => {
                              setprodmodalvisible(true);
                              setCurrentReport(curProdReport);
                            }}
                            className="my-0 theme-color fw-bold truncate sbd-tooltip"
                            level={2}
                          >

                            <Tooltip title={currentReport && currentReport.totalWelds} placement="topLeft">
                              {currentReport && currentReport.totalWelds}
                            </Tooltip>

                          </Title>
                        </div>
                      </Col>
                      <Col md={12} lg={8} xl={8}>
                        <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center">
                          <Text className="mb-1 theme-color">Repair</Text>
                          <Title
                            className="my-0 theme-color fw-bold truncate"
                            level={2}
                          >
                            <Tooltip title={currentReport && currentReport.totalRepairs} placement="topLeft">
                              {currentReport && currentReport.totalRepairs}
                            </Tooltip>
                          </Title>
                        </div>
                      </Col>
                      <Col md={12} lg={8} xl={8}>
                        <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center">
                          <Text className="mb-1 theme-color">
                            Repair Rate
                          </Text>
                          <Title
                            className="my-0 theme-color fw-bold truncate"
                            level={2}
                          >
                            <Tooltip title={currentReport && currentReport.repairRate} placement="topLeft">
                              {currentReport && currentReport.repairRate}
                            </Tooltip>
                          </Title>
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 small-text">
                      { currentReport && currentReport.lastUpdatedAt ? 'Last updated: ' : ''}
                      {' '}
                      {currentReport && currentReport.lastUpdatedAt
                          && moment(currentReport.lastUpdatedAt).format(
                            constants.DATETIME
                          )}
                    </p>
                  </>
                  )}
                </div>
                <div className="flex-fill overflow-auto chart-wrapper">
                  <div className="h-100 overflow-hidden">
                    {currentReport ? <ProdGraph currentReport={currentReport} /> : <Empty />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-fill custom-fill overflow-auto">
          {currentReport && (
            <div className="d-flex flex-column h-100">
              <div
                className="flex-auto w-100"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Title className="mb-3 ps-4" level={4}>
                  Previous Reports
                </Title>

                <Button
                  size="small"
                  shape="round"
                  type="primary"
                  className="export-excel"
                  onClick={exportToExcel}
                  disabled={Object.keys(currentReport).length === 0}
                >
                  <Tooltip title="Download excel report" placement="topRight">
                    <span className="icon-16 mr-2">
                      <svg>
                        <use xlinkHref="#export" />
                      </svg>
                    </span>
                  </Tooltip>
                </Button>

              </div>
              <div className="custom-line" />
              <div className="flex-fill overflow-auto ps-4">
                <div className={previousReports.length > 0 ? 'h-100 overflow-y-hidden overflow-x-auto has-pointer' : 'h-100 overflow-y-hidden overflow-x-auto'}>
                  <Table
                    className="h-100"
                    scroll={{ x: previousReports.length > 0 ? 600 : 0, y: '100%' }}
                    pagination={false}
                    rowKey={(record) => record.reportId}
                    columns={headercolumns}
                    dataSource={previousReports}
                    onRow={(record) => ({
                      onClick: (e) => {
                        e.stopPropagation();
                        setprodmodalvisible(true);
                        setPrevReport(record);
                        modal();
                      },
                    })}
                    components={{ TableCell, TableHeaderCell }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      )}
    </>
  );
};

Production.propTypes = {
  onGetProdReports: func.isRequired,
  prodData: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
};

const mapStateToProps = ({
  prodLogReports
}) => {
  const prodData = get(prodLogReports, 'data', []);
  const showProdLoader = get(prodLogReports, 'isLoading', false);
  return {
    prodData,
    showProdLoader,
  };
};

const mapDispatchToProps = {
  onGetProdReports: getProdReports
};

export default connect(mapStateToProps, mapDispatchToProps)(Production);
