import { getJwtToken } from './auth';

const customFetch = async (api, data, defaultHeader) => {
  const authToken = await getJwtToken();
  const headers = {
    ...data.headers,
    Authorization: authToken,
  };

  if (defaultHeader) {
    headers.Accept = 'application/json';
    headers['content-type'] = 'application/json';
  }

  const response = await fetch(api, {
    ...data,
    headers
  });
  return response;
};

export default customFetch;
