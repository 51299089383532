import { notification } from 'antd';
import fetch from './fetch';
import { BASE_URL } from '../config';

export const getAwsConfigData = async () => {
  try {
    const res = await fetch(`${BASE_URL}/dataMigration/awsConfigData`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const assetsData = await res.json();
      return assetsData;
    }
    return null;
  } catch (error) {
    notification.error({
      message: 'There was a problem fetching the data.',
      description: `${error}`,
    });
    return null;
  }
};
