import { combineReducers } from 'redux';
import qualityReports from './qualityReportReducer';
import prodLogReports from './prodLogReducer';
import techLogReports from './techLogReducer';
import coatingProdLogReducer from './coatingProdLogReducer';
import partRequests from './partsRequestReducer';
import userRequests from './userReducer';
import projectRequests from './projectReducer';
import projectListRequests from './projectListReducer';
import assetRequests from './assetReducer';
import auth from './authReducer';
import damageReports from './damageReportReducer';
import wireUsageReport from './wireUsageReducer';
import teamMembers from './teamMembersReducer';
import notifications from './notificationReducer';
import emailRequests from './emailConfigReducer';
import lemRequests from './lemRequestReducer';
import timesheetRequests from './timesheetRequestReducer';
import analysisRequests from './analysisReducer';
import projectAssetRequests from './projectAssetReducer';
import projectInventoryRequests from './projectInventoryReducer';
import awsConfigDataRequests from './awsConfigDataReducer';
import advisoryData from './advisoryReducer';

import { SIGNOUT } from '../types';

const appReducer = combineReducers({
  qualityReports,
  prodLogReports,
  techLogReports,
  coatingProdLogReducer,
  partRequests,
  userRequests,
  projectRequests,
  projectListRequests,
  auth,
  damageReports,
  wireUsageReport,
  teamMembers,
  notifications,
  assetRequests,
  emailRequests,
  lemRequests,
  timesheetRequests,
  analysisRequests,
  projectAssetRequests,
  projectInventoryRequests,
  awsConfigDataRequests,
  advisoryData
});

const rootReducer = (state, action) => {
  const newState = action.type === SIGNOUT ? undefined : state;
  return appReducer(newState, action);
};

export default rootReducer;
