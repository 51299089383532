/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/plots';
import moment from 'moment';
import * as constants from '../utils/constants';

const compare = (a, b) => {
  const date1 = new Date(a.date);
  const date2 = new Date(b.date);
  if (date1 < date2) {
    return -1;
  }
  if (date1 > date2) {
    return 0;
  }
  return 0;
};

function formatMinutesToTime(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours > 0 ? `${hours} hours ` : ''}${mins > 0 ? `${mins} mins` : ''}`;
}

const DprEmeaDownTimeGragh = (props) => {
  const { currentMonthData } = props;
  const graphData = [];
  const [data, setData] = useState([]);
  useEffect(() => {
    // eslint-disable-next-line no-plusplus
    if (currentMonthData) {
      currentMonthData.forEach((item) => {
        graphData.push({ date: moment(item.date).format(constants.DATE).replaceAll('/', '-'), total: moment.duration(item.downtime_crce_equipment).asMinutes(), value: 'CRCE Equipment' });
        graphData.push({ date: moment(item.date).format(constants.DATE).replaceAll('/', '-'), total: moment.duration(item.downtime_crce_personnel).asMinutes(), value: 'CRCE Personnel' });
        graphData.push({ date: moment(item.date).format(constants.DATE).replaceAll('/', '-'), total: moment.duration(item.downtime_contractor).asMinutes(), value: 'Contractor' });
      });
      graphData.sort(compare);
      setData(graphData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonthData]);

  const config = {
    autoFit: true,
    height: 1,
    data,
    xField: 'date',
    yField: 'total',
    seriesField: 'value',
    tooltip: {
      showMarkers: true
    },
    point: {
      shape: 'breath-point'
    },
    yAxis: {
      grid: { line: { style: { stroke: '' } } },
      label: {
        formatter: (v) => formatMinutesToTime(v), // format the Y-axis labels
      },
    },
    color: ['#FF0000', '#FFDB0A', '#007500'],
  };

  return (

    currentMonthData

      ? (<Line {...config} />) : (<Line {...config} />)

  // eslint-disable jsx-props-no-spreading

  // eslint-disable-next-line react/jsx-props-no-spreading

  );
};

export default DprEmeaDownTimeGragh;
