import get from 'lodash/get';
import moment from 'moment';
import {
  GET_DAMAGE_REPORTS,
  SET_DAMAGE_REPORTS,
  SET_DAMAGE_REPORTS_FAILURE
} from '../types';
import * as constants from '../../utils/constants';

const initialState = {
  isLoading: false,
};

const damageReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DAMAGE_REPORTS: {
      const qReports = get(action, ['response'], []);
      const report = qReports.data;
      const data = [];
      for (let i = 0; i < report.length; i += 1) {
        let date;
        if (qReports.data[i].date_and_time_of_discovery === 'Invalid date') {
          date = null;
        } else {
          date = qReports.data[i].date_and_time_of_discovery?.slice(0, 10);
          date = moment(date).format(constants.DATE);
          date = date.replaceAll('/', '-');
        }
        data[i] = {
          key: i,
          equipment: qReports.data[i].serial_number,
          date,
          number: qReports.data[i].serial_number,
          damage: qReports.data[i].type_of_loss,
          overallStatus: qReports.data[i].type_of_loss,
          action: qReports.data[i].type_of_loss,
          discovered: qReports.data[i].person_discovering_loss,
          report: qReports.data[i].pdf_url
        };
      }
      return {
        ...state,
        data,
        isLoading: false,
      };
    }

    case GET_DAMAGE_REPORTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_DAMAGE_REPORTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default damageReportReducer;
