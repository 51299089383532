/* eslint-disable linebreak-style */
import get from 'lodash/get';
import {
  FETCH_PROJECTS_LIST, SET_PROJECTS_LIST, SET_PROJECT_LIST_FAILURE
} from '../types';

const initialState = {
  isProjectListLoading: false,
};

const projectListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECTS_LIST: {
      const Projects = get(action, ['response', 'data'], []);
      const projectListData = [];
      for (let i = 0; i < Projects.length; i += 1) {
        const projectName = get(Projects[i], 'project_name', '');
        const projectNo = get(Projects[i], 'project_number', '');
        const contractor = get(Projects[i], 'contractor', '');
        const leadTech = get(Projects[i], 'lead_tech', '');
        const completedWelds = get(Projects[i], 'completedwelds', 0);
        const plannedWelds = get(Projects[i], 'planned_welds', 0);
        const startDate = get(Projects[i], 'start_date', '');
        const endDate = get(Projects[i], 'end_date', '');
        const projectStatus = get(Projects[i], 'project_status', '');
        const contractorEmail = get(Projects[i], 'contractor_email', '');
        const docusignRequired = get(Projects[i], 'esign_required', false);
        const projectType = get(Projects[i], 'project_type', 'onshore');
        if (projectNo !== '') {
          projectListData[i] = {
            id: i,
            key: i,
            projectName,
            projectNo,
            contractor,
            leadTech,
            completedWelds,
            plannedWelds,
            startDate,
            endDate,
            projectStatus,
            contractorEmail,
            docusignRequired,
            projectType
          };
        }
      }

      return {
        ...state,
        projectListData,
        isProjectListLoading: false,
      };
    }

    case FETCH_PROJECTS_LIST: {
      return {
        ...state,
        isProjectListLoading: true,
      };
    }

    case SET_PROJECT_LIST_FAILURE: {
      return {
        ...state,
        isProjectListLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default projectListReducer;
