/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import {
  Modal, Button, notification, Switch, Tabs, Tooltip, Table, Typography
} from 'antd';
import Excel from 'exceljs';
import {
  FileImageFilled, MoreOutlined, DownloadOutlined
} from '@ant-design/icons';
import { calcDowntime } from '../services/calendar';
import { prepareDataForEMEADPRExcel, writeWorkSheetForEMEADPR } from '../utils/utility';
import DprEmeaProdGragh from '../layout/dprEmeaProdGragh';
import DprEmeaDownTimeGragh from '../layout/dprEmeaDownTimeGragh';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const EMEAWeldesModal = (props) => {
  const {
    currentReport, visible, setVisible, projNo
  } = props;
  const [newData, setNewData] = useState(false);
  const [tablehtml, setTablehtml] = useState('');
  const [showGraph, setShowGraph] = useState(false);
  const [imageView, setImageView] = useState(false);
  const [imageProperties, setImageProperties] = useState('');

  const tableData = () => {
    let tableval = null;

    /** Function to view images for specified checks (on click to view) */
    const handleViewCheckImages = async (record) => {
      setImageView(true);
      setImageProperties(record);
    };

    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        align: 'center',
      },
      {
        title: 'Welds',
        dataIndex: 'weld_count_accepted',
        key: 'weld_count_accepted',
        align: 'center',
        render: (text) => <>{text || 0}</>
      },
      {
        title: 'Repairs',
        dataIndex: 'weld_count_repairs',
        key: 'weld_count_repairs',
        align: 'center',
        render: (text) => <>{text || 0}</>
      },
      {
        title: 'Repair Rate',
        dataIndex: 'repair_rate',
        key: 'repair_rate',
        align: 'center',
        render: (text) => <>{text || 0}</>
      },
      {
        title: 'Comments',
        dataIndex: 'comments',
        key: 'comments',
        align: 'center',
      },
      {
        title: 'Report',
        dataIndex: 'pdf_url',
        key: 'pdf_url',
        align: 'center',
        render: (record) => {
          if (record) {
            return (
              <div>
                {' '}
                <a
                  className="export-link"
                  title="Download pdf report"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={record}
                  // eslint-disable-next-line react/jsx-closing-bracket-location
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <span className="icon-16 mr-2">
                    <svg>
                      <use xlinkHref="#export" />
                    </svg>
                  </span>
                </a>
              </div>
            );
            // eslint-disable-next-line brace-style
          }
          // eslint-disable-next-line no-else-return
          else {
            return <div>-</div>;
          }
        },
      },
      {
        title: () => <MoreOutlined style={{ fontSize: '18px' }} />,
        key: 'production_image',
        dataIndex: 'production_image',
        width: '100px',
        render: (record) => (
          <>
            {record && record[0].content_type !== null
              ? (
                <Tooltip title="View Image">
                  <span className="icon-24 mr-2 cursor-pointer edit-hover">
                    <FileImageFilled
                      className="pictureIcon"
                      onClick={() => { handleViewCheckImages(record); }}
                    />
                  </span>
                </Tooltip>
              )
              : (
                <Tooltip title="No Image Found">
                  <span className="icon-24 mr-2 cursor-pointer edit-hover">
                    <FileImageFilled
                      className="gradeOutPictureIcon"
                      style={{ cursor: 'not-allowed' }}
                    />
                  </span>
                </Tooltip>
              )}
          </>

        ),
        align: 'center'
      },
    ];
    const downtimeColumns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        align: 'center',
      },
      {
        title: 'Contractor',
        dataIndex: 'downtime_contractor',
        key: 'downtime_contractor',
        align: 'center',
        render: (text) => <>{calcDowntime(text)}</>
      },
      {
        title: 'CRCE Equipment',
        dataIndex: 'downtime_crce_equipment',
        key: 'downtime_crce_equipment',
        align: 'center',
        render: (text) => <>{calcDowntime(text)}</>
      },
      {
        title: 'CRCE Personnel',
        dataIndex: 'downtime_crce_personnel',
        key: 'downtime_crce_personnel',
        align: 'center',
        render: (text) => <>{calcDowntime(text)}</>
      },
      {
        title: 'Total',
        dataIndex: 'downtime',
        key: 'downtime',
        align: 'center',
        render: (text) => <>{calcDowntime(text)}</>
      },
    ];
    if (!showGraph) {
      tableval = (
        <Tabs className="custom-tab h-95">
          <TabPane tab="Production" key={1}>
            <Table
              pagination={false}
              scroll={{ x: newData?.length > 0 ? 600 : 0, y: '100%' }}
              dataSource={newData}
              columns={columns}
            />
          </TabPane>
          <TabPane tab="Down Time" key={2}>
            <Table
              pagination={false}
              scroll={{ x: newData?.length > 0 ? 600 : 0, y: '100%' }}
              dataSource={newData}
              columns={downtimeColumns}
            />
          </TabPane>
        </Tabs>
      );
    } else {
      tableval = (
        <Tabs className="custom-tab h-95">
          <TabPane tab="Production" key={1}>
            <div style={{ height: '300px' }}>
              <DprEmeaProdGragh currentMonthData={newData} />
            </div>
          </TabPane>
          <TabPane tab="Downtime" key={2}>
            <div style={{ height: '300px' }}>
              <DprEmeaDownTimeGragh currentMonthData={newData} />
            </div>
          </TabPane>
        </Tabs>
      );
    }
    setTablehtml(tableval);
  };

  useEffect(() => {
    if (visible === false) {
      setTablehtml('');
      return;
    }
    tableData();

    if (currentReport) {
      currentReport.dpr_data.sort((a, b) => new Date(a.date) - new Date(b.date));
      setNewData(currentReport.dpr_data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport, visible]);

  useEffect(() => {
    if (newData !== []) {
      tableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);

  const handleDownloadImage = (link) => {
    const a = document.createElement('a');
    a.href = `${link}`;
    a.download = imageProperties.checkImageFileName;
    a.click();
  };

  const exportToExcel = async () => {
    try {
      const { columns } = prepareDataForEMEADPRExcel();
      const workbook = new Excel.Workbook();
      const workSheetName = 'Sheet1';
      const fileName = `${projNo}_monthly_production_${currentReport.period}`;
      let worksheet = workbook.addWorksheet(workSheetName);
      worksheet.columns = columns;
      worksheet = writeWorkSheetForEMEADPR(worksheet, currentReport.dpr_data);

      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.log('error', error);
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  useEffect(() => {
    if (showGraph) {
      tableData(showGraph);
    } else {
      tableData(showGraph);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showGraph]);

  return (
    <>
      <Button className="hidden-view-btn" onClick={() => setVisible(true)}>v</Button>
      <Modal
        title={(
          <>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '53%', fontSize: '13px' }}>
                <Title level={4}>Monthly Production Log Report</Title>
              </div>

              {
                currentReport && (
                  <>
                    <Switch
                      className="modal-switch"
                      checkedChildren="Graphical"
                      unCheckedChildren="Tabular"
                      checked={showGraph}
                      onChange={(checked) => {
                        if (checked) {
                          setShowGraph(checked);
                        } else {
                          setShowGraph(checked);
                        }
                      }}
                    />
                    <Button
                      size="small"
                      shape="round"
                      type="primary"
                      className="export-excel"
                      onClick={exportToExcel}
                    >
                      <Tooltip title="Download excel report" placement="topRight">
                        <span className="icon-16 mr-2">
                          <svg>
                            <use xlinkHref="#export" />
                          </svg>
                        </span>
                      </Tooltip>

                    </Button>
                  </>

                )
              }
            </div>
            <div>
              <span style={{ color: '#F33E4C', fontWeight: 'bold' }}>Total Weld :  </span>
              {currentReport && currentReport.total_welds}
              {', '}
              <span style={{ color: '#F33E4C', fontWeight: 'bold' }}>Total Repair :  </span>
              {currentReport && currentReport.total_repair}
              {', '}
              <span style={{ color: '#F33E4C', fontWeight: 'bold' }}>Repair Rate :  </span>
              {currentReport && currentReport.monthly_repair_rate}
              {', '}
              <span style={{ color: '#F33E4C', fontWeight: 'bold' }}>Total Downtime :  </span>
              { currentReport && calcDowntime(currentReport.monthly_downtime)}
            </div>
          </>
        )}
        className="welds-modal"
        centered
        visible={visible}
        width={1009}
        footer={[
          <Button key="submit" type="primary" onClick={() => { setVisible(false); setShowGraph(false); }}>
            OK
          </Button>
        ]}
      >
        <div className="ant-table scroll-table">
          <div className="ant-table-container h-100">
            <div className="ant-table-content h-100">

              {tablehtml}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        className="welds-modal"
        width={1009}
        title="Repair Pictures"
        centered
        visible={imageView}
        footer={[
          <Button key="submit" type="primary" onClick={() => { setImageView(false); }}>
            OK
          </Button>
        ]}
      >
        {(imageProperties && imageProperties[0].content_type !== null)
        && (
        <div style={{ marginTop: '12px' }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '16px',
            height: '100%',
            maxHeight: '300px',
            overflow: 'auto',
            position: 'relative',
          }}
          >
            {imageProperties.map((data, index) => data.link
                && (

                <div style={{
                  height: '210px', width: '200px', overflow: 'none', display: 'flex', flexDirection: 'column', marginBottom: '8px', marginRight: '8px'
                }}
                >
                  <img alt={data.content_type} width={150} height={150} src={data.link} />
                  <Button onClick={() => handleDownloadImage(data.link)} style={{ width: '150px', border: 'none' }}>
                    <Text>
                      <Tooltip className="my-0 theme-color fw-bold truncate" title={`Image ${index}`}>
                        {`Image ${index}`}
                        {' '}
                      </Tooltip>
                    </Text>
                    <DownloadOutlined className="pictureIcon" />
                  </Button>
                </div>
                ))}
          </div>
        </div>
        )}
      </Modal>
    </>
  );
};

export default EMEAWeldesModal;
