/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import {
  Button, Table, Spin, Tooltip, notification
} from 'antd';
import {
  func, arrayOf, objectOf, oneOfType, string, number, bool,
  array,
} from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import {
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import AttachmentsViewer from './AttachmentsViewer';
import ApprovalRequestModal from './ApprovalRequestModal';
import { getAdvisoryApproval } from '../state/action/advisoryAction';
import { ADV_DATE_TABLE } from '../utils/constants';
import { updateApprovalRequest } from '../services/advisory';
import AddMachine from './AddMachine';

const itemsPerPage = 10;
const defaultPage = 0;

function Advisory(props) {
  const {
    onGetAdvisory, data, showLoader, total, currentTab
  } = props;

  const [form] = useForm();

  const [pageNumber, setPageNumber] = useState(defaultPage);
  const [isAttachmentModalVisible, setisAttachmentModalVisible] = useState(false);
  const [attachmentsData, setAttachmentsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (currentTab === '4') onGetAdvisory(pageNumber, itemsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const showAttachmentModal = (attachmentData) => {
    setAttachmentsData(attachmentData);
    setisAttachmentModalVisible(true);
  };

  const onSave = async (values) => {
    setIsLoading(true);
    const res = await updateApprovalRequest(values);
    if (res) {
      setIsModalVisible(false);
      await onGetAdvisory(pageNumber, itemsPerPage);
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
    form.resetFields();
    setIsLoading(false);
  };

  const approveHandler = (id) => {
    setIsReject(false);
    form.setFieldsValue({ approval_id: id });
    setIsModalVisible(true);
  };

  const rejectHandler = (id) => {
    setIsReject(true);
    form.setFieldsValue({ approval_id: id });
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: 'Advisory ID',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'Business Unit',
      dataIndex: 'businessUnit',
      key: 'businessUnit',
    },
    {
      title: 'Effective Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Attachment',
      key: 'attachments',
      dataIndex: 'attachments',
      render: (record) => {
        if (!record || record.length === 0) return '-';
        if (record.length === 1) {
          return (
            <div>
              {' '}
              <a
                className="export-link"
                title="Download attachments"
                target="_blank"
                rel="noopener noreferrer"
                href={record[0].url ? record[0].url : ''}
                // eslint-disable-next-line react/jsx-closing-bracket-location
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span className="icon-16 mr-2">
                  <svg>
                    <use xlinkHref="#export" />
                  </svg>
                </span>
              </a>
            </div>
          );
        }
        return (
          <Button className="b-0 export-link border-0 px-0" shape="round" size="small" onClick={() => showAttachmentModal(record)}>
            <span className="icon-16 mr-2">
              <svg>
                <use xlinkHref="#export" />
              </svg>
            </span>
            {' '}
            <span className="mx-1">
              {record.length}
            </span>
          </Button>
        );
      }
    }, {
      title: '',
      render: (record, tableData) => {
        if (record) {
          return (
            <div>
              <Tooltip title="Approve">
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    approveHandler(tableData.id);
                  }}
                  shape="circle"
                  className="ant-btn approval me-2"
                >
                  <CheckOutlined />
                </Button>
              </Tooltip>
              <Tooltip title="Reject">
                {' '}
                <Button
                  shape="circle"
                  className="ant-btn refresh"
                  onClick={(event) => {
                    event.stopPropagation();
                    rejectHandler(tableData.id);
                  }}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip>
            </div>
          );
        }
        return <div>-</div>;
      },
      align: 'right',
    },

  ];

  const tableData = data.map((item) => ({
    key: item.id,
    id: item.id,
    type: item.name,
    description: item.description,
    title: item.title,
    date: moment(item.date).format(ADV_DATE_TABLE),
    attachments: item.attachments,
    createdBy: item.createdBy,
    priority: Number(item.priority),
    dateOriginal: item.date,
    typeId: item.type,
    businessUnit: item.business_unit,
    code: item.code,
    parent: item.parent_id,
  }));

  const onPaginationChange = (page) => {
    setPageNumber(page - 1);
    onGetAdvisory(page - 1, itemsPerPage);
  };

  const onEditAdvisory = (record) => {
    form.setFieldsValue({
      ...record,
      type: record.typeId,
      date: moment(record.dateOriginal),
      priority: Number(record.priority),
      businessUnit: record.businessUnit
    });
    setIsVisible(true);
  };

  return (
    <Spin spinning={showLoader} className="h-100">
      <div className="h-100 advisory-approval">
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={{
            defaultPageSize: itemsPerPage,
            showSizeChanger: false,
            pageSizeOptions: ['10'],
            onChange: onPaginationChange,
            total,
            current: pageNumber + 1,
            showLessItems: true,
            hideOnSinglePage: true,
          }}
          onRow={(record) => ({
            onClick: () => { onEditAdvisory(record); }
          })}
        />
      </div>

      <AddMachine
        isLoading={false}
        isVisible={isVisible}
        onCancel={() => setIsVisible(false)}
        form={form}
        title="View Advisory"
        key="View"
        disabled
        isApproval
        cancelText="Close"
      />

      <AttachmentsViewer
        isVisible={isAttachmentModalVisible}
        onOk={() => setisAttachmentModalVisible(false)}
        attachments={attachmentsData}
      />

      <ApprovalRequestModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        isLoading={isLoading}
        onSave={onSave}
        form={form}
        isReject={isReject}
      />
    </Spin>
  );
}

Advisory.propTypes = {
  onGetAdvisory: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showLoader: bool.isRequired,
};

const mapStateToProps = ({ advisoryData }) => {
  const data = get(advisoryData, 'approvalRequests', []);
  const showLoader = get(advisoryData, 'isAdvApprovalLoading', false);
  return {
    data,
    showLoader,
  };
};

const mapDispatchToProps = {
  onGetAdvisory: getAdvisoryApproval
};

export default connect(mapStateToProps, mapDispatchToProps)(Advisory);
