/* eslint-disable linebreak-style */
import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import {
  setProjects, setProjectFailure
} from '../state/action/projectAction';
import {
  FETCH_PROJECTS
} from '../state/types';
import fetch from '../services/fetch';

import { BASE_URL } from '../config';

function* fetchProject() {
  try {
    const res = yield fetch(`${BASE_URL}/get-all-projects`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      throw new Error('Unauthorized');
    }
    const response = yield res.json();
    yield put(setProjects({ response }));
  } catch (error) {
    yield put(setProjectFailure());
    notification.error({
      message: 'There was a problem fetching the app projects.',
      description: `${error}`,
    });
  }
}

function* projectWatcher() {
  yield takeLatest(FETCH_PROJECTS, fetchProject);
}

function* projectSagas() {
  yield all([
    call(projectWatcher),
  ]);
}

export default projectSagas;
