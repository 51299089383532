/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lonely-if */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable linebreak-style */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import {
  DatePicker, Input, Button, Tooltip, notification, Switch, Select, Collapse, Radio,
  Spin
} from 'antd';
import Carousel from 'react-elastic-carousel';
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import CreateProject from '../Components/Createproject';
import SearchModal from '../Components/Search';
import * as constants from '../utils/constants';

const { Option } = Select;
const { Panel } = Collapse;

const ProjectListHeader = (props) => {
  // eslint-disable-next-line react/prop-types
  const {
    // eslint-disable-next-line no-unused-vars
    getReportListByProject,
    projectList,
    completedWeldsCount,
    reloadData,
    reload,
    refresh,
    setProject,
    project,
    index,
    setIndex,
    hideComponents,
    group,
    onUpdate,
    disableSelection,
    setDisableSelection,
    setIsUpdate,
    setSelectedProjectDetail,
    setDates,
    showProjectListLoader
  } = props;
  const [masterProject, setMasterProject] = useState([]);
  const [selectedProject, setSelectedProj] = useState([]);
  const [isEditStartDate, setIsEditStartDate] = useState(false);
  const [isEditEndDate, setIsEditEndDate] = useState(false);
  const [isEditDocuSignReqd, setIsEditDocuSignReqd] = useState(false);
  const [isEditPlannedWelds, setIsEditPlannedWelds] = useState(false);
  const [isEditProjectManagerEmail, setIsEditProjectManagerEmail] = useState(false);
  const [isEditProjectLeadTech, setIsEditProjectLeadTech] = useState(false);
  const [isEditProjectStatus, setIsEditProjectStatus] = useState(false);
  const [searchText, onSearch] = useState('');
  const [text, setText] = useState('');
  const [startDateInput, setStartDateInput] = useState('');
  const [endDateInput, setEndDateInput] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [docusignRequired, setDocuSignRequired] = useState('');
  const [plannedWeldInput, setPlannedWeldInput] = useState('');
  const [docusignRequiredInput, setDocuSignRequiredInput] = useState('');
  const [plannedWeld, setPlannedWeld] = useState('');
  const [projectManagerEmailInput, setProjectManagerEmailInput] = useState('');
  const [projectManagerEmail, setProjectManagerEmail] = useState('');
  const [projectStatusInput, setProjectStatusInput] = useState('');
  const [projectLeadTechInput, setProjectLeadTechInput] = useState('');
  const [projectLeadTech, setProjectLeadTech] = useState('');
  const [projectStatus, setProjectStatus] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [showArchivedProject, setShowArchivedProject] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [valueUpdated, setValueUpdated] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidLeadTech, setIsValidLeadTech] = useState(true);
  const [selectedOption, setSelectedOption] = useState('All');

  const carouselBreakWidths = [
    {
      width: 400,
      itemsToShow: 3,
      pagination: false,
      itemsToScroll: 1,
    },
    {
      width: 850,
      itemsToShow: 4,
      itemsToScroll: 1,
      pagination: false,
    },

    {
      width: 1300,
      itemsToShow: 5,
      itemsToScroll: 1,
      pagination: false,
    },

    {
      width: 1750,
      itemsToShow: 7,
      itemsToScroll: 3,
      pagination: false,
    },
  ];

  const close = () => {
    clearData();
  };

  const searchData = () => {
    const data = [];
    if (projectList && projectList.length > 0) {
      if (localStorage.getItem('searchVal').length > 0) {
        const searchvalue = localStorage.getItem('searchVal');
        // if (masterProject.length > 0) {
        //   masterProject.map((value) => {
        //     if (
        //       value.projectName
        //         .toLowerCase()
        //         .includes(searchvalue.toLowerCase())
        //       || value.projectNo.toLowerCase().includes(searchvalue.toLowerCase())
        //     ) {
        //       console.log('value after update: ', value);
        //       data.push(value);
        //     }
        //   });
        // } else {

        if (localStorage.getItem('pjctStatus') === 'false'
             && localStorage.getItem('projectType').length > 0 && localStorage.getItem('projectType') !== 'All') {
          projectList.map((value) => {
            if (value.projectStatus === 'InProgress') {
              if (
                (value.projectName
                  .toLowerCase()
                  .includes(searchvalue.toLowerCase())
                  || value.projectNo.toLowerCase().includes(searchvalue.toLowerCase()))
                  && value.projectType === localStorage.getItem('projectType')
              ) {
                data.push(value);
              }
            }
          });
        } else if (localStorage.getItem('pjctStatus') === 'false') {
          projectList.map((value) => {
            if (value.projectStatus === 'InProgress') {
              if (
                value.projectName
                  .toLowerCase()
                  .includes(searchvalue.toLowerCase())
                  || value.projectNo.toLowerCase().includes(searchvalue.toLowerCase())
              ) {
                data.push(value);
              }
            }
          });
        } else if (localStorage.getItem('projectType').length > 0 && localStorage.getItem('projectType') !== 'All') {
          projectList.map((value) => {
            if (
              (value.projectName
                .toLowerCase()
                .includes(searchvalue.toLowerCase())
                  || value.projectNo.toLowerCase().includes(searchvalue.toLowerCase()))
                  && value.projectType === localStorage.getItem('projectType')
            ) {
              data.push(value);
            }
          });
        } else {
          projectList.map((value) => {
            if (
              value.projectName
                .toLowerCase()
                .includes(searchvalue.toLowerCase())
                  || value.projectNo.toLowerCase().includes(searchvalue.toLowerCase())
            ) {
              data.push(value);
            }
          });
        }
        // }
        setDataSource([]);
        if (!reload) {
          setActiveIndex(0);
        }
        setTimeout(() => {
          setDataSource(data);
          if (!reload) {
            if (data && data.length > 1) {
              setSelectedProj([]);
              getReportListByProject('');
            } else if (data && data.length === 1) {
              data.map((dat) => {
                setSelectedProj(dat);
                setProject(dat);
                getReportListByProject(dat.projectNo);
              });
            }
          }
        }, 500);
        if (data.length === 0) {
          notification.warning({
            message: 'Invalid Project Name or Project Number.',
            onClose: close,
          });
        }
      } else {
        setDataSource(masterProject);
        setSelectedProj([]);
        getReportListByProject('');
        // localStorage.setItem('searchVal', '');
        // setText('');
      }
    } else {
      setText('');
      onSearch('');
      localStorage.setItem('searchVal', '');
    }
  };

  const filterByProjectType = () => {
    const data = [];
    if (projectList && projectList.length > 0) {
      if (localStorage.getItem('projectType').length > 0
      && localStorage.getItem('projectType') !== 'All') {
        const searchvalue = localStorage.getItem('projectType');
        const searchVal = localStorage.getItem('searchVal');

        if (localStorage.getItem('searchVal').length > 0
        && localStorage.getItem('pjctStatus') === 'false') {
          projectList.map((value) => {
            if (value.projectStatus === 'InProgress'
                && value.projectType === searchvalue
                && (value.projectName
                  .toLowerCase()
                  .includes(searchVal.toLowerCase())
                  || value.projectNo.toLowerCase().includes(searchVal.toLowerCase()))
            ) {
              data.push(value);
            }
          });
        } else if (localStorage.getItem('pjctStatus') === 'false') {
          projectList.map((value) => {
            if (value.projectStatus === 'InProgress') {
              if (
                value.projectType === searchvalue
              ) {
                data.push(value);
              }
            }
          });
        } else {
          projectList.map((value) => {
            if (
              value.projectType === searchvalue
            ) {
              data.push(value);
            }
          });
        }
        setDataSource([]);
        if (!reload) {
          setActiveIndex(0);
        }
        setTimeout(() => {
          setDataSource(data);
          if (!reload) {
            if (data && data.length > 1) {
              setSelectedProj([]);
              getReportListByProject('');
            } else if (data && data.length === 1) {
              data.map((dat) => {
                setSelectedProj(dat);
                setProject(dat);
                getReportListByProject(dat.projectNo);
              });
            }
          }
        }, 500);
      } else if (localStorage.getItem('projectType') === 'All') {
        setDataSource(projectList);
      } else {
        setDataSource(masterProject);
        setSelectedProj([]);
        getReportListByProject('');
      }
    } else {
      // setText('');
      // onSearch('');
      localStorage.setItem('projectType', '');
    }
  };

  const getSelectedProjectId = (id) => {
    // eslint-disable-next-line radix
    setDates([]);
    setIsEditEndDate(false);
    setIsEditStartDate(false);
    setIsEditPlannedWelds(false);
    setIsEditProjectManagerEmail(false);
    setIsEditProjectStatus(false);
    setIsEditDocuSignReqd(false);
    refresh(false);
    if (!reload) {
      if (masterProject.length > 0) {
        // eslint-disable-next-line array-callback-return
        masterProject.map((data) => {
          if (data.id === id) {
            getReportListByProject(data.projectNo);
            setSelectedProj(data);
            setProject(data);
          }
        });
      }
    } else {
      if (projectList.length > 0) {
        projectList.map((data) => {
          if (data.id === project.id) {
            setSelectedProj(data);
          }
        });
      }
    }
  };

  useEffect(() => {
    const inProgressData = [];
    const archivedData = [];
    if (projectList !== []) {
      if (localStorage.getItem('pjctStatus') === 'false') {
        projectList.map((pjct, i) => {
          if (pjct.projectStatus === 'InProgress') {
            inProgressData.push(pjct);
          }
        });
        setDataSource(inProgressData);
        setMasterProject(inProgressData);

        if (selectedProject && selectedProject.projectNo) {
          projectList.map((data) => {
            if (data.projectNo === selectedProject.projectNo) {
              if (data.projectStatus === 'Archived') {
                setText('');
                onSearch('');
                getReportListByProject('');
                setSelectedProj([]);
                localStorage.setItem('searchVal', '');
              }
            }
          });
        }
      } else {
        projectList.map((pjct, i) => {
          if (pjct.projectStatus === 'InProgress') {
            inProgressData.push(pjct);
          } else {
            archivedData.push(pjct);
          }
        });
        setDataSource(inProgressData.concat(archivedData));
        setMasterProject(inProgressData.concat(archivedData));
        if (selectedProject && selectedProject.projectNo) {
          projectList.map((data) => {
            if (data.projectNo === selectedProject.projectNo) {
              if (!(data.projectStatus === selectedProject.projectStatus)) {
                if (data.projectStatus === 'Archived') {
                  setText('');
                  onSearch('');
                  getReportListByProject('');
                  setSelectedProj([]);
                  localStorage.setItem('searchVal', '');
                } else {
                  setText('');
                  onSearch('');
                  getReportListByProject('');
                  setSelectedProj([]);
                  localStorage.setItem('searchVal', '');
                }
              }
            }
          });
        }
      }
      if (localStorage.getItem('searchVal') !== '') {
        setText(localStorage.getItem('searchVal'));
        onSearch(localStorage.getItem('searchVal'));
        searchData();
      }
      if (localStorage.getItem('projectType') !== '') {
        filterByProjectType();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectList]);

  useEffect(() => {
    if (selectedProject.length !== 0) {
      setSelectedProjectDetail(selectedProject);
      if (selectedProject.startDate) {
        setStartDate(
          moment(selectedProject.startDate)
            .format(constants.DATE)
            .replaceAll('/', '-')
        );
        setStartDateInput('');
      } else {
        setStartDate('');
        setStartDateInput('');
      }
      if (selectedProject.endDate) {
        setEndDate(
          moment(selectedProject.endDate)
            .format(constants.DATE)
            .replaceAll('/', '-')
        );
        setEndDateInput('');
      } else {
        setEndDate('');
        setEndDateInput('');
      }
      setPlannedWeld(selectedProject.plannedWelds);
      setDocuSignRequired(selectedProject.docusignRequired);
      setProjectManagerEmail(selectedProject.contractorEmail);
      setProjectLeadTech(selectedProject.leadTech);

      if (selectedProject.projectStatus === 'InProgress') {
        setProjectStatus('In Progress');
      } else {
        setProjectStatus('Archived');
      }
    } else {
      setStartDate('');
      setEndDate('');
      setPlannedWeld('');
      setProjectManagerEmail('');
      setProjectLeadTech('');
      setProjectStatus('');
      setActiveIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  const changeIndex = (e) => {
    refresh(false);
    setActiveIndex(e.index);
    setIndex(e.index);
  };

  useEffect(() => {
    reloadData();
    // setSelectedOption('All');
    if (reload) {
      setActiveIndex(index);
      getSelectedProjectId(project.id);
      if (localStorage.getItem('searchVal') !== '') {
        setText(localStorage.getItem('searchVal'));
        onSearch(localStorage.getItem('searchVal'));
        searchData();
      }
      if (localStorage.getItem('projectType') !== '') {
        filterByProjectType();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const clearData = (value) => {
    if (value === 'searchCancelClick') {
      if (text) {
        setText('');
        onSearch('');
        setDataSource([]);
        getReportListByProject('');
        setSelectedProj([]);
        localStorage.setItem('searchVal', '');
        localStorage.setItem('projectType', '');
        setSelectedOption('All');
        setTimeout(() => {
          setDataSource(masterProject);
          setActiveIndex(0);
          setIndex(0);
        }, 0);
      } else {
        setText('');
        onSearch('');
        setDataSource([]);
        localStorage.setItem('searchVal', '');
        localStorage.setItem('projectType', '');
        setSelectedOption('All');
        setTimeout(() => {
          setDataSource(masterProject);
        }, 0);
      }
    } else {
      setText('');
      onSearch('');
      setDataSource([]);
      getReportListByProject('');
      setSelectedProj([]);
      localStorage.setItem('searchVal', '');
      localStorage.setItem('projectType', '');
      setSelectedOption('All');
      setTimeout(() => {
        setDataSource(masterProject);
        setActiveIndex(0);
        setIndex(0);
      }, 0);
    }
  };

  const cancelAction = (value) => {
    clearData(value);
  };

  const updatePjctData = async (sD, eD, pW, pME, status, dS, pLT) => {
    setDisableSelection(true); // to be added in notification menu
    setIsUpdate(true);
    setValueUpdated(false);
    onUpdate(sD, eD, pW, pME, selectedProject.projectNo, status, dS, pLT);
  };

  const onlyNumberKey = (event) => {
    // Only ASCII character in that range allowed
    const ASCIICode = (event.which) ? event.which : event.keyCode;
    if (ASCIICode >= 48 && ASCIICode <= 57) {
      return true;
    }
    event.preventDefault();
    return false;
  };

  const onlyCharacterKey = (event) => {
    // Only ASCII character in that range allowed
    const ASCIICode = (event.which) ? event.which : event.keyCode;
    if (ASCIICode !== 32) {
      return true;
    }
    event.preventDefault();
    return false;
  };

  const onChange = (e) => {
    if (projectManagerEmail) {
      setDocuSignRequiredInput(e.target.value);
      setValueUpdated(true);
    } else {
      notification.warning({
        message: 'Please add the Project Manager\'s Email first to enable eSign for LEM.'
      });
      setIsEditDocuSignReqd(false);
    }
  };

  // if ((showProjectListLoader && isUpdate)) {
  //   console.log('enter');
  //   return (
  //     <div className="d-flex align-items-center justify-content-center h-100">
  //       <Spin size="large" tip="Loading data..." />
  //     </div>
  //   );
  // }

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    localStorage.setItem(
      'projectType',
      e.target.value.replace(selectedOption)
    );
    setText('');
    onSearch('');
    localStorage.setItem('searchVal', '');
    filterByProjectType();
  };
  return (
    <>
      <div className="d-flex pb-2 align-items-center justify-content-between">
        <h1 className="project-title ">
          Projects
        </h1>
        <div className="search-container d-flex">
          <div className="toggle-container px-2">
            <Radio.Group onChange={handleOptionChange} value={selectedOption} className="toggle-radio-group">
              <Radio.Button value="All" className="toggle-radio-button">
                <div className="button-content">
                  All
                </div>
              </Radio.Button>
              <Radio.Button value={constants.projectType.onshoreEmea} className="toggle-radio-button">
                <div className="button-content">
                  {constants.projectTypeMap.get('Onshore_EMEA')}
                </div>
              </Radio.Button>
              <Radio.Button value={constants.projectType.onshore} className="toggle-radio-button">
                <div className="button-content">
                  {constants.projectTypeMap.get('onshore')}
                </div>
              </Radio.Button>
              <Radio.Button value={constants.projectType.offshore} className="toggle-radio-button">
                <div className="button-content">
                  {constants.projectTypeMap.get('offshore')}
                </div>
              </Radio.Button>
            </Radio.Group>
          </div>
          <Tooltip
            placement="topLeft"
            title={!showArchivedProject
              ? 'Hiding Archived Project' : 'Showing All Project'}
          >

            <Switch
              checkedChildren="Show Archive"
              unCheckedChildren="Hide Archive"
              defaultChecked={localStorage.getItem('pjctStatus') === 'true' ? true : false}
              onChange={(checked) => {
                // setSelectedOption('All');
                setText('');
                onSearch('');
                getReportListByProject('');
                setSelectedProj([]);
                setProject([]);
                localStorage.setItem('searchVal', '');
                filterByProjectType();
                if (checked) {
                  const inProgressData = [];
                  const archivedData = [];
                  projectList.map((pjct) => {
                    if (pjct.projectStatus === 'InProgress') {
                      inProgressData.push(pjct);
                    } else {
                      archivedData.push(pjct);
                    }
                  });
                  setShowArchivedProject(checked);
                  localStorage.setItem('pjctStatus', 'true');
                  setDataSource(inProgressData.concat(archivedData));
                  setMasterProject(inProgressData.concat(archivedData));
                  filterByProjectType();
                } else {
                  const inProgressData = [];
                  setShowArchivedProject(checked);
                  localStorage.setItem('pjctStatus', 'false');
                  projectList.map((pjct) => {
                    if (pjct.projectStatus === 'InProgress') {
                      inProgressData.push(pjct);
                    }
                  });
                  setDataSource(inProgressData);
                  setMasterProject(inProgressData);
                }
                filterByProjectType();
              }}
              disabled={!group}
            />
          </Tooltip>
          {text !== '' && (
            <div className="top-bar-input d-flex justify-content-between align-items-center me-3">
              <Tooltip title={text} placement="topLeft">
                <span className="truncate pe-2">{text}</span>
              </Tooltip>
              {' '}
              <a className="export-link" onClick={() => clearData('')}>
                <CloseOutlined />
              </a>
              {' '}
            </div>
          )}
          <SearchModal
            onSearch={(e) => {
              onSearch(e.target.value.replace(/^[\s]/, ''));
              localStorage.setItem(
                'searchVal',
                e.target.value.replace(/^[\s]/, '')
              );
            }}
            searchText={searchText}
            searchData={searchData}
            setValue={(val) => setText(val)}
            cancelAction={(value) => cancelAction(value)}
            group={group}
          />
          <Tooltip placement="topLeft" title="Reload">
            <Button
              className="refresh ms-3"
              shape="circle"
              onClick={() => {
                refresh(true);
              }}
              disabled={!group}
            >
              <ReloadOutlined />
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className="d-flex mb-3">
        {!hideComponents ? (
        // showProjectListLoader ? (
        //   <Spin size="large" tip="Loading data..." />
        // ) : (
          <>
            <div className="flex-auto">
              <CreateProject
                group={group}
                masterProject={projectList}
              />
            </div>

          </>
        // )

        ) : null}
        {dataSource.length > 0 && (
          showProjectListLoader ? (
            <div className="d-flex align-items-center justify-content-center h-100">

              {' '}
              <Spin style={{ paddingTop: '25px' }} size="large" tip="Loading data..." />

              {' '}

            </div>
          ) : (
            <div className="flex-fill overflow-auto">
              <div className="flexslider h-100">
                <Carousel
                  breakPoints={carouselBreakWidths}
                  initialActiveIndex={activeIndex}
                  onChange={changeIndex}
                  showEmptySlots
                >
                  {dataSource.map((item) => (
                    <div className={!disableSelection ? 'float-start slides-divs slides' : 'float-start slides-divs disable-slides'} key={item.id}>
                      <a
                        id={item.id}
                        onClick={!disableSelection ? () => {
                          getSelectedProjectId(item.id);
                        } : null}
                        className={
                  item.id === selectedProject.id
                    ? 'slides-items active'
                    : 'slides-items'
                      }
                      >
                        <div className="text-center project-name-number">
                          {' '}
                          <Tooltip placement="topLeft" title={item.projectName}>
                            {item.projectName}
                          </Tooltip>
                        </div>
                        <div className="text-center project-name-number">
                          {' '}
                          <Tooltip placement="topLeft" title={item.projectNo}>
                            {item.projectNo}
                          </Tooltip>
                        </div>
                      </a>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          )

        )}
      </div>
      <div className="custom-line mb-3" />
      {selectedProject.length !== 0 && (
        <Collapse ghost className="project-detail-collapse">
          <Panel header="Project Details" className="project-sub-title">
            <div className="d-flex justify-content-between mb-2 overflow-auto">
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">Contractor</p>
                  <div className="d-flex edit-session justify-content-center mb-3">
                    <Tooltip
                      placement="topLeft"
                      title={selectedProject ? selectedProject.contractor : ''}
                    >
                      <p className="mb-0 name-max-width">
                        {selectedProject ? selectedProject.contractor : ''}
                      </p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">Project Type</p>
                  <div className="d-flex edit-session justify-content-center mb-3">
                    <Tooltip
                      placement="topLeft"
                      title={selectedProject ? constants.projectTypeMap.get(selectedProject?.projectType) : ''}
                    >
                      <p className="mb-0 name-max-width">
                        {selectedProject ? constants.projectTypeMap.get(selectedProject?.projectType) : ''}
                      </p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">
                    Project Manager Email
                    {selectedProject.length !== 0 ? (
                      <span>
                        {!isEditProjectManagerEmail ? (
                          <>
                            {' '}
                            <EditOutlined
                              className={disableSelection ? 'edit-project-detail-disable' : 'edit-project-detail'}
                              onClick={!disableSelection ? () => setIsEditProjectManagerEmail(true)
                                : false}
                            />
                          </>
                        ) : (
                          <>
                            {' '}
                            <CloseOutlined
                              onClick={() => {
                                setIsEditProjectManagerEmail(false);
                                setIsValidEmail(true);
                              }}
                            />
                          </>

                        )}
                      </span>
                    ) : null}

                  </p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    {isEditProjectManagerEmail ? (
                      <Input.Group compact>
                        <Input
                          style={{ width: 'calc(100% - 45px)' }}
                          placeholder=""
                          defaultValue={projectManagerEmail ? projectManagerEmail : ''}
                          onChange={(e) => {
                            const validEmailRegex = /^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/gi;
                            setIsValidEmail(validEmailRegex.test(e.target.value));
                            setProjectManagerEmailInput(e.target.value);
                            setValueUpdated(true);
                          }}
                        />
                        <Button
                          type="primary"
                          disabled={!isValidEmail}
                          onClick={() => {
                            if (startDate) {
                              if (projectManagerEmailInput && valueUpdated) {
                                setProjectManagerEmail(projectManagerEmailInput);
                                updatePjctData(
                                  startDateInput
                                    ? startDateInput
                                    : startDate
                                      ? startDate
                                      : null,
                                  endDateInput
                                    ? endDateInput
                                    : endDate
                                      ? endDate
                                      : null,
                                  plannedWeldInput
                                    ? plannedWeldInput
                                    : plannedWeld
                                      ? plannedWeld
                                      : 0,
                                  projectManagerEmailInput,
                                  projectStatusInput ? projectStatusInput : (projectStatus === 'In Progress' ? 'InProgress' : 'Archived'),
                                  docusignRequiredInput ? docusignRequiredInput : docusignRequired,
                                  projectLeadTechInput
                                    ? projectLeadTechInput
                                    : projectLeadTech
                                      ? projectLeadTech
                                      : ''
                                );
                              }
                            } else {
                              notification.warning({
                                message: 'Start date is required.',
                              });
                            }
                            setIsEditProjectManagerEmail(false);
                          }}
                        >
                          <CheckOutlined />
                          {' '}
                        </Button>
                      </Input.Group>

                    ) : (
                      <Tooltip
                        placement="topLeft"
                        title={projectManagerEmail}
                      >
                        <p className="mb-0 name-max-width">
                          {projectManagerEmail}
                        </p>
                      </Tooltip>
                    )}
                  </div>
                  {
                    isEditProjectManagerEmail && !isValidEmail ? (
                      <span className="project-detail-email-edit">Provide a valid Project Manager Email.</span>
                    ) : null
                  }

                </div>
              </div>
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">
                    Lead Tech
                    {selectedProject.length !== 0 ? (
                      <span>
                        {!isEditProjectLeadTech ? (
                          <>
                            {' '}
                            <EditOutlined
                              className={disableSelection ? 'edit-project-detail-disable' : 'edit-project-detail'}
                              onClick={!disableSelection ? () => setIsEditProjectLeadTech(true)
                                : false}
                            />
                          </>
                        ) : (
                          <>
                            {' '}
                            <CloseOutlined
                              onClick={() => {
                                setIsEditProjectLeadTech(false);
                                setIsValidLeadTech(true);
                              }}
                            />
                          </>

                        )}
                      </span>
                    ) : null}
                  </p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    {isEditProjectLeadTech ? (
                      <Input.Group compact>
                        <Input
                          style={{ width: 'calc(100% - 45px)' }}
                          placeholder=""
                          defaultValue={projectLeadTech ? projectLeadTech : ''}
                          onKeyPress={(e) => onlyCharacterKey(e)}
                          onChange={(e) => {
                            if (e.target.value.length === 0) {
                              setIsValidLeadTech(false);
                            } else {
                              setIsValidLeadTech(true);
                            }
                            setProjectLeadTechInput(e.target.value);
                            setValueUpdated(true);
                          }}
                        />
                        <Button
                          type="primary"
                          disabled={!isValidLeadTech}
                          onClick={() => {
                            if (startDate) {
                              if (projectLeadTechInput && valueUpdated) {
                                setProjectLeadTech(projectLeadTechInput);
                                updatePjctData(
                                  startDateInput
                                    ? startDateInput
                                    : startDate
                                      ? startDate
                                      : null,
                                  endDateInput
                                    ? endDateInput
                                    : endDate
                                      ? endDate
                                      : null,
                                  plannedWeldInput
                                    ? plannedWeldInput
                                    : plannedWeld
                                      ? plannedWeld
                                      : 0,
                                  projectManagerEmailInput
                                    ? projectManagerEmailInput
                                    : projectManagerEmail
                                      ? projectManagerEmail
                                      : '',
                                  projectStatusInput ? projectStatusInput : (projectStatus === 'In Progress' ? 'InProgress' : 'Archived'),
                                  docusignRequiredInput ? docusignRequiredInput : docusignRequired,
                                  projectLeadTechInput
                                );
                              }
                            } else {
                              notification.warning({
                                message: 'Start date is required.',
                              });
                            }
                            setIsEditProjectLeadTech(false);
                          }}
                        >
                          <CheckOutlined />
                          {' '}
                        </Button>
                      </Input.Group>

                    ) : (
                      <Tooltip
                        placement="topLeft"
                        title={projectLeadTech}
                      >
                        <p className="mb-0 name-max-width">
                          {projectLeadTech}
                        </p>
                      </Tooltip>
                    )}
                  </div>
                  {
                    isEditProjectLeadTech && !isValidLeadTech ? (
                      <span className="project-detail-email-edit">Provide a valid Lead Tech name.</span>
                    ) : null
                  }
                </div>
              </div>
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">
                    Start Date
                    {selectedProject.length !== 0 ? (
                      <span className="ps-2">
                        {!isEditStartDate ? (
                          <>
                            {' '}
                            <EditOutlined
                              onClick={!disableSelection ? () => setIsEditStartDate(true) : false}
                              className={disableSelection ? 'edit-project-detail-disable' : 'edit-project-detail'}
                            />
                          </>
                        ) : (
                          <>
                            {' '}
                            <CloseOutlined
                              onClick={() => setIsEditStartDate(false)}
                            />
                          </>
                        )}
                      </span>
                    ) : null}
                  </p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    {isEditStartDate ? (
                      <>
                        <div className="d-flex justify-content-center ">
                          <DatePicker
                            format="DD-MMM-YY"
                            bordered={false}
                            defaultValue={
                        startDate ? moment(startDate, 'DD-MMM-YY') : ''
                      }
                            onChange={(date, dateString) => {
                              setStartDateInput(dateString);
                              setValueUpdated(true);
                            }}
                          />
                          <Button
                            type="primary"
                            onClick={() => {
                              const strtDate = startDateInput
                                ? moment(startDateInput).format('L')
                                : moment(startDate).format('L');
                              const edDate = endDateInput
                                ? moment(endDateInput).format('L')
                                : moment(endDate).format('L');
                              let count = 0;
                              if (
                                startDateInput
                          && new Date(strtDate) > new Date(edDate)
                              ) {
                                count = 1;
                              }
                              if (count === 1) {
                                notification.warning({
                                  message:
                              'Start date should be less than the end date.',
                                });
                              } else {
                                if (startDateInput && valueUpdated) {
                                  setStartDate(startDateInput);
                                  updatePjctData(
                                    startDateInput,
                                    endDateInput ? endDateInput : endDate,
                                    plannedWeldInput
                                      ? plannedWeldInput
                                      : plannedWeld
                                        ? plannedWeld
                                        : 0,
                                    projectManagerEmailInput
                                      ? projectManagerEmailInput
                                      : projectManagerEmail
                                        ? projectManagerEmail
                                        : '',
                                    projectStatusInput ? projectStatusInput : (projectStatus === 'In Progress' ? 'InProgress' : 'Archived'),
                                    docusignRequiredInput ? (docusignRequiredInput === 'No' ? false : true) : docusignRequired,
                                    projectLeadTechInput
                                      ? projectLeadTechInput
                                      : projectLeadTech
                                        ? projectLeadTech
                                        : ''
                                  );
                                }
                              }
                              setIsEditStartDate(false);
                            }}
                          >
                            <CheckOutlined />
                            {' '}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <p className="mb-0">{startDate ? startDate : ''}</p>
                        {' '}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">
                    End Date
                    {selectedProject.length !== 0 ? (
                      <span className="ps-2">
                        {!isEditEndDate ? (
                          <>
                            {' '}
                            <EditOutlined
                              onClick={!disableSelection ? () => setIsEditEndDate(true) : false}
                              className={disableSelection ? 'edit-project-detail-disable' : 'edit-project-detail'}
                            />
                          </>
                        ) : (
                          <>
                            {' '}
                            <CloseOutlined onClick={() => setIsEditEndDate(false)} />
                          </>
                        )}
                      </span>
                    ) : null}
                  </p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    {isEditEndDate ? (
                      <div className="d-flex justify-content-center ">
                        <DatePicker
                          format="DD-MMM-YY"
                          bordered={false}
                          defaultValue={endDate ? moment(endDate, 'DD-MMM-YY') : ''}
                          onChange={(date, dateString) => {
                            setEndDateInput(dateString);
                            setValueUpdated(true);
                          }}
                        />
                        <Button
                          type="primary"
                          onClick={!disableSelection ? () => {
                            const strtDate = startDateInput
                              ? moment(startDateInput).format('L')
                              : moment(startDate).format('L');
                            const edDate = endDateInput
                              ? moment(endDateInput).format('L')
                              : moment(endDate).format('L');
                            let count = 0;
                            if (startDate && new Date(edDate) < new Date(strtDate)) {
                              count = 1;
                            }
                            if (count === 1) {
                              notification.warning({
                                message:
                            'End date should be greater than start date.',
                              });
                            } else {
                              if (startDate) {
                                if (endDateInput && valueUpdated) {
                                  setEndDate(endDateInput);
                                  updatePjctData(
                                    startDateInput
                                      ? startDateInput
                                      : startDate
                                        ? startDate
                                        : null,
                                    endDateInput,
                                    plannedWeldInput
                                      ? plannedWeldInput
                                      : plannedWeld
                                        ? plannedWeld
                                        : 0,
                                    projectManagerEmailInput
                                      ? projectManagerEmailInput
                                      : projectManagerEmail
                                        ? projectManagerEmail
                                        : '',
                                    projectStatusInput ? projectStatusInput : (projectStatus === 'In Progress' ? 'InProgress' : 'Archived'),
                                    docusignRequiredInput ? (docusignRequiredInput === 'No' ? false : true) : docusignRequired,
                                    projectLeadTechInput
                                      ? projectLeadTechInput
                                      : projectLeadTech
                                        ? projectLeadTech
                                        : ''
                                  );
                                }
                              } else {
                                notification.warning({
                                  message: 'Start date is required.',
                                });
                              }
                            }

                            setIsEditEndDate(false);
                          } : false}
                        >
                          <CheckOutlined />
                          {' '}
                        </Button>
                      </div>
                    ) : (
                      <>
                        <p className="mb-0">{endDate ? endDate : ''}</p>
                        {' '}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">
                    eSign for LEM
                    {selectedProject.length !== 0 ? (
                      <span className="ps-2">
                        {!isEditDocuSignReqd ? (
                          <>
                            {' '}
                            <EditOutlined
                              onClick={!disableSelection ? () => { setIsEditDocuSignReqd(true); }
                                : false}
                              className={disableSelection ? 'edit-project-detail-disable'
                                : 'edit-project-detail'}
                            />
                          </>
                        ) : (
                          <>
                            {' '}
                            <CloseOutlined onClick={() => setIsEditDocuSignReqd(false)} />
                          </>
                        )}
                      </span>
                    ) : null}
                  </p>
                  <div
                    className="d-flex edit-session justify-content-center mb-6"
                    style={{ width: '106%' }}
                  >
                    {isEditDocuSignReqd ? (
                      <div className="d-flex justify-content-center" style={{ alignItems: 'baseline' }}>
                        <Radio.Group onChange={onChange} defaultValue={docusignRequired ? 'Yes' : 'No'}>
                          <Radio
                            value="Yes"
                          >
                            {' '}
                            Yes
                            {' '}

                          </Radio>
                          <Radio
                            value="No"
                          >
                            {' '}
                            No
                            {' '}

                          </Radio>
                        </Radio.Group>

                        <Button
                          type="primary"
                          onClick={() => {
                            if (startDate) {
                              if (docusignRequiredInput && valueUpdated) {
                                setDocuSignRequired(docusignRequiredInput === 'No' ? false : true);
                                updatePjctData(
                                  startDateInput
                                    ? startDateInput
                                    : startDate
                                      ? startDate
                                      : null,
                                  endDateInput
                                    ? endDateInput
                                    : endDate
                                      ? endDate
                                      : null,
                                  plannedWeldInput
                                    ? plannedWeldInput
                                    : plannedWeld
                                      ? plannedWeld
                                      : 0,
                                  projectManagerEmailInput
                                    ? projectManagerEmailInput
                                    : projectManagerEmail
                                      ? projectManagerEmail
                                      : '',
                                  projectStatusInput
                                    ? projectStatusInput
                                    : (projectStatus === 'In Progress' ? 'InProgress' : 'Archived'),
                                  docusignRequiredInput === 'No' ? false : true
                                );
                              }
                            } else {
                              notification.warning({
                                message: 'Start date is required.',
                              });
                            }
                            setIsEditDocuSignReqd(false);
                          }}
                        >
                          <CheckOutlined />
                          {' '}
                        </Button>
                      </div>
                    ) : (
                      <>
                        <p className="mb-0">{docusignRequired ? 'Enabled' : 'Disabled'}</p>
                        {' '}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">
                    Planned Welds
                    {selectedProject.length !== 0 ? (
                      <span className="ps-2">
                        {!isEditPlannedWelds ? (
                          <>
                            {' '}
                            <EditOutlined
                              className={disableSelection ? 'edit-project-detail-disable' : 'edit-project-detail'}
                              onClick={!disableSelection ? () => setIsEditPlannedWelds(true)
                                : false}
                            />
                          </>
                        ) : (
                          <>
                            {' '}
                            <CloseOutlined
                              onClick={() => setIsEditPlannedWelds(false)}
                            />
                          </>

                        )}
                      </span>
                    ) : null}
                  </p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    {isEditPlannedWelds ? (
                      <Input.Group compact>
                        <Input
                          style={{ width: 'calc(100% - 45px)' }}
                          placeholder=""
                          defaultValue={plannedWeld ? plannedWeld : 0}
                          maxLength={6}
                          onKeyPress={(e) => onlyNumberKey(e)}
                          onChange={(e) => {
                            setPlannedWeldInput(e.target.value);
                            setValueUpdated(true);
                          }}
                        />
                        <Button
                          type="primary"
                          onClick={() => {
                            if (startDate) {
                              if (plannedWeldInput && valueUpdated) {
                                setPlannedWeld(plannedWeldInput);
                                updatePjctData(
                                  startDateInput
                                    ? startDateInput
                                    : startDate
                                      ? startDate
                                      : null,
                                  endDateInput
                                    ? endDateInput
                                    : endDate
                                      ? endDate
                                      : null,
                                  plannedWeldInput,
                                  projectManagerEmailInput
                                    ? projectManagerEmailInput
                                    : projectManagerEmail
                                      ? projectManagerEmail
                                      : '',
                                  projectStatusInput ? projectStatusInput : (projectStatus === 'In Progress' ? 'InProgress' : 'Archived'),
                                  docusignRequiredInput ? (docusignRequiredInput === 'No' ? false : true) : docusignRequired,
                                  projectLeadTechInput
                                    ? projectLeadTechInput
                                    : projectLeadTech
                                      ? projectLeadTech
                                      : ''
                                );
                              }
                            } else {
                              notification.warning({
                                message: 'Start date is required.',
                              });
                            }
                            setIsEditPlannedWelds(false);
                          }}
                        >
                          <CheckOutlined />
                          {' '}
                        </Button>
                      </Input.Group>
                    ) : (
                      <>
                        <p className="mb-0">{plannedWeld}</p>
                        {' '}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">
                    Status
                    {selectedProject.length !== 0 ? (
                      <span className="ps-2">
                        {!isEditProjectStatus ? (
                          <>
                            {' '}
                            <EditOutlined
                              className={disableSelection ? 'edit-project-detail-disable' : 'edit-project-detail'}
                              onClick={!disableSelection ? () => setIsEditProjectStatus(true)
                                : false}
                            />
                          </>
                        ) : (
                          <>
                            {' '}
                            <CloseOutlined
                              onClick={() => setIsEditProjectStatus(false)}
                            />
                          </>
                        )}
                      </span>
                    ) : null}
                  </p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    {isEditProjectStatus ? (
                      <>
                        <Select
                          defaultValue={projectStatus ? projectStatus : ''}
                          onChange={(value) => {
                            setProjectStatusInput(value);
                            setValueUpdated(true);
                          }}
                        >
                          <Option value="InProgress">In Progress</Option>
                          <Option value="Archived">Archived</Option>
                        </Select>
                        <Button
                          type="primary"
                          onClick={(value) => {
                            if (startDate) {
                              if (projectStatusInput && valueUpdated) {
                                setProjectStatus(projectStatusInput);
                                updatePjctData(
                                  startDateInput
                                    ? startDateInput
                                    : startDate
                                      ? startDate
                                      : null,
                                  endDateInput
                                    ? endDateInput
                                    : endDate
                                      ? endDate
                                      : null,
                                  plannedWeldInput
                                    ? plannedWeldInput
                                    : plannedWeld
                                      ? plannedWeld
                                      : 0,
                                  projectManagerEmailInput
                                    ? projectManagerEmailInput
                                    : projectManagerEmail
                                      ? projectManagerEmail
                                      : '',
                                  projectStatusInput,
                                  docusignRequiredInput ? (docusignRequiredInput === 'No' ? false : true) : docusignRequired,
                                  projectLeadTechInput
                                    ? projectLeadTechInput
                                    : projectLeadTech
                                      ? projectLeadTech
                                      : ''
                                );
                              }
                            } else {
                              notification.warning({
                                message: 'Start date is required.',
                              });
                            }
                            setIsEditProjectStatus(false);
                          }}
                        >
                          <CheckOutlined />
                          {' '}
                        </Button>
                      </>
                    ) : (
                      <>
                        <p className="mb-0">{projectStatus}</p>
                        {' '}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">Completed Welds</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <p className="mb-0">
                      {selectedProject && completedWeldsCount !== 0
                        ? completedWeldsCount
                        : ''}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
      )}

      <div className="custom-line-collapse mb-3" />

    </>
  );
};

export default ProjectListHeader;
