/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const trimSpacesInValues = (inputValue) => {
  if (inputValue !== undefined || inputValue !== null) {
    let value = inputValue.trimLeft();
    value = value.replace(/( )\1+/g, '$1');
    return value;
  }
  return '';
};

const columnToLetter = (columnNumber) => {
  let column = columnNumber;
  let temp; let
    letter = '';
  while (column > 0) {
    temp = (column - 1) % 26;
    letter = String.fromCharCode(temp + 65) + letter;
    column = (column - temp - 1) / 26;
  }
  return letter;
};

export const onlyNumberKey = (event) => {
  // Only ASCII character in that range allowed
  const ASCIICode = (event.which) ? event.which : event.keyCode;
  if (ASCIICode >= 48 && ASCIICode <= 57) {
    return true;
  }
  event.preventDefault();
  return false;
};

const writeProjectDetailsToWorksheet = (worksheet, projName, projNo, projLead, projContractor) => {
  worksheet.mergeCells('A1:B1');
  worksheet.getCell('A1').font = { bold: true };
  worksheet.getCell('A1').value = 'Project Name';
  worksheet.getCell('A1').alignment = { vertical: 'top', horizontal: 'left' };
  worksheet.getCell('C1').font = { bold: true };
  worksheet.getCell('C1').value = ':';
  worksheet.getCell('C1').alignment = { vertical: 'top', horizontal: 'center' };

  worksheet.mergeCells('D1:E1');
  worksheet.getCell('D1').value = `${projName}`;
  worksheet.getCell('D1').alignment = { vertical: 'top', horizontal: 'left' };

  worksheet.mergeCells('G1:H1');
  worksheet.getCell('G1').font = { bold: true };
  worksheet.getCell('G1').value = 'Project Number';
  worksheet.getCell('G1').alignment = { vertical: 'top', horizontal: 'left' };
  worksheet.getCell('I1').font = { bold: true };
  worksheet.getCell('I1').value = ':';
  worksheet.getCell('I1').alignment = { vertical: 'top', horizontal: 'center' };

  worksheet.mergeCells('J1:K1');
  worksheet.getCell('J1').value = `${projNo}`;
  worksheet.getCell('J1').alignment = { vertical: 'top', horizontal: 'left' };

  worksheet.mergeCells('A2:B2');
  worksheet.getCell('A2').font = { bold: true };
  worksheet.getCell('A2').value = 'Lead Tech';
  worksheet.getCell('A2').alignment = { vertical: 'top', horizontal: 'left' };
  worksheet.getCell('C2').font = { bold: true };
  worksheet.getCell('C2').value = ':';
  worksheet.getCell('C2').alignment = { vertical: 'top', horizontal: 'center' };

  worksheet.mergeCells('D2:E2');
  worksheet.getCell('D2').value = `${projLead}`;
  worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'left' };

  worksheet.mergeCells('G2:H2');
  worksheet.getCell('G2').font = { bold: true };
  worksheet.getCell('G2').value = 'Contractor';
  worksheet.getCell('G2').alignment = { vertical: 'top', horizontal: 'left' };
  worksheet.getCell('I2').font = { bold: true };
  worksheet.getCell('I2').value = ':';
  worksheet.getCell('I2').alignment = { vertical: 'top', horizontal: 'center' };

  worksheet.mergeCells('J2:K2');
  worksheet.getCell('J2').value = `${projContractor}`;
  worksheet.getCell('J2').alignment = { vertical: 'top', horizontal: 'left' };
};

export const writeWorkSheet = (sheet, crews, data, projName, projNo, projLead, projContractor) => {
  const worksheet = sheet;
  worksheet.getRow(4).font = { bold: true };

  for (let i = 0; i < (crews.length) * 2; i += 2) {
    const colName = columnToLetter(i + 2);
    const nextColName = columnToLetter(i + 3);
    worksheet.mergeCells(`${colName}4:${nextColName}4`);
    worksheet.getCell(`${colName}4`).value = crews[Math.floor(i / 2)].name;
    worksheet.getCell(`${colName}4`).alignment = { vertical: 'top', horizontal: 'left' };
    worksheet.getCell(`${colName}5`).value = 'Welds';
    worksheet.getCell(`${nextColName}5`).value = 'Repairs';
  }

  worksheet.getCell(`${columnToLetter((crews.length) * 2 + 2)}4`).value = 'Total Welds';
  worksheet.getCell(`${columnToLetter((crews.length) * 2 + 3)}4`).value = 'Total Repairs';
  worksheet.getCell(`${columnToLetter((crews.length) * 2 + 4)}4`).value = 'Comments';
  worksheet.mergeCells('A4:A5');
  worksheet.getCell('A4').value = 'Dates';
  worksheet.getCell('A4').alignment = { vertical: 'top', horizontal: 'left' };

  // Project Details
  writeProjectDetailsToWorksheet(worksheet, projName, projNo, projLead, projContractor);

  data.forEach((singleData) => {
    worksheet.addRow(singleData);
  });
  return worksheet;
};

export const writeTimesheetWorkSheet = (sheet, users, data, projName, projNo, projLead, projContractor, timesheetData) => {
  const worksheet = sheet;

  worksheet.getRow(7).font = { bold: true };
  const usr = users.filter((item,
    index) => users.indexOf(item) === index);
  for (let i = 0; i < (usr.length) * 2; i += 1) {
    const colName = columnToLetter(i + 2);
    worksheet.getCell(`${colName}7`).value = usr[Math.floor(i / 1)];
    worksheet.getCell(`${colName}7`).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
  }
  worksheet.getCell('A7').value = 'Dates';
  worksheet.getCell('A7').alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };

  worksheet.getCell('A4').value = 'Total Days Worked';
  worksheet.getCell('A4').font = { bold: true };
  worksheet.getCell('A4').alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };

  worksheet.getCell('A5').value = 'Total Hours Worked';
  worksheet.getCell('A5').font = { bold: true };
  worksheet.getCell('A5').alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };

  timesheetData.map((val, i) => {
    const colName = columnToLetter(i + 2);
    worksheet.getCell(`${colName}4`).value = val.totalDays;
    worksheet.getCell(`${colName}4`).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    worksheet.getCell(`${colName}5`).value = val.totalHours;
    worksheet.getCell(`${colName}5`).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
  });

  // Project Details
  writeProjectDetailsToWorksheet(worksheet, projName, projNo, projLead, projContractor);

  data.forEach((singleData) => {
    worksheet.addRow(singleData);
  });
  return worksheet;
};

export const writeOffshoreTimesheetWorkSheet = (sheet, daysCount, data, projName, projNo, projLead, projContractor, startDate, endDate) => {
  const worksheet = sheet;

  worksheet.getRow(7).font = { bold: true };

  worksheet.mergeCells('A3:B3');
  worksheet.getCell('A3').font = { bold: true };
  worksheet.getCell('A3').value = 'Project Name';
  worksheet.getCell('A3').alignment = { vertical: 'top', horizontal: 'left' };
  worksheet.getCell('C3').font = { bold: true };
  worksheet.getCell('C3').value = ':';
  worksheet.getCell('C3').alignment = { vertical: 'top', horizontal: 'center' };

  worksheet.mergeCells('D3:E3');
  worksheet.getCell('D3').value = `${startDate} to ${endDate}`;
  worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'left' };

  let count;

  for (let i = 0; i < (daysCount.length); i += 1) {
    const colName = columnToLetter(i + 5);
    worksheet.getCell(`${colName}7`).value = daysCount[Math.floor(i / 1)];
    worksheet.getCell(`${colName}7`).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    count = i;
  }
  count += 5;
  worksheet.getCell(`${columnToLetter(count + 1)}7`).value = 'Days';
  worksheet.getCell(`${columnToLetter(count + 1)}7`).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

  worksheet.getCell(`${columnToLetter(count + 2)}7`).value = 'Rate/day';
  worksheet.getCell(`${columnToLetter(count + 2)}7`).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

  worksheet.getCell(`${columnToLetter(count + 3)}7`).value = 'Amount';
  worksheet.getCell(`${columnToLetter(count + 3)}7`).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

  worksheet.getCell('A7').value = 'Pipeline';
  worksheet.getCell('A7').alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };

  worksheet.getCell('B7').value = 'Name';
  worksheet.getCell('B7').alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };

  worksheet.getCell('C7').value = 'Position';
  worksheet.getCell('C7').alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };

  worksheet.getCell('D7').value = 'Shift';
  worksheet.getCell('D7').alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };

  writeProjectDetailsToWorksheet(worksheet, projName, projNo, projLead, projContractor);
  data.forEach((singleData) => {
    worksheet.addRow(singleData);
  });
  return worksheet;
};

export const writeUserTimesheetWorkSheet = (sheet, data, projName, projNo, projLead, projContractor) => {
  const finalData = [];
  const worksheet = sheet;
  worksheet.getRow(4).font = { bold: true };

  worksheet.getCell('A4').font = { bold: true };
  worksheet.getCell('A4').value = 'Date';
  worksheet.getCell('A4').alignment = { vertical: 'top', horizontal: 'center', wrapText: true };

  worksheet.getCell('B4').font = { bold: true };
  worksheet.getCell('B4').value = 'Hours Worked';
  worksheet.getCell('B4').alignment = { vertical: 'top', horizontal: 'center', wrapText: true };

  data.forEach((dt) => {
    finalData.push({ date: dt.date, hoursWorked: dt.hoursWorked });
  });

  // Project Details
  writeProjectDetailsToWorksheet(worksheet, projName, projNo, projLead, projContractor);

  finalData.forEach((singleData) => {
    worksheet.addRow(singleData);
  });
  return worksheet;
};

export const prepareDataForExcel = (crewData, reportDetails) => {
  const columns = [{ key: 'date' }];
  crewData.forEach((crew) => {
    columns.push({ key: `${crew.name}_weld` });
    columns.push({ key: `${crew.name}_repair` });
  });
  columns.push({ key: 'totalWelds' }, { key: 'totalRepairs' }, { key: 'comments' });

  const data = [];
  reportDetails.forEach((reportData) => {
    let rowData = {};
    Object.keys(reportData).forEach((info) => {
      let weldKey = '';
      let repairKey = '';
      if (reportData[info] && reportData[info].crewName) {
        weldKey = `${reportData[info].crewName}_weld`;
        repairKey = `${reportData[info].crewName}_repair`;
        rowData = {
          ...rowData,
          [weldKey]: reportData[info].welds,
          [repairKey]: reportData[info].repairs
        };
      }
    });
    data.push({
      ...rowData,
      date: reportData.date,
      comments: reportData.comments,
      totalWelds: reportData.totalWelds,
      totalRepairs: reportData.totalRepairs
    });
  });
  return { data, columns };
};

export const prepareTimesheetDataForExcel = (userData, timesheetDetails) => {
  const usr = [];
  const cols = [{ key: 'date' }];
  userData.forEach((user) => {
    if (!usr.includes(user)) {
      usr.push(user);
      cols.push({ key: `${user}_hrsSpent` });
    }
  });
  const dt = [];
  timesheetDetails.forEach((timesheetData) => {
    let rowData = {};
    Object.keys(timesheetData).forEach((info) => {
      let hrsKey = '';
      if (timesheetData[info] && timesheetData[info].name) {
        hrsKey = `${timesheetData[info].name}_hrsSpent`;
        rowData = {
          ...rowData,
          [hrsKey]: timesheetData[info].hrs_spent,
        };
      }
    });
    dt.push({
      ...rowData,
      date: timesheetData.date,
      comments: timesheetData.comments
    });
  });
  return { dt, cols };
};

export const prepareOffshoreTimesheetDataForExcel = (days, timesheetDetails) => {
  const dayKey = [];
  const cols = [{ key: 'pipeline' }, { key: 'name' }, { key: 'role' }, { key: 'shift' }];
  days.forEach((d) => {
    if (!dayKey.includes(d)) {
      dayKey.push(d);
      cols.push({ key: `${d}` });
    }
  });
  cols.push({ key: 'days' });
  return cols;
};

export const prepareUserWiseTimesheetDataForExcel = () => {
  const cols = [{ key: 'date' }];
  cols.push({ key: 'hoursWorked' });
  return { cols };
};

export const prepareDataForCombinedExcel = (crewData, reportDetails) => {
  const columns = [{ key: 'reportingPeriod' }, { key: 'date' }];
  crewData.forEach((crew) => {
    columns.push({ key: `${crew}_weld` });
    columns.push({ key: `${crew}_repair` });
  });
  columns.push({ key: 'totalWelds' }, { key: 'totalRepairs' }, { key: 'comments' });

  const data = [];
  reportDetails.forEach((reportData) => {
    let rowData = {};
    Object.keys(reportData).forEach((info) => {
      let weldKey = '';
      let repairKey = '';
      if (reportData[info] && reportData[info].crewName) {
        weldKey = `${reportData[info].crewName}_weld`;
        repairKey = `${reportData[info].crewName}_repair`;
        rowData = {
          ...rowData,
          [weldKey]: reportData[info].welds,
          [repairKey]: reportData[info].repairs
        };
      }
    });
    data.push({
      ...rowData,
      date: reportData.date,
      comments: reportData.comments,
      reportingPeriod: reportData.reportingPeriod,
      totalWelds: reportData.totalWelds,
      totalRepairs: reportData.totalRepairs
    });
  });
  return { data, columns };
};

export const prepareDataForEMEADPRExcel = () => {
  const columns = [
    { key: 'date' },
    { key: 'weld_count_accepted' },
    { key: 'weld_count_repairs' },
    { key: 'repair_rate' },
    { key: 'downtime_contractor' },
    { key: 'downtime_crce_equipment' },
    { key: 'downtime_crce_personnel' },
    { key: 'downtime' },
    { key: 'comments' },
  ];

  return { columns };
};

export const prepareDataForEMEADPRExcelOverall = () => {
  const columns = [
    { key: 'period' },
    { key: 'date' },
    { key: 'weld_count_accepted' },
    { key: 'weld_count_repairs' },
    { key: 'repair_rate' },
    { key: 'downtime_contractor' },
    { key: 'downtime_crce_equipment' },
    { key: 'downtime_crce_personnel' },
    { key: 'downtime' },
    { key: 'comments' },
  ];

  return { columns };
};

export const writeWorkSheetForCombined = (sheet, crews, data) => {
  const worksheet = sheet;
  worksheet.getRow(1).font = { bold: true };

  for (let i = 1; i <= (crews.length) * 2; i += 2) {
    const colName = columnToLetter(i + 2);
    const nextColName = columnToLetter(i + 3);
    worksheet.mergeCells(`${colName}1:${nextColName}1`);
    worksheet.getCell(`${colName}1`).value = crews[Math.floor(i / 2)];
    worksheet.getCell(`${colName}1`).alignment = { vertical: 'top', horizontal: 'left' };
    worksheet.getCell(`${colName}2`).value = 'Welds';
    worksheet.getCell(`${nextColName}2`).value = 'Repairs';
  }

  worksheet.getCell(`${columnToLetter((crews.length) * 2 + 3)}1`).value = 'Total Welds';
  worksheet.getCell(`${columnToLetter((crews.length) * 2 + 4)}1`).value = 'Total Repairs';
  worksheet.getCell(`${columnToLetter((crews.length) * 2 + 5)}1`).value = 'Comments';
  worksheet.mergeCells('A4:A5');
  worksheet.getCell('A4').value = 'Reporting Period';
  worksheet.getCell('A4').alignment = { vertical: 'top', horizontal: 'left' };

  worksheet.mergeCells('B4:B5');
  worksheet.getCell('B4').value = 'Dates';
  worksheet.getCell('B4').alignment = { vertical: 'top', horizontal: 'left' };

  data.forEach((singleData) => {
    worksheet.addRow(singleData);
  });
  return worksheet;
};

export const writeWorkSheetForEMEADPR = (sheet, data) => {
  const worksheet = sheet;
  worksheet.getRow(1).font = { bold: true };

  worksheet.mergeCells('A1:A2');
  worksheet.getCell('A1').value = 'Dates';

  worksheet.mergeCells('B1:C1:D1');
  worksheet.getCell('B1').value = 'Production';

  worksheet.mergeCells('E1:F1:G1:H1');
  worksheet.getCell('E1').value = 'Downtime';

  worksheet.mergeCells('I1:I2');
  worksheet.getCell('I1').value = 'Comments';

  worksheet.getCell('B2').value = 'Welds';
  worksheet.getCell('C2').value = 'Repairs';
  worksheet.getCell('D2').value = 'Repair Rate';

  worksheet.getCell('E2').value = 'Contractor';
  worksheet.getCell('F2').value = 'CRCE Equipment';
  worksheet.getCell('G2').value = 'CRCE Personnel';
  worksheet.getCell('H2').value = 'Total';

  data.forEach((singleData) => {
    worksheet.addRow(singleData);
  });
  return worksheet;
};

export const writeWorkSheetForEMEADPROverall = (sheet, data) => {
  const worksheet = sheet;
  worksheet.getRow(1).font = { bold: true };

  worksheet.mergeCells('A1:A2');
  worksheet.getCell('A1').value = 'Month';

  worksheet.mergeCells('B1:B2');
  worksheet.getCell('B1').value = 'Dates';

  worksheet.mergeCells('C1:D1:E1');
  worksheet.getCell('C1').value = 'Production';

  worksheet.mergeCells('F1:G1:H1:I1');
  worksheet.getCell('F1').value = 'Downtime';

  worksheet.mergeCells('J1:J2');
  worksheet.getCell('J1').value = 'Comments';

  worksheet.getCell('C2').value = 'Welds';
  worksheet.getCell('D2').value = 'Repairs';
  worksheet.getCell('E2').value = 'Repair Rate';

  worksheet.getCell('F2').value = 'Contractor';
  worksheet.getCell('G2').value = 'CRCE Equipment';
  worksheet.getCell('H2').value = 'CRCE Personnel';
  worksheet.getCell('I2').value = 'Total';

  data.forEach((singleData) => {
    worksheet.addRow(singleData);
  });
  return worksheet;
};

export const toNumber = (value) => (value ? Number(value) : 0);

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getOrdinal(day) {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const v = day % 100;
  return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
}

export const getOrdinalDaysArray = (startDate, endDate) => {
  const result = [];
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Iterate through each day
  const currentDate = start;
  while (currentDate <= end) {
    result.push(getOrdinal(currentDate.getDate()));
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }
  return result;
};
