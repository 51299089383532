/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
import React from 'react';
import {
  Modal, Form, Input, Select
} from 'antd';

const { Option } = Select;

const QualityModal = (props) => {
  const {
    isModalVisible, setIsModalVisible, editStatusForm, onSave
  } = props;

  const handleOk = () => {
    editStatusForm
      .validateFields()
      .then((data) => {
        onSave(data.guid, data.status, data.notes);
        setIsModalVisible(false);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        className="quality-modal"
        title={false}
        centered
        visible={isModalVisible}
        onOk={() => handleOk(false)}
        onCancel={() => handleCancel(false)}
      >
        <div>
          <Form
            form={editStatusForm}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
          >
            <Form.Item name="status" label="Overall Status">
              <Select
                placeholder="Select Status"
              >
                <Option value="ToDo">To Do</Option>
                <Option value="In Progress">In Progress</Option>
                <Option value="Review">Review</Option>
                <Option value="Done">Done</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Notes"
              name="notes"
              rules={[{ message: 'Please input your Notes.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="guid" style={{ display: 'none' }}><></></Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default QualityModal;
