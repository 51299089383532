/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable operator-assignment */
/* eslint-disable no-unneeded-ternary */
import {
  Button, DatePicker, Space, Table, Spin, Tooltip, notification
} from 'antd';
import React, { useState, useEffect } from 'react';
import {
  CheckOutlined
} from '@ant-design/icons';
import moment from 'moment';
import get from 'lodash/get';
import { connect } from 'react-redux';
import {
  func, arrayOf, objectOf, oneOfType, string, number,
  array, bool
} from 'prop-types';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { getTimesheet } from '../state/action/timesheetAction';
import TimesheetModal from './TimesheetModal';
import { prepareTimesheetDataForExcel, writeTimesheetWorkSheet } from '../utils/utility';

const { RangePicker } = DatePicker;

const Timesheet = (props) => {
  const {
    onGetTimesheet, data, projNo, showLoader, selectedProjectDetail, reload, dates, setDates
  } = props;
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [showTimesheet, setShowTimesheet] = useState(false);
  const [timesheetModalVisible, setTimesheetModalVisible] = useState(false);
  const [monthBtnClicked, setMonthBtnClicked] = useState(false);
  const [weekBtnClicked, setWeekBtnClicked] = useState(false);
  const [rangeBtnClicked, setRangeBtnClicked] = useState(false);
  const [timesheetData, setTimesheetData] = useState([]);
  const [modalTimesheetData, setModalTimesheetData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [userName, setUserName] = useState('');
  const [disableRangeDataFetchBtn, setDisableRangeDataFetchBtn] = useState(true);
  const [newData, setNewData] = useState(false);
  const [finalDt, setFinalDt] = useState(false);
  const [userList, setUserList] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [totalTimesheetData, setTotalTimesheetData] = useState(0);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center'
    },
    {
      title: 'Total Hours Worked',
      dataIndex: 'totalHours',
      key: 'totalHours',
      align: 'center'
    },
    {
      title: 'Total Days Worked',
      dataIndex: 'totalDays',
      key: 'totalDays',
      align: 'center'
    }
  ];

  const hideModal = () => {
    setTimesheetModalVisible(false);
  };

  const getTimesheetData = (startDt, endDt) => {
    onGetTimesheet(startDt, endDt, projNo);
  };

  const currentMonthStartEndDate = (type) => {
    if (type === 'month') {
      const firstdate = moment().startOf('month').format('YYYY-MM-DD');
      const lastdate = moment().endOf('month').format('YYYY-MM-DD');
      setStartDate(firstdate);
      setEndDate(lastdate);
      getTimesheetData(firstdate, lastdate);
    }
    if (type === 'week') {
      const firstdate = moment().startOf('week').format('YYYY-MM-DD');
      const lastdate = moment().endOf('week').format('YYYY-MM-DD');
      setStartDate(firstdate);
      setEndDate(lastdate);
      getTimesheetData(firstdate, lastdate);
    }
    if (type === 'range') {
      const firstdate = moment(dates[0]).format('YYYY-MM-DD');
      const lastdate = moment(dates[1]).format('YYYY-MM-DD');
      setStartDate(firstdate);
      setEndDate(lastdate);
      getTimesheetData(firstdate, lastdate);
    }
  };

  const handleTableChnage = (pagination,
    filters,
    sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const countNoOfDays = (arr, n, x) => {
    let res = 0;
    for (let i = 0; i < n; i++) {
      if (x === arr[i]) { res++; }
    }
    return res;
  };

  const getDates = (startDt, endDt) => {
    const date = new Date(startDt.getTime());

    const dts = [];

    while (date <= endDt) {
      dts.push(moment(date).format('DD-MMM-YY'));
      date.setDate(date.getDate() + 1);
    }

    return dts;
  };

  useEffect(() => {
    if (!reload && projNo !== '') {
      const holder = {};
      const users = [];
      const userNoHrs = [];
      let hr;
      let min;
      data.forEach((d) => {
        if (holder.hasOwnProperty(d.name)) {
          if (d.hrs_spent !== '') {
            users.push(d.name);
            const a = d.hrs_spent ? (d.hrs_spent).toFixed(2).toString().split('.') : '';
            const b = holder[d.name] ? (holder[d.name]).toFixed(2).toString().split('.') : '';
            if (a.length > 1 && b.length > 1) {
              hr = parseInt(a[0]) + parseInt(b[0]);
              min = parseInt(a[1]) + parseInt(b[1]);
              if (min > 60) {
                hr = hr + Math.floor(min / 60);
                min = Math.floor(min % 60);
              }
              holder[d.name] = hr + (min / 100);
            } else if (a.length > 1 && b.length === 1) {
              hr = parseInt(a[0]) + parseInt(b[0]);
              min = parseInt(a[1]) + 0;
              if (min > 60) {
                hr = hr + Math.floor(min / 60);
                min = Math.floor(min % 60);
              }
              holder[d.name] = hr + (min / 100);
            } else if (a.length === 1 && b.length > 1) {
              hr = parseInt(a[0]) + parseInt(b[0]);
              min = parseInt(b[1]) + 0;
              if (min > 60) {
                hr = hr + Math.floor(min / 60);
                min = Math.floor(min % 60);
              }
              holder[d.name] = hr + (min / 100);
            } else {
              hr = parseInt(a[0]) + parseInt(b[0]);
              min = 0;
              holder[d.name] = hr + (min / 100);
            }
          }
        } else if (d.hrs_spent !== '') {
          users.push(d.name);
          holder[d.name] = d.hrs_spent;
        } else if (!userNoHrs.includes(d.name)) userNoHrs.push(d.name);
      });
      const mainData = [];
      const n = users.length;
      const finalData = [];
      for (const prop in holder) {
        mainData.push({ name: prop, totalHours: holder[prop] });
      }
      mainData.forEach((d) => {
        const noOfDays = countNoOfDays(users, n, d.name);

        const totalHoursWorked = d.totalHours.toFixed(2);
        finalData.push({ name: d.name, totalHours: totalHoursWorked, totalDays: noOfDays });
      });
      userNoHrs.map((val) => {
        if (!users.includes(val)) {
          finalData.push({ name: val, totalHours: '-', totalDays: '-' });
        }
      });
      setTimesheetData(finalData);
      setUserList(users);
      setTotalTimesheetData(finalData.length);
    }

    const dt = [];
    const output = [];
    const final = [];
    const opdt = [];
    let hours = [];
    let i = 0;

    data.forEach((val) => {
      const dat = { date: '', comments: '', hoursSpent: [] };
      i = i + 1;
      if (dt.includes(val.date)) {
        dat.date = val.date;
        dat.comments = val.comments;
        hours.push({
          name: val.name, hrs_spent: val.hrs_spent !== '' ? val.hrs_spent : '-'
        });
        dat.hoursSpent = hours;
      } else {
        hours = [];
        dt.push(val.date);
        dat.date = val.date;
        dat.comments = val.comments;
        hours.push({
          name: val.name, hrs_spent: val.hrs_spent !== '' ? val.hrs_spent : '-'
        });
        dat.hoursSpent = hours;
      }
      if (!output.length) {
        output.push(dat);
        opdt.push(dat.date);
      } else if (!opdt.includes(val.date)) {
        opdt.push(val.date);
        output.push(dat);
      }
    });

    output.map((op) => {
      const out = {};
      out.date = op.date;
      out.comments = op.comments;
      op.hoursSpent.map((dts, idx) => {
        out[`${idx}`] = dts;
      });
      final.push(out);
    });
    setNewData(final);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (newData) {
      const datesArray = getDates(new Date(startDate), new Date(endDate));
      const dt = [];
      const date = [];
      const name = [];
      datesArray.forEach((dtArr) => {
        newData.forEach((dts) => {
          Object.keys(dts).map((val) => {
            if (typeof dts[val] === 'object') {
              if (!name.includes(dts[val].name)) {
                name.push(dts[val].name);
              }
            }
          });
          if (dtArr === dts.date) {
            if (!date.includes(dts.date)) {
              date.push(dts.date);
              delete dts.comments;
              dt.push(dts);
            }
          }
        });
      });
      datesArray.forEach((arr) => {
        const dat = { };
        if (!date.includes(arr)) {
          date.push(arr);
          name.map((val, index) => {
            dat[`${index}`] = { name: val, hrs_spent: '-' };
          });
          dat.date = arr;
          dt.push(dat);
        }
      });
      dt.sort((a, b) => new Date(a.date) - new Date(b.date));
      setFinalDt(dt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);

  const getModalTimesheetData = (user) => {
    const modalData = [];
    data.map((usr) => {
      if (usr.name === user) {
        modalData.push(usr);
      }
    });
    setModalTimesheetData(modalData);
  };

  const exportToExcel = async () => {
    try {
      const { dt, cols } = prepareTimesheetDataForExcel(userList, finalDt);
      const workbook = new Excel.Workbook();
      const startDt = moment(startDate).format('DD-MMM-YYYY');
      const endDt = moment(endDate).format('DD-MMM-YYYY');
      const workSheetName = `${startDt} to ${endDt}`;
      const fileName = `Timesheet_${startDt} to ${endDt}_${selectedProjectDetail.projectNo}`;
      let worksheet = workbook.addWorksheet(workSheetName);
      worksheet.columns = cols;
      worksheet = writeTimesheetWorkSheet(worksheet, userList, dt,
        selectedProjectDetail.projectName,
        selectedProjectDetail.projectNo, selectedProjectDetail.leadTech,
        selectedProjectDetail.contractor, timesheetData);
      // Loop through all table's row
      for (let i = 8; i <= worksheet.lastRow.number; i++) {
        const row = worksheet.getRow(i);

        // Now loop through every row's cell and finally set alignment
        row.eachCell({ includeEmpty: true }, ((cell) => {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }));
      }
      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  useEffect(() => {
    setWeekBtnClicked(false);
    setRangeBtnClicked(false);
    setMonthBtnClicked(false);
    setShowTimesheet(false);
    setShowDateFilter(false);
    localStorage.setItem('monthClicked', 'false');
    localStorage.setItem('rangeClicked', 'false');
    localStorage.setItem('weekClicked', 'false');
    localStorage.setItem('showTimesheet', 'false');
    localStorage.setItem('showDateFilter', 'false');
    setTimesheetData([]);
  }, [projNo]);

  useEffect(() => {
    if (!reload) {
      if (localStorage.getItem('monthClicked') === 'true'
      && localStorage.getItem('showTimesheet') === 'true') {
        setMonthBtnClicked(true);
        setShowTimesheet(true);
        currentMonthStartEndDate('month');
      } else if (localStorage.getItem('weekClicked') === 'true'
      && localStorage.getItem('showTimesheet') === 'true') {
        setWeekBtnClicked(true);
        setShowTimesheet(true);
        currentMonthStartEndDate('week');
      } else if (localStorage.getItem('rangeClicked') === 'true'
      && localStorage.getItem('showTimesheet') === 'true'
      && localStorage.getItem('showDateFilter') === 'true') {
        setRangeBtnClicked(true);
        setShowTimesheet(true);
        setShowDateFilter(true);
        currentMonthStartEndDate('range');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    if (dates.length === 2 && dates[0] !== '' && dates[1] !== '') {
      setDisableRangeDataFetchBtn(false);
    } else {
      setDisableRangeDataFetchBtn(true);
    }
  }, [dates]);

  return (
    <>
      <Space size="large">
        {
          monthBtnClicked ? (
            <Button
              key="submit"
              size="medium"
              shape="round"
              type="primary"
            >
              Current Month
            </Button>
          ) : (
            <Button
              key="submit"
              size="medium"
              shape="round"
              type="default"
              className="timesheetButton"
              onClick={() => {
                setShowDateFilter(false);
                setMonthBtnClicked(true);
                setShowTimesheet(true); setWeekBtnClicked(false); setRangeBtnClicked(false);
                currentMonthStartEndDate('month');
                localStorage.setItem('monthClicked', 'true');
                localStorage.setItem('rangeClicked', 'false');
                localStorage.setItem('weekClicked', 'false');
                localStorage.setItem('showTimesheet', 'true');
                setTimesheetData([]);
                setDates([]);
                setDisableRangeDataFetchBtn(true);
              }}
            >
              Current Month
            </Button>
          )
        }
        {
          weekBtnClicked ? (
            <Button
              key="submit"
              size="medium"
              shape="round"
              type="primary"
            >
              Current Week
            </Button>
          ) : (
            <Button
              key="submit"
              size="medium"
              shape="round"
              type="default"
              className="timesheetButton"
              onClick={() => {
                setShowDateFilter(false);
                setWeekBtnClicked(true);
                setShowTimesheet(true); setMonthBtnClicked(false); setRangeBtnClicked(false);
                currentMonthStartEndDate('week');
                localStorage.setItem('monthClicked', 'false');
                localStorage.setItem('rangeClicked', 'false');
                localStorage.setItem('weekClicked', 'true');
                localStorage.setItem('showTimesheet', 'true');
                setTimesheetData([]);
                setDates([]);
                setDisableRangeDataFetchBtn(true);
              }}
            >
              Current Week
            </Button>
          )
        }
        {
          rangeBtnClicked ? (
            <Button
              key="submit"
              size="medium"
              shape="round"
              type="primary"
            >
              Select Date Range
            </Button>
          ) : (
            <Button
              key="submit"
              size="medium"
              shape="round"
              type="default"
              className="timesheetButton"
              onClick={() => {
                setShowDateFilter(true); setMonthBtnClicked(false);
                setRangeBtnClicked(true);
                setWeekBtnClicked(false);
                localStorage.setItem('monthClicked', 'false');
                localStorage.setItem('rangeClicked', 'true');
                localStorage.setItem('weekClicked', 'false');
                localStorage.setItem('showDateFilter', 'true');
                setTimesheetData([]);
              }}
            >
              Select Date Range
            </Button>
          )
        }

        {
            showDateFilter && (
            <div className="d-flex edit-session justify-content-center">
              <RangePicker
                className="rangePicker_separator"
                showToday
                separator="to"
                format="YYYY-MMM-DD"
                placeholder={['Start Date', 'End Date']}
                defaultValue={dates}
                onCalendarChange={(val, dateString) => { setDates(dateString); }}
              />
              {}
              <Button
                type="primary"
                disabled={disableRangeDataFetchBtn ? true : false}
                onClick={() => { setShowTimesheet(true); currentMonthStartEndDate('range'); localStorage.setItem('showTimesheet', 'true'); }}
              >
                <CheckOutlined />
                {' '}
              </Button>
            </div>

            )
        }
        <Button
          size="small"
          shape="round"
          type="primary"
          className="export-excel"
          disabled={timesheetData.length > 0 ? false : true}
          onClick={exportToExcel}
        >
          <Tooltip title="Download excel report" placement="topRight">
            <span className="icon-16 mr-2">
              <svg>
                <use xlinkHref="#export" />
              </svg>
            </span>
          </Tooltip>

        </Button>
      </Space>
      {
        showTimesheet && (
        <>
          <div className={timesheetData.length > 0 ? 'h-85 overflow-y-hidden overflow-x-auto has-pointer' : 'h-85 overflow-y-hidden overflow-x-auto'}>
            <Table
              className="h-85"
              scroll={{ x: timesheetData.length > 0 ? 1200 : 0, y: '100%' }}
              rowKey={(record) => record.id}
              pagination={totalTimesheetData > 10 ? tableParams.pagination : false}
              columns={columns}
              style={{ marginTop: '20px' }}
              dataSource={timesheetData}
              loading={{
                indicator: <Spin />,
                spinning: showLoader
              }}
              onChange={handleTableChnage}
              onRow={(record) => ({
                onClick: (e) => {
                  e.stopPropagation();
                  setTimesheetModalVisible(true);
                  getModalTimesheetData(record.name);
                  setUserName(record.name);
                },
              })}
            />
          </div>
          <TimesheetModal
            modalVisible={timesheetModalVisible}
            hideModal={hideModal}
            modalTimesheetData={modalTimesheetData}
            startDate={startDate}
            endDate={endDate}
            userName={userName}
            selectedProjectDetail={selectedProjectDetail}
          />
        </>
        )
      }

    </>
  );
};

Timesheet.propTypes = {
  onGetTimesheet: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showLoader: bool.isRequired,
};

const mapStateToProps = ({ timesheetRequests }) => {
  const data = get(timesheetRequests, 'tdata', []);
  const showLoader = get(timesheetRequests, 'isLoading', false);
  return {
    data,
    showLoader
  };
};

const mapDispatchToProps = {
  onGetTimesheet: getTimesheet
};

export default connect(mapStateToProps, mapDispatchToProps)(Timesheet);
