import React, { useState, useEffect } from 'react';
import {
  Modal, Button, Table, notification, Spin, Switch, Tabs
} from 'antd';
import { getSafetyData } from '../services/summary';
import { shift } from '../utils/constants';
import { toNumber } from '../utils/utility';
import ModalOffshoreSummaryGraph from './ModalOffshoreSummaryGraph';

const { Column, ColumnGroup } = Table;
const { TabPane } = Tabs;

const SafetyOverallModal = (props) => {
  const {
    visible, setVisible, projNo
  } = props;
  const [loading, setLoading] = useState(false);
  const [safetyData, setSafetyData] = useState([]);
  const [showGraph, setShowGraph] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await getSafetyData(projNo, '', '', true);
      if (res?.safetyData) {
        const sortedData = res.safetyData.sort((a, b) => new Date(b.date) - new Date(a.date))
          .map((item) => {
            const dayData = item.safetyData.find((
              element
            ) => element.shift === shift.day) || {};
            const nightData = item.safetyData.find((
              element
            ) => element.shift === shift.night) || {};
            const rowData = {
              date: item.date,
              no_of_incidents_day: dayData.no_of_incidents || 0,
              no_of_incidents_night: nightData.no_of_incidents || 0,
              no_of_incidents_overall: toNumber(dayData.no_of_incidents)
              + toNumber(nightData.no_of_incidents),
              near_misses_night: nightData.near_misses || 0,
              near_misses_day: dayData.near_misses || 0,
              near_misses_overall: toNumber(dayData.near_misses)
              + toNumber(nightData.near_misses),
              observation_cards_raised_night: nightData.obs_card_raised || 0,
              observation_cards_raised_day: dayData.obs_card_raised || 0,
              observation_cards_raised_overall: toNumber(dayData.obs_card_raised)
              + toNumber(nightData.obs_card_raised),
              observation_cards_closed_night: nightData.obs_card_closed || 0,
              observation_cards_closed_day: dayData.obs_card_closed || 0,
              observation_cards_closed_overall: toNumber(dayData.obs_card_closed)
              + toNumber(nightData.obs_card_closed),
              toolbox_talks_night: nightData.toolbox_talks || 0,
              toolbox_talks_day: dayData.toolbox_talks || 0,
              toolbox_talks_overall: toNumber(dayData.toolbox_talks)
              + toNumber(nightData.toolbox_talks),
              comments_night: nightData.safety_comments || '-',
              comments_day: dayData.safety_comments || '-',
            };
            return rowData;
          });
        setSafetyData(sortedData);
      } else setSafetyData([]);
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (visible) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  return (
    <>
      <Modal
        title={(
          <div>
            <div style={{ 'max-width': '50%' }}>Safety</div>
            <div className="d-flex flex-column align-items-end">
              <Switch
                className="modal-switch"
                checkedChildren="Graphical View"
                unCheckedChildren="Tabular View"
                onChange={(checked) => {
                  if (checked) {
                    setShowGraph(checked);
                  } else {
                    setShowGraph(checked);
                  }
                }}
              />
            </div>
          </div>
        )}
        className="welds-modal"
        centered
        visible={visible}
        width={1009}
        footer={[
          <Button key="submit" type="primary" onClick={() => { setVisible(false); }}>
            OK
          </Button>
        ]}
      >
        { !showGraph ? (
          <Table
            dataSource={safetyData}
            className="table-with-button-assets"
            loading={{
              indicator: <Spin />,
              spinning: loading
            }}
            scroll={{ x: true }}
            style={{ whiteSpace: 'nowrap' }}
            pagination={false}
          >
            <Column title="Date" dataIndex="date" key="date" />
            <Column
              title="Report No."
              dataIndex="reportNo"
              key="reportNo"
              align="center"
              render={(text, record, index) => (
                <>
                  {index + 1}
                  {' '}
                </>
              )}
            />
            <ColumnGroup title="No. of Incidents" align="center">
              <Column title="Day" dataIndex="no_of_incidents_day" key="no_of_incidents_day" />
              <Column title="Night" dataIndex="no_of_incidents_night" key="no_of_incidents_night" />
              <Column title="Overall" dataIndex="no_of_incidents_overall" key="no_of_incidents_overall" />
            </ColumnGroup>
            <ColumnGroup title="Near Misses" align="center">
              <Column title="Day" dataIndex="near_misses_day" key="near_misses_day" />
              <Column title="Night" dataIndex="near_misses_night" key="near_misses_night" />
              <Column title="Overall" dataIndex="near_misses_overall" key="near_misses_overall" />
            </ColumnGroup>
            <ColumnGroup title="Observation Cards Raised" align="center">
              <Column title="Day" dataIndex="observation_cards_raised_day" key="observation_cards_raised_day" />
              <Column title="Night" dataIndex="observation_cards_raised_night" key="observation_cards_raised_night" />
              <Column title="Overall" dataIndex="observation_cards_raised_overall" key="observation_cards_raised_overall" />
            </ColumnGroup>
            <ColumnGroup title="Observation Cards Closed" align="center">
              <Column title="Day" dataIndex="observation_cards_closed_day" key="observation_cards_closed_day" />
              <Column title="Night" dataIndex="observation_cards_closed_night" key="observation_cards_closed_night" />
              <Column title="Overall" dataIndex="observation_cards_closed_overall" key="observation_cards_closed_overall" />
            </ColumnGroup>
            <ColumnGroup title="Toolbox Talks" align="center">
              <Column title="Day" dataIndex="toolbox_talks_day" key="toolbox_talks_day" />
              <Column title="Night" dataIndex="toolbox_talks_night" key="toolbox_talks_night" />
              <Column title="Overall" dataIndex="toolbox_talks_overall" key="toolbox_talks_overall" />
            </ColumnGroup>
            <ColumnGroup title="Comments" align="center">
              <Column title="Day" dataIndex="comments_day" key="comments_day" />
              <Column title="Night" dataIndex="comments_night" key="comments_night" />
            </ColumnGroup>
          </Table>
        ) : (
          <Tabs className="custom-tab h-95">
            <TabPane tab="No. of Incidents" key={0}>
              <ModalOffshoreSummaryGraph Reportdata={safetyData} coloumnType="no_of_incidents" />
            </TabPane>
            <TabPane tab="Near Misses" key={1}>
              <ModalOffshoreSummaryGraph Reportdata={safetyData} coloumnType="near_misses" />
            </TabPane>
            <TabPane tab="Observation Cards Raised" key={2}>
              <ModalOffshoreSummaryGraph Reportdata={safetyData} coloumnType="observation_cards_raised" />
            </TabPane>
            <TabPane tab="Observation Cards Closed" key={3}>
              <ModalOffshoreSummaryGraph Reportdata={safetyData} coloumnType="observation_cards_closed" />
            </TabPane>
            <TabPane tab="Toolbox Talks" key={4}>
              <ModalOffshoreSummaryGraph Reportdata={safetyData} coloumnType="toolbox_talks" />
            </TabPane>
          </Tabs>
        )}
      </Modal>
    </>
  );
};

export default SafetyOverallModal;
