import {
  GET_MACHINE_TYPE,
  SET_MACHINE_TYPE,
  SET_MACHINE_TYPE_FAILURE,
  SET_MACHINE_SUB_TYPE,
  GET_ADVISORY,
  SET_ADVISORY,
  SET_ADVISORY_FAILURE,
  GET_ADVISORY_APPROVAL,
  SET_ADVISORY_APPROVAL,
  SET_ADVISORY_APPROVAL_FAILURE
} from '../types';

const getMachineType = (typeId) => ({
  type: GET_MACHINE_TYPE,
  typeId
});

const setMachineType = (payload) => ({
  type: SET_MACHINE_TYPE, ...payload,
});

const setMachineSubType = (payload) => ({
  type: SET_MACHINE_SUB_TYPE, ...payload,
});

const setMachineTypeFailure = () => ({
  type: SET_MACHINE_TYPE_FAILURE,
});

const getAdvisory = (typeId, page, limit, startDate, endDate) => ({
  type: GET_ADVISORY,
  typeId,
  page,
  limit,
  startDate,
  endDate
});

const setAdvisory = (payload) => ({
  type: SET_ADVISORY, ...payload,
});

const setAdvisoryFailure = () => ({
  type: SET_ADVISORY_FAILURE,
});

const getAdvisoryApproval = (typeId, page, limit, startDate, endDate) => ({
  type: GET_ADVISORY_APPROVAL,
  typeId,
  page,
  limit,
  startDate,
  endDate
});

const setAdvisoryApproval = (payload) => ({
  type: SET_ADVISORY_APPROVAL, ...payload,
});

const setAdvisoryFailureApproval = () => ({
  type: SET_ADVISORY_APPROVAL_FAILURE,
});

export {
  getMachineType,
  setMachineType,
  setMachineTypeFailure,
  setMachineSubType,
  getAdvisory,
  setAdvisory,
  setAdvisoryFailure,
  getAdvisoryApproval,
  setAdvisoryApproval,
  setAdvisoryFailureApproval
};
