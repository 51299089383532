import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { setWireUsageReports, setWireUsageReportFailure } from '../state/action/wireUsageAction';
import { GET_WIRE_USAGE_REPORTS } from '../state/types';
import fetch from '../services/fetch';
import { BASE_URL } from '../config';

function* getReports({ projectNo }) {
  let status = 0;
  try {
    const projNo = encodeURIComponent(projectNo.projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = yield fetch(`${BASE_URL}/project/get-wire-usage-reports?project_numbers=${projNo}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      status = res.status;
      throw new Error('Unauthorized');
    }
    const response = yield res.json();
    yield put(setWireUsageReports({ response }));
  } catch (error) {
    yield put(setWireUsageReportFailure());
    if (status !== 403) {
      notification.error({
        message: 'There was a problem fetching the report details.',
        description: `${error}`,
      });
    }
  }
}

function* wireUsageReportWatcher() {
  yield takeLatest(GET_WIRE_USAGE_REPORTS, getReports);
}

function* wireUsageReportSagas() {
  yield all([
    call(wireUsageReportWatcher),
  ]);
}

export default wireUsageReportSagas;
