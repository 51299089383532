/* eslint-disable camelcase */
import React from 'react';
import {
  Modal, Form, Tooltip, Select, Input, notification
} from 'antd';
import * as utility from '../utils/utility';

const MachineTypeModal = (props) => {
  const {
    isModalVisible, setIsModalVisible, onSave, form, machineTypes,
    isLoading
  } = props;

  const handleOk = async () => {
    const data = await form.validateFields();
    const isNameAlreadyExists = machineTypes.find((
      item
    ) => item.name.toLowerCase() === data.name.toLowerCase() && item.id !== data.id);
    if (isNameAlreadyExists) {
      return notification.error({
        message: 'Type already exists.',
      });
    }
    onSave(data);
    return '';
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        className="quality-modal"
        title="Update Machine Type"
        centered
        visible={isModalVisible}
        onOk={handleOk}
        okText="Submit"
        cancelText="Cancel"
        onCancel={handleCancel}
        okButtonProps={{
          disabled: isLoading
        }}
        cancelButtonProps={{ disabled: isLoading }}
      >

        <Form
          layout="vertical"
          name="update-status"
          form={form}
        >
          <Form.Item
            name="id"
            rules={[
              {
                required: true,
                message: 'Please choose a type.',
              },
            ]}
          >
            <Select
              className="select-dropdown-signup me-2"
              placeholder="Choose a type"
              showArrow
              allowClear
              showSearch
              autoClearSearchValue
              optionFilterProp="children"
              filterOption={(input, option) => (option.children[0]).toLowerCase()
                .includes(input.toLowerCase())}
            >
              {
                machineTypes.length > 0
                  ? machineTypes.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      <Tooltip title={item.name}>{item.name}</Tooltip>
                    </Select.Option>
                  ))
                  : null
              }
            </Select>
          </Form.Item>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter a name.',
              },
            ]}
            normalize={(value) => utility.trimSpacesInValues(value)}
          >
            <Input showCount size="large" placeholder="Enter a name" maxLength={50} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default MachineTypeModal;
