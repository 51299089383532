/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import {
  func, arrayOf, objectOf, oneOfType, string, number,
  array, bool
} from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  Row, Empty, Spin, notification, Button, Typography
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useHistory, useLocation } from 'react-router-dom';
import { getMachineType } from '../state/action/advisoryAction';
import MachineTypeModal from './MachineTypeModal';
import { updateMachinetype } from '../services/advisory';
import TypeCardTitle from './TypeCardTitle';

const { Title } = Typography;

function MachineTypeCard(props) {
  const {
    onGetMachineType, data, goToAdvisory, goToType, typeId, showLoader,
    isModalVisible, setIsModalVisible
  } = props;
  const [isLoading, setIsLoading] = useState();
  const [currentEditId, setCurrentEditId] = useState('');
  const [isEditLoading, setIsEditLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const tabValue = queryParams.get('tabValue');

  const [form] = useForm();
  const history = useHistory();

  const onSave = async (value) => {
    setIsLoading(true);
    const res = await updateMachinetype(value);
    if (!res.err) {
      onGetMachineType(typeId);
      form.resetFields();
      setIsModalVisible(false);
    } else {
      notification.error({
        message: res.message || 'Something went wrong.',
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    onGetMachineType(typeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const typeClick = (item) => {
    history.replace({
      pathname: window.location.pathname,
      search: `?tabValue=${tabValue}&type=${type}&subType=${item.name}`
    });
    goToAdvisory(item.id);
  };

  return (
    showLoader ? (
      <div className="loader">
        <Spin spinning={showLoader} />
      </div>
    ) : (
      <div className="type-card">
        <Spin spinning={showLoader}>
          <div className="flex-auto d-flex pb-2 align-items-center justify-content-between">
            <Button
              className="text-right mb-2 back-advisory border-0"
              onClick={goToType}
              key="submit"
              type="primary"
              shape="round"
            >
              Back

            </Button>
            <div className="breadcrumb">
              <Title className="mb-3 ps-4 breadcrumb" level={4}>
                {type}
              </Title>
              {/* <div className="cursor-pointer" onClick={goToType}>
                {type}
              </div> */}
            </div>
            <div />
          </div>
          <Row gutter={[16, 16]} className="pt-3 ">
            {
        data.length > 0
          ? data.map((item) => (
            <TypeCardTitle
              id={item.id}
              name={item.name}
              onClick={() => typeClick(item)}
              machineTypes={data}
              onGetMachineType={() => onGetMachineType(typeId)}
              key={item.id}
              currentEditId={currentEditId}
              setCurrentEditId={setCurrentEditId}
              isLoading={isEditLoading}
              setIsLoading={setIsEditLoading}
            />
          ))
          : <Empty />
    }
          </Row>
        </Spin>
        <MachineTypeModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          onSave={onSave}
          form={form}
          machineTypes={data}
          isLoading={isLoading}
        />
      </div>
    )
  );
}

MachineTypeCard.propTypes = {
  onGetMachineType: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showLoader: bool.isRequired,

};

const mapStateToProps = ({ advisoryData }) => {
  const data = get(advisoryData, 'machineSubTypes', []);
  const showLoader = get(advisoryData, 'isLoading', false);
  const total = get(advisoryData, 'total', 0);
  return {
    data,
    showLoader,
    total
  };
};

const mapDispatchToProps = {
  onGetMachineType: getMachineType
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineTypeCard);
