/* eslint-disable linebreak-style */
import {
  FETCH_PROJECT_INVENTORY,
  SET_PROJECT_INVENTORY,
  SET_PROJECT_INVENTORY_FAILURE
} from '../types';

const getProjectInventory = (projectNo) => ({
  type: FETCH_PROJECT_INVENTORY,
  projectNo,
});

const setProjectInvetory = (payload) => ({
  type: SET_PROJECT_INVENTORY, ...payload,
});

const setProjectInvetoryFailure = () => ({
  type: SET_PROJECT_INVENTORY_FAILURE,
});

export {
  getProjectInventory,
  setProjectInvetory,
  setProjectInvetoryFailure
};
