import get from 'lodash/get';
import moment from 'moment';
import { GET_TIMESHEET, SET_TIMESHEET, SET_TIMESHEET_FAILURE } from '../types';

const initialState = {
  isLoading: false,
};

const timesheetRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TIMESHEET: {
      const tReports = get(action, ['response', 'data'], []);
      const tdata = [];
      for (let i = 0; i < tReports.length; i += 1) {
        let date;
        if (tReports[i].date) {
          date = moment(tReports[i].date).format('DD-MMM-YY');
        } else {
          date = null;
        }
        tdata[i] = {
          reportId: get(tReports[i], 'id', '-'),
          name: get(tReports[i], 'name', '-'),
          date,
          projectNo: get(tReports[i], 'project_id', '-'),
          comments: get(tReports[i], 'comments', ''),
          period: get(tReports[i], 'period', ''),
          hrs_spent: get(tReports[i], 'hrs_spent', ''),
          submitted_at: get(tReports[i], 'submitted_at', ''),
          pdf_url: get(tReports[i], 'pdf_url', ''),
          excel_url: get(tReports[i], 'excel_url', '')
        };
      }
      return {
        ...state,
        tdata,
        isLoading: false,
      };
    }

    case GET_TIMESHEET: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_TIMESHEET_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default timesheetRequestReducer;
