import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import get from 'lodash/get';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import {
  Table, Modal, Spin, Input,
} from 'antd';
import fuzzySort from 'fuzzysort';
import {
  func, arrayOf, bool, objectOf, oneOfType, string, number, array,
} from 'prop-types';
import { getCoatingReports } from '../../state/action/coatingLogAction';
import { getProjectInfo, getCoatingProdLogDetails } from '../../services/getModalInfo';
import { datesInPeriod } from '../../services/calendar';
import * as constants from '../../utils/constants';
import 'antd/dist/antd.dark.css';

const { Column, ColumnGroup } = Table;
const { Search } = Input;

const CoatingProductionLog = (props) => {
  const { data, onGetCoatingReports, showLoader } = props;
  const [modalVisible, showModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [dates, setDatesInPeriod] = useState();
  const [searchText, onSearch] = useState();

  let dataSource = [];
  const searchResult = fuzzySort.go(searchText, data, {
    keys:
      ['projectNo', 'projectName', 'lead', 'contractor', 'author'],
    threshold: -750,
    allowTypo: false,
  });

  if (searchText && searchText.length > 0) {
    for (let i = 0, len = values(searchResult).length; i < len - 1; i += 1) {
      dataSource[i] = values(searchResult)[i].obj;
    }
  } else {
    dataSource = data;
  }

  useEffect(() => {
    onGetCoatingReports(constants.REPORT_COATING_LOG);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hideModal = () => {
    showModal(false);
  };

  const entries = modalData && modalData[0].entries;
  const newData = [];
  let found = false;
  let count;

  if (dates) {
    dates.forEach((day) => {
      count = 0;
      for (let i = 0; entries && i < entries.length; i += 1) {
        if (entries[i].date === day) {
          newData.push(entries[i]);
          count += 1;
          found = true;
          if (count === 2) {
            break;
          }
        }
      }
      if (!found) {
        newData.push({ date: day });
        found = false;
      }
      found = false;
    });
  }

  const showDowntime = (val) => (
    !isEmpty(val.downtime) ? (
      <div>
        <tr>
          <td>start time</td>
          <td>end time</td>
          <td>reason</td>
        </tr>

        {values(val.downtime).map((dVal) => (
          !isEmpty(dVal)
          && (dVal.startTime || dVal.endTime || dVal.downtimeComments)
          && (
            <tr>
              <td>{dVal.startTime}</td>
              <td>{dVal.endTime}</td>
              <td>{dVal.downtimeComments}</td>
            </tr>
          )
        ))}
      </div>
    ) : (
      null
    )
  );

  const showPipeSizes = (val) => {
    if (typeof (val.pipe_size) === 'number') {
      return val.pipe_size;
    }
    return (
      !isEmpty(val.pipe_size) ? (
        <div>
          <tr>
            {values(val.pipe_size).map((pVal, i) => (
              <td>{i + 1}</td>
            ))}
          </tr>

          <tr>
            {values(val.pipe_size).map((pVal) => (
              <td>{pVal}</td>
            ))}
          </tr>
        </div>
      ) : (
        null
      )
    );
  };

  if (showLoader) {
    return (
      <div className="loader">
        <Spin size="large" tip="Loading data..." />
      </div>
    );
  }

  return (
    <div>
      <div className="search-div">
        <Search
          className="search-button"
          placeholder="Input Search Text"
          value={searchText}
          onChange={(e) => {
            onSearch(e.target.value);
          }}
        />
      </div>

      <div>
        <Table
          dataSource={dataSource}
          pagination={{ hideOnSinglePage: true }}
          bordered
          rowKey={(record) => record.reportId}
          scroll={{ x: '175%', y: 500 }}
          onRow={(record) => ({
            onClick: () => {
              showModal(true);
              setDatesInPeriod(datesInPeriod(record.period));
              setModalData(dataSource.filter((d) => d.reportId === record.reportId));
            },
          })}
        >
          <ColumnGroup title="COATING PRODUCTION LOG">
            <Column title="Reporting Period" dataIndex="reportingPeriod" key="reportingPeriod" />
            <Column title="Project #" dataIndex="projectNo" key="projectNo" />
            <Column title="Project Name" dataIndex="projectName" key="projectName" />
            <Column title="Lead" dataIndex="lead" key="lead" />
            <Column title="Contractor" dataIndex="contractor" key="contractor" />
            <Column title="Author" dataIndex="author" key="author" />
            <Column
              title="Last Updated At"
              dataIndex="lastUpdatedAt"
              key="lastUpdatedAt"
              render={(record) => {
                const date = moment(record).format('YYYY-MM-DD, hh:mm:ss A');
                return (
                  <div>{date}</div>
                );
              }}
            />
            <Column title="Total Production FJ Completed" dataIndex="totalProductionFJCompleted" key="totalProductionFJCompleted" />
            <Column title="Total Quality Incidents" dataIndex="totalQualityIncidents" key="totalQualityIncidents" />
            <Column title="Total Quality FJ Repairs" dataIndex="totalQualityFJRepairs" key="totalQualityFJRepairs" />
            <Column title="Total Downtime Minutes" dataIndex="totalDowntimeMinutes" key="totalDowntimeMinutes" />
            <Column title="Total Equipment Breakdowns" dataIndex="totalEquipmentBreakdowns" key="totalEquipmentBreakdowns" />
            <Column title="Total Safety Incidents" dataIndex="totalSafetyIncidents" key="totalSafetyIncidents" />
          </ColumnGroup>
        </Table>

        <Modal
          title="Coating Production Log Report"
          visible={modalVisible}
          onCancel={hideModal}
          footer={null}
          width={window.innerWidth * 0.9}
          bodyStyle={{ overflow: 'auto' }}
        >
          {getProjectInfo(modalData)}

          <div className="rTable">
            <table className="report-table">
              <tbody>
                <tr className="rTableRow">
                  <td className="report-noBorder" />
                  {
                    modalData
                    && (modalData[0].fields).map((field) => (
                      <td className="rTableCell report-header label" key={field}>
                        {field}
                      </td>
                    ))
                  }
                </tr>
                {
                  newData
                  && newData.map((val) => (
                    <tr key={`${val.date}${val.shift}`}>
                      <td className="rTableCell">{val.date}</td>
                      <td className="rTableCell">{val.shift}</td>
                      <td className="rTableCell">{showPipeSizes(val)}</td>
                      <td className="rTableCell">{val.start_weld}</td>
                      <td className="rTableCell">{val.finish_weld}</td>
                      <td className="rTableCell">{val.production_fj_completed}</td>
                      <td className="rTableCell">{val.production_comments}</td>
                      <td className="rTableCell">{val.quality_incidents}</td>
                      <td className="rTableCell">{val.quality_fj_repairs}</td>
                      <td className="rTableCell">{val.quality_comments}</td>
                      <td className="rTableCell">{val.equipment_breakdowns}</td>
                      <td className="rTableCell">{val.equipment_comments}</td>
                      <td className="rTableCell">{val.safety_incidents}</td>
                      <td className="rTableCell">{val.safety_comments}</td>
                      <td className="rTableCell">{val.personnel_comments}</td>
                      <td className="rTableCell">{showDowntime(val)}</td>
                    </tr>
                  ))
                }

              </tbody>
            </table>
          </div>

          {getCoatingProdLogDetails(modalData)}

        </Modal>
      </div>
    </div>
  );
};

CoatingProductionLog.propTypes = {
  onGetCoatingReports: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showLoader: bool.isRequired,
};

const mapStateToProps = ({ coatingProdLogReducer }) => {
  const data = get(coatingProdLogReducer, 'data', []);
  const showLoader = get(coatingProdLogReducer, 'isLoading', false);
  return {
    data,
    showLoader,
  };
};

const mapDispatchToProps = {
  onGetCoatingReports: getCoatingReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoatingProductionLog);
