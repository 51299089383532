/* eslint-disable no-constant-condition */
/* eslint-disable no-trailing-spaces */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable array-callback-return */
import React from 'react';
import {
  Modal, Form, Input, Row, Col, Checkbox
} from 'antd';
import * as utility from '../utils/utility';
import * as constants from '../utils/constants';

const AddEditEmailModal = (props) => {
  const {
    emailModalVisible, setEmailModalVisible, onSave, emailForm, isEdit, setIsEdit, onUpdate, 
    emailModalLoading, setEmailModalLoading, isOnshoreEmea, isOffshore
  } = props;

const handleCancel = () => {
  setEmailModalVisible(false);
  setIsEdit(false);
  emailForm.resetFields();
};

const handleOk = () => {
  if (!isEdit) {
    emailForm
    .validateFields()
    .then((data) => {
      setEmailModalLoading(true);
      onSave(data.email, data.reportEmailAccess);
    });
  } else {
    emailForm
    .validateFields()
    .then((data) => {
      setEmailModalLoading(true);
      onUpdate(data.email, data.emailOld, data.reportEmailAccess);
    });
  }
};
 
  return (
    <>
      <Modal
        className="quality-modal"
        title={isEdit ? 'Edit Email' : 'Add Email'}
        centered
        visible={emailModalVisible}
        onOk={() => handleOk()}
        okText={isEdit ? 'Update' : 'Add'}
        onCancel={handleCancel}
        okButtonProps={{ disabled: emailModalLoading }}
        cancelButtonProps={{ disabled: emailModalLoading }}
        confirmLoading={emailModalLoading}
      >
        <div>
          <Form
            id="create-email-form"
            name="basic"
            layout="vertical"
            autoComplete="off"
            form={emailForm}
            onFinish=""
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                    {
                      required: true,
                      message: 'Please enter an Email.',
                    },
                    {
                      pattern: true
                        ? constants.EMAIL_PATTERN
                        : undefined,
                      message: 'Provide a valid Email.',
                    }
                  ]}
              normalize={(value) => utility.trimSpacesInValues(value)}
            >
              <Input size="large" placeholder="Enter Email" />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="mb-0"
              label="Report Email Access"
              name="reportEmailAccess"
              rules={[
              {
                required: true,
                message: 'At least one Report Email Access is required.',
              },
            ]}
            >
              <Checkbox.Group className="w-100">
                <Row>
                  {(isOnshoreEmea || !isOffshore) && (
                  <>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                      <Checkbox value="ProductionLogReport">
                        Production
                      </Checkbox>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                      <Checkbox value="TechLogReport">
                        Tech
                      </Checkbox>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                      <Checkbox value="QualityReport">
                        Quality
                      </Checkbox>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                      <Checkbox value="PropertyDamageReport">Property Damage</Checkbox>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                      <Checkbox value="PartsRequestReport">
                        Parts Ordering
                      </Checkbox>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                      <Checkbox value="WireUsageReport">
                        Wire Usage
                      </Checkbox>
                    </Col>
                    {isOnshoreEmea ? (
                      <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                        <Checkbox value="TimesheetReport">
                          Timesheet
                        </Checkbox>
                      </Col>
                    )
                    : (
                      <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                        <Checkbox value="LEMReport">
                          LEM
                        </Checkbox>
                      </Col>
                    )}
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                      <Checkbox value="ProjectPic">
                        Project Pictures
                      </Checkbox>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                      <Checkbox value="CheckoutInventoryReport">
                        Inventory
                      </Checkbox>
                    </Col> 
                  </>
                  )}
                  {(!isOnshoreEmea && !isOffshore) && (
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                    <Checkbox value="DailyStatusReport">
                      Daily Status
                    </Checkbox>
                  </Col>
                  )}
                  {isOffshore && (
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                    <Checkbox value="DPRReport">
                      DPR
                    </Checkbox>
                  </Col>
)}
                </Row>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item name="emailOld" style={{ display: 'none' }} />
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddEditEmailModal;
