/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/plots';
import moment from 'moment';
import * as constants from '../utils/constants';

const compare = (a, b) => {
  const date1 = new Date(a.date);
  const date2 = new Date(b.date);
  if (date1 < date2) {
    return -1;
  }
  if (date1 > date2) {
    return 0;
  }
  return 0;
};

const DprEmeaProdGragh = (props) => {
  const { currentMonthData } = props;
  const graphData = [];
  const [data, setData] = useState([]);
  useEffect(() => {
    // eslint-disable-next-line no-plusplus
    if (currentMonthData) {
      currentMonthData.forEach((item) => {
        graphData.push({ date: moment(item.date).format(constants.DATE).replaceAll('/', '-'), total: Number(item.weld_count_accepted), value: 'Total Weld' });
        graphData.push({ date: moment(item.date).format(constants.DATE).replaceAll('/', '-'), total: Number(item.weld_count_repairs), value: 'Total Repair' });
      });
      graphData.sort(compare);
      setData(graphData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonthData]);

  const config = {
    autoFit: true,
    height: 1,
    data,
    xField: 'date',
    yField: 'total',
    seriesField: 'value',
    tooltip: {
      showMarkers: true
    },
    point: {
      shape: 'breath-point'
    },
    yAxis: {
      grid: { line: { style: { stroke: '' } } }
    },
    color: ['#FF0000', '#FFDB0A'],
  };

  return (

    currentMonthData

      ? (<Line {...config} />) : (<Line {...config} />)

  // eslint-disable jsx-props-no-spreading

  // eslint-disable-next-line react/jsx-props-no-spreading

  );
};

export default DprEmeaProdGragh;
