import get from 'lodash/get';
import {
  GET_MACHINE_TYPE, SET_MACHINE_TYPE, SET_MACHINE_TYPE_FAILURE, SET_MACHINE_SUB_TYPE,
  SET_ADVISORY, SET_ADVISORY_FAILURE, GET_ADVISORY, SET_ADVISORY_APPROVAL,
  SET_ADVISORY_APPROVAL_FAILURE, GET_ADVISORY_APPROVAL
} from '../types';

const initialState = {
  isLoading: false,
};

const advisoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MACHINE_TYPE: {
      const machineTypesRes = get(action, ['response'], []);
      const { machineTypes } = machineTypesRes;
      return {
        ...state,
        machineTypes,
        isLoading: false,
      };
    }

    case SET_MACHINE_SUB_TYPE: {
      const machineTypesRes = get(action, ['response'], []);
      const { machineTypes } = machineTypesRes;
      return {
        ...state,
        machineSubTypes: machineTypes,
        isLoading: false,
      };
    }

    case SET_ADVISORY: {
      const advisoryRes = get(action, ['response'], []);
      const { advisory, total } = advisoryRes;
      return {
        ...state,
        advisory,
        total,
        isAdvLoading: false,
      };
    }

    case SET_ADVISORY_APPROVAL: {
      const advisoryRes = get(action, ['response'], []);
      const { approvalRequests } = advisoryRes;
      return {
        ...state,
        approvalRequests,
        isAdvApprovalLoading: false,
      };
    }

    case GET_MACHINE_TYPE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_ADVISORY: {
      return {
        ...state,
        isAdvLoading: true,
      };
    }

    case GET_ADVISORY_APPROVAL: {
      return {
        ...state,
        isAdvApprovalLoading: true,
      };
    }

    case SET_MACHINE_TYPE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case SET_ADVISORY_FAILURE: {
      return {
        ...state,
        isAdvLoading: false,
      };
    }

    case SET_ADVISORY_APPROVAL_FAILURE: {
      return {
        ...state,
        isAdvApprovalLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default advisoryReducer;
