import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './global-styles/base-style.scss';
import './global-styles/antd-custom.scss';
import './global-styles/light-theme.scss';
import './global-styles/switch-styles.scss';

ReactDOM.render(<App />, document.getElementById('root'));
