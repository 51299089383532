import { notification } from 'antd';
import fetch from './fetch';
import { BASE_URL } from '../config';

export const getPipeline = async (projectNo) => {
  try {
    const res = await fetch(`${BASE_URL}/project/summary/get-project-pipelines?project_id=${projectNo}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const usersData = await res.json();
      return usersData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getPipelineIssue = async (projectNo) => {
  try {
    const res = await fetch(`${BASE_URL}/project/summary/get-pipeline-issue?project_id=${projectNo}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const usersData = await res.json();
      return usersData;
    }
    return null;
  } catch (error) {
    return null;
  }
};
export const getQualityData = async (projectNo, issueId, date, shift) => {
  try {
    const res = await fetch(`${BASE_URL}/project/summary/get-quality-entry-details?project_id=${projectNo}&issue_id=${issueId}&date=${date}&shift=${shift}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    if (res.status === 200) {
      const safetyData = await res.json();
      return safetyData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const addProjectPipelines = async (projectNo, pipeline, requiredHeader) => {
  try {
    const projNo = encodeURIComponent(projectNo).replace(/[!'()]/g, escape).replace(/\*/g, '%2A');
    const res = await fetch(`${BASE_URL}/project/summary/pipelines`, {
      method: 'POST',
      body: JSON.stringify({
        name: pipeline,
        project_id: projNo,
        required_for_header: requiredHeader
      }),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const removeProjectPipeline = async (deletedDate, userId) => {
  try {
    const res = await fetch(`${BASE_URL}/project/summary/delete-project-pipeline?deleted_date=${deletedDate}&id=${userId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getPersonnelData = async (
  projectNo, projectPipelinesId, date, status, shift, startDate, endDate) => {
  try {
    const res = await fetch(`${BASE_URL}/project/summary/get-personnel-entries?project_id=${projectNo}&project_pipelines_id=${projectPipelinesId}&date=${date}&status=${status}&shift=${shift}&startDate=${startDate}&endDate=${endDate}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    if (res.status === 200) {
      const safetyData = await res.json();
      return safetyData;
    }
    return null;
  } catch (error) {
    return null;
  }
};
