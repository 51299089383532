/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import {
  Modal, Button, Table, notification, Spin
} from 'antd';
import moment from 'moment';
import { getSafetyData } from '../services/summary';

const column = [
  {
    title: 'Report No.',
    dataIndex: 'reportNo',
    key: 'reportNo',
    align: 'center',
    render: (text, record, index) => <>{index + 1}</>
  },
  {
    title: 'No. of Incidents',
    dataIndex: 'no_of_incidents',
    key: 'no_of_incidents',
    align: 'center'
  },
  {
    title: 'Near Misses',
    dataIndex: 'near_misses',
    key: 'near_misses',
    align: 'center'
  },
  {
    title: 'Observation Cards Raised',
    dataIndex: 'obs_card_raised',
    key: 'obs_card_raised',
    align: 'center'
  },
  {
    title: 'Observation Cards Closed',
    dataIndex: 'obs_card_closed',
    key: 'obs_card_closed',
    align: 'center'
  },
  {
    title: 'Toolbox Talks',
    dataIndex: 'toolbox_talks',
    key: 'toolbox_talks',
    align: 'center'
  },
  {
    title: 'Comments',
    dataIndex: 'safety_comments',
    key: 'safety_comments',
    align: 'center'
  },

];

const SafetyModal = (props) => {
  const {
    visible, setVisible, projNo, shift
  } = props;
  const [loading, setLoading] = useState(false);
  const [safetyData, setSafetyData] = useState([]);

  const getData = async () => {
    setLoading(true);
    try {
      const date = moment(new Date()).format('YYYY-MM-DD');
      const res = await getSafetyData(projNo, date, shift);
      if (res?.safetyData) setSafetyData(res?.safetyData);
      else setSafetyData([]);
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (visible) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <>
      <Modal
        title="Safety"
        className="welds-modal"
        centered
        visible={visible}
        width={1009}
        footer={[
          <Button key="submit" type="primary" onClick={() => { setVisible(false); }}>
            OK
          </Button>
        ]}
      >
        <Table
          pagination={false}
          columns={column}
          dataSource={safetyData}
          className="table-with-button-assets"
          loading={{
            indicator: <Spin />,
            spinning: loading
          }}
          scroll={{ x: true }}
          style={{ whiteSpace: 'nowrap' }}
        />
      </Modal>
    </>
  );
};

export default SafetyModal;
