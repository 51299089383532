/* eslint-disable  */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import {
  Modal, Select, Button, notification, Spin, Space, Row, Col, Input, Form, Divider, Typography,Checkbox
} from 'antd';
import { CloseOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';
import { getUsers, getRoles, addNonCognitoUser, getNonCognitoUsers, addPersonalMasterRoles } from '../services/teamMembers';
import * as constants from '../utils/constants';
import * as utility from '../utils/utility';
import isEmpty from 'lodash/isEmpty';

const uuid = require("uuid");

const { Option } = Select;

const AddMemberModal = (props) => {
  const {
    isModalVisible, setIsModalVisible, onSave, setTeamModalLoading, 
    teamModalLoading, teamMemberList, onUpdate, userIds, isEdit, projectType, accessToLogs, 
    setAccessToLogs, recordData, setRecordData
  } = props;
  const [selectedValue, setSelectedValue] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [nonCognitoUsers, setNonCognitoUsers] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [personnelOptions, setPersonnelOptions] = useState([]);
  const [isAddNonCognitoUserVisible, setIsAddNonCognitoUserVisible] = useState(false);
  const [cognitoUserLoading, setCognitoUserLoading] = useState(false);
  const [newRole, setNewRole] = useState('');
  const [roleLoading, setRoleLoading] = useState(false);

  const [nonCognitoForm] = Form.useForm()
  
  const handleOk = () => {
    if(selectedValue.length === 0){
        notification.warning({
          message: 'Please select any user!'
        });
    } else {
      const userIds = selectedValue.map((user) => {
        const UserId = user.key;
        const isNonCognitoUser = userOptions.find(val => val.user_id === user.key)?.isNonCognitoUser;
        return { UserId: UserId, isNonCognitoUser: isNonCognitoUser, roleId: selectedRole.key,accessToLogs:accessToLogs };
      });
      onSave(userIds);
    }
  };

  const handleUpdate = () => {
    // if (newRole === ''){
    //   notification.warning({
    //     message: 'Please select any user!'
    //   });
    // }else {
    //   const roleIds = selectedRole.key;
    //   onUpdate(roleIds,accessToLogs);
    // }
      const roleIds = selectedRole.key;
      onUpdate(roleIds,accessToLogs);
  };

  const handleCancel = () => {
    setSelectedValue([]);
    setRecordData([]);
    setSelectedRole([]);
    setIsModalVisible(false);
    setAccessToLogs(false);
  };

  const onChange = (value) => {
    setSelectedValue(value);
  };

  const onChangeRole = (value) => {
    setSelectedRole(value);
  };

  const getUsersList = async () => {
    setTeamModalLoading(true);
    const response = await getUsers();
    let nonUsersListData = []
    if (response && response.Users.length) {
      let usersForProject = response.Users.map((obj) => {
        if (!teamMemberList.some((obj2) => obj.Username === obj2.userId)) {
          const details = { user_id: obj.Username };
          obj.Attributes.forEach((element) => {
            details.isNonCognitoUser = false;
            if (element.Name === 'name') {
              details.name = element.Value;
            } else if (element.Name === 'email') {
              details.email = element.Value;
            }
          });
          if (details.name && details.email) {
            return details;
          }
        }
        return null;
      });
      let nonCognitoUsersList = await getNonCognitoUsers();
      if (nonCognitoUsersList.data && nonCognitoUsersList.data.length > 0) {
            setNonCognitoUsers(nonCognitoUsersList.data);
            nonUsersListData = nonCognitoUsersList.data.map(nonUserData => {
            if (!teamMemberList.some((obj) => nonUserData.user_id === obj.userId)) {
              nonUserData.name = `${nonUserData?.first_name} ${nonUserData.last_name || '' }`
              return nonUserData;
            }
            return null;
          })
          usersForProject.push(...nonUsersListData)
      }
      usersForProject = usersForProject.filter((el) => el);
      usersForProject.sort((a, b) => a.name.localeCompare(b.name))
      setUserOptions(usersForProject);
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
    setTeamModalLoading(false);
  };

  const getRolesList = async () => {
    setTeamModalLoading(true);
    const response = await getRoles();
    if (response && response.masterPersonnelData.length) {
      let roleForProject = response.masterPersonnelData.map((obj) => ({
          id: obj.id,
          role: obj.role,
          userId: userIds
        }));

      roleForProject = roleForProject.filter((el) => el);
         setPersonnelOptions(roleForProject);
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
    setTeamModalLoading(false);
  };

  const onTagClose = (item) => {
    const newTags = selectedValue.filter((value) => value.key !== item.key);
    setSelectedValue(newTags);
  };

  useEffect(() => {
    getUsersList();
    getRolesList();
  }, []);

  const handleAddNonCognitoUser = async () => {
    nonCognitoForm
      .validateFields()
      .then(async (data) => {
          if (!data.first_name && !data.last_name 
          ) {
              notification.warning({
                  message: 'First name and Last name required!'
              });
          } else if (!data.first_name) {
              notification.warning({
                  message: 'Enter First Name to proceed!'
              });
          } else if (!data.last_name) {
              notification.warning({
                  message: 'Enter Last Name to proceed!'
              });
                    }
          else {
            if(nonCognitoUsers.find(item => item.isNonCognitoUser 
              && item.first_name === data.first_name.trim() 
              && item.last_name === data.last_name.trim()  )){
              notification.warning({
                message: 'This Name Already Exist!'
            });
            }else if(data.email && nonCognitoUsers.find(item => item.isNonCognitoUser && item.email === data.email )){
              notification.warning({
                message: 'This Email Already Exist!'
            });
            }
            else{
              setCognitoUserLoading(true)
                let res = await addNonCognitoUser({
                  user_id: uuid.v4(),
                  first_name: data.first_name.trim(),
                  last_name: data.last_name.trim(),
                  email: data.email ? data.email : null 
                  })
              if(res){
                getUsersList();
                setCognitoUserLoading(false);
                setIsAddNonCognitoUserVisible(false)
                nonCognitoForm.resetFields();
              } 
            }   
          }
      });
}

const onAddNewRole = (event) => {
  setNewRole(event.target.value);
};

const addItem = async (e) => {
  e.preventDefault();
  if (!isEmpty(newRole) && !isEmpty(newRole.trim())) {
    setRoleLoading(true);
    try {
      if(personnelOptions.find(item => item.role === newRole )){
        notification.warning({
          message: 'This Role Already Exist!'
      });
      } else{
        await addPersonalMasterRoles(newRole);
        await getRolesList()
        setNewRole('');
      }
    } catch (error) {
      console.error('Error adding new role:', error);
    } finally {
      setRoleLoading(false);
    }
  }
};

const removeItem = (e) => {
  e.preventDefault();
  setNewRole('');
}

const accessToLogsValue = () => {
  if (accessToLogs === true) {
      setAccessToLogs(false);Time
  } else {
      setAccessToLogs(true);
  }
}

const valueKey = selectedRole?.key || (recordData?.role && String(recordData.role));
  return (
    <>
      <Modal
        className="quality-modal"
        title={isEdit ? `Assign Role - ${recordData.name}` : 'Add Team Members'}
        centered
        visible={isModalVisible}
        onOk={() => handleOk(false)}
        footer={isEdit ? [
          <Button 
            key="submit" 
            type="primary" 
            onClick={handleCancel} 
            disabled={teamModalLoading}
          >
            Cancel
          </Button>,
          <Button
            key="submit" 
            type="primary" 
            // disabled={selectedRole.length === 0}
            onClick={() => handleUpdate(false)} 
            loading={teamModalLoading}
          >
            Update
          </Button>
        ] : [
          <Button 
            key="submit" 
            type="primary" 
            onClick={() => handleCancel(false)} 
            disabled={teamModalLoading}
          >
            Cancel
          </Button>,
          <Button
            key="submit" 
            type="primary" 
            onClick={() => handleOk(false)} 
            disabled={teamModalLoading} 
            loading={teamModalLoading}
          >
            Add
          </Button>
          ]}
      >
        <div>
          <div className="tag-wrapper pb-3">
            { selectedValue.map((item) => (
              <>
                <div className="d-flex align-items-center">
                  <b>{item.label}</b>
                  <Button
                    type="circle"
                    className="close-btn d-flex align-items-center justify-content-center"
                    onClick={() => onTagClose(item)}
                  >
                    <CloseOutlined />
                  </Button>
                </div>
              </>
                ))}
          </div>
          <Row gutter={16}>
              <Col lg={24}>

          {!isEdit && (
              <Select
                className="select-dropdown"
                placeholder="Select User"
                mode="multiple"
                maxTagCount={0}
                maxTagPlaceholder={null}
                showArrow
                tagRender={() => <></>}
                style={{ width: '60%' }}
                onChange={onChange}
                value={selectedValue}
                labelInValue
                showSearch
                autoClearSearchValue
                optionFilterProp="children"
                dropdownRender={menu => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                    <Space style={{ padding: '0 8px 4px' }}>
                      <Button type="text" icon={<PlusOutlined />} onClick={() => setIsAddNonCognitoUserVisible(true)}>
                        Add User
                      </Button>
                    </Space>
                  </>
                )}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {userOptions.map(user => (
                  <Option value={user.user_id} key={user.user_id}>
                    {user.name}
                  </Option>
                ))}
              </Select>
            )}
            </Col>
            <Col lg={24}>
            {projectType === 'offshore' && (
              <Select
                className="select-dropdown-role"
                placeholder="Select Role"
                showArrow
                style={{ width: '60%' }}
                onChange={onChangeRole}
                // onFocus={getRolesList}
                loading={roleLoading}
                labelInValue
                value={valueKey !== "" && valueKey !== undefined ? { key: valueKey } : undefined}
                showSearch
                autoClearSearchValue
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                dropdownRender={menu => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                      <Input placeholder="Add Role" value={newRole} onChange={onAddNewRole} maxLength={30} />
                      <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                        <CheckOutlined />
                      </Typography.Link>
                      <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                        <CloseOutlined />
                      </Typography.Link>
                    </Space>
                  </>
                )}
              >
                {personnelOptions.map(role => (
                  <Option key={role.id} value={role.id}>
                    {role.role}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
          <Col lg={24}>
          {projectType === 'offshore' || projectType === 'onshore' ? 
          (<p style={{ marginTop: '20px', marginLeft: '4px' }}>
              <Checkbox 
                onChange={accessToLogsValue} 
                checked={accessToLogs}
              >
                  {projectType === 'offshore' ? 'To be listed in DPR' : 'To be Listed in LEM & Timesheet'}
              </Checkbox>
          </p>):''
}
          </Col>
          {teamModalLoading ? <Spin /> : null}
          </Row>
        </div>
      </Modal>
      <Modal
        width="45vw"
        title={"Add User"}
        centered
        visible={isAddNonCognitoUserVisible}
        footer={[
            <Button key="cancel" onClick={() => {
                setIsAddNonCognitoUserVisible(false)
                nonCognitoForm.resetFields()
            }}>
                Cancel
            </Button>,
            <Button key="apply" type="primary" 
            onClick={handleAddNonCognitoUser} 
            loading={cognitoUserLoading}
            >
              {cognitoUserLoading ? 'Adding' : 'Add'}
            </Button>
        ]}
      >
        <Form
          form={nonCognitoForm}
          name="basic"
          layout="vertical"
          autoComplete="off" >
            <Row gutter={16}>
              <Col lg={12}>
                <Form.Item
                label="First Name"
                name="first_name"
                required
                >
                  <Input
                    placeholder="First Name"
                    required={true}
                    maxLength={20}
                    size="large" />
                </Form.Item>
              </Col>              
              <Col lg={12}>
                <Form.Item
                  label="Last Name"
                  name="last_name"
                  required
                  >
                    <Input
                        placeholder="Last Name"
                        required={true}
                        maxLength={20}
                        size="large" />
                </Form.Item>
              </Col>
                <Col lg={24}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        pattern: true
                          ? constants.EMAIL_PATTERN
                          : undefined,
                        message: 'Provide a valid Email.',
                      }
                    ]}
                    normalize={(value) => utility.trimSpacesInValues(value)}
                    require={false}
                  >
                    <Input size="large" placeholder="Enter Email Id" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
      </Modal>
    </>
  );
};

export default AddMemberModal;
