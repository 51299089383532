/* eslint-disable prefer-template */
/* eslint-disable linebreak-style */
import get from 'lodash/get';
import uuid from 'uuid/v4';
import moment from 'moment';
import { GET_PROD_REPORTS, SET_PROD_REPORTS, SET_PROD_REPORT_FAILURE } from '../types';
import { calcReportingPeriod } from '../../services/calendar';
import * as constants from '../../utils/constants';

const initialState = {
  isLoading: false,
};

const calcParams = (data, entryData) => {
  let value = 0;
  if (data && entryData) {
    value = data + entryData;
  } else if (!data && entryData) {
    value = entryData;
  } else if (data) {
    value = data;
  } else {
    value = 0;
  }
  return value;
};

const prodLogReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROD_REPORTS: {
      const pReports = get(action, ['response', 'items'], []);
      const data = [];
      for (let i = 0; i < pReports.length; i += 1) {
        const pData = get(pReports[i], 'data', {});
        const reportingPeriod = calcReportingPeriod(pData.period);
        const splitDate = reportingPeriod.split(' - ');
        const startDate = new Date(splitDate[0]);
        const endDate = new Date(splitDate[1]);
        const formatReportingPeriod = moment(startDate).format(constants.DATE) + ' to ' + moment(endDate).format(constants.DATE);
        const entries = [];
        const crews = [];

        let welds; let repairs; let
          repairRate = 0;

        if (pData.crews) {
          Object.values(pData.crews).map((crew) => (
            crews.push({ id: crew.crew_id || uuid(), name: crew.name })
          ));
        }

        if (pData.entries) {
          for (let x = 0; x < pData.entries.length; x += 1) {
            entries[x] = [];
          }
        }

        if (pData.entries) {
          Object.values(pData.entries).forEach((entry, index) => {
            if (entry.crews) {
              entries[index] = {
                date: entry.date,
                comments: entry.comments,
              };

              Object.values(entry.crews).forEach((d, y) => {
                if (new Date(entry.date) <= new Date()) {
                  welds = calcParams(welds, d.welds);
                  repairs = calcParams(repairs, d.repairs);
                }
                entries[index][y] = {
                  crewName: d.name,
                  welds: d.welds,
                  repairs: d.repairs,
                  crewId: d.crew_id
                };
                return d;
              });
            }
            return entry;
          });
        }

        repairRate = (welds ? (repairs / welds) * 100 : 0).toFixed(2);

        data[i] = {
          reportId: get(pReports[i], 'id', '-'),
          type: get(pReports[i], 'type', '-'),
          period: get(pData, 'period', ''),
          reportingPeriod,
          formatReportingPeriod,
          projectNo: get(pData, 'project_number', '-'),
          projectName: get(pData, 'project_name', '-'),
          lead: get(pData, 'lead_tech', '-'),
          author: get(pReports[i], 'author', '-'),
          lastUpdatedAt: get(pReports[i], 'updatedAt', '-'),
          totalWelds: welds || 0,
          totalRepairs: repairs || 0,
          repairRate: repairRate ? `${repairRate} %` : '0.00 %',
          pdf: get(pReports[i], 'pdf_url', ''),
          contractor: get(pData, 'contractor', '-'),
          email: get(pReports[i], 'email', '-'),
          createdAt: get(pReports[i], 'createdAt', '-'),
          entries,
          crews,
        };
      }
      return {
        ...state,
        data,
        isLoading: false,
      };
    }

    case GET_PROD_REPORTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_PROD_REPORT_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default prodLogReportReducer;
