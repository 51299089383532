/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-constant-condition */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  func
} from 'prop-types';
import moment from 'moment';
import {
  Modal, Form, Input, Row, Col, DatePicker, notification, Tooltip, Checkbox, Radio
} from 'antd';
import * as utility from '../utils/utility';
import * as constants from '../utils/constants';
import { saveProject } from '../state/action/projectListAction';
import { projectType as projectTypeConstant } from '../utils/constants';

const { offshore, onshore, onshoreEmea } = projectTypeConstant;

const CreateProject = (props) => {
  const {
    onSaveProject, masterProject
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [docusignRequired, setDocuSignRequired] = useState(false);
  const [type, setType] = useState('onshore');
  const [pjctManagerEmailRequired, setPjctManagerEmailRequired] = useState(false);

  const [form] = Form.useForm();
  const projNumInputRef = useRef();

  const {
    // eslint-disable-next-line no-unused-vars
    group
  } = props;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onChange = (e) => {
    setDocuSignRequired(e.target.checked);
    if (e.target.checked) {
      setPjctManagerEmailRequired(true);
    } else {
      setPjctManagerEmailRequired(false);
    }
  };

  const onRadioChange = (e) => setType(e.target.value);

  const createProject = async () => {
    let projectFormData = null;
    if (form.getFieldValue('endDate')) {
      projectFormData = {
        pjctName: form.getFieldValue('projectName') ? form.getFieldValue('projectName') : '',
        pjctNumber: form.getFieldValue('projectNumber') ? form.getFieldValue('projectNumber') : '',
        pjctContractor: form.getFieldValue('contractor') ? form.getFieldValue('contractor') : '',
        pjctLead: form.getFieldValue('leadTech') ? form.getFieldValue('leadTech') : '',
        pjctStartDate: form.getFieldValue('startDate') ? moment(form.getFieldValue('startDate')).format(constants.DATETIMEFULL) : '',
        pjctEndDate: form.getFieldValue('endDate') ? moment(form.getFieldValue('endDate')).format(constants.DATETIMEFULL) : '',
        pjctPlannedWelds: form.getFieldValue('plannedWelds') ? form.getFieldValue('plannedWelds') : 0,
        pjctPipeSize: form.getFieldValue('size') ? form.getFieldValue('size') : 0,
        pjctMileage: form.getFieldValue('mileage') ? form.getFieldValue('mileage') : 0,
        pjctWallThickness: form.getFieldValue('thickness') ? form.getFieldValue('thickness') : 0,
        pjctContractorEmail: form.getFieldValue('contractorEmail')
          ? form.getFieldValue('contractorEmail') : '',
        pjctDocuSign: docusignRequired,
        pjctType: type
      };
      if (projectFormData.pjctContractorEmail === '') {
        await onSaveProject({
          data: {
            project_name: projectFormData.pjctName,
            project_number: projectFormData.pjctNumber,
            contractor: projectFormData.pjctContractor,
            lead_tech: projectFormData.pjctLead,
            start_date: projectFormData.pjctStartDate,
            end_date: projectFormData.pjctEndDate,
            planned_welds: projectFormData.pjctPlannedWelds,
            pipe_size: projectFormData.pjctPipeSize,
            mileage: projectFormData.pjctMileage,
            wall_thickness: projectFormData.pjctWallThickness,
            project_status: 'InProgress',
            esign_required: projectFormData.pjctDocuSign,
            project_type: projectFormData.pjctType
          },
        });
      } else {
        await onSaveProject({
          data: {
            project_name: projectFormData.pjctName,
            project_number: projectFormData.pjctNumber,
            contractor: projectFormData.pjctContractor,
            lead_tech: projectFormData.pjctLead,
            start_date: projectFormData.pjctStartDate,
            end_date: projectFormData.pjctEndDate,
            planned_welds: projectFormData.pjctPlannedWelds,
            pipe_size: projectFormData.pjctPipeSize,
            mileage: projectFormData.pjctMileage,
            wall_thickness: projectFormData.pjctWallThickness,
            project_status: 'InProgress',
            contractor_email: projectFormData.pjctContractorEmail,
            esign_required: projectFormData.pjctDocuSign,
            project_type: projectFormData.pjctType
          },
        });
      }
    } else {
      projectFormData = {
        pjctName: form.getFieldValue('projectName') ? form.getFieldValue('projectName') : '',
        pjctNumber: form.getFieldValue('projectNumber') ? form.getFieldValue('projectNumber') : '',
        pjctContractor: form.getFieldValue('contractor') ? form.getFieldValue('contractor') : '',
        pjctLead: form.getFieldValue('leadTech') ? form.getFieldValue('leadTech') : '',
        pjctStartDate: form.getFieldValue('startDate') ? moment(form.getFieldValue('startDate')).format(constants.DATETIMEFULL) : '',
        pjctPlannedWelds: form.getFieldValue('plannedWelds') ? form.getFieldValue('plannedWelds') : 0,
        pjctPipeSize: form.getFieldValue('size') ? form.getFieldValue('size') : 0,
        pjctMileage: form.getFieldValue('mileage') ? form.getFieldValue('mileage') : 0,
        pjctWallThickness: form.getFieldValue('thickness') ? form.getFieldValue('thickness') : 0,
        pjctContractorEmail: form.getFieldValue('contractorEmail')
          ? form.getFieldValue('contractorEmail') : '',
        pjctDocuSign: docusignRequired,
        pjctType: type
      };
      if (projectFormData.pjctContractorEmail === '') {
        await onSaveProject({
          data: {
            project_name: projectFormData.pjctName,
            project_number: projectFormData.pjctNumber,
            contractor: projectFormData.pjctContractor,
            lead_tech: projectFormData.pjctLead,
            start_date: projectFormData.pjctStartDate,
            planned_welds: projectFormData.pjctPlannedWelds,
            pipe_size: projectFormData.pjctPipeSize,
            mileage: projectFormData.pjctMileage,
            wall_thickness: projectFormData.pjctWallThickness,
            project_status: 'InProgress',
            esign_required: projectFormData.pjctDocuSign,
            project_type: projectFormData.pjctType

          },
        });
      } else {
        await onSaveProject({
          data: {
            project_name: projectFormData.pjctName,
            project_number: projectFormData.pjctNumber,
            contractor: projectFormData.pjctContractor,
            lead_tech: projectFormData.pjctLead,
            start_date: projectFormData.pjctStartDate,
            planned_welds: projectFormData.pjctPlannedWelds,
            pipe_size: projectFormData.pjctPipeSize,
            mileage: projectFormData.pjctMileage,
            wall_thickness: projectFormData.pjctWallThickness,
            project_status: 'InProgress',
            contractor_email: projectFormData.pjctContractorEmail,
            esign_required: projectFormData.pjctDocuSign,
            project_type: projectFormData.pjctType
          },
        });
      }
    }
    setPjctManagerEmailRequired(false);
    setDocuSignRequired(false);
  };

  const checkPjctNumberExist = async () => {
    await form.validateFields();
    if (masterProject) {
      let count = 0;
      Object.values(masterProject).map((value) => {
        if (typeof (value) === 'object') {
          if (form.getFieldValue('projectNumber').toLowerCase() === value.projectNo.toLowerCase()) {
            notification.warning({
              message: 'Project number already exists.'
            });
            count += 1;
          }
        }
      });
      if (count === 0) {
        createProject();
        form.resetFields();
        setIsModalVisible(false);
        setIsDisabled(true);
      }
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setIsDisabled(true);
    setPjctManagerEmailRequired(false);
  };

  const validateEndDate = () => {
    // eslint-disable-next-line no-unused-vars
    let count = 0;
    const startDate = moment(form.getFieldValue('startDate')).format('L');
    const endDate = moment(form.getFieldValue('endDate')).format('L');
    if (new Date(endDate) < new Date(startDate)) {
      count += 1;
      form.setFieldsValue({ endDate: '' });
      notification.error({
        message: 'End date should be greater than start date.'
      });
    }
  };

  const validateStartDate = () => {
    // eslint-disable-next-line no-unused-vars
    let count = 0;
    const startDate = moment(form.getFieldValue('startDate')).format('L');
    const endDate = moment(form.getFieldValue('endDate')).format('L');
    if (form.getFieldValue('endDate') && new Date(startDate) > new Date(endDate)) {
      count += 1;
      form.setFieldsValue({ startDate: '' });
      notification.error({
        message: 'Start date should be less than the end date.'
      });
    } else {
      setIsDisabled(false);
    }
  };

  const onlyNumberKey = (event) => {
    // Only ASCII character in that range allowed
    const ASCIICode = (event.which) ? event.which : event.keyCode;
    if (ASCIICode >= 48 && ASCIICode <= 57) {
      return true;
    }
    event.preventDefault();
    return false;
  };

  const onlyNumberDecimalKey = (event) => {
    const ASCIICode = (event.which) ? event.which : event.keyCode;

    const t = event.target.value;
    if (ASCIICode === 46) {
      if (!t) {
        event.preventDefault();
        return false;
      }
    } else if (ASCIICode < 48 || ASCIICode > 57) {
      event.preventDefault();
      return false;
    }
    if (t.indexOf('.') >= 0) {
      if (String.fromCharCode(event.which) === '.') {
        event.preventDefault();
        return false;
      }
    }
    event.target.value = (t.indexOf('.') >= 0) ? (t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 2)) : t;
    return true;
  };

  useEffect(() => {
    projNumInputRef.current && projNumInputRef.current.focus();
  });

  const numberValidator = (rule, value, callback) => {
    if (value) {
      const intValue = parseFloat(value, 10);
      if (Number.isNaN(intValue) || String(intValue) !== value) {
        callback('Please enter a valid integer.');
      } else {
        callback();
      }
    } callback();
  };

  return (
    <>
      {
        group ? (
          <div
            onClick={showModal}
            className="project-details-wrapper create-project">
            <Tooltip placement="topLeft" title="Create Project">
              <span className="icon-22">
                <svg>
                  <use xlinkHref="#add" />
                </svg>
              </span>
            </Tooltip>

          </div>
        ) : (
          <div
            className="project-details-wrapper create-project-disable">
            <span className="icon-22">
              <svg>
                <use xlinkHref="#add" />
              </svg>
            </span>
          </div>
        )
      }
      <Modal
        className="quality-modal"
        title="Create Project"
        centered
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={form.submit}
        maskClosable={false}
      >
        <div>
          <Form
            id="create-project-form"
            name="basic"
            layout="vertical"
            autoComplete="off"
            form={form}
            onFinish={checkPjctNumberExist}
          >
            <Row gutter={16}>
              <Col lg={12}>
                <Form.Item
                  label="Project Name"
                  name="projectName"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the Project Name.',
                    },
                  ]}
                  normalize={(value) => utility.trimSpacesInValues(value)}
                >
                  <Input ref={projNumInputRef} size="large" placeholder="Enter Project Name" maxLength={255} />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Project Number"
                  name="projectNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the Project Number.',
                    },
                    {
                      pattern: true
                        ? constants.PROJECT_NUMBER_PATTERN
                        : undefined,
                      message: 'Please enter a valid project number. Spaces are not allowed',
                    }
                  ]}
                  normalize={(value) => utility.trimSpacesInValues(value)}
                >
                  <Input size="large" placeholder="Enter Project Number" maxLength={255} />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Contractor "
                  name="contractor"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the Contractor name.',
                    },
                  ]}
                  normalize={(value) => utility.trimSpacesInValues(value)}
                >
                  <Input size="large" placeholder="Enter Contractor" maxLength={255} />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Project Manager Email "
                  name="contractorEmail"
                  rules={[
                    {
                      required: pjctManagerEmailRequired,
                      message: 'Please enter Project Manager Email.',
                    },
                    {
                      pattern: true
                        ? constants.EMAIL_PATTERN
                        : undefined,
                      message: 'Provide a valid Project Manager Email.',
                    }
                  ]}
                  normalize={(value) => utility.trimSpacesInValues(value)}
                >
                  <Input size="large" placeholder="Enter Project Manager Email" style={{ width: '227px' }} />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Lead Tech"
                  name="leadTech"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the Lead Tech name.',
                    },
                  ]}
                  normalize={(value) => utility.trimSpacesInValues(value)}
                >
                  <Input size="large" placeholder="Enter Lead Tech" maxLength={255} />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the Start Date.',
                    },
                  ]}
                >
                  <DatePicker
                    format="DD-MMM-YY"
                    placeholder="Enter Start Date"
                    onChange={validateStartDate}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="End Date"
                  name="endDate"
                >
                  <DatePicker
                    format="DD-MMM-YY"
                    placeholder="Enter End Date"
                    onChange={validateEndDate}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Planned Welds"
                  name="plannedWelds"
                  normalize={(value) => utility.trimSpacesInValues(value)}
                  rules={[
                    { validator: numberValidator },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter Planned Welds"
                    maxLength={6}
                    onKeyPress={(event) => onlyNumberKey(event)} />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Pipe Size (in inches)"
                  name="size"
                  normalize={(value) => utility.trimSpacesInValues(value)}
                  rules={[
                    { validator: numberValidator },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter Pipe Size"
                    maxLength={3}
                    onKeyPress={(event) => onlyNumberKey(event)}
                    />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Mileage (in miles)"
                  name="mileage"
                  normalize={(value) => utility.trimSpacesInValues(value)}
                  rules={[
                    { validator: numberValidator },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter Mileage"
                    onKeyPress={(event) => onlyNumberDecimalKey(event)}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Wall Thickness (in inches)"
                  name="thickness"
                  normalize={(value) => utility.trimSpacesInValues(value)}
                  rules={[
                    { validator: numberValidator },
                  ]}
                >
                  <Input
                    maxLength={9}
                    size="large"
                    placeholder="Enter Wall Thickness"
                    onKeyPress={(event) => onlyNumberDecimalKey(event)} />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="eSign for LEM"
                  name="esignChecked"
                  valuePropName="checked"
                >
                  <Checkbox onChange={onChange} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Radio.Group onChange={onRadioChange} value={type}>
                    <Radio value={onshore}>Onshore</Radio>
                    <Radio value={offshore}>Offshore</Radio>
                    <Radio value={onshoreEmea}>Onshore EMEA</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

CreateProject.propTypes = {
  onSaveProject: func.isRequired
};

const mapStateToProps = ({ saveProjectRequest }) => {
  const saveStatus = get(saveProjectRequest, '', []);
  return {
    saveStatus
  };
};

const mapDispatchToProps = {
  onSaveProject: saveProject
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject);
