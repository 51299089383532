/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({
  isLogin, component: Component, path, exact,
}) => (
  <Route
    exact={exact}
    path={path}
    render={() => (isLogin ? <Redirect to="/main" />
      : <Component />
    )}
  />
);

export default PublicRoute;
