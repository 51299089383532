import React, { useEffect, useState } from 'react';
import {
  Modal, Form, Input, DatePicker, Upload, Button, Radio, Space, notification, Select,
  Tooltip
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  UploadOutlined,
  EditOutlined,
} from '@ant-design/icons';
import uuid from 'uuid/v4';
import * as utility from '../utils/utility';
import MachineTypeSelect from './MachineTypeSelect';
import { ADV_DATE, BusinessUnit } from '../utils/constants';
import { getSignedUrl } from '../services/advisory';
import { authenticateCurrentUser } from '../services/auth';
import { getUsers } from '../services/teamMembers';

const maxSize = 10;
const { Option } = Select;
function AddMachine(props) {
  const {
    isVisible, onOk, onCancel, form, title, isLoading, setIsLoading, isCreate,
    disabled, isApproval, cancelText
  } = props;
  const [userOptions, setUserOptions] = useState([]);
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);

  const onsubmit = async () => {
    if (!isFormUpdated && !isCreate) {
      setisEditMode(false);
      onCancel();
      return '';
    }

    const data = await form.validateFields();
    const isAttachmentValid = data.attachments && data.attachments.find((item) => {
      if (item.status && item.status !== 'done') return true;
      return false;
    });
    if (isAttachmentValid) {
      return notification.error({
        message: `Please provide a file less than ${maxSize} mb.`,
      });
    }
    data.attachments = data.attachments && data.attachments.map((item) => {
      const url = item.response || item;
      return {
        ...url,
        uid: uuid(),
      };
    });
    setisEditMode(false);
    onOk(data);
    return '';
  };

  function beforeUpload(file) {
    let isValidSize = true;
    if (file?.type) {
      isValidSize = file.size / 1024 / 1024 <= maxSize;
      if (!isValidSize) {
        notification.error({
          message: `Please provide a file less than ${maxSize} mb`,
        });
      }
    }

    return isValidSize;
  }

  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      setIsLoading(true);

      const isValid = beforeUpload(file);
      if (!isValid) return onError('', file);

      const data = {
        files: [{
          filename: file.name,
          contentType: file.type
        }]
      };
      const response = await getSignedUrl(data);

      if (response.code === 200) {
        const result = await fetch(response.imageData[0].url, {
          method: 'PUT',
          body: file,
        });
        if (result.status === 200) {
          return onSuccess(response.imageData[0], file);
        }
      }
      return onError(response, file);
    } catch (error) {
      return onError(error, file);
    } finally {
      setIsLoading(false);
    }
  };
  const getUsersData = async () => {
    try {
      setIsLoading(true);
      const response = await getUsers();
      const userDetails = await authenticateCurrentUser();
      if (response && response.Users.length) {
        const usersForProject = response.Users.map((obj) => {
          const details = {
            user_id: obj.Username,
            disabled: obj.Username === userDetails.username
          };
          const nameAttribute = obj.Attributes.find((element) => element.Name === 'name');
          const emailAttribute = obj.Attributes.find((element) => element.Name === 'email');
          if (nameAttribute && emailAttribute) {
            details.name = nameAttribute.Value;
            details.email = emailAttribute.Value;
            return details;
          }
          return null;
        });
        const sorted = usersForProject.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
        setUserOptions(sorted);
      }
    } catch (error) {
      notification.error({
        message: error.message || 'Something went wrong.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      if (!isApproval) getUsersData();
      if (isCreate) {
        authenticateCurrentUser()
          .then((data) => {
            form.setFieldsValue({ createdBy: data.attributes.name || '' });
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleFormValuesChange = (changedValues) => {
    if (!changedValues.approverId) setIsFormUpdated(true);
  };

  const toggleContent = (checked) => {
    setisEditMode(checked);
  };
  const customTitle = (
    <div className="modal-title-container">
      <span className="modal-title-left">{title}</span>
      {!isApproval && !isCreate && !disabled && (
        <Tooltip
          arrowPointAtCenter
          placement="topLeft"
          title={isEditMode ? 'View Advisory' : 'Edit Advisory'}
        >
          <Button
            className="border-0"
            disabled={disabled}
            onClick={() => {
              toggleContent(!isEditMode);
            }}
          >
            {' '}
            <EditOutlined
              style={{ color: isEditMode ? 'grey' : 'red' }}
              className="edit-project-detail"
            />
          </Button>
        </Tooltip>
      )}
    </div>
  );

  const isDisabled = isCreate ? false : !isEditMode;
  return (
    <Modal
      className="quality-modal"
      title={customTitle}
      centered
      visible={isVisible}
      okText={title}
      cancelText={cancelText || 'Cancel'}
      onOk={onsubmit}
      onCancel={() => {
        setisEditMode(false);
        form.resetFields();
        onCancel();
      }}
      destroyOnClose
      okButtonProps={{ loading: isLoading, disabled: isLoading || isDisabled, hidden: isApproval }}
      maskClosable={false}
      width={600}
    >
      <Form
        layout="vertical"
        name="add_advisory"
        form={form}
        initialValues={{
          priority: 0,
          businessUnit: BusinessUnit.NAO
        }}
        onValuesChange={handleFormValuesChange}
      >

        <MachineTypeSelect placeholder="Select Machine Type" formName="parent" disabled={isDisabled} />
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            if (prevValues.parent !== currentValues.parent) {
              form.setFieldsValue({ type: undefined });
              return true;
            }
            return false;
          }}
        >
          {
            ({ getFieldValue }) => (getFieldValue('parent') ? (
              <MachineTypeSelect
                type={getFieldValue('parent')}
                placeholder="Select Machine Sub Type"
                formName="type"
                disabled={isDisabled}
              />
            ) : null)
          }
        </Form.Item>

        <Form.Item
          name="title"
          rules={[
            {
              required: true,
              message: 'Please enter your Title.',
            },
          ]}
          normalize={(value) => utility.trimSpacesInValues(value)}
        >

          <Input disabled={isDisabled} size="large" placeholder="Enter Advisory Title" maxLength={25} />
        </Form.Item>
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
              message: 'Please enter your Description.',
            },
          ]}
          normalize={(value) => utility.trimSpacesInValues(value)}
        >

          <TextArea disabled={isDisabled} showCount size="large" placeholder="Enter Short Advisory Description" maxLength={1000} />
        </Form.Item>
        <Form.Item
          name="date"
          rules={[
            {
              required: true,
              message: 'Please choose an effective date.',
            },
          ]}
        >
          <DatePicker
            disabled={isDisabled}
            format={ADV_DATE}
            placeholder="Choose an effective Date"
          />
        </Form.Item>
        <Form.Item
          name="createdBy"
          rules={[
            {
              required: true,
              message: 'Please enter created by.',
            },
          ]}
          normalize={(value) => utility.trimSpacesInValues(value)}
        >

          <Input disabled={isDisabled} size="large" placeholder="Effective Date" maxLength={25} />
        </Form.Item>
        <Form.Item
          name="priority"
          label="Priority"
          rules={[
            {
              required: true,
              message: 'Please enter priority.',
            },
          ]}
        >
          <Radio.Group disabled={isDisabled}>
            <Space direction="horizontal">
              <Radio value={0}>Action Required</Radio>
              <Radio value={1}>Be on Look Out</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="businessUnit"
          label="Business Unit"
          rules={[
            {
              required: true,
              message: 'Please enter business unit.',
            },
          ]}
        >
          <Radio.Group disabled={isDisabled}>
            <Space direction="horizontal">
              <Radio value={BusinessUnit.NAO}>{BusinessUnit.NAO}</Radio>
              <Radio value={BusinessUnit.EMEA}>{BusinessUnit.EMEA}</Radio>
              <Radio value={BusinessUnit.NAL}>{BusinessUnit.NAL}</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        {/* <label className="mb-2"> </label> */}
        <Form.Item
          name="attachments"
          label="Attachments:"
          valuePropName="fileList"
          getValueFromEvent={(info) => info.fileList}
        >
          <Upload
            name="attachments"
            customRequest={customRequest}
            multiple
            maxCount={10}
            accept=".jpg,.jpeg,.png,.mp4,.pdf"
          >
            <Button disabled={isDisabled} icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
        {!isApproval
        && (
        <Form.Item
          name="approverId"
          label="Approver"
          rules={[
            {
              required: true,
              message: 'Please choose an approver.',
            },
          ]}
        >
          <Select
            disabled={isDisabled}
            // className="select-dropdown"
            placeholder="Select Approver"
            style={{ width: '60%' }}
            showSearch
            autoClearSearchValue
            optionFilterProp="children"
            filterOption={(input, option) => (option.children).toLowerCase()
              .includes(input.toLowerCase())}
            loading={isLoading}
          >
            {userOptions.map((user) => {
              if (user) {
                return (
                  <Option
                    value={user?.user_id}
                    key={user?.user_id}
                    disabled={user?.disabled}
                  >
                    {user?.name}
                  </Option>
                );
              }
              return <></>;
            })}
          </Select>
        </Form.Item>
        )}
      </Form>
    </Modal>
  );
}

export default AddMachine;
