/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable operator-assignment */
/* eslint-disable no-unneeded-ternary */
import {
  Button, DatePicker, Table, Spin, notification, Modal, Col, Row, Input
} from 'antd';
import React, { useState, useEffect } from 'react';
import {
  CheckOutlined
} from '@ant-design/icons';
import moment from 'moment';
import get from 'lodash/get';
import { connect } from 'react-redux';
import {
  func, arrayOf, objectOf, oneOfType, string, number,
  array, bool
} from 'prop-types';
import { uploadFile } from 'react-s3';
import uuid from 'uuid/v4';
import { getTimesheet } from '../state/action/timesheetAction';
import { getProdLogReportData, addMonthlySignature, getMonthlySignature } from '../services/teamMembers';
import { getAwsConfigData } from '../services/awsConfigData';
import { authenticateCurrentUser } from '../services/auth';

const { RangePicker } = DatePicker;

const TimesheetEMEA = (props) => {
  const {
    onGetTimesheet, data, projNo, showLoader, selectedProjectDetail,
    dates, setDates, projectType
  } = props;

  const [disableRangeDataFetchBtn, setDisableRangeDataFetchBtn] = useState(true);
  const [showGenerateReportModal, setShowGenerateReportModal] = useState(false);
  const [defaultDateRange, setDefaultDateRange] = useState([moment().startOf('month'), moment().endOf('month')]);
  const [sendReportModalLoading, setSendReportModalLoading] = useState(false);
  const [awsConfigDt, setAwsConfigDt] = useState([]);
  const [signatureFileURL, setSignatureFileURL] = useState('');
  const [fileUploaded, setFileUploaded] = useState(false);

  let config = {};

  if (awsConfigDt.length > 0) {
    const S3_BUCKET = 'parts-data';
    const REGION = 'us-west-2';
    const ACCESS_KEY = awsConfigDt[0].access_key_id;
    const SECRET_ACCESS_KEY = awsConfigDt[0].secret_access_key;

    config = {
      bucketName: S3_BUCKET,
      region: REGION,
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
    };
  }
  const columns = [
    {
      title: 'Created Time',
      dataIndex: 'submitted_at',
      key: 'submitted_at',
      render: (text, record) => (record.submitted_at ? moment(record.submitted_at).format('DD-MMM-YYYY, hh:mm A') : '-'),
      align: 'center'
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      render: (text, record) => {
        const [startDateString, endDateString] = record.period.split(' to ');
        return (
          `${moment(startDateString, 'YYYY-MM-DD').format('DD-MMM-YYYY')} to ${moment(endDateString, 'YYYY-MM-DD').format('DD-MMM-YYYY')}`
        );
      },
      align: 'center'
    },
    {
      title: 'Report',
      dataIndex: 'pdf_url',
      key: 'pdf_url',
      render: (text, record) => {
        if (record) {
          return (
            <div>
              {' '}
              <a
                className="export-link"
                title="Reports"
                target="_blank"
                rel="noopener noreferrer"
                href={record.pdf_url}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span className="icon-16 mr-2">
                  <svg>
                    <use
                      xlinkHref="#export"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </svg>
                </span>
              </a>
            </div>
          );
        }
        return <div>-</div>;
      },
      align: 'center'
    }
  ];

  const getTimesheetData = async (startDt = '', endDt = '') => {
    onGetTimesheet(startDt, endDt, projNo, projectType);
  };

  const currentMonthStartEndDate = () => {
    const firstdate = moment(dates[0]).format('YYYY-MM-DD');
    const lastdate = moment(dates[1]).format('YYYY-MM-DD');
    getTimesheetData(firstdate, lastdate);
  };

  const getAwsConfigDatas = async () => {
    const awsConfigData = await getAwsConfigData();
    setAwsConfigDt(awsConfigData.data);
  };

  const getSign = async () => {
    const usr = await authenticateCurrentUser();
    try {
      const res = await getMonthlySignature(projNo, usr.username);
      if (res?.data) {
        setSignatureFileURL(res?.data?.signature_url);
      } else {
        setSignatureFileURL('');
      }
    } catch (error) {
      notification.error({
        message: error.message || 'Something went wrong.',
      });
    }
  };

  useEffect(() => {
    getTimesheetData();
    getSign();
    getAwsConfigDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo]);

  useEffect(() => {
    if (dates.length === 2 && dates[0] !== '' && dates[1] !== '') {
      setDisableRangeDataFetchBtn(false);
    } else {
      getTimesheetData();
      setDisableRangeDataFetchBtn(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  const handleCancel = () => {
    // Reset state when modal is closed
    setSendReportModalLoading(false);
    setFileUploaded(false);
    setShowGenerateReportModal(false);
    const startOfMonth = moment().startOf('month');
    const endOfMonth = moment().endOf('month');
    setDefaultDateRange([startOfMonth, endOfMonth]);
  };

  const getProdLogReport = async () => {
    try {
      setSendReportModalLoading(true);
      const firstdate = moment(defaultDateRange[0]).format('YYYY-MM-DD');
      const lastdate = moment(defaultDateRange[1]).format('YYYY-MM-DD');
      const userDetails = await authenticateCurrentUser();
      const res = await getProdLogReportData(firstdate,
        lastdate,
        selectedProjectDetail.projectNo,
        userDetails.attributes.name,
        signatureFileURL);
      if (res) {
        notification.success({
          message: 'Report Send successfully',
        });
        setTimeout(async () => {
          await getTimesheetData();
          handleCancel();
        }, 7000);
      }
    } catch (error) {
      setSendReportModalLoading(false);
      notification.error({
        message: 'Please choose any date range...',
      });
    }
  };

  const addSignature = async (signature) => {
    try {
      const userD = await authenticateCurrentUser();
      await addMonthlySignature(projNo, signature, userD.username);
      await getSign();
      setFileUploaded(false);
    } catch (error) {
      setFileUploaded(false);
      notification.error({
        message: error.message || 'Something went wrong.',
      });
    }
  };

  const handleFileInput = (event) => {
    const { files } = event.target;
    if (files.length) {
      const file = files[0];
      const fileTypeArr = file.name.split('.');
      const extention = fileTypeArr.pop();
      const typeArr = ['jpg', 'jpeg', 'png'];
      if (!typeArr.includes(extention.toLowerCase())) {
        notification.warning({
          message: 'Invalid file format. Please select a file with valid format (.jpg , .jpeg, .png)',
          duration: 1.5
        });
      } else {
        setFileUploaded(true);

        const modifiedFile = new File([file], `${projNo}_Signature_${moment().format('MMMM')}_${uuid()}.${extention}`, { type: file.type });
        uploadFile(modifiedFile, config)
          .then((fileData) => {
            // setSignatureFileURL(fileData.location);
            addSignature(fileData.location);
          })
          .catch((err) => console.error('err', err));
        // .finally((fileData) => {
        //   addSignature(fileData.location);
        // });
      }
    }
  };

  return (
    <>
      <div
        className="d-flex pb-2 align-items-center justify-content-between"
        style={{ height: '49px' }}
      >
        <div className="d-flex edit-session justify-content-center">
          <b className="mx-2">Filter:</b>
          <RangePicker
            className="rangePicker_separator"
            showToday
            separator="to"
            format="YYYY-MMM-DD"
            placeholder={['Start Date', 'End Date']}
            defaultValue={dates}
            onCalendarChange={(val, dateString) => { setDates(dateString); }}
          />
          {}
          <Button
            type="primary"
            disabled={disableRangeDataFetchBtn ? true : false}
            onClick={() => { currentMonthStartEndDate(); }}
          >
            <CheckOutlined />
            {' '}
          </Button>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            type="primary"
            size="medium"
            shape="round"
            className="timesheetButton"
            onClick={() => {
              setShowGenerateReportModal(true);
            }}
          >
            Generate Monthly Production Log Report
          </Button>
        </div>
      </div>
      <>
        <div className={data.length > 0 ? 'h-85 overflow-y-hidden overflow-x-auto has-pointer' : 'h-85 overflow-y-hidden overflow-x-auto'}>
          <Table
            pagination={false}
            className="h-85"
            scroll={{ x: data.length > 0 ? 1200 : 0, y: '100%' }}
            rowKey={(record) => record.id}
            columns={columns}
            style={{ marginTop: '20px' }}
            dataSource={data}
            loading={{
              indicator: <Spin />,
              spinning: showLoader
            }}
          />
        </div>
      </>
      <Modal
        className="quality-modal"
        title="Generate Monthly Report"
        centered
        visible={showGenerateReportModal}
        destroyOnClose
        footer={[
          <>
            <Button
              onClick={() => handleCancel()}
              disabled={sendReportModalLoading}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={sendReportModalLoading}
              loading={sendReportModalLoading || fileUploaded}
              onClick={() => {
                getProdLogReport();
              }}
            >
              Generate Report
            </Button>
          </>
        ]}
      >
        <div>
          <Row gutter={16} className="mb-3">
            { signatureFileURL
              ? (
                <Col lg={24}>
                  <img src={signatureFileURL} alt="" width="50px" height="50px" />
                </Col>
              ) : ''}
          </Row>
          <Row gutter={16} className="mb-3">
            <Col lg={24}>
              <span htmlFor="inputGroupFile">
                Choose
                {signatureFileURL ? ' New ' : ' '}
                Signature (Optional):
              </span>
              <Input
                type="file"
                name="file"
                id="inputGroupFile"
                required
                onChange={handleFileInput}
                accept="image/*"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={24}>
              <RangePicker
                className="rangePicker_separator"
                showToday
                separator="to"
                format="DD-MMM-YYYY"
                placeholder={['Start Date', 'End Date']}
                defaultValue={defaultDateRange}
                onCalendarChange={(dateString) => { setDefaultDateRange(dateString); }}
              />
            </Col>
          </Row>
        </div>

      </Modal>
    </>
  );
};

TimesheetEMEA.propTypes = {
  onGetTimesheet: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showLoader: bool.isRequired,
};

const mapStateToProps = ({ timesheetRequests }) => {
  const data = get(timesheetRequests, 'tdata', []);
  const showLoader = get(timesheetRequests, 'isLoading', false);
  return {
    data,
    showLoader
  };
};

const mapDispatchToProps = {
  onGetTimesheet: getTimesheet
};

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetEMEA);
