/* eslint-disable linebreak-style */
import {
  SET_PROJECTS_LIST,
  SAVE_PROJECT,
  UPDATE_PROJECT,
  SET_PROJECT_LIST_FAILURE,
  FETCH_PROJECTS_LIST
} from '../types';

const fetchProjectList = () => ({
  type: FETCH_PROJECTS_LIST,
});

const saveProject = (payload) => ({
  type: SAVE_PROJECT, ...payload
});

const updateProject = (payload) => ({
  type: UPDATE_PROJECT, ...payload,
});

const setProjectsList = (payload) => ({
  type: SET_PROJECTS_LIST, ...payload,
});

const setProjectListFailure = () => ({
  type: SET_PROJECT_LIST_FAILURE,
});

export {
  fetchProjectList,
  saveProject,
  updateProject,
  setProjectsList,
  setProjectListFailure
};
