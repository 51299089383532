/* eslint-disable no-shadow */

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Modal, Button, Empty,
} from 'antd';
import { datesInPeriod, datesInPeriodForEmea } from '../services/calendar';
import * as constants from '../utils/constants';

const TechModal = (props) => {
  const {
    currentReport, visible, setVisible, projectType
  } = props;
  const [newData, setNewData] = useState(false);
  const [tablehtml, setTablehtml] = useState('');

  const tableData = () => {
    const tableval = (
      <table className="logreport report-table h-100">
        {
          currentReport && currentReport.techs ? (
            <>
              <thead className="ant-table-thead">
                <tr>
                  <th rowSpan="2" className="ant-table-cell align-bottom">Date</th>
                  {
                    currentReport && currentReport.techs
                    && (currentReport.techs).map((tech, i) => (
                      <>
                        <th className="ant-table-cell text-center" key={tech.name ? tech.name : i}>
                          <div className="main-title py-2 me-2">
                            {tech.name && tech.name.length > 0 ? tech.name : '(No Name)'}
                          </div>
                        </th>

                      </>

                    ))
                  }
                </tr>
              </thead>
              <tbody className="ant-table-tbody">
                {
                  newData
                  && newData.map((e) => {
                    const isMemberIdPresent = currentReport.techs.find((tech) => tech.member_id);
                    const entryDetails = Object.values(e)
                      .filter((item) => item && typeof (item) === 'object');
                    return (
                      <tr key={e.date} className="ant-table-row ant-table-row-level-0">
                        <td className="rTableCell table-date-column">{moment(e.date).format(constants.DATE).replaceAll('/', '-')}</td>
                        {

                          currentReport && currentReport.techs.map((tech, i) => {
                            let filterTech = {};
                            if (isMemberIdPresent) {
                              filterTech = entryDetails.find((
                                ent
                              ) => ent.member_id === tech.member_id && !!ent.status);
                            } else {
                              filterTech = entryDetails.find((
                                ent
                              ) => ent.techName === tech.name);
                            }
                            return (
                              <td className="rTableCell text-center" key={(e.date && filterTech && filterTech.techName) ? `${e.date}${filterTech.techName}` : i}>
                                {filterTech?.status || ''}
                              </td>
                            );
                          })

                        }
                      </tr>
                    );
                  })
                }
              </tbody>
            </>
          ) : <Empty />
        }

      </table>
    );
    setTablehtml(tableval);
  };

  useEffect(() => {
    if (visible === false) {
      setTablehtml('');
      return;
    }
    let dates;
    if (projectType === 'Onshore_EMEA') {
      dates = datesInPeriodForEmea(currentReport.period);
    } else {
      dates = datesInPeriod(currentReport.period);
    }
    const { entries } = currentReport;
    const newData = [];
    let recordFound = false;
    if (dates) {
      dates.forEach((day) => {
        for (let i = 0; i < entries.length; i += 1) {
          if (new Date(entries[i].date) <= new Date()) {
            if (entries[i].date === day) {
              newData.push(entries[i]);
              recordFound = true;
              break;
            }
          }
        }
        if (!recordFound) {
          let dummyMem = [];
          if (currentReport) {
            dummyMem = currentReport.techs.map((tech) => ({
              techName: tech.name, status: undefined,
            }));
          }
          newData.push({ date: day, ...dummyMem });
          recordFound = false;
        }
        recordFound = false;
      });
    }

    setNewData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport, visible]);

  useEffect(() => {
    if (newData !== []) {
      tableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);

  return (
    <>
      <Button className="hidden-view-btn" onClick={() => setVisible(true)}>v</Button>
      <Modal
        title="Tech Log Report"
        className="welds-modal"
        centered
        visible={visible}
        width={1000}
        footer={[
          <Button key="submit" type="primary" onClick={() => setVisible(false)}>
            OK
          </Button>,
        ]}
      >
        <div className="ant-table scroll-table">
          <div className="ant-table-container h-100">
            <div className="ant-table-content" />
            {tablehtml}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TechModal;
