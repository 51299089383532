/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect } from 'react';
import {
  Router, Switch,
} from 'react-router-dom';
import { Layout } from 'antd';
import { Hub } from 'aws-amplify';
import routes from './routes';
import history from '../services/history';
import './style.scss';
import Sidebar from '../layout/sidebar';
import LoginPage from '../pages/Login';
import ForgotPassword from '../pages/Login/ForgotPassword';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { getCurrentUser } from '../sagas/auth';
import { authenticateCurrentUser } from '../services/auth';
import ResetPassword from '../pages/Login/ResetPassword';

const AppContainer = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  Hub.listen(
    'auth',
    // eslint-disable-next-line no-unused-vars
    async ({ payload: { event, data } }) => {
      switch (event) {
        case 'cognitoHostedUI': // set user data and get that data below
          break;
        case 'signIn':
          setIsAuthenticated(true);
          break;
        case 'signOut':
          setIsAuthenticated(false);
          break;
        case 'cognitoHostedUI_failure':
          break;
        default:
          break;
      }
    },
    []
  );
  useEffect(() => {
    const element = document.getElementById('body-wrapper');
    const theme = localStorage.getItem('theme');
    if (theme === 'True') {
      element.classList.remove('light-theme');
    } else {
      element.classList.add('light-theme');
    }
    authenticateCurrentUser()
      .then(() => {
        setIsAuthenticated(true);
      })
      .catch(() => {
        setIsAuthenticated(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <PublicRoute path="/login" isLogin={isAuthenticated} component={LoginPage} />
        <PublicRoute path="/forgot-password" isLogin={isAuthenticated} component={ForgotPassword} />
        <PublicRoute path="/reset-password" isLogin={isAuthenticated} component={ResetPassword} />
        <Layout className="h-100">
          <Sidebar />
          <Layout.Content className="h-100 pt-3 px-4 pb-4">
            <Switch>

              {routes.map((route) => (
                <PrivateRoute
                  isLogin={isAuthenticated}
                  path={route.path}
                  exact={route.exact}
                  component={((props) => (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  ))}
                />
              ))}
              <PrivateRoute
                path="*"
                isLogin={getCurrentUser()}
                component={() => '404 Not Found'}
              />
            </Switch>
          </Layout.Content>
        </Layout>
      </Switch>
    </Router>
  );
};

export default AppContainer;
