/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-trailing-spaces */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable array-callback-return */
import React from 'react';
import {
  Modal, Form, Row, Col, Button, Radio
} from 'antd';

const EditAssetStatusModal = (props) => {
  const {
    isStatusModalVisible, setIsStatusModalVisible, assetStatusModalLoading, 
    updateAssetStatusForm, onUpdate,
    setIsStatusEdit,
  } = props;

  const handleCancel = () => {
    setIsStatusModalVisible(false);
    updateAssetStatusForm.resetFields();
    setIsStatusEdit(false);
  };

  const handleUpdate = () => {
    updateAssetStatusForm
      .validateFields()
      .then((data) => {
          onUpdate(data.description, data.serial_number,
             data.project_asset_id, data.status);
      });
  };

  return (
    <>
      <Modal
        className="quality-modal"
        title="Update Status"
        centered
        visible={isStatusModalVisible}
        footer={[
          <Button 
            key="submit" 
            type="primary" 
            onClick={() => handleCancel(false)} 
            disabled={assetStatusModalLoading}
          >
            Cancel
          </Button>,
          <Button
            key="submit" 
            type="primary" 
            onClick={() => handleUpdate()} 
            disabled={assetStatusModalLoading} 
            loading={assetStatusModalLoading}
          >
            Update
          </Button>
        ]}
      >
        <div>
          
          <Form
            id="create-project-form"
            name="basic"
            layout="vertical"
            autoComplete="off"
            form={updateAssetStatusForm}
            onFinish=""
          >
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="mb-0"
              label="Status"
              name="status"
            >
              <Radio.Group className="w-100">
                <Row>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                    <Radio value="Onsite" className="radio-text d-flex align-items-center">
                      <span>
                        Onsite
                        {' '}
                        <span className="green_dot" />
                      </span>
                    </Radio>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                    <Radio value="In Use" className="radio-text">
                      <span>
                        Onsite
                        {' '}
                        <span className="red_dot" />
                      </span>
                    </Radio>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                    <Radio value="Spare" className="radio-text">Spare</Radio>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                    <Radio value="Sent to Tulsa" className="radio-text">
                      Sent to Tulsa
                    </Radio>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} className="mb-1">
                    <Radio value="Sent to Edmonton" className="radio-text">
                      Sent to Edmonton
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Form>
        </div>
        
      </Modal>
    </>
  );
};

export default EditAssetStatusModal;
