import {
  GET_TECH_REPORTS,
  SET_TECH_REPORTS,
  SET_TECH_REPORT_FAILURE,
} from '../types';

const getTechReports = (projectNo) => ({
  type: GET_TECH_REPORTS,
  projectNo
});

const setTechReports = (payload) => ({
  type: SET_TECH_REPORTS, ...payload,
});

const setTechReportFailure = () => ({
  type: SET_TECH_REPORT_FAILURE,
});

export {
  getTechReports,
  setTechReports,
  setTechReportFailure,
};
