/* eslint-disable linebreak-style */
import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { setProjectsList, setProjectListFailure } from '../state/action/projectListAction';
import { FETCH_PROJECTS_LIST, SAVE_PROJECT, UPDATE_PROJECT } from '../state/types';
import fetch from '../services/fetch';
import { addPipelineIssue } from '../services/summary';
import { BASE_URL } from '../config';

function* fetchProjectList() {
  try {
    const res = yield fetch(`${BASE_URL}/project/get-projects-list`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      throw new Error('Unauthorized');
    }
    const response = yield res.json();
    yield put(setProjectsList({ response }));
  } catch (error) {
    yield put(setProjectListFailure());
    notification.error({
      message: 'There was a problem fetching the app projects.',
      description: `${error}`,
    });
  }
}

function* saveProject({
  data,
}) {
  try {
    const res = yield fetch(`${BASE_URL}/project/create-project`, {
      method: 'POST',
      headers: {},
      body: JSON.stringify(data)
    });
    notification.destroy();
    if (res.status === 200) {
      const response = yield res.json();
      if (response.project.project_type === 'offshore') yield addPipelineIssue(response.project.project_number, response.project.start_date);
      yield fetchProjectList();
      notification.success({
        message: 'Project created Successfully.',
      });
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    notification.destroy();
    notification.error({
      message: 'Project creation Failed.',
      description: `${error}`,
    });
  }
}

function* updateProject({
  data
}) {
  try {
    const res = yield fetch(`${BASE_URL}/project/update-project`, {
      method: 'POST',
      headers: {},
      body: JSON.stringify(data)
    });
    notification.destroy();
    if (res.status === 200) {
      yield fetchProjectList();
      notification.success({
        message: 'Project updated Successfully.',
      });
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    notification.destroy();
    notification.error({
      message: 'Project updation Failed.',
      description: `${error}`,
    });
  }
}

function* projectListWatcher() {
  yield takeLatest(FETCH_PROJECTS_LIST, fetchProjectList);
  yield takeLatest(SAVE_PROJECT, saveProject);
  yield takeLatest(UPDATE_PROJECT, updateProject);
}

function* projectListSagas() {
  yield all([
    call(projectListWatcher),
  ]);
}

export default projectListSagas;
