/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import {
  Modal, Button, Table, notification, Spin, Switch, Tabs
} from 'antd';
import { shift } from '../utils/constants';
import { getDownTimeData } from '../services/summary';
import { toNumber } from '../utils/utility';
import ModalOffshoreSummaryGraph from './ModalOffshoreSummaryGraph';
import { getPipeline } from '../services/pipeline';

const { Column, ColumnGroup } = Table;
const { TabPane } = Tabs;

const DownTimeModalOverall = (props) => {
  const {
    visible, setVisible, projNo,
  } = props;
  const [loading, setLoading] = useState(false);

  const [downtimeData, setDownTimeData] = useState([]);
  const [showGraph, setShowGraph] = useState(false);
  const [pipelineData, setPipelineData] = useState([]);
  const [tabValue, setTabValue] = useState('');

  const getData = async () => {
    setLoading(true);
    try {
      const res = await getDownTimeData(projNo, '', '', true, tabValue);
      if (res?.downtimeEntriesData) {
        const sortedData = res.downtimeEntriesData.sort((
          a, b
        ) => new Date(b.date) - new Date(a.date))
          .map((item) => {
            const dayData = item.downtimeData.find((
              element
            ) => element.shift === shift.day) || {};
            const nightData = item.downtimeData.find((
              element
            ) => element.shift === shift.night) || {};
            const rowData = {
              date: item.date,
              gps_downtime_day: dayData.gps_downtime || 0,
              gps_downtime_night: nightData.gps_downtime || 0,
              gps_downtime_overall: toNumber(dayData.gps_downtime)
                + toNumber(nightData.gps_downtime),
              crce_downtime_day: dayData.crce_downtime || 0,
              crce_downtime_night: nightData.crce_downtime || 0,
              crce_downtime_overall: toNumber(dayData.crce_downtime)
                + toNumber(nightData.crce_downtime),
              comments_night: nightData.downtime_comments || '-',
              comments_day: dayData.downtime_comments || '-',
            };

            return rowData;
          });
        setDownTimeData(sortedData);
      } else setDownTimeData([]);
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, tabValue]);

  const getTableColumns = async () => {
    try {
      const res = await getPipeline(projNo);
      if (res?.pipelineData && res?.pipelineData.length > 0) {
        const pipelineFilteredData = res?.pipelineData.filter((item) => item.is_deleted === 0);
        setPipelineData(pipelineFilteredData);
        setTabValue(pipelineFilteredData[0] && pipelineFilteredData[0]?.id ? pipelineFilteredData[0]?.id : '');
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  useEffect(() => () => {
    setTabValue('');
    setPipelineData([]);
  }, []);

  const onTabChange = (activeKey) => {
    setTabValue(activeKey);
  };

  useEffect(() => {
    if (projNo && visible) {
      getTableColumns();
    }
  }, [projNo, visible]);

  return (
    <>
      <Modal
        title={(
          <div>
            <div style={{ 'max-width': '70%' }}>DownTime</div>
            <div className="d-flex flex-column align-items-end">
              <Switch
                className="modal-switch"
                checkedChildren="Graphical View"
                unCheckedChildren="Tabular View"
                onChange={(checked) => {
                  if (checked) {
                    setShowGraph(checked);
                  } else {
                    setShowGraph(checked);
                  }
                }}
              />
            </div>
          </div>
        )}
        className="welds-modal"
        centered
        visible={visible}
        width={1009}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setVisible(false);
              setTabValue('');
              setPipelineData([]);
              setDownTimeData([]);
            }}
          >
            OK
          </Button>
        ]}
      >
        <div>
          <div className="flex-fill overflow-auto">
            {
              pipelineData && pipelineData.length > 0 && (
                <Tabs disabled={loading} activeKey={tabValue.toString()} onChange={onTabChange} className="custom-tab h-100">
                  {
                    pipelineData.map((item) => (
                      <TabPane tab={item.name} key={item.id} value={item.id} />
                    ))
                  }
                </Tabs>
              )
            }
          </div>
          { !showGraph ? (
            <Table
              dataSource={downtimeData}
              className="table-with-button-assets"
              loading={{
                indicator: <Spin />,
                spinning: loading
              }}
              scroll={{ x: true }}
              style={{ whiteSpace: 'nowrap' }}
              pagination={false}
            >
              <Column title="Date" dataIndex="date" key="date" />
              <ColumnGroup title="Customer Downtime Today (Minutes)" align="center">
                <Column title="Day" dataIndex="gps_downtime_day" key="gps_downtime_day" />
                <Column title="Night" dataIndex="gps_downtime_night" key="gps_downtime_night" />
                <Column title="Overall" dataIndex="gps_downtime_overall" key="gps_downtime_overall" />
              </ColumnGroup>
              <ColumnGroup title="CRCE Downtime Today (Minutes)" align="center">
                <Column title="Day" dataIndex="crce_downtime_day" key="crce_downtime_day" />
                <Column title="Night" dataIndex="crce_downtime_night" key="crce_downtime_night" />
                <Column title="Overall" dataIndex="crce_downtime_overall" key="crce_downtime_overall" />
              </ColumnGroup>
              <ColumnGroup title="Comments" align="center">
                <Column title="Day" dataIndex="comments_day" key="comments_day" />
                <Column title="Night" dataIndex="comments_night" key="comments_night" />
              </ColumnGroup>
            </Table>
          ) : (
            <Tabs className="custom-tab h-95">
              <TabPane tab="Customer Downtime Today (Minutes)" key={0}>
                <ModalOffshoreSummaryGraph Reportdata={downtimeData} coloumnType="gps_downtime" />
              </TabPane>
              <TabPane tab="CRCE Downtime Today (Minutes)" key={1}>
                <ModalOffshoreSummaryGraph Reportdata={downtimeData} coloumnType="crce_downtime" />
              </TabPane>
            </Tabs>
          )}
        </div>
      </Modal>
    </>
  );
};

export default DownTimeModalOverall;
