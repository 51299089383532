import {
  GET_PARTS_REPORTS,
  SET_PARTS_REPORTS,
  SET_PARTS_REPORT_FAILURE,
} from '../types';

const getPartsReports = (projectNo) => ({
  type: GET_PARTS_REPORTS,
  projectNo,
});

const setPartsReports = (payload) => ({
  type: SET_PARTS_REPORTS, ...payload,
});

const setPartsReportFailure = () => ({
  type: SET_PARTS_REPORT_FAILURE,
});

export {
  getPartsReports,
  setPartsReports,
  setPartsReportFailure,
};
