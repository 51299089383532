/* eslint-disable linebreak-style */
// auth
export const SIGNIN = '@@authentication/signin';
export const SIGNOUT = '@@authentication/signout';
export const SIGNINFAILURE = '@@authentication/signfailure';
export const SIGNINSUCCESS = '@@authentication/signinsuccess';

// quality report
export const GET_QUALITY_REPORTS = '@@quality/get-reports';
export const SET_QUALITY_REPORTS = '@@quality/set-reports';
export const SET_QUALITY_REPORT_FAILURE = '@@quality/set-report-failure';
export const SET_STATUS = '@@quality/set-status';
export const SUBMIT_STATUS = '@@quality/submit-status';

// prod log
export const GET_PROD_REPORTS = '@@prod/get-reports';
export const SET_PROD_REPORTS = '@@prod/set-reports';
export const SET_PROD_REPORT_FAILURE = '@@prod/set-report-failure';

// tech log
export const GET_TECH_REPORTS = '@@tech/get-reports';
export const SET_TECH_REPORTS = '@@tech/set-reports';
export const SET_TECH_REPORT_FAILURE = '@@tech/set-report-failure';

// coating prod log
export const GET_COATING_REPORTS = '@@coating/get-reports';
export const SET_COATING_REPORTS = '@@coating/set-reports';
export const SET_COATING_REPORT_FAILURE = '@@coating/set-report-failure';

// parts request
export const GET_PARTS_REPORTS = '@@parts/get-reports';
export const SET_PARTS_REPORTS = '@@parts/set-reports';
export const SET_PARTS_REPORT_FAILURE = '@@parts/set-report-failure';

// lem
export const GET_TIMESHEET = '@@timesheet/get-timesheet';
export const SET_TIMESHEET = '@@timesheet/set-timesheet';
export const SET_TIMESHEET_FAILURE = '@@timesheet/set-timesheet-failure';

// lem
export const GET_LEM_REPORTS = '@@lem/get-reports';
export const SET_LEM_REPORTS = '@@lem/set-reports';
export const SET_LEM_REPORT_FAILURE = '@@lem/set-report-failure';

// users
export const FETCH_USERS = '@@user/list-users';
export const SET_USERS = '@@user/set-users';
export const SET_USER_FAILURE = '@@user/set-user-failure';

// project list
export const FETCH_PROJECTS = '@@/list-projects';
export const FETCH_PROJECTS_LIST = '@@/projects-list';
export const SET_PROJECTS = '@@user/set-projects';
export const SET_PROJECT_FAILURE = '@@user/set-project-failure';
export const SET_PROJECTS_LIST = '@@user/set-projects-list';
export const SET_PROJECT_LIST_FAILURE = '@@user/set-project-list-failure';
export const SAVE_PROJECT = '@@/save-project';
export const UPDATE_PROJECT = '@@/update-project';

// asset
export const FETCH_ASSETS = '@@/list-assets';
export const SET_ASSETS = '@@user/set-assets';
export const SET_AWS_CONFIG_DATA_FAILURE = '@@user/set-asset-failure';

// aws config data
export const FETCH_AWS_CONFIG_DATA = '@@/list-aws-config-data';
export const SET_AWS_CONFIG_DATA = '@@user/set-aws-config-data';
export const SET_ASSET_FAILURE = '@@user/set-aws-config-data-failure';

// project asset
export const FETCH_PROJECT_ASSETS = '@@/list-assets';
export const SET_PROJECT_ASSETS = '@@user/set-assets';
export const SET_PROJECT_ASSET_FAILURE = '@@user/set-asset-failure';

// project inventory
export const FETCH_PROJECT_INVENTORY = '@@/list-inventory';
export const SET_PROJECT_INVENTORY = '@@user/set-inventory';
export const SET_PROJECT_INVENTORY_FAILURE = '@@user/set-inventory-failure';

// email congiguration
export const FETCH_EMAILS = '@@/list-emails';
export const SET_EMAILS = '@@user/set-emails';
export const SET_EMAIL_FAILURE = '@@user/set-email-failure';

// analysis
export const FETCH_ANALYSIS = '@@/list-analysis';
export const SET_ANALYSIS = '@@user/set-analysis';
export const SET_ANALYSIS_FAILURE = '@@user/set-analysis-failure';

// damage report
export const GET_DAMAGE_REPORTS = '@@damage/get-reports';
export const SET_DAMAGE_REPORTS = '@@damage/set-reports';
export const SET_DAMAGE_REPORTS_FAILURE = '@@damage/set-report-failure';

// wire usage report
export const GET_WIRE_USAGE_REPORTS = '@@wireUsage/get-reports';
export const SET_WIRE_USAGE_REPORTS = '@@wireUsage/set-reports';
export const SET_WIRE_USAGE_REPORTS_FAILURE = '@@wireUsage/set-report-failure';

// project members
export const FETCH_TEAM_MEMBERS = '@@project/get-team-members';
export const SET_TEAM_MEMBERS = '@@project/set-team-members';
export const SET_TEAM_MEMBERS_FAILURE = '@@project/set-team-members-failure';

// notifications
export const GET_NOTIFICATIONS = '@@project/get-notifications';
export const SET_NOTIFICATIONS = '@@project/set-notifications';
export const SET_NOTIFICATIONS_FAILURE = '@@project/set-notifications-failure';

// advisory
export const GET_MACHINE_TYPE = '@@advisory/get-machine-type';
export const SET_MACHINE_TYPE = '@@advisory/set-machine-type';
export const SET_MACHINE_SUB_TYPE = '@@advisory/set-machine-sub-type';
export const SET_MACHINE_TYPE_FAILURE = '@@advisory/set-machine-type-failure';
export const GET_ADVISORY = '@@advisory/get-list';
export const SET_ADVISORY = '@@advisory/set-lisr';
export const SET_ADVISORY_FAILURE = '@@advisory/set-list-failure';
export const GET_ADVISORY_APPROVAL = '@@advisory/get-approval-list';
export const SET_ADVISORY_APPROVAL = '@@advisory/set-approval-list';
export const SET_ADVISORY_APPROVAL_FAILURE = '@@advisory/set-approval-list-failure';
