/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-template */
/* eslint-disable react/prop-types */
/* eslint-disable no-lonely-if */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable arrow-body-style */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  func, arrayOf, objectOf, oneOfType, string, number, array, bool
} from 'prop-types';
import {
  Typography, Row, Col, Table, Spin
} from 'antd';
import moment from 'moment';
import { getTechReports } from '../state/action/techLogAction';
import TechModal from './TechModal';
import TechCrewModal from './TechCrewModal';
import * as constants from '../utils/constants';
import { calcReportingPeriodMonth } from '../services/calendar';

const { Title, Text } = Typography;
const columns1 = [
  {
    title: 'Crew Names',
    dataIndex: 'crew',
    key: 'crew',
    width: '26%',

  },
  {
    title: 'Onsite Days',
    dataIndex: 'onsite',
    key: 'onsite',
    align: 'center'
  },
  {
    title: 'Travel Days',
    dataIndex: 'travel',
    key: 'travel',
    align: 'center'
  },
  {
    title: 'Rain Out Days',
    dataIndex: 'rainout',
    key: 'rainout',
    align: 'center'
  },
  {
    title: 'Total Working Days',
    dataIndex: 'working',
    key: 'working',
    align: 'center'
  },

];

const columns = [
  {
    title: 'Reporting Period',
    dataIndex: 'formatReportingPeriod',
    key: 'formatReportingPeriod',

  },
  {
    title: 'Report',
    key: 'pdf',
    dataIndex: 'pdf',
    render: (record) => {
      if (record) {
        return (
          <div>
            {' '}
            <a
              className="export-link"
              title="Download pdf report"
              target="_blank"
              rel="noopener noreferrer"
              href={record}
              // eslint-disable-next-line react/jsx-closing-bracket-location
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <span className="icon-16 mr-2">
                <svg>
                  <use xlinkHref="#export" />
                </svg>
              </span>
            </a>
          </div>
        );
        // eslint-disable-next-line brace-style
      }
      // eslint-disable-next-line no-else-return
      else {
        return <div>-</div>;
      }
    },
    align: 'center'
  },

];

const Technician = (props) => {
  const {
    onGetTechReports, techData,
    projNo, reload, setCurTechRep, setPrevTechRep, showTechLoader,
    refresh, projectType
  } = props;
  const [currentTechReport, setCurrentTechReport] = useState([]);
  const [previousTechReports, setPreviousTechReports] = useState([]);
  const [currentCrews, setCurrentCrews] = useState([]);
  const [techmodalvisible, settechmodalvisible] = useState(false);
  const [techCrewModalVisible, setTechCrewModalVisible] = useState(false);
  const [selectedCrew, setSelectedCrew] = useState('');
  const [prevReport, setPrevReport] = useState({});
  const [techLogDetails, setTechLogDetails] = useState([]);

  useEffect(() => {
    if (projNo) {
      onGetTechReports({ projectNo: projNo });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo]);

  useEffect(() => {
    if (reload) {
      if (projNo) {
        onGetTechReports({ projectNo: projNo });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    const techLogArrayObj = [];
    if (techData !== []) {
      // eslint-disable-next-line array-callback-return
      techData.map((item) => {
        techLogArrayObj.push(item);
      });
      setTechLogDetails(techLogArrayObj);
    }
  }, [techData]);

  const formatCurrentDate = (currentDate) => {
    let dd = currentDate.getDate();
    let mm = currentDate.getMonth() + 1;
    const yyyy = currentDate.getFullYear();
    if (dd < 10) {
      dd = `0${dd}`;
    }
    if (mm < 10) {
      mm = `0${mm}`;
    }
    return `${yyyy}-${mm}-${dd}`;
  };

  const setTechnicianReportForEmea = (emeaTechData) => {
    const reportsByPeriod = emeaTechData.reduce((acc, report) => {
      acc[report.period] = report;
      return acc;
    }, {});

    const allEntries = [];

    // Collect all entries from all reports
    emeaTechData.forEach((report) => {
      report.entries.forEach((entry) => {
        allEntries.push({ entry, techs: report.techs });
      });
      report.entries = []; // Clear the entries from the original report
      report.techs = []; // Clear the techs from the original report
    });

    // Distribute the entries to the correct reports and append missing techs
    allEntries.forEach(({ entry, techs }) => {
      const entryDate = new Date(entry.date);
      const year = entryDate.getFullYear();
      const month = String(entryDate.getMonth() + 1).padStart(2, '0');
      const entryPeriod = `${year}-${month}`;

      if (reportsByPeriod[entryPeriod]) {
        reportsByPeriod[entryPeriod].entries.push(entry);

        // Append techs if missing
        techs.forEach((tech) => {
          if (!reportsByPeriod[entryPeriod].techs.some((t) => t.member_id === tech.member_id)) {
            reportsByPeriod[entryPeriod].techs.push(tech);
          }
        });
      }
    });
    return emeaTechData;
  };

  const setTechnicianReport = () => {
    setCurrentTechReport([]);
    setPreviousTechReports([]);

    if (projNo !== '') {
      const project = Object.keys(techLogDetails)
        .map((items) => techLogDetails[items])
        .filter((item) => item.projectNo.toLowerCase() === projNo.toLowerCase())
        .map((item) => {
          if (projectType === 'Onshore_EMEA') {
            const reportingPeriod = calcReportingPeriodMonth(item.period);
            item.reportingPeriod = reportingPeriod;
            const splitDate = reportingPeriod.split(' - ');
            const startDate = new Date(splitDate[0]);
            const endDate = new Date(splitDate[1]);
            item.formatReportingPeriod = `${moment(startDate).format(constants.DATE)} to ${moment(endDate).format(constants.DATE)}`;
          }
          item.reportingPeriod = item.reportingPeriod.replace(' - ', ' to ');
          item.formatReportingPeriod = item.formatReportingPeriod.replaceAll('/', '-');
          return item;
        });

      if (project.length > 0) {
        const current = [];
        const today = formatCurrentDate(new Date());
        const curDate = new Date(today);
        const prevRepo = {};

        project.forEach((item) => {
          const splitDate = item.reportingPeriod.split(' to ');
          const startDate = new Date(splitDate[0]);
          const endDate = new Date(splitDate[1]);

          if (curDate >= startDate && curDate <= endDate) {
            const currentLastUpdateDateTime = current.length > 0 ? current[0]?.lastUpdatedAt : new Date(0);
            const itemLastUpdateDateTime = new Date(item.lastUpdatedAt);

            if (current.length === 0 || currentLastUpdateDateTime < itemLastUpdateDateTime) {
              current[0] = item;
            }

            return;
          }

          const prevRepoKey = item.reportingPeriod;

          if (!(prevRepoKey in prevRepo) || prevRepo[prevRepoKey].lastUpdatedAt < item.lastUpdatedAt) {
            prevRepo[prevRepoKey] = item;
          }
        });

        if (current.length > 0) {
          setCurrentTechReport(current[0]);
          setCurTechRep(current[0]);
        }
        if (projectType === 'Onshore_EMEA') {
          const emeaData = setTechnicianReportForEmea(Object.values(prevRepo));
          setPreviousTechReports(emeaData);
          setPrevTechRep(emeaData);
        } else {
          setPreviousTechReports(Object.values(prevRepo));
          setPrevTechRep(Object.values(prevRepo));
        }
      }
    }
  };

  useEffect(() => {
    refresh(false);
    setTechnicianReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo, techLogDetails]);

  useEffect(() => {
    const crewData = [];
    const crews = currentTechReport.techs;
    const entrydata = [];
    const entry = [];
    const { entries } = currentTechReport;
    Object.keys(entries).map((value, i) => {
      if (new Date(entries[value]?.date) > new Date()) {
        entries.splice(i, 1);
      }
    });

    // if (crews !== undefined) {
    //   newCrew = crews.filter((param, idx, arr) => idx === arr.findIndex((t) => (
    //     t.name === param.name)));
    // }

    if (crews && crews.length > 0) {
      crews.map((crew, index) => {
        crew.crewId = index;
      });
      crews.map((crew, index) => {
        const crewname = crew.name;
        const { member_id } = crew;

        let techonsite = 0;
        let techtravel = 0;
        let techrainout = 0;

        for (let i = 0; entries && i < entries.length; i += 1) {
          const entrydatas = entries[i];

          Object.values(entrydatas).map((val, idx) => {
            if (typeof (val) === 'object') {
              if (!entry.includes(val.techName)) {
                entry.push(val.techName);
                val.id = idx;
                entrydata.push(val);
              } else {
                if (!entrydata.includes(val)) {
                  val.id = idx;
                  entrydata.push(val);
                }
              }
            }
          });
        }
        entrydata.map((d, idx) => {
          if (d && typeof (d) === 'object') {
            if (d.techName === crewname && d.member_id === member_id) {
              if (d.status === 'Travel') techtravel++;
              if (d.status === 'Onsite') techonsite++;
              if (d.status === 'Rain Out') techrainout++;
            }
          }
        });
        const crewdetails = {
          key: index,
          crew: crew.name ? crew.name : '(No Name)',
          onsite: techonsite,
          travel: techtravel,
          rainout: techrainout,
          working: techtravel + techonsite + techrainout
        };
        crewData.push(crewdetails);
      });
    }
    setCurrentCrews(crewData);
  }, [currentTechReport]);

  const hideModal = () => {
    setTechCrewModalVisible(false);
  };

  const showModal = () => {
    setTechCrewModalVisible(true);
  };

  const modal = () => (
    <TechModal
      visible={techmodalvisible}
      currentReport={Object.keys(prevReport).length > 0 ? prevReport : currentTechReport}
      setVisible={(value) => { settechmodalvisible(value); setPrevReport([]); }}
      projectType={projectType}
    />
  );

  if (showTechLoader) {
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        <Spin size="large" tip="Loading data..." />
      </div>
    );
  }

  return (
    <div className="d-flex h-100">
      <div className="flex-fill custom-fill overflow-auto" style={{ maxWidth: '65%' }}>
        <div className="d-flex flex-column h-100">
          <div className="flex-auto">
            {currentTechReport && (
            <div className="d-flex mb-3 align-items-center tab-content-title">
              <Title className="mb-0" level={4}>
                Current Reporting Period
              </Title>
              <Text className="ps-3">
                {currentTechReport && currentTechReport.formatReportingPeriod}
              </Text>
            </div>
            )}

            {currentTechReport && <div className="custom-line" />}
          </div>
          <div className="flex-fill border-right overflow-auto">
            <div className="d-flex flex-column h-100">
              <div className="flex-auto">
                {currentTechReport && (
                <>
                  <Row gutter={[16]} className="pt-3 m-0">
                    <Col md={12} lg={8} xl={6} className="ps-0">
                      <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center position-relative">
                        <Text className="mb-1 theme-color">
                          Total Onsite Days
                          {modal()}
                        </Text>
                        <Title
                          onClick={() => settechmodalvisible(true)}
                          className="my-0 theme-color fw-bold"
                          level={2}
                        >
                          {currentTechReport && currentTechReport.totalDaysOnsite}
                        </Title>
                      </div>
                    </Col>
                    <Col md={12} lg={8} xl={6}>
                      <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center">
                        <Text className="mb-1 theme-color">Total Travel Days</Text>
                        <Title
                          className="my-0 theme-color fw-bold"
                          level={2}
                        >
                          {currentTechReport && currentTechReport.totalTravelDays}
                        </Title>
                      </div>
                    </Col>
                    <Col md={12} lg={8} xl={6}>
                      <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center">
                        <Text className="mb-1 theme-color">Total Rain Out Days</Text>
                        <Title
                          className="my-0 theme-color fw-bold"
                          level={2}
                        >
                          {currentTechReport && currentTechReport.totalRainOutDays}
                        </Title>
                      </div>
                    </Col>
                    <Col md={12} lg={8} xl={6}>
                      <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center">
                        <Text className="mb-1 theme-color">
                          Total Work Days
                        </Text>
                        <Title
                          className="my-0 theme-color fw-bold"
                          level={2}
                        >
                          {currentTechReport && currentTechReport.totalWorkDays}
                        </Title>
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 small-text">

                    {currentTechReport && currentTechReport.lastUpdatedAt ? 'Last updated: ' : '' }
                    {' '}
                    {currentTechReport && currentTechReport.lastUpdatedAt
                          && moment(currentTechReport.lastUpdatedAt).format(
                            constants.DATETIME
                          )}
                  </p>
                </>
                )}
              </div>
              <div className={currentCrews.length > 0 ? 'flex-fill overflow-y-hidden overflow-x-auto has-pointer' : 'flex-fill overflow-y-hidden overflow-x-auto'}>
                <Table
                  className="table-head-yellow pe-3"
                  scroll={{ x: currentCrews.length > 0 ? 300 : 0, y: '100%' }}
                  pagination={false}
                  columns={columns1}
                  dataSource={currentCrews}
                  onRow={(currentTechReports) => ({
                    onClick: () => {
                      setTechCrewModalVisible(true);
                      setSelectedCrew(currentTechReports.crew);
                    },
                  })}
                />
              </div>
              <TechCrewModal
                modalVisible={techCrewModalVisible}
                currentReport={currentTechReport}
                hideModal={hideModal}
                showModal={showModal}
                crew={selectedCrew}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-fill custom-fill overflow-auto" style={{ maxWidth: '35%' }}>
        <div className="d-flex flex-column h-100">
          <div className="flex-auto w-100 tab-content-title">
            <Title className="mb-3 ps-4" level={4}>Previous Reports</Title>
            <div className="custom-line" />
          </div>
          <div className="flex-fill overflow-auto ps-4 overflow-x-auto">
            <div className={previousTechReports.length > 0 ? 'h-100 overflow-y-hidden overflow-x-auto has-pointer' : 'h-100 overflow-y-hidden overflow-x-auto'}>
              <Table
                scroll={{ x: previousTechReports.length > 0 ? 300 : 0, y: '100%' }}
                pagination={false}
                rowKey={(record) => record.reportId}
                columns={columns}
                dataSource={previousTechReports}
                onRow={(record) => ({
                  onClick: (e) => {
                    e.stopPropagation();
                    settechmodalvisible(true);
                    setPrevReport(record);
                    modal();
                  },
                })}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

Technician.propTypes = {
  onGetTechReports: func.isRequired,
  techData: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
};

const mapStateToProps = ({
  techLogReports
}) => {
  const techData = get(techLogReports, 'data', []);
  const showTechLoader = get(techLogReports, 'isLoading', false);
  return {
    techData,
    showTechLoader,
  };
};

const mapDispatchToProps = {
  onGetTechReports: getTechReports
};

export default connect(mapStateToProps, mapDispatchToProps)(Technician);
