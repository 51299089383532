/* eslint-disable no-useless-escape */
// REPORT TYPES
export const REPORT_QUALITY = 'Quality';
export const REPORT_PRODUCTION_LOG = 'ProductionLog';
export const REPORT_TECH_LOG = 'TechLog';
export const REPORT_COATING_LOG = 'CoatingProductionLog';
export const REPORT_PARTS_REQUEST = 'PartsRequest';
export const REPORT_DAMAGE = 'Damage';
// DATE FORMATS
export const ADV_DATE = 'YYYY-MM-DD';
export const ADV_DATE_TABLE = 'DD-MMM-YY';
export const DATE = 'DD/MMM/YY';
export const DATETIMESEC = 'DD/MMM/YY, hh:mm:ss A';
export const DATETIME = 'DD-MMM-YY, hh:mm A';
export const DATETIMEFULL = 'YYYY-MM-DD hh:mm:ss';
// CREATE NEW PASSWORD
export const CHALLENGE_NAME = 'NEW_PASSWORD_REQUIRED';
// Number Pattern
export const COUNT_PATTERN = new RegExp(/^[0-9]*$/);
// NUMBER AND DECIMAL PATTERN
export const NUMBER_DECIMAL_PATTERN = new RegExp(/^\d*\.?\d{1,2}$/);
// Email Pattern
export const EMAIL_PATTERN = new RegExp(/^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/gi);
// Project Number Pattern
export const PROJECT_NUMBER_PATTERN = new RegExp(/^\S*$/);
// Password validation
export const PASSWORD_VALIDATION = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.[\]{}()?!"@#%&/\\,><'':;|_~`+=])[A-Za-z\d\^$*.[\]{}()?!"@#%&/\\,><'':;|_~`+=]{8,}$/;

export const shift = {
  day: 0,
  night: 1
};

export const BusinessUnit = {
  NAL: 'NAL',
  EMEA: 'EMEA',
  NAO: 'NAO'
};

export const advStatus = {
  open: 'open',
  closed: 'closed'
};

export const advApprovalStatus = {
  pending: 'pending',
  approved: 'approved',
  rejected: 'rejected'
};

export const advApprovalMode = {
  create: 'create',
  update: 'update'
};

export const advReqStatus = {
  approve: 1,
  reject: 0
};

export const onshoreEmeaAssetWebElements = [
  '02-01-01',
  '02-01-01-01',
  '02-01-01-02',
  '02-01-01-03',
  '02-01-06',
  '02-01-06-01',
  '02-01-06-02'
];
export const onshoreEmeaInventoryWebElements = [
  '02-01-04',
  '02-03-01',
  '02-03-02',

];
export const offshoreshoreAssetWebElements = [
  '02-01-01',
  '02-01-01-02',
  '02-01-02',
  '02-01-02-02'
];

export const offshoreInventoryWebElements = [
  '02-03-04',
  '02-03-02',
];

export const projectType = {
  onshoreEmea: 'Onshore_EMEA',
  onshore: 'onshore',
  offshore: 'offshore'
};

export const projectTypeMap = new Map([
  [projectType.onshoreEmea, 'Onshore EMEA'],
  [projectType.onshore, 'Onshore'],
  [projectType.offshore, 'Offshore']
]);
