import { notification } from 'antd';
import fetch from './fetch';
import { BASE_URL } from '../config';

export const getDocument = async (projectNo, searchVal = '') => {
  try {
    const projNo = projectNo || '';
    const res = await fetch(`${BASE_URL}/projectsData/document?projectNo=${projNo}&searchVal=${searchVal}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    if (res.status === 200) {
      const reportData = await res.json();
      return reportData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getDocumentTag = async () => {
  try {
    const res = await fetch(`${BASE_URL}/projectsData/documentTag`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    if (res.status === 200) {
      const reportData = await res.json();
      return reportData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getSignedUrl = async (body) => {
  try {
    console.log('body', body);
    const res = await fetch(`${BASE_URL}/projectsData/document/getSignedUrl`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
      },
    });
    const response = await res.json();
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return response;
    }
    return response;
  } catch (error) {
    return null;
  }
};

export const addDocument = async (body) => {
  try {
    const res = await fetch(`${BASE_URL}/projectsData/document`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return { err: true };
    } if (res.status === 200) {
      return { err: false };
    }
    const response = await res.json();
    return { err: true, message: response.message };
  } catch (error) {
    return { err: true };
  }
};

export const updateDocumentHistoryTag = async (body) => {
  try {
    const res = await fetch(`${BASE_URL}/projectsData/documentHistory`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return { err: true };
    } if (res.status === 200) {
      return { err: false };
    }
    const response = await res.json();
    return { err: true, message: response.message };
  } catch (error) {
    return { err: true };
  }
};

export const addDocumentTag = async (body) => {
  try {
    const res = await fetch(`${BASE_URL}/projectsData/documentTag`, {
      method: 'POST',
      body: JSON.stringify({ tag: body }),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return { err: true };
    } if (res.status === 200) {
      return { err: false };
    }
    const response = await res.json();
    return { err: true, message: response.message };
  } catch (error) {
    return { err: true };
  }
};

export const deleteDocument = async (id) => {
  try {
    const res = await fetch(`${BASE_URL}/projectsData/document?document_id=${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};
