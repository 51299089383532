/* eslint-disable linebreak-style */
import {
  GET_TIMESHEET,
  SET_TIMESHEET,
  SET_TIMESHEET_FAILURE
} from '../types';

const getTimesheet = (startDate, endDate, projectNo, projectType) => ({
  type: GET_TIMESHEET,
  startDate,
  endDate,
  projectNo,
  projectType
});

const setTimesheet = (payload) => ({
  type: SET_TIMESHEET, ...payload,
});

const setTimesheetFailure = () => ({
  type: SET_TIMESHEET_FAILURE,
});

export {
  getTimesheet,
  setTimesheet,
  setTimesheetFailure
};
