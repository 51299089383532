import {
  FETCH_USERS,
  SET_USERS,
  SET_USER_FAILURE,
} from '../types';

const fetchUsers = () => ({
  type: FETCH_USERS,
});

const setUsers = (payload) => ({
  type: SET_USERS, ...payload,
});

const setUserFailure = () => ({
  type: SET_USER_FAILURE,
});

export {
  fetchUsers,
  setUsers,
  setUserFailure,
};
