import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { notification, Table, Spin } from 'antd';
import get from 'lodash/get';

import {
  func, arrayOf, bool, objectOf, oneOfType, string, number,
} from 'prop-types';

import { BASE_URL } from '../../config';
import { fetchUsers } from '../../state/action/userAction';
import fetch from '../../services/fetch';

import 'antd/dist/antd.dark.css';

const { Column } = Table;

const UsersActivity = (props) => {
  const { data, onFetchUsers, showLoader } = props;

  useEffect(() => {
    onFetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [nestedData, setNestedData] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const expandedRowRender = (record) => {
    const subTableData = nestedData[record.id];
    return (
      <Table
        loading={isLoading[record.id] && !subTableData}
        dataSource={nestedData[record.id]}
        pagination={false}
      >
        <Column
          title="Report Type"
          dataIndex="type"
          key="type"
        />
        <Column
          title="Report Link"
          dataIndex="pdf_url"
          key="pdf_url"
          render={(rowRecord) => {
            if (rowRecord) {
              return (
                <div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    title="link"
                    href={rowRecord}
                  >
                    Link
                  </a>
                </div>
              );
            }
            return (
              <div>-</div>
            );
          }}
        />
        <Column
          title="Report Created Date"
          dataIndex="createdAt"
          key="createdAt"
        />
        <Column
          title="Report Updated Date"
          dataIndex="updatedAt"
          key="updatedAt"
        />
      </Table>
    );
  };

  const columns = [
    { title: 'User Name', dataIndex: 'userName', key: 'userName' },
    { title: 'User Email', dataIndex: 'userEmail', key: 'userEmail' },
  ];

  const handleExpand = async (expanded, record) => {
    setIsLoading({
      [record.id]: true,
    });

    try {
      const res = await fetch(`${BASE_URL}/users/${record.userEmail}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });
      if (res.status === 403) {
        notification.error({
          message: 'Unauthorized',
          description: 'User is not authorized to perform this action',
        });
      }
      const response = await res.json();
      const responseCopy = response.map((report) => ({
        ...report,
        key: report.id,
      }));

      setNestedData((state) => ({
        ...state,
        [record.key]: responseCopy,
      }));

      setIsLoading({
        [record.id]: false,
      });
    } catch (error) {
      notification.error({
        message: 'Failed To Fetch User Report Details',
        description: `${error}`,
      });
    }
  };

  if (showLoader) {
    return (
      <div className="loader">
        <Spin size="large" tip="Loading data..." />
      </div>
    );
  }

  return (
    <Table
      className="components-table-demo-nested"
      pagination={{ hideOnSinglePage: true }}
      columns={columns}
      bordered
      expandedRowRender={expandedRowRender}
      onExpand={handleExpand}
      dataSource={data}
    />
  );
};

UsersActivity.propTypes = {
  onFetchUsers: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number]))).isRequired,
  showLoader: bool.isRequired,
};

const mapStateToProps = ({ userRequests }) => {
  const data = get(userRequests, 'data', []);
  const showLoader = get(userRequests, 'isLoading', false);
  return {
    data,
    showLoader,
  };
};

const mapDispatchToProps = {
  onFetchUsers: fetchUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersActivity);
