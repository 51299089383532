/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  func, arrayOf, objectOf, oneOfType, string, number, array
} from 'prop-types';
import { Spin, notification } from 'antd';
import moment from 'moment';
import ProjectListHeader from '../../layout/projectListHeader';
import Notifications from '../../Components/Notifications';
import { fetchProject } from '../../state/action/projectAction';
import { authenticateCurrentUser } from '../../services/auth';
import { fetchProjectList } from '../../state/action/projectListAction';
import 'antd/dist/antd.dark.css';
import { updateProjectDetail } from '../../services/projectList';
import ComingSoon from '../../Components/Comingsoon';

const NotificationsPage = (props) => {
  const {
    onGetProjects, projectData, showProdLoader, showProjectLoader,
    onGetProjectsList, showProjectListLoader, projectListData,
  } = props;
  const [projNo, setProjNo] = useState('');
  const [projectList, setProjectList] = useState([]);
  const [completedWeldsCount,setCompletedWeldCount] = useState(0);
  const [reload, setReload] = useState(false);
  const [proj, setProj] = useState([]);
  const [index,setIndex] = useState(0);
  const [notifications, setNotification] = useState([]);
  const [group, setGroup] = useState(false);
  const [disableSelection, setDisableSelection] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedProjectDetail, setSelectedProjectDetail] = useState([]);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    authenticateCurrentUser()
      .then((data) => {
        if (data.signInUserSession.accessToken.payload["cognito:groups"] && data.signInUserSession.accessToken.payload["cognito:groups"].includes('crc-admin')) {
          onGetProjectsList();
          setGroup(true);
        } else {
          notification.info({
            message: 'User created successfully. You are not added to any group. Please contact Vinay.Baburao@sbdinc.com.'
          });
          setGroup(false);
        }
      })
      .catch((error) => {
        setGroup(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let projectObj = [];
    setIsUpdate(false);
    if (projectListData) {
      projectListData.map((item) => {
        projectObj.push(item);
      });
      setProjectList(projectObj);
    }
  },[projectListData]);

  useEffect(() => {
    localStorage.setItem('searchVal', '');
  },[]);

  const reloadNewData = () => {
    if (group) {
      setIsUpdate(true);
      onGetProjectsList();
    }
  };

  const getReportListByProject = (projectNo) => {
    setProjNo(projectNo);
  };

  const setCompletedWeldsCount = (totalCompletedWelds) => {
    setCompletedWeldCount(totalCompletedWelds);
  };

  if (showProjectListLoader && isUpdate) {
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        <Spin size="large" tip="Loading data..." />
      </div>
    );
  }

  const onUpdate = async (startDt, endDt, plannedWelds, projectNo, projectStatus) => {
    setIsUpdate(false);
    const value = {
      project_number: projectNo,
      start_date: startDt ? moment(startDt).format('YYYY-MM-DD hh:mm:ss') : null,
      end_date: endDt ? moment(endDt).format('YYYY-MM-DD hh:mm:ss') : null,
      planned_welds: plannedWelds,
      project_status: projectStatus
    };
    const res = await updateProjectDetail(value);
    if (res) {
      notification.success({
        message: 'Project updated Successfully.',
      });
      setDisableSelection(false); // to be added in notification menu
      onGetProjectsList();
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  return (

    <div className="h-100 d-flex flex-column">
      <div className="flex-auto">
        <ProjectListHeader
          projectList={projectList}
          getReportListByProject={getReportListByProject}
          completedWeldsCount={completedWeldsCount}
          reloadData={reloadNewData}
          reload={reload}
          refresh={(isRefresh) => setReload(isRefresh)}
          setProject={(value) => setProj(value)}
          project={proj}
          setIndex={(value) => setIndex(value)}
          index={index}
          group={group}
          onUpdate={onUpdate}
          disableSelection={disableSelection}
          setDisableSelection={(value) => setDisableSelection(value)}
          setIsUpdate={(value) => setIsUpdate(value)}
          setSelectedProjectDetail={(value) => setSelectedProjectDetail(value)}
          dates={dates}
          setDates={(value) => setDates(value)}
          hideComponents
        />
      </div>
      <div className="flex-fill overflow-auto">
        {
          projNo ? (
            <Notifications
              projNo={projNo}
              reload={reload}
              projectList={projectList}
              refresh={(isRefresh) => setReload(isRefresh)}
              setNotification={(value) => setNotification(value)}
              notifications={notifications}
            />
          )
            : <ComingSoon title="Select a project to view report details" />
        }
      </div>
    </div>
  );
};

NotificationsPage.propTypes = {
  onGetProjects: func.isRequired,
  projectData: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  onGetProjectsList: func.isRequired,
  projectListData: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
};

const mapStateToProps = ({
  prodLogReports, projectRequests,projectListRequests
}) => {
  const projectData = get(projectRequests, 'projectData', []);
  const projectListData = get(projectListRequests, 'projectListData', []);
  const showProdLoader = get(prodLogReports, 'isLoading', false);
  const showProjectLoader = get(projectRequests, 'isProjectLoading', false);
  const showProjectListLoader = get(projectListRequests, 'isProjectListLoading', false);
  return {
    projectData,
    projectListData,
    showProdLoader,
    showProjectLoader,
    showProjectListLoader,
  };
};

const mapDispatchToProps = {
  onGetProjects: fetchProject,
  onGetProjectsList: fetchProjectList
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPage);
