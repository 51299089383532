import { notification } from 'antd';
import fetch from './fetch';
import { BASE_URL } from '../config';

export const getSafetyData = async (projectNo, date, shift, overall) => {
  try {
    let url = `project/summary/get-safety-details?project_id=${projectNo}&date=${date}&shift=${shift}`;
    if (overall) url = url.concat(`&overall=${overall}`);
    const res = await fetch(`${BASE_URL}/${url}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    if (res.status === 200) {
      const safetyData = await res.json();
      return safetyData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getProductivityData = async (projectNo, date, state, shift) => {
  try {
    const res = await fetch(`${BASE_URL}/project/summary/get-productivity-entry-details?project_id=${projectNo}&date=${date}&state=${state}&shift=${shift}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    if (res.status === 200) {
      const safetyData = await res.json();
      return safetyData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getDownTimeData = async (projectNo, date, shift, overall, projectPipelinesId) => {
  try {
    let url = `project/summary/get-downtime-details?project_id=${projectNo}&date=${date}&shift=${shift}&project_pipelines_id=${projectPipelinesId}`;
    if (overall) url = url.concat(`&overall=${overall}`);

    const res = await fetch(`${BASE_URL}/${url}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    if (res.status === 200) {
      const safetyData = await res.json();
      return safetyData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getInventoryData = async (projectNo, date, shift, projectPipelinesId) => {
  try {
    const url = `projectsData/offshore/getInventoryAssets?project_id=${projectNo}&date=${date}&shift=${shift}&project_pipelines_id=${projectPipelinesId}`;

    const res = await fetch(`${BASE_URL}/${url}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    if (res.status === 200) {
      const safetyData = await res.json();
      return safetyData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getSummaryData = async (projectNo, date, shift) => {
  try {
    const res = await fetch(`${BASE_URL}/project/summary/get-summary-details?project_id=${projectNo}&date=${date}&shift=${shift}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    if (res.status === 200) {
      const safetyData = await res.json();
      return safetyData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const addPipelineIssue = async (projectNo, date) => {
  try {
    const res = await fetch(`${BASE_URL}/project/summary/add-pipeline-issue`, {
      method: 'POST',
      body: JSON.stringify({ project_id: projectNo, isNew: true, created_date: date }),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getProjectReports = async (projectNo) => {
  try {
    const res = await fetch(`${BASE_URL}/project/dpr/get-reports?project_id=${projectNo}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    if (res.status === 200) {
      const reportData = await res.json();
      return reportData;
    }
    return null;
  } catch (error) {
    return null;
  }
};
