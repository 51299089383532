/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import {
  func, arrayOf, objectOf, oneOfType, string, number,
  array, bool, notification
} from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  Row, Empty, Spin, Typography
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useHistory, useLocation } from 'react-router-dom';
import { getMachineType } from '../state/action/advisoryAction';
import MachineTypeModal from './MachineTypeModal';
import { updateMachinetype } from '../services/advisory';
import TypeCardTitle from './TypeCardTitle';

const { Title } = Typography;
function MachineTypeCard(props) {
  const {
    onGetMachineType, data, isModalVisible, setIsModalVisible, goToSubType, showLoader
  } = props;
  const [isLoading, setIsLoading] = useState();
  const [currentEditId, setCurrentEditId] = useState('');
  const [isEditLoading, setIsEditLoading] = useState(false);

  const [form] = useForm();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabValue = queryParams.get('tabValue');

  useEffect(() => {
    onGetMachineType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = async (value) => {
    setIsLoading(true);
    const res = await updateMachinetype(value);
    if (!res.err) {
      onGetMachineType();
      form.resetFields();
      setIsModalVisible(false);
    } else {
      notification.error({
        message: res.message || 'Something went wrong.',
      });
    }
    setIsLoading(false);
  };

  const typeClick = (item) => {
    history.replace({
      pathname: window.location.pathname,
      search: `?tabValue=${tabValue}&type=${item.name}`
    });
    goToSubType(item.id);
  };
  return (
    showLoader
      ? (
        <div className="loader">
          <Spin spinning={showLoader} />
        </div>
      ) : (
        <div className="type-card">
          <Spin spinning={showLoader}>
            <div className="flex-auto d-flex pb-2 align-items-center justify-content-center">
              {/* <div className="breadcrumb">Machine Type</div> */}
              <Title className="mb-3 ps-4 breadcrumb" level={4}>
                Machine Type
              </Title>
            </div>
            <Row gutter={[16, 16]} className="pt-3">
              {
          data.length > 0
            ? data.map((item) => (
              <TypeCardTitle
                id={item.id}
                name={item.name}
                onClick={() => typeClick(item)}
                machineTypes={data}
                onGetMachineType={onGetMachineType}
                key={item.id}
                currentEditId={currentEditId}
                setCurrentEditId={setCurrentEditId}
                isLoading={isEditLoading}
                setIsLoading={setIsEditLoading}
              />
            ))
            : <Empty />
              }
            </Row>
          </Spin>
          <MachineTypeModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            onSave={onSave}
            form={form}
            machineTypes={data}
            isLoading={isLoading}
          />
        </div>
      )
  );
}

MachineTypeCard.propTypes = {
  onGetMachineType: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showLoader: bool.isRequired,
};

const mapStateToProps = ({ advisoryData }) => {
  const data = get(advisoryData, 'machineTypes', []);
  const showLoader = get(advisoryData, 'isLoading', false);
  const total = get(advisoryData, 'total', 0);
  return {
    data,
    showLoader,
    total
  };
};

const mapDispatchToProps = {
  onGetMachineType: getMachineType
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineTypeCard);
