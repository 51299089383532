/* eslint-disable  */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import {
  Modal, Select, Button, notification, Space, Row, Col, Input, Form, Divider, Typography, Upload,
} from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {
  UploadOutlined,
} from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';

import {getDocument, getSignedUrl, addDocumentTag} from '../services/document'

const uuid = require("uuid");

const { Option } = Select;

const maxSize = 100;

const AddDocumentModal = (props) => {
  const {
    addDocumentModal, setAddDocumentModal, documentTagData, loading, setLoading,
    onSave, documentForm, recordData, isEdit, onUpdate, getDocumentTagData,setIsEdit,
    fileUloaded, setIsFileUploaded
  } = props;

  const [newDocumentTag, setNewDocumentTag] = useState('');
  
  const handleDocument = async () => {
    try {
      const data = await documentForm.validateFields();
      if (!data.name || data.name.trim() === '') {
        notification.warning({
          message: 'Document name is required!'
        });
      } else if (!data.tag) {
        notification.warning({
          message: 'Document tag is required!'
        });
      } else if (data?.documentUrl === undefined && !isEdit) {
        notification.warning({
          message: 'Please Upload Document!'
        });
      } 
      else {
        if(isEdit){
            // data.documentUrl = data?.documentUrl && data.documentUrl[0]?.response?.url || recordData.document_url;
            let url = '';
            let attachmentKey = '';
            let ContentType = ''
            if(data?.documentUrl && data.documentUrl[0]?.response){
                url = data.documentUrl[0]?.response?.url;
                attachmentKey = data.documentUrl[0]?.response?.attachmentKey
                ContentType = data.documentUrl[0]?.response?.ContentType
                
            } else{
                url = recordData.document_url;
                attachmentKey = recordData?.attachments_data?.attachmentKey
                ContentType = recordData?.attachments_data?.ContentType
            }

            data.documentUrl=url

            data.attachmentsData={
                attachmentKey,
                ContentType
            }          
            onUpdate(data)
        } else{
          const url = data.documentUrl[0]?.response?.url;
          const attachmentKey = data.documentUrl[0]?.response?.attachmentKey
          const ContentType = data.documentUrl[0]?.response?.ContentType
          data.documentUrl=url

          data.attachmentsData={
            attachmentKey,
            ContentType
          }
          onSave(data);
        }
      }
    } catch (error) {
      console.log('Validation error:', error);
      notification.error({
        message: 'Please fill all the fields.'
      });
    }
  };
  
  function beforeUpload(file) {
    let isValidSize = true;
    if (file?.type) {
      isValidSize = file.size / 1024 / 1024 <= maxSize;
      if (!isValidSize) {
        notification.error({
          message: `Please provide a file less than ${maxSize} mb`,
        });
      }
    }

    return isValidSize;
  }

  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      setLoading(true);
      setIsFileUploaded(true)
      const isValid = beforeUpload(file);
      if (!isValid) return onError('', file);

      const data = {
          filename: `${uuid.v4()}_${file.name}`,
          contentType: file.type
      };
      const response = await getSignedUrl(data);

      if (response.code === 200) {
        const result = await fetch(response.imageData.url, {
          method: 'PUT',
          body: file,
        });
        if (result.status === 200) {
          return onSuccess(response.imageData, file);
        }
      }
      return onError(response, file);
    } catch (error) {
      return onError(error, file);
    } finally {
      setLoading(false);
    }
  };

  const onAddNewDocumentTag = (event) => {
    setNewDocumentTag(event.target.value);
  };

  const removeItem = (e) => {
    e.preventDefault();
    setNewDocumentTag('');
  }

  const addItem = async (e) => {
    e.preventDefault();
    if (!isEmpty(newDocumentTag) && !isEmpty(newDocumentTag.trim())) {
      setLoading(true);
      try {
        if(documentTagData.find(item => item.tag === newDocumentTag )){
          notification.warning({
            message: 'This Document tag Already Exist!'
        });
        } else{
          await addDocumentTag(newDocumentTag);
          await getDocumentTagData();
          setNewDocumentTag('');
        }
      } catch (error) {
        console.error('Error adding new role:', error);
      } finally {
        setLoading(false);
      }
    }
  };
  
  return (
    <>
      <Modal
        width="40vw"
        title={isEdit ? `Update Document - ${recordData.document_name}` : 'Add Document'}
        centered
        visible={addDocumentModal}
        okButtonProps={{ loading: loading, disabled: loading}}
        className='document-modal'
        footer={[
            <Button 
            key="cancel" 
            disabled={loading}
            onClick={() => {
                setAddDocumentModal(false)
                documentForm.resetFields();
                setIsEdit(false)
                setIsFileUploaded(false)
            }}>
                Cancel
            </Button>,
            <Button key="apply" type="primary" 
            onClick={handleDocument} 
            loading={loading}
            >
              {isEdit ? 'Update Document' : 'Add Document'}
            </Button>
        ]}
      >
        <Form
          form={documentForm}
          name="basic"
          layout="vertical"
          autoComplete="off" >
            <Row gutter={16}>
              <Col lg={24}>
                <Form.Item
                label="Document Name"
                name="name"
                rules={[
                  { required: true, message: 'Document name is required' },
                ]}
                >
                  <Input
                    placeholder="Document Name"
                    required={true}
                    maxLength={30}
                    size="large" />
                </Form.Item>
              </Col>              
              <Col lg={24}>
              <Form.Item
                name="tag"
                label="Document Tag"
                rules={[
                  { required: true, message: 'Please select at least one tag' },
                ]}
              >
                <Select 
                mode="multiple" 
                placeholder="Please select"
                labelInValue
                dropdownRender={menu => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                      <Input 
                      placeholder="Add Document Tag" 
                      value={newDocumentTag} 
                      onChange={onAddNewDocumentTag} 
                      maxLength={30} />
                      <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                        <CheckOutlined />
                      </Typography.Link>
                      <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                        <CloseOutlined />
                      </Typography.Link>
                    </Space>
                  </>
                )}
                >
                {documentTagData.map(tags => (
                  <Option value={tags.id} key={tags.id}>
                    {tags.tag}
                  </Option>
                ))}
                </Select>
              </Form.Item>
              </Col>
              {isEdit ? (
                  <Col lg={24}>
                    <div class="upload-label">View Existing Document {' '}
                    <a
                    className="export-link"
                    title="Reports"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={recordData.document_url}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                  <span className="icon-16 mr-2">
                    <svg>
                      <use
                        xlinkHref="#export"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    </svg>
                  </span>
                    </a>
                    </div>
                  </Col>
                ) : ''
              }
              <Col lg={24}>
                <Form.Item
                  name="documentUrl"
                  label="Upload Document"
                  valuePropName="fileList"
                  getValueFromEvent={(info) => info.fileList}
                >
                  <Upload
                    name="documentUrl"
                    maxCount={1}
                    multiple={false}
                    customRequest={customRequest}
                    accept=".xlx,.xlsx,.pdf"
                    onRemove={()=> setIsFileUploaded(false)}
                  >
                    <Button type="primary" disabled={fileUloaded || loading} icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </Form>
      </Modal>
    </>
  );
};

export default AddDocumentModal;
