/* eslint-disable linebreak-style */
import { all } from 'redux-saga/effects';
import qualityReportSagas from './quality';
import prodLogSagas from './prodLog';
import techLogSagas from './techLog';
import coatingLogSagas from './coatingLog';
import partsSagas from './parts';
import userSagas from './user';
import projectSagas from './project';
import projectListSagas from './projectList';
import authSagas from './auth';
import damageReportSagas from './damage';
import wireUsageReportSagas from './wireUsage';
import teamMembersSagas from './teamMembers';
import notificationSagas from './notifications';
import emailConfigSagas from './emailConfig';
import lemSagas from './lem';
import timesheetSagas from './timesheet';
import analysisSagas from './analysis';
import projectAssetSagas from './projectAsset';
import projectInventorySagas from './projectInventory';
import awsConfigSagas from './awsConfigData';
import advisorySagas from './advisory';
import advisoryList from './advisoryList';
import advisoryApprovalList from './advisoryApprovalList';

export default function* rootSaga() {
  yield all([
    qualityReportSagas(),
    prodLogSagas(),
    techLogSagas(),
    coatingLogSagas(),
    partsSagas(),
    userSagas(),
    projectSagas(),
    projectListSagas(),
    authSagas(),
    damageReportSagas(),
    wireUsageReportSagas(),
    teamMembersSagas(),
    notificationSagas(),
    emailConfigSagas(),
    lemSagas(),
    timesheetSagas(),
    analysisSagas(),
    projectAssetSagas(),
    projectInventorySagas(),
    awsConfigSagas(),
    advisorySagas(),
    advisoryList(),
    advisoryApprovalList()
  ]);
}
