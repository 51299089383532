/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/plots';

const ModalOffshoreSummaryGraph = (props) => {
  const { Reportdata, coloumnType } = props;
  const graphData = [];
  const [data, setData] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line no-plusplus
    if (Reportdata) {
      Reportdata.forEach((item) => {
        graphData.push({ date: item.date, total: Number(item[`${coloumnType}_day`]), value: 'Day' });
        graphData.push({ date: item.date, total: Number(item[`${coloumnType}_night`]), value: 'Night' });
        graphData.push({ date: item.date, total: Number(item[`${coloumnType}_overall`]), value: 'Overoll' });
      });
      graphData.sort((a, b) => new Date(a.date) - new Date(b.date));
      setData(graphData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Reportdata]);

  const config = {
    autoFit: true,
    height: 10,
    data,
    xField: 'date',
    yField: 'total',
    seriesField: 'value',
    tooltip: {
      showMarkers: true
    },
    point: {
      shape: 'breath-point'
    },
    yAxis: {
      grid: { line: { style: { stroke: '' } } }
    },
    color: ['#FF0000', '#FFDB0A', '#0000FF'],
  };

  return (
    <div className="chart-wrapper" style={{ height: '400px' }}>
      <Line {...config} />
    </div>

  // eslint-disable jsx-props-no-spreading

  // eslint-disable-next-line react/jsx-props-no-spreading
  );
};
export default ModalOffshoreSummaryGraph;
