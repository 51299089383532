import { notification } from 'antd';
import { Auth } from '@aws-amplify/auth';
import fetch from './fetch';
import { BASE_URL } from '../config';

export const addNotification = async (data) => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    if (!currentUser) {
      return null;
    }
    const notificationdata = {
      fromUserId: currentUser?.attributes?.sub,
      fromUserName: currentUser?.attributes?.email,
      toUserId: data.toUserId,
      toUserName: data.toUserName,
      messageTitle: data.title,
      messageText: data.message
    };
    const res = await fetch(`${BASE_URL}/notification`, {
      method: 'POST',
      body: JSON.stringify(notificationdata),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};
