import get from 'lodash/get';
import moment from 'moment';
import * as constants from '../../utils/constants';
import { GET_LEM_REPORTS, SET_LEM_REPORTS, SET_LEM_REPORT_FAILURE } from '../types';

const initialState = {
  isLoading: false,
};

const lemRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LEM_REPORTS: {
      const response = get(action, ['response', 'data'], []);
      const lReports = response && response.lemReportData;
      const { total } = response;
      const ldata = [];
      for (let i = 0; i < lReports.length; i += 1) {
        let date;
        if (lReports[i].date_and_time_of_submission) {
          date = moment(lReports[i].date_and_time_of_submission).format(constants.DATETIMESEC);
        } else {
          date = null;
        }
        ldata[i] = {
          reportId: get(lReports[i], 'id', '-'),
          pdf_url: get(lReports[i], 'pdf_url', '-'),
          created_by: get(lReports[i], 'created_by', '-'),
          date_and_time_of_submission: date,
          projectNo: get(lReports[i], 'project_number', '-'),
          selected_date: get(lReports[i], 'selected_date', '-'),
          assets: get(lReports[i], 'assets', '[]'),
          members: get(lReports[i], 'members', '[]'),
          comments: get(lReports[i], 'comments', ''),
          pdf_signed: get(lReports[i], 'pdf_signed', '0'),
          guid: get(lReports[i], 'guid', '0')
        };
      }
      return {
        ...state,
        ldata,
        total,
        isLoading: false,
      };
    }

    case GET_LEM_REPORTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_LEM_REPORT_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default lemRequestReducer;
