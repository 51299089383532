/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

import React, { useState, useEffect } from 'react';
import {
  Modal, Button
} from 'antd';

const WireUsageModal = (props) => {
  const {
    modalVisible, hideModal, createDate, data
  } = props;
  const [newData, setNewData] = useState(false);
  const [tablehtml, setTablehtml] = useState('');

  const tableData = () => {
    const tableval = (
      <table className="logreport report-table">
        <thead className="ant-table-thead">
          <tr>
            <th rowSpan="2" className="ant-table-cell text-center">
              <div className="main-title py-2 me-2">
                SIZE
              </div>
            </th>
            <th rowSpan="2" className="ant-table-cell text-center">
              <div className="main-title py-2 me-2">
                HEAT NUMBER
              </div>
            </th>
            <th rowSpan="2" className="ant-table-cell text-center">
              <div className="main-title py-2 me-2">
                TOTAL SENT TO DATE
              </div>
            </th>
            <th rowSpan="2" className="ant-table-cell text-center">
              <div className="main-title py-2 me-2">
                INVENTORY
              </div>
            </th>
            <th rowSpan="2" className="ant-table-cell text-center">
              <div className="main-title py-2 me-2">
                USAGE THIS MONTH
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="ant-table-tbody">
          {
                  newData
                  && Object.keys(newData).map((e) => {
                    return (
                      <tr className="ant-table-row ant-table-row-level-0">
                        <td className="rTableCell text-center">{newData[e].size}</td>
                        <td className="rTableCell text-center">{newData[e].heat}</td>
                        <td className="rTableCell text-center">{newData[e].total}</td>
                        <td className="rTableCell text-center">{newData[e].inventory}</td>
                        <td className="rTableCell text-center">{newData[e].usage}</td>
                      </tr>
                    );
                  })
                }
        </tbody>
      </table>
    );
    setTablehtml(tableval);
  };

  useEffect(() => {
    if (newData !== []) {
      tableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible, newData]);

  useEffect(() => {
    const newData = [];
    data.map((item) => {
      item.createdAt = item.createdAt.replaceAll('/', '-');
      if (item.createdAt === createDate) {
        newData.push({
          size: item.size !== '' ? item.size : 0, heat: item.heat !== '' ? item.heat : 0, total: item.total ? item.total : 0, usage: item.usage ? item.usage : 0, inventory: item.inventory
        });
      }
    });
    setNewData(newData);
  }, [createDate, data]);

  return (
    <>
      <Modal
        title="Parts Ordering Report"
        className="welds-modal"
        centered
        visible={modalVisible}
        width={1000}
        footer={[
          <Button key="submit" type="primary" onClick={() => hideModal()}>
            OK
          </Button>
        ]}
      >
        <div>
          <div className="ant-table scroll-table">
            <div className="ant-table-container">
              <div className="ant-table-content">
                {tablehtml}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WireUsageModal;
