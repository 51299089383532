import React, { useState } from 'react';
import {
  Form, Button, notification
} from 'antd';
import moment from 'moment';
import Advisory from './Advisory';
import MachineTypeCard from './MachineTypeCard';
import MachineSubTypeCard from './MachineSubTypeCard';
import AddMachine from './AddMachine';
import { addAdvisory } from '../services/advisory';
import { ADV_DATE } from '../utils/constants';

function AdvisoryTab({ currentSection, setCurrentSection }) {
  const [isVisible, setIsVisible] = useState(false);
  const [typeId, setTypeId] = useState('');
  const [subTypeId, setSubTypeId] = useState('');
  const [isLoading, setIsLoading] = useState();
  const [isModalVisible, setIsModalVisible] = useState();

  const [form] = Form.useForm();

  const goToSubType = (id) => {
    if (id) setTypeId(id);
    setCurrentSection('subType');
  };
  const goToType = () => setCurrentSection('type');
  const goToAdvisory = (id) => {
    setSubTypeId(id);
    setCurrentSection('advisory');
  };

  const onSave = async (values) => {
    setIsLoading(true);
    const {
      title, description, type, date, attachments, priority, createdBy, approverId,
      businessUnit
    } = values;
    const data = {
      title,
      description,
      type,
      date: moment(date).format(ADV_DATE),
      attachments,
      createdBy,
      priority,
      businessUnit,
      approverId
    };
    const res = await addAdvisory(data);
    setIsLoading(false);
    if (!res.err) {
      form.resetFields();
      setIsVisible(false);
    } else {
      notification.error({
        message: res.message || 'Something went wrong.',
      });
    }
  };

  const btnHandler = () => {
    setIsVisible(true);
    const data = {};

    if (currentSection === 'subType') data.parent = typeId;
    else if (currentSection === 'advisory') {
      data.parent = typeId;
      data.type = subTypeId;
    }
    form.setFieldsValue(data);
  };
  return (
    <>
      <div className="text-right mb-2">
        <Button
          key="submit"
          size="medium"
          shape="round"
          type="primary"
          style={{ 'margin-right': '10px' }}
          onClick={btnHandler}
        >
          Add Advisory
        </Button>
      </div>
      {
        currentSection === 'type' && (
          <MachineTypeCard
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            goToSubType={goToSubType}
          />
        )
      }
      {
        currentSection === 'subType' && (
          <MachineSubTypeCard
            typeId={typeId}
            goToType={goToType}
            goToAdvisory={goToAdvisory}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
          />
        )
      }
      {
        currentSection === 'advisory' && (
          <Advisory
            subTypeId={subTypeId}
            goToSubType={goToSubType}
            goToType={goToType}
          />
        )
      }
      <AddMachine
        isLoading={isLoading}
        isVisible={isVisible}
        onCancel={() => setIsVisible(false)}
        onOk={onSave}
        form={form}
        title="Add Advisory"
        setIsLoading={setIsLoading}
        key="Add"
        isCreate
      />
    </>
  );
}

export default AdvisoryTab;
