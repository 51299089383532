/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { Table, Spin } from 'antd';
import get from 'lodash/get';
import { connect } from 'react-redux';
import {
  func, arrayOf, objectOf, oneOfType, string, number,
  array, bool
} from 'prop-types';
import { getDamageReports } from '../state/action/damageAction';

const columns = [
  {
    title: 'Date of Discovery',
    dataIndex: 'date',
    key: 'date',
    align: 'center'
  },
  {
    title: 'Serial Number',
    dataIndex: 'number',
    key: 'number',
    align: 'center'
  },
  {
    title: 'Damage Description',
    dataIndex: 'damage',
    key: 'damage',
    align: 'center'
  },
  {
    title: 'Discovered By',
    key: 'discovered',
    dataIndex: 'discovered',
    align: 'center'
  },
  {
    title: 'Report',
    key: 'report',
    dataIndex: 'report',
    render: (record) => {
      if (record) {
        return (
          <div>
            {' '}
            <a
              className="export-link"
              title="Download pdf report"
              target="_blank"
              rel="noopener noreferrer"
              href={record}
              // eslint-disable-next-line react/jsx-closing-bracket-location
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <span className="icon-16 mr-2">
                <svg>
                  <use xlinkHref="#export" />
                </svg>
              </span>
            </a>
          </div>
        );
        // eslint-disable-next-line brace-style
      }
      // eslint-disable-next-line no-else-return
      else {
        return <div>-</div>;
      }
    },
    align: 'center',
  },
];

const DamageReport = ({
  // eslint-disable-next-line no-unused-vars
  onGetDamageReports, data, projNo, showLoader, reload, setDamageRep, damageReport, refresh
}) => {
  const [damagedData, setDamagedData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [totalReports, setTotalReports] = useState(0);

  useEffect(() => {
    // refresh(false);
    // if (!reload) {
    if (projNo !== '') {
      onGetDamageReports({ projectNo: projNo });
    } else {
      setDamagedData([]);
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo]);

  useEffect(() => {
    if (reload) {
      if (projNo) {
        onGetDamageReports({ projectNo: projNo });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    if (!reload) {
      const damageDt = [];
      data.forEach((dt) => {
        if (dt.number === '') {
          dt.number = 'N/A';
        }
        if (dt.discovered === '') {
          dt.discovered = 'N/A';
        }
        damageDt.push(dt);
      });
      setTotalReports(damageDt.length);
      setDamagedData(damageDt);
      setDamageRep(damageDt);
    } else {
      setDamagedData(damageReport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleTableChnage = (pagination,
    filters,
    sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  return (
    <div className="h-85 overflow-y-hidden overflow-x-auto">
      <Table
        pagination={totalReports > 10 ? tableParams.pagination : false}
        columns={columns}
        dataSource={damagedData}
        className="h-85"
        scroll={{ x: damagedData.length > 0 ? 1200 : 0, y: '100%' }}
        loading={{
          indicator: <Spin />,
          spinning: showLoader
        }}
        onChange={handleTableChnage}
      />
      {' '}

    </div>
  );
};

DamageReport.propTypes = {
  onGetDamageReports: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showLoader: bool.isRequired,
};

const mapStateToProps = ({ damageReports }) => {
  const data = get(damageReports, 'data', []);
  const showLoader = get(damageReports, 'isLoading', false);
  return {
    data,
    showLoader,
  };
};

const mapDispatchToProps = {
  onGetDamageReports: getDamageReports
};

export default connect(mapStateToProps, mapDispatchToProps)(DamageReport);
