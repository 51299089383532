/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
/* eslint-disable linebreak-style */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable semi-spacing */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Empty, Typography, Row, Col, Table, Button, notification, Tooltip, Spin, DatePicker, Modal
} from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import EMEAWeldesModal from './EMEAWeldesModal';
import EMEAWeldesOverallModal from './EMEAWeldesOverallModal';
import DprEmeaProdGragh from '../layout/dprEmeaProdGragh';
import DprEmeaDownTimeGragh from '../layout/dprEmeaDownTimeGragh';
import { getMonthFromPeriod, calcDowntime } from '../services/calendar';
import { getDPREMEA, getDPREMEAReport } from '../services/DPREMEA';

const { Title, Text } = Typography;

const DPREMEA = (props) => {
  /* eslint-disable react/prop-types */
  const {
    projNo, reload,
    setPrevProdRep, refresh
  } = props;

  const [prodmodalvisible, setprodmodalvisible] = useState(false);
  const [overallModalVisible, setOverallModalVisible] = useState(false);
  const [currentMonthData, setCurrentMonthData] = useState([]);
  const [overallData, setOverallData] = useState([]);
  const [overallDprData, setOverallDprData] = useState([]);
  const [currentDayData, setCurrentDayData] = useState([]);
  const [prevReport, setPrevReport] = useState([]);
  const [dprEmeaData, setDPREMEAData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportGenerationModal, setReportGenerationModal] = useState(false);
  const [reportDate, setReportDate] = useState('');
  const [reportSubmittedDate, setReportSubmittedDate] = useState([]);

  const addStartEndDate = async (data) => {
    const currentPeriod = moment().format('YYYY-MM');

    const filter = data.items.filter((item) => item.period !== currentPeriod);

    filter.forEach((it) => {
      if (it.period !== currentPeriod) it.date_period = getMonthFromPeriod(it.period);
    });
    return filter;
  };

  const getCurrentMonthData = async (dprData) => {
    const currentYear = moment().year();
    const currentMonth = moment().format('MM');
    const currentPeriod = `${currentYear}-${currentMonth}`;
    return dprData.items.filter((item) => item.period === currentPeriod)[0];
  };

  const getOverallData = async (dprData) => {
    const overall = {
      overallWeldCount: dprData.items[0]?.overallWeldCount || '',
      overallrepairCount: dprData.items[0]?.overallrepairCount || '',
      overallRpairRate: dprData.items[0]?.overallRpairRate || '',
      overallDowntime: dprData.items[0]?.overallDowntime || '',
    };
    return overall;
  };

  const getCurrentDateData = async (monthData) => {
    const currentDate = moment().format('YYYY-MM-DD');
    return monthData.filter((item) => item.date === currentDate)[0];
  };

  const transformOverallData = async (data) => {
    const transformedItems = data.items.flatMap((item) => item.dpr_data.map((dpr) => ({
      period: getMonthFromPeriod(item.period),
      total_welds: item.total_welds,
      total_repair: item.total_repair,
      date: dpr.date,
      pdf_url: dpr.pdf_url,
      comments: dpr.comments,
      downtime: calcDowntime(dpr.downtime),
      repair_rate: dpr.repair_rate,
      production_time: dpr.production_time,
      production_image: dpr.production_image,
      weld_count_repairs: dpr.weld_count_repairs,
      welding_time_start: dpr.welding_time_start,
      welds_joints_start: dpr.welds_joints_start,
      downtime_contractor: dpr.downtime_contractor,
      reporting_time_crce: dpr.reporting_time_crce,
      reporting_time_crew: dpr.reporting_time_crew,
      weld_count_accepted: dpr.weld_count_accepted,
      welding_time_finish: dpr.welding_time_finish,
      welds_joints_finish: dpr.welds_joints_finish,
      isSubmitted: dpr.isSubmitted,
      downtime_crce_equipment: dpr.downtime_crce_equipment,
      downtime_crce_personnel: dpr.downtime_crce_personnel,
      overallWeldCount: item.overallWeldCount,
      overallrepairCount: item.overallrepairCount,
      overallRpairRate: item.overallRpairRate,
      overallDowntime: calcDowntime(item.overallDowntime),
    })));

    const submittedDates = transformedItems
      .filter((item) => item.isSubmitted === 1)
      .map((item) => item.date);

    setReportSubmittedDate(submittedDates);

    return { items: transformedItems };
  };

  const getData = async () => {
    setLoading(true);
    try {
      if (projNo !== '') {
        setDPREMEAData([]);
        setOverallDprData([]);
        setOverallData([]);
        setCurrentDayData([]);
        setPrevProdRep([]);
        const res = await getDPREMEA(projNo);
        if (res) {
          const data = await addStartEndDate(res);
          const overallDpr = await transformOverallData(res);
          const currentMonth = await getCurrentMonthData(res);
          const overall = await getOverallData(res);
          setOverallDprData(overallDpr);
          setOverallData(overall);
          setCurrentMonthData(currentMonth);
          if (currentMonth) {
            const currentDateData = await getCurrentDateData(currentMonth?.dpr_data);
            setCurrentDayData(currentDateData);
          }
          setDPREMEAData(data);
        }
      } else {
        setDPREMEAData([]);
        setOverallDprData([]);
        setOverallData([]);
        setCurrentDayData([]);
        setPrevProdRep([]);
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (reload) {
      if (projNo) {
        getData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    refresh(false);
    if (!reload) {
      getData();
    } else {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo]);

  const modal = () => (
    <EMEAWeldesModal
      visible={prodmodalvisible}
      currentReport={prevReport?.dpr_data ? prevReport : currentMonthData}
      setVisible={(value) => { setprodmodalvisible(value); setPrevReport([]); }}
      projNo={projNo}
    />
  );

  const overallModal = () => (
    <EMEAWeldesOverallModal
      visible={overallModalVisible}
      currentReport={overallDprData.items}
      setVisible={(value) => { setOverallModalVisible(value); setPrevReport([]); }}
      projNo={projNo}
    />
  );

  const headercolumns = [
    {
      title: 'Month',
      dataIndex: 'date_period',
      key: 'date_period',
      width: '100px',
    },
    {
      title: 'Welds',
      dataIndex: 'total_welds',
      key: 'total_welds',
      align: 'center',
      className: 'truncate',
      render: (text) => <Tooltip title={text}>{text}</Tooltip>
    },
    {
      title: 'Repair',
      dataIndex: 'total_repair',
      key: 'total_repair',
      align: 'center',
      className: 'truncate',
      render: (text) => <Tooltip title={text}>{text}</Tooltip>
    },
    {
      title: 'Rate',
      dataIndex: 'monthly_repair_rate',
      key: 'monthly_repair_rate',
      align: 'center',
      className: 'truncate',
      render: (text) => <Tooltip title={text}>{text}</Tooltip>
    },
    {
      title: 'View',
      render: (record) => {
        if (record) {
          return (
            <div>
              {' '}
              <EyeOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  setPrevReport(record);
                  setprodmodalvisible(true);
                  modal();
                }}
              />
            </div>
          );
          // eslint-disable-next-line brace-style
        }
        // eslint-disable-next-line no-else-return
        else {
          return <div>-</div>;
        }
      },
      align: 'center',
    },
  ];

  const getDprEmeaReport = async () => {
    try {
      const dateValue = moment(reportDate).format('YYYY-MM-DD');
      const pdf = await getDPREMEAReport(projNo, dateValue);
      if (pdf.reportUrl.length > 0) {
        const a = document.createElement('a');
        a.href = `${pdf.reportUrl[0].pdf_url}`;
        a.target = '_blank';
        a.download = `DprEmea-${dateValue}.pdf`;
        a.click();
      } else {
        notification.warning({
          message: `Report was not submitted in ${reportDate}`,
        });
      }
      setReportGenerationModal(false);
    } catch (error) {
      setReportGenerationModal(false);
      notification.error({
        message: 'Something went wrong',
      });
    }
  };

  const dateRender = (current) => {
    const style = {};
    if (reportSubmittedDate.includes(current.format('YYYY-MM-DD'))) {
      style.border = '1px solid green';
      style.borderRadius = '50%';
      style.backgroundColor = 'green';
    }
    return (
      <div className="ant-picker-cell-inner" style={style}>
        {current.date()}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        <Spin size="large" tip="Loading data..." />
      </div>
    );
  }

  return (
    <>
      <div className="d-flex h-100">
        <div className="flex-fill custom-fill overflow-auto flex-grow-7" style={{ maxWidth: '60%' }}>
          <div className="d-flex flex-column h-100">
            <div className="flex-fill border-right overflow-auto">
              <div className="d-flex flex-column h-100">
                <div className="flex-auto px-3">
                  <div className="flex-auto">
                    <div className="d-flex mb-3 align-items-center">
                      <Title className="mb-0" level={4}>
                        Today
                      </Title>
                      <Text className="ps-3">
                        {moment().format('DD-MMM-YYYY')}
                      </Text>
                    </div>
                  </div>
                  <div className="custom-line" />
                  <Row gutter={[16, 16]} className="pt-3">
                    <Col md={12} lg={8} xl={6} className="ps-0">
                      <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center position-relative">
                        <Text className="mb-1 theme-color">
                          Total Welds
                        </Text>
                        <Title
                          className="my-0 theme-color fw-bold truncate sbd-tooltip"
                          style={{ fontSize: '14px' }}
                        >

                          <Tooltip title={currentDayData && currentDayData.weld_count_accepted} placement="topLeft">
                            {currentDayData && currentDayData.weld_count_accepted
                              ? currentDayData.weld_count_accepted : 0}
                          </Tooltip>

                        </Title>
                      </div>
                    </Col>
                    <Col md={12} lg={8} xl={6}>
                      <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center">
                        <Text className="mb-1 theme-color">Repair</Text>
                        <Title
                          className="my-0 theme-color fw-bold truncate"
                          style={{ fontSize: '14px' }}
                        >
                          <Tooltip title={currentDayData && currentDayData.weld_count_repairs} placement="topLeft">
                            {currentDayData && currentDayData.weld_count_repairs
                              ? currentDayData.weld_count_repairs : 0}
                          </Tooltip>
                        </Title>
                      </div>
                    </Col>
                    <Col md={12} lg={8} xl={6}>
                      <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center">
                        <Text className="mb-1 theme-color">
                          Repair Rate
                        </Text>
                        <Title
                          className="my-0 theme-color fw-bold truncate"
                          style={{ fontSize: '14px' }}
                        >
                          <Tooltip title={currentDayData && currentDayData.repair_rate} placement="topLeft">
                            {currentDayData && currentDayData.repair_rate
                              ? currentDayData.repair_rate : 0}
                          </Tooltip>
                        </Title>
                      </div>
                    </Col>
                    <Col md={12} lg={8} xl={6}>
                      <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center">
                        <Text className="mb-1 theme-color">
                          Downtime
                        </Text>
                        <Title
                          className="my-0 theme-color fw-bold truncate"
                          // level={4}
                          style={{ fontSize: '14px' }}
                        >
                          <Tooltip title={currentDayData && calcDowntime(currentDayData.downtime)} placement="topLeft">
                            {currentDayData ? calcDowntime(currentDayData.downtime) : '0 mins'}
                          </Tooltip>
                        </Title>
                      </div>
                    </Col>
                  </Row>
                  <div className="flex-auto">
                    <div className="d-flex mb-3 align-items-center">
                      <Title className="mb-0" level={4}>
                        Current Month
                      </Title>
                      <Text className="ps-3">
                        {moment().format('MMMM YYYY')}
                      </Text>
                    </div>
                    <div className="custom-line" />
                  </div>
                  <Row gutter={[16, 16]} className="pt-3">
                    <Col md={12} lg={8} xl={6} className="ps-0">
                      <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center position-relative">
                        <Text className="mb-1 theme-color">
                          Total Welds
                          {modal()}
                        </Text>
                        <Title
                          onClick={() => {
                            setprodmodalvisible(true);
                          }}
                          className="my-0 theme-color fw-bold truncate sbd-tooltip"
                          style={{ fontSize: '14px' }}
                        >

                          <Tooltip title={currentMonthData && currentMonthData.total_welds} placement="topLeft">
                            {currentMonthData && currentMonthData.total_welds
                              ? currentMonthData.total_welds : 0}
                          </Tooltip>

                        </Title>
                      </div>
                    </Col>
                    <Col md={12} lg={8} xl={6}>
                      <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center">
                        <Text className="mb-1 theme-color">Repair</Text>
                        <Title
                          className="my-0 theme-color fw-bold truncate"
                          style={{ fontSize: '14px' }}
                        >
                          <Tooltip title={currentMonthData && currentMonthData.total_repair} placement="topLeft">
                            {currentMonthData && currentMonthData.total_repair
                              ? currentMonthData.total_repair : 0}
                          </Tooltip>
                        </Title>
                      </div>
                    </Col>
                    <Col md={12} lg={8} xl={6}>
                      <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center">
                        <Text className="mb-1 theme-color">
                          Repair Rate
                        </Text>
                        <Title
                          className="my-0 theme-color fw-bold truncate"
                          style={{ fontSize: '14px' }}
                        >
                          <Tooltip title={currentMonthData && currentMonthData.monthly_repair_rate} placement="topLeft">
                            {currentMonthData && currentMonthData.monthly_repair_rate
                              ? currentMonthData.monthly_repair_rate : 0}
                          </Tooltip>
                        </Title>
                      </div>
                    </Col>
                    <Col md={12} lg={8} xl={6}>
                      <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center">
                        <Text className="mb-1 theme-color">
                          Downtime
                        </Text>
                        <Title
                          className="my-0 theme-color fw-bold truncate"
                          // level={4}
                          style={{ fontSize: '14px' }}
                        >
                          <Tooltip title={currentMonthData && calcDowntime(currentMonthData.monthly_downtime)} placement="topLeft">
                            {currentMonthData ? calcDowntime(currentMonthData.monthly_downtime) : '0 mins'}
                          </Tooltip>
                        </Title>
                      </div>
                    </Col>
                  </Row>
                  <div className="flex-auto">
                    <div className="d-flex mb-3 align-items-center">
                      <Title className="mb-0" level={4}>
                        Overall
                      </Title>
                    </div>
                    <div className="custom-line" />
                  </div>
                  <Row gutter={[16, 16]} className="pt-3">
                    <Col md={12} lg={8} xl={6} className="ps-0">
                      <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center position-relative">
                        <Text className="mb-1 theme-color">
                          Total Welds
                          {overallModal()}
                        </Text>
                        <Title
                          onClick={() => {
                            setOverallModalVisible(true);
                            // setCurrentReport(curProdReport);
                          }}
                          className="my-0 theme-color fw-bold truncate sbd-tooltip"
                          style={{ fontSize: '14px' }}
                        >

                          <Tooltip title={overallData && overallData.overallWeldCount} placement="topLeft">
                            {overallData && overallData.overallWeldCount
                              ? overallData && overallData.overallWeldCount : 0}
                          </Tooltip>

                        </Title>
                      </div>
                    </Col>
                    <Col md={12} lg={8} xl={6}>
                      <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center">
                        <Text className="mb-1 theme-color">Repair</Text>
                        <Title
                          className="my-0 theme-color fw-bold truncate"
                          style={{ fontSize: '14px' }}
                        >
                          <Tooltip title={overallData && overallData.overallrepairCount} placement="topLeft">
                            {overallData && overallData.overallrepairCount
                              ? overallData.overallrepairCount : 0}
                          </Tooltip>
                        </Title>
                      </div>
                    </Col>
                    <Col md={12} lg={8} xl={6}>
                      <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center">
                        <Text className="mb-1 theme-color">
                          Repair Rate
                        </Text>
                        <Title
                          className="my-0 theme-color fw-bold truncate"
                          style={{ fontSize: '14px' }}
                        >
                          <Tooltip title={overallData && overallData.overallRpairRate} placement="topLeft">
                            {overallData && overallData.overallRpairRate
                              ? overallData.overallRpairRate : 0}
                          </Tooltip>
                        </Title>
                      </div>
                    </Col>
                    <Col md={12} lg={8} xl={6}>
                      <div className="custom-card card-height d-flex flex-column align-items-center justify-content-center">
                        <Text className="mb-1 theme-color">
                          Downtime
                        </Text>
                        <Title
                          className="my-0 theme-color fw-bold truncate"
                          style={{ fontSize: '14px' }}
                          // level={5}
                        >
                          <Tooltip title={overallData && calcDowntime(overallData.overallDowntime)} placement="topLeft">
                            {overallData ? calcDowntime(overallData.overallDowntime) : '0 mins'}
                          </Tooltip>
                        </Title>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div><p className="fw-medium title">Production</p></div>
                <div className="flex-fill mb-2">
                  <div style={{ height: '200px' }}>
                    {currentMonthData && currentMonthData?.dpr_data
                      ? <DprEmeaProdGragh currentMonthData={currentMonthData?.dpr_data} />
                      : <Empty />}
                  </div>
                </div>
                <div className="custom-line" />
                <div><p className="fw-medium title">Dowtime</p></div>
                <div className="flex-fill">
                  <div style={{ height: '200px' }}>
                    {currentMonthData && currentMonthData?.dpr_data
                      ? <DprEmeaDownTimeGragh currentMonthData={currentMonthData?.dpr_data} />
                      : <Empty />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-fill custom-fill overflow-auto flex-grow-3" style={{ maxWidth: '40%' }}>

          <div className="d-flex flex-column h-100">
            <div
              className="flex-auto w-100"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Title className="mb-3 ps-4" level={4}>
                Previous Reports
              </Title>

              <Button
                size="small"
                shape="round"
                type="primary"
                className="timesheetButton"
                onClick={() => setReportGenerationModal(true)}
              >
                Generate Daily Report
              </Button>

            </div>
            <div className="custom-line" />
            <div className="flex-fill overflow-auto ps-4">
              <div className={dprEmeaData.length > 0 ? 'h-100 overflow-y-hidden overflow-x-auto has-pointer' : 'h-100 overflow-y-hidden overflow-x-auto'}>
                <Table
                  className="h-100"
                  scroll={{ x: dprEmeaData.length > 0 ? 400 : 0, y: '100%' }}
                  pagination={false}
                  rowKey={(record) => record.reportId}
                  columns={headercolumns}
                  dataSource={dprEmeaData}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
      <Modal
        className="quality-modal"
        title="Generate Report"
        centered
        visible={reportGenerationModal}
        destroyOnClose
        footer={[
          <>
            <Button onClick={() => setReportGenerationModal(false)}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                getDprEmeaReport();
              }}
            >
              Generate
            </Button>
          </>
        ]}
      >
        <div>
          <Row gutter={16}>
            <Col lg={24}>
              <DatePicker
                className="rangePicker_separator"
                showToday
                separator="to"
                format="DD-MMM-YYYY"
                placeholder="select Date"
                dateRender={dateRender}
                onChange={(date, dateString) => {
                  setReportDate(dateString);
                }}
              />
            </Col>
          </Row>
        </div>

      </Modal>

    </>
  );
};

export default DPREMEA;
