/* eslint-disable linebreak-style */
import get from 'lodash/get';
import {
  FETCH_PROJECTS, SET_PROJECTS, SET_PROJECT_FAILURE, SAVE_PROJECT, UPDATE_PROJECT
} from '../types';

const initialState = {
  isProjectLoading: false,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECTS: {
      const Projects = get(action, ['response', 'data'], []);
      const projectData = [];
      for (let i = 0; i < Projects.length; i += 1) {
        const projectName = get(Projects[i], 'project_name', '');
        const projectNo = get(Projects[i], 'project_id', '');
        const contractor = get(Projects[i], 'contractor', '');
        const leadTech = get(Projects[i], 'leadtech', '');
        const completedWelds = get(Projects[i], 'completedwelds', 0);
        const plannedWelds = get(Projects[i], 'plannedwelds', 0);
        const startDate = get(Projects[i], 'start_date', '');
        const endDate = get(Projects[i], 'end_date', '');
        if (projectNo !== '') {
          projectData[i] = {
            id: i,
            key: i,
            projectName,
            projectNo,
            contractor,
            leadTech,
            completedWelds,
            plannedWelds,
            startDate,
            endDate
          };
        }
      }

      return {
        ...state,
        projectData,
        isProjectLoading: false,
      };
    }

    case FETCH_PROJECTS: {
      return {
        ...state,
        isProjectLoading: true,
      };
    }

    case SET_PROJECT_FAILURE: {
      return {
        ...state,
        isProjectLoading: false,
      };
    }

    case SAVE_PROJECT: {
      return {
        ...state
      };
    }

    case UPDATE_PROJECT: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
};

export default projectReducer;
