import React, { useState, useEffect } from 'react';
import {
  Table, Spin, notification
} from 'antd';
import moment from 'moment';
import * as constants from '../utils/constants';

import { getProjectReports } from '../services/summary';

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
  },
  {
    title: 'Created By',
    dataIndex: 'created_by',
    key: 'created_by',
    align: 'center',

  },
  {
    title: 'Created Time',
    dataIndex: 'submitted_at',
    key: 'submitted_at',
    align: 'center',
    render: (text, record) => (record.submitted_at ? moment(record.submitted_at).format(constants.DATETIMESEC) : '-')
  },
  {
    title: 'Report',
    key: 'pdf_url',
    dataIndex: 'pdf_url',
    render: (text, record) => {
      if (record) {
        return (
          <div>
            {' '}
            <a
              className="export-link"
              title="Reports"
              target="_blank"
              rel="noopener noreferrer"
              href={record.pdf_url}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <span className="icon-16 mr-2">
                <svg>
                  <use
                    xlinkHref="#export"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </svg>
              </span>
            </a>
          </div>
        );
      }
      return <div>-</div>;
    },
    align: 'center'
  },

];

function DprReport(props) {
  const { projNo, projectType, reload } = props;
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await getProjectReports(projNo);
      if (res?.reportData) {
        setReportData(res?.reportData.sort((a, b) => new Date(b.date) - new Date(a.date)));
      } else setReportData([]);
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (projNo && projectType === 'offshore') getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo]);

  useEffect(() => {
    if (reload && projectType === 'offshore') getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <>
      <div className={reportData.length > 0 ? 'h-85 overflow-y-hidden overflow-x-auto has-pointer' : 'h-85 overflow-y-hidden overflow-x-auto'}>
        <Table
          pagination={false}
          columns={columns}
          dataSource={reportData}
          className="table-with-button"
          scroll={{ x: reportData.length > 0 ? 1200 : 0, y: '100%' }}
          rowKey={(record) => record.id}
          loading={{
            indicator: <Spin />,
            spinning: loading
          }}
        />
      </div>

    </>
  );
}

export default DprReport;
