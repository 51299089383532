import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducer';
import rootSaga from '../sagas';

const { logger } = require('redux-logger');

// eslint-disable-next-line prefer-const
let ENABLE_LOGGER = true;

function configureStore() {
  const sagaMiddleWare = createSagaMiddleware();

  let middlewares = [sagaMiddleWare];

  if (process.env.NODE_ENV !== 'production' && ENABLE_LOGGER) {
    // eslint-disable-next-line import/no-extraneous-dependencies
    middlewares = [...middlewares, logger];
  }
  const store = createStore(rootReducer, applyMiddleware(...middlewares));
  sagaMiddleWare.run(rootSaga);
  return store;
}

export default configureStore;
