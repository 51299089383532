/* eslint-disable  */
import React, { useState, useEffect } from 'react';
import {
  Modal, Button, Tabs, Table, Spin, notification, Switch
} from 'antd';
import { shift } from '../utils/constants';
import { getPipeline } from '../services/pipeline';
import { getProductivityData } from '../services/summary';
import { toNumber } from '../utils/utility';
import { element } from 'prop-types';
import ModalOffshoreSummaryGraph from './ModalOffshoreSummaryGraph';

const { TabPane } = Tabs;
const { Column, ColumnGroup } = Table;

const ProductivityModalOverall = (props) => {
  const {
    visible, setVisible, projNo
  } = props;
  const [tabValue, setTabValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [objectFormat, setObjectFormat] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [summaryDataSource, setSummaryDataSource] = useState([]);
  const [pipelinesData, setPipelinesData] = useState([]);
  const [showGraph, setShowGraph] = useState(false);

  useEffect(() => {
    if (visible) {
      setTabValue('Completed');
      getTableColumns();
    }
    return () => {
      setPipelinesData([]);
      setDataSource([]);
      setTabValue('')
    };
  }, [visible]);

  const getTableColumns = async () => {
    setLoading(true);
    try {
      const dummyObject = {};
      dummyObject.date = '';
      if (projNo) {
        const res = await getPipeline(projNo);
        if (res?.pipelineData) {
          const pipelineData = res?.pipelineData.filter((item) => item.is_deleted === 0);
       
          pipelineData.forEach((item, index) => {
          dummyObject[`${item.id.toString()}_day`] = '0';
          dummyObject[`${item.id.toString()}_night`] = '0';
          dummyObject[`${item.id.toString()}_overall`] = '0';         
        });

        setPipelinesData(pipelineData)
        setObjectFormat(dummyObject);
        getProductiveComments('Completed');
        getProductiveData('Completed', dummyObject);
        }
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  function groupArrayByField(arr, field) {
    const groups = {};

    arr.forEach((obj) => {
      const value = obj[field];
      if (groups[value]) {
        groups[value].push(obj);
      } else {
        groups[value] = [obj];
      }
    });

    return groups;
  }

  const getProductiveData = async (tab, dummyObject) => {
    setLoading(true);
    try {
      if (projNo) {
        const currentTab = tab || tabValue;
        const res = await getProductivityData(projNo, '', currentTab, '');
        if (res?.pipelineEntryData) {

          const groupedByDate = res.pipelineEntryData.reduce((groups, obj) => {
            const date = obj.date;
            
            if (!groups[date]) {
              groups[date] = { ...dummyObject, date: obj.date };
            }
            const value = obj.project_pipelines_id;
            const shiftType = obj.shift === shift.day ? 'day' : 'night';
          
            groups[date][`${value}_${shiftType}`] = obj.value;
            groups[date][`${value}_overall`] = (toNumber(groups[date][`${value}_overall`]) || 0) + toNumber(obj.value);
          
            return groups;
          }, {});

          const finalData = Object.values(groupedByDate)
            .sort((a, b) => new Date(b.date) - new Date(a.date));
          
          setDataSource(finalData);
        } else {
          setDataSource([])
        }
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };

  const getProductiveComments = async (tab) => {
    try {
      if (projNo) {
        const currentTab = tab || tabValue;
        const res = await getProductivityData(projNo, '', currentTab, '');
        if (res?.summaryCommentData) {
          const comments = res?.summaryCommentData
          .filter((item) => item.productivity_comments)

          const groupedByDate = groupArrayByField(comments, 'date');
          const commentData = Object.keys(groupedByDate).map((
            item
          ) => { 
            const tempObject = { date: item };
            const dayData = groupedByDate[item].find((element) => element.shift === shift.day) || {};
            const nightData = groupedByDate[item].find((element) => element.shift === shift.night) || {};
            tempObject.comments_day = dayData.productivity_comments || '';
            tempObject.comments_night = nightData.productivity_comments || '';
            return tempObject
          })
          setSummaryDataSource(commentData.sort((a,b) => new Date(b.date) - new Date(a.date)));
        } else {
          setSummaryDataSource([])
        }
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong.',
      });
    } finally {
      setLoading(false);
    }
  };

  const onTabChange = (activeKey) => {
    setTabValue(activeKey);
    if (activeKey !== 'Comments') getProductiveData(activeKey, objectFormat);
  };

  const onOk = () => {
    setPipelinesData([]);
    setDataSource([]);
    setSummaryDataSource([]);
    setVisible(false);
  };

  return (
    <>
      <Modal
        title="Productivity"
        className="welds-modal"
        centered
        visible={visible}
        width={1009}
        footer={[
          <Button key="submit" type="primary" onClick={onOk}>
            OK
          </Button>
        ]}
      >
        <div>
          <div className="flex-fill overflow-auto">
            <Tabs onChange={onTabChange} activeKey={tabValue} className="custom-tab h-100">
              <TabPane tab="Completed" key="Completed" value="Completed" checked />
              <TabPane tab="Accepted" key="Accepted" value="Accepted" />
              <TabPane tab="Hold Items" key="Hold Items" value="Hold Items" />
              <TabPane tab="Comments" key="Comments" value="Comments" />
            </Tabs>
          </div>
          {
        tabValue !== 'Comments'
          ? (
            <>
              <div className='d-flex flex-column align-items-end py-2'>
                <Switch
                  className="modal-switch"
                  checkedChildren="Graphical View"
                  unCheckedChildren="Tabular View"
                  onChange={(checked) => {
                    if (checked) {
                      setShowGraph(checked);
                    } else {
                      setShowGraph(checked);
                    }
                  }}
                />
              </div>
              { !showGraph ? (
                <Table
                dataSource={dataSource}
                className="table-with-button-assets"
                loading={{
                  indicator: <Spin />,
                  spinning: loading
                }}
                scroll={{ x: true }}
                style={{ whiteSpace: 'nowrap' }}
                pagination={false}
              >
                <Column title="Date" dataIndex="date" key="date" />
                {pipelinesData.length && pipelinesData.map((item) => 
                <ColumnGroup title={item?.name} align="center">
                  <Column title="Day" dataIndex={`${item.id.toString()}_day`} key={`${item.id.toString()}_day`} />
                  <Column title="Night" dataIndex={`${item.id.toString()}_night`} key={`${item.id.toString()}_night`} />
                  <Column title="Overall" dataIndex={`${item.id.toString()}_overall`} key={`${item.id.toString()}_overall`} />
                </ColumnGroup>
                )}
                </Table>
              ):(
                <Tabs className="custom-tab h-95">
                  {pipelinesData.length && pipelinesData.map((item,i) => 
                    <TabPane tab={item?.name} key={i}>
                      <ModalOffshoreSummaryGraph 
                      Reportdata={dataSource}
                      coloumnType={item.id.toString()} />
                    </TabPane>
                  )}
                </Tabs>
              )
            }
            </>
          )
          : (
            <Table
            dataSource={summaryDataSource}
            className="table-with-button-assets"
            loading={{
              indicator: <Spin />,
              spinning: loading
            }}
            scroll={{ x: true }}
            style={{ whiteSpace: 'nowrap' }}
            pagination={false}
          >
            <Column title="Date" dataIndex="date" key="date" />
             <ColumnGroup title="Comments" align="center">
              <Column title="Day" dataIndex="comments_day" key="comments_day" />
              <Column title="Night" dataIndex="comments_night" key="comments_night" />
            </ColumnGroup>
            </Table>
          )
        }
        </div>
      </Modal>
    </>

  );
};

export default ProductivityModalOverall;
