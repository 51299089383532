/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.dark.css';
import {
  Table, Tabs, notification,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import ReportingEmailPage from '../../Components/ReportingEmails';
import UerManagementPage from '../../Components/UserManagement';
import AdvisoryApproval from '../../Components/AdvisoryApproval';
import AdvisoryTab from '../../Components/AdvisoryTab';
import Document from '../../Components/Document';
import { authenticateCurrentUser } from '../../services/auth';

const { TabPane } = Tabs;

const AdminPage = () => {
  const history = useHistory();
  const [group, setGroup] = useState(false);
  const [currentSection, setCurrentSection] = useState('type');
  const [currentTab, setCurrentTab] = useState('1');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabValue = queryParams.get('tabValue');

  useEffect(() => {
    if (tabValue) setCurrentTab(tabValue);
  }, [tabValue]);

  useEffect(() => {
    authenticateCurrentUser()
      .then((data) => {
        if (data.signInUserSession.accessToken.payload["cognito:groups"] && data.signInUserSession.accessToken.payload["cognito:groups"].includes('crc-admin')) {
          setGroup(true);
        } else {
          notification.info({
            message: 'User created successfully. You are not added to any group. Please contact Vinay.Baburao@sbdinc.com.'
          });
          setGroup(false);
        }
      })
      .catch((error) => {
        setGroup(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (key) => {
    if (key === "3") { setCurrentSection('type'); }
    history.replace({
      pathname: window.location.pathname,
      search: `?tabValue=${key}`
    });
    setCurrentTab(key);
  };

  return (
    <div className="h-100 d-flex flex-column">
      {
        group ? (
          <div className="flex-fill overflow-auto">
            <Tabs
              className="custom-tab h-100"
              onChange={onChange}
              activeKey={currentTab}
              defaultActiveKey="1"
            >
              <TabPane tab="Configure Emails For Report" key="1" forceRender>
                <ReportingEmailPage />
              </TabPane>
              <TabPane tab="User Management" key="2" forceRender>
                <UerManagementPage />
              </TabPane>
              <TabPane tab="Advisory" key="3" className="advisory-tab" forceRender>
                <AdvisoryTab
                  currentSection={currentSection}
                  setCurrentSection={setCurrentSection}
                  currentTab={currentTab}
                />
              </TabPane>
              <TabPane tab="Advisory Approval" key="4" className="advisory-tab" forceRender>
                <AdvisoryApproval currentTab={currentTab} />
              </TabPane>
              <TabPane tab="Documents" key="5" className="advisory-tab" forceRender>
                <Document currentTab={currentTab} />
              </TabPane>
            </Tabs>
          </div>
        ) : <Table />
      }

    </div>
  );
};

export default AdminPage;
