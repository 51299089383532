import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { setUsers, setUserFailure } from '../state/action/userAction';
import { FETCH_USERS } from '../state/types';
import fetch from '../services/fetch';

import { BASE_URL } from '../config';

function* fetchUsers() {
  try {
    const res = yield fetch(`${BASE_URL}/user`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      throw new Error('Unauthorized');
    }
    const response = yield res.json();
    yield put(setUsers({ response }));
  } catch (error) {
    yield put(setUserFailure());
    notification.error({
      message: 'There was a problem fetching the app users.',
      description: `${error}`,
    });
  }
}

function* userWatcher() {
  yield takeLatest(FETCH_USERS, fetchUsers);
}

function* userSagas() {
  yield all([
    call(userWatcher),
  ]);
}

export default userSagas;
