import React, { useEffect, useState } from 'react';
import {
  Modal, Table, notification
} from 'antd';
import moment from 'moment';
import { getMembers } from '../services/teamMembers';
import { ADV_DATE_TABLE } from '../utils/constants';

const columns = [
  {
    title: 'Send By',
    dataIndex: 'sendBy',
    key: 'sendBy',
  },
  {
    title: 'Send Date',
    dataIndex: 'sendDate',
    key: 'sendDate',
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
  }];

function AdvAdvCommentsViewer(props) {
  const { isVisible, onOk, comments } = props;
  const [commentData, setCommentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getMemberDetails = async (userData) => {
    try {
      setIsLoading(true);
      const response = await getMembers(userData);
      setCommentData(response.data);
    } catch (error) {
      notification.error({
        message: error.message || 'Something went wrong.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (comments.length) {
      getMemberDetails(comments);
    } else {
      setCommentData([]);
    }
  }, [comments]);

  const data = commentData.map((item) => ({
    comment: item.comment,
    sendBy: item.name || '-',
    sendDate: moment(item.createdAt).format(ADV_DATE_TABLE)
  }));

  return (
    <Modal
      className="quality-modal"
      title="View User Comments"
      centered
      visible={isVisible}
      okText="Close"
      onOk={onOk}
      destroyOnClose
      width={750}
      cancelButtonProps={{ style: { display: 'none' } }}
    >

      <div className="ant-modal-body-adv">
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
        />
      </div>

    </Modal>
  );
}

export default AdvAdvCommentsViewer;
