/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Table, Spin } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';
import {
  func, arrayOf, objectOf, oneOfType, string, number,
  array, bool
} from 'prop-types';
import { getWireUsageReports } from '../state/action/wireUsageAction';
import * as constants from '../utils/constants';
import WireUsageModal from './WireUsageModal';

const columns = [
  {
    title: 'Created Time',
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center'
  },
  {
    title: 'PDF Report',
    key: 'pdfReport',
    dataIndex: 'pdfReport',
    render: (record) => {
      if (record) {
        return (
          <div>
            {' '}
            <a
              className="export-link"
              title="Download pdf report"
              target="_blank"
              rel="noopener noreferrer"
              href={record}
              // eslint-disable-next-line react/jsx-closing-bracket-location
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <span className="icon-16 mr-2">
                <svg>
                  <use xlinkHref="#export" />
                </svg>
              </span>
            </a>
          </div>
        );
        // eslint-disable-next-line brace-style
      }
      // eslint-disable-next-line no-else-return
      else {
        return <div>-</div>;
      }
    },
    align: 'center',
  },
  {
    title: 'CSV Report',
    key: 'report',
    dataIndex: 'report',
    render: (record) => {
      if (record) {
        return (
          <div>
            {' '}
            <a
              className="export-link"
              title="Download csv report"
              target="_blank"
              rel="noopener noreferrer"
              href={record}
              // eslint-disable-next-line react/jsx-closing-bracket-location
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <span className="icon-16 mr-2">
                <svg>
                  <use xlinkHref="#export" />
                </svg>
              </span>
            </a>
          </div>
        );
        // eslint-disable-next-line brace-style
      }
      // eslint-disable-next-line no-else-return
      else {
        return <div>-</div>;
      }
    },
    align: 'center',
  }

];

const WireUsage = ({
  onGetWireUsageReports, data, showLoader, projNo, reload, setWireUsageRep, wireUsageReport, refresh
}) => {
  const [wireUsageData, setwireUsageData] = useState([]);
  const [wireUsageModalVisible, setWireUsageModalVisible] = useState(false);
  const [createDate, setCreateDate] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [totalReports, setTotalReports] = useState(0);

  useEffect(() => {
    // refresh(false);
    // if (!reload) {
    if (projNo !== '') {
      onGetWireUsageReports({ projectNo: projNo });
    } else {
      setwireUsageData([]);
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projNo]);

  useEffect(() => {
    if (reload) {
      if (projNo) {
        onGetWireUsageReports({ projectNo: projNo });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    if (!reload) {
      const wires = [];
      const date = [];
      if (data !== []) {
        data.map((item) => {
          item.createdAt = item.createdAt.replaceAll('/', '-');
          if (!date.includes(moment(item.createdAt).format(constants.DATETIMESEC))) {
            date.push(moment(item.createdAt).format(
              constants.DATETIMESEC
            ));
            wires.push(item);
          }
        });
        setwireUsageData(wires);
      }
      setTotalReports(wires.length);
      setWireUsageRep(wires);
    } else {
      setwireUsageData(wireUsageReport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleTableChnage = (pagination,
    filters,
    sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const hideModal = () => {
    setWireUsageModalVisible(false);
  };

  return (
    <>
      <div className={wireUsageData.length > 0 ? 'h-85 overflow-y-hidden overflow-x-auto has-pointer' : 'h-85 overflow-y-hidden overflow-x-auto'}>
        <Table
          pagination={totalReports > 10 ? tableParams.pagination : false}
          columns={columns}
          dataSource={wireUsageData}
          className="h-85"
          scroll={{ x: wireUsageData.length > 0 ? 1200 : 0, y: '100%' }}
          loading={{
            indicator: <Spin />,
            spinning: showLoader
          }}
          onRow={(record) => ({
            onClick: (e) => {
              e.stopPropagation();
              setWireUsageModalVisible(true);
              setCreateDate(record.createdAt);
            },
          })}
          onChange={handleTableChnage}
        />
        {' '}

      </div>
      <WireUsageModal
        modalVisible={wireUsageModalVisible}
        hideModal={hideModal}
        createDate={createDate}
        data={data}
      />
    </>

  );
};

WireUsage.propTypes = {
  onGetWireUsageReports: func.isRequired,
  data: arrayOf(objectOf(oneOfType([string, number, array]))).isRequired,
  showLoader: bool.isRequired,
};

const mapStateToProps = ({ wireUsageReport }) => {
  const data = get(wireUsageReport, 'data', []);
  const showLoader = get(wireUsageReport, 'isLoading', false);
  return {
    data,
    showLoader,
  };
};

const mapDispatchToProps = {
  onGetWireUsageReports: getWireUsageReports
};

export default connect(mapStateToProps, mapDispatchToProps)(WireUsage);
